import React from 'react';
import { Container } from "react-bootstrap";
import './Footer.scss';



const Footer = () => {
  return (
    <>
    <footer className="footer admin">
        <Container fluid>
         <span>© {new Date().getFullYear()} SwapIT</span>
        </Container>
    </footer>
    </>
  )
}

export default Footer