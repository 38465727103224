import { useState } from 'react';
import { Form, Popover } from "react-bootstrap";
import { useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { RootState } from '../../../../app/store';
import { ErrorIcon, ExclamationIcon } from "../../../../assets/svgIcons/svgIcons";
import { NETWORKS } from '../../../../constant';
import { CommonService } from '../../../../services/commonService';
import CommonBtn from "../../CommonBtn/CommonBtn";
import './ImportedTokens.scss';

const ImportConfirmation = ({ callback, tokenDetails }: any) => {
    const userDetails = useSelector((state: RootState) => state.connect);
    const defaultNetworks = useSelector(
        (state: RootState) => state.connect.networksDetails
    );
    const [isChecked, setIsChecked] = useState(false)
    const LowRiskpopover = (
        <Popover className="commonPopover low-risk">
            <Popover.Body>
                <p>
                    Risk scan results are provided by a third party{" "}
                    <Link to="#" className="clr-green">
                        <u>AvengerDAO</u>
                    </Link>
                </p>
                <p>
                    It is a tool for indicative purposes only to allow users to check the
                    reference risk level of a BCIO Chain Smart Contract. Please do your
                    own research - interactions with any BCIO Chain Smart Contract is at
                    your own risk.
                </p>
                <p>
                    Learn more about risk rating{" "}
                    <Link to="#" className="clr-green">
                        <u>Here.</u>
                    </Link>
                </p>
            </Popover.Body>
        </Popover>
    );
    const checkHandler = () => {
        setIsChecked(!isChecked)
    }
    // handler to import locally  in redux
    const handlerToImportToken = async (e: any) => {
        e.preventDefault();
        callback()
    }
    return (
        <>
            <div className="ImportConfirm">
                <div className="ImportConfirmWarningBox">
                    <p>Anyone can create a {defaultNetworks && defaultNetworks?.chainType == "BSC" ? "BEP20" : "ERC20"} token on {defaultNetworks && defaultNetworks?.networkName} with any name, including creating fake versions of existing tokens and tokens that claim to represent projects that do not have a token.</p>
                    <p>If you purchase an arbitrary token, you may be unable to sell it back. </p>
                    <ErrorIcon />
                </div>
                <div className="ImportConfirmVia">
                    <ExclamationIcon /> Unknown source
                </div>
                <div className="ImportConfirmCoin">
                    <div className="left">
                        <p>{tokenDetails && tokenDetails?.name} ({tokenDetails && tokenDetails?.symbol})</p>
                        <span>{userDetails && userDetails?.walletAddress ? CommonService.custmizeAddress(tokenDetails?.address) : null}</span>
                        <Link target={'_blank'} to={`${defaultNetworks && defaultNetworks?.explorerUrl ? `${defaultNetworks?.explorerUrl}address/${tokenDetails?.address}` : null}`}>(View on {defaultNetworks && defaultNetworks?.chainType == NETWORKS[0].chainType ? "BscScan" : "Etherscan"})</Link>
                    </div>
                </div>
                <Form.Group className="mb-3 mt-5" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" checked={isChecked} onChange={checkHandler} label="I Understand" />
                </Form.Group>
                <CommonBtn type="button" className={`w-100  mt-2 ${isChecked ? '' : "disabled"}`} title="Import" onClick={(e: any) => handlerToImportToken(e)} />
            </div>
        </>
    )
}

export default ImportConfirmation