import Lottie from 'lottie-react';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BSC from '../../../../assets/icons/bsc-black.png';
import EmptyLottie from '../../../../assets/lottie/empty-file.json';
import { CopyIcon, ErrorIcon } from '../../../../assets/svgIcons/svgIcons';
import CommonBtn from '../../CommonBtn/CommonBtn';
import CommonModal from '../CommonModal/CommonModal';
import './YourWallet.scss';

const YourWallet = ({ show, handleClose }: { show?: boolean, handleClose?: () => void }) => {

    const [WalletAndTranstions, setWalletAndTranstions] = useState(true);
    const WalletTab = () => {
        setWalletAndTranstions(true);
    };
    const TransactionTab = () => {
        setWalletAndTranstions(false);
    };
    return (
        <CommonModal className="YourWalletModal" modalTitle="Your Wallet" show={show} onHide={handleClose}>
            <div className="YourWalletModalInner">
                <ul className="YourWalletModalInnerTabs">
                    <li className={WalletAndTranstions ? 'active' : ""} onClick={() => WalletTab()}><Link to="#">Wallet</Link></li>
                    <li className={WalletAndTranstions ? "" : 'active'} onClick={() => TransactionTab()}><Link to="#">Transactions</Link></li>
                </ul>
                {WalletAndTranstions
                    ?
                    <>
                        <Form.Group className='address_input'>
                            <Form.Label>Your Address</Form.Label>
                            <div className="address_input_inner">
                                <Form.Control disabled placeholder='' />
                                <button className="copy_icon">
                                    <CopyIcon />
                                </button>
                            </div>
                        </Form.Group>
                        <div className="LowBalance">
                            <strong>BSC Balance Low</strong>
                            <p>You Need BCIO for Transaction Fees</p>
                            <ErrorIcon />
                        </div>
                        <div className="TokenBtn">
                            <img src={BSC} alt="" /> BSC Smarts Chain
                        </div>
                        <ul className="Bal-Details">
                            <li><p>BSC Balance</p> <span>0.0</span></li>
                            <li><p>LUNI Balance</p> <span>0.0</span></li>
                        </ul>
                        <ul className="Bal-Details locked">
                            <li><p>No LUNI Locked</p> <span>0.0</span></li>
                        </ul>
                        <div className="LowBalance lock">
                            <p>Lock LUNI to enjoy the benefits of farm yield boosting. Participating in IFOs, Voting power Boosts, & so much more!</p>
                            <Link to="#">Go to Pools</Link>
                            <ErrorIcon />
                        </div>
                        <CommonBtn to="#" title="DISCONNECT WALLET" className="bgdark w-100 " /></>
                    :
                    <>
                        <div className="no-transaction">
                            <h4>No Recent Transactions</h4>
                            <div className="Lottie">
                                <Lottie
                                    animationData={EmptyLottie}
                                    loop={true}
                                    style={{ width: 192 }}
                                />
                            </div>
                            <CommonBtn title="CONTINUE" className="" onClick={handleClose} />
                        </div>
                    </>
                }
            </div>
        </CommonModal>
    )
}

export default YourWallet
