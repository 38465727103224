import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import MetaIcon from '../../../../assets/icons/metamask_icon.png';
import { CommonService } from '../../../../services/commonService';
import CommonBtn from "../../CommonBtn/CommonBtn";
import CommonModal from "../CommonModal/CommonModal";

const DisconnectAdminModal = ({ show, onHide, DisconectClick }: any) => {
    const walletAddress: any = useSelector((state: RootState) => state.connect.walletAddress);

    return (
        <>
            <CommonModal show={show} onHide={onHide} modalTitle="Disconnect Wallet" className="adminDisconnect">
                <img className="ConnectedNetwork" src={MetaIcon} alt="" />
                <h3>
                    Connected with MetaMask
                    <br />
                    {CommonService.custmizeAddress(walletAddress)}
                </h3>
                <CommonBtn title="Disconnect" className="" onClick={DisconectClick} />
            </CommonModal>
        </>
    )
}

export default DisconnectAdminModal