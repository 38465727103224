import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";
import { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAccount, useDisconnect, useNetwork, useSwitchNetwork } from "wagmi";
import { callContractGetMethod } from "../../../../app/actions/contract.action";
import { RootState, store } from "../../../../app/store";
import { InfoIcon, WalletIcon } from "../../../../assets/svgIcons/svgIcons";
import { NETWORKS } from "../../../../constant";
import {
  addUserDetails,
  removeUserDetails,
  saveNetworkDetails,
} from "../../../../features/connect.slice";
import { createInstance } from "../../../../services/ContractService";
import { CommonService } from "../../../../services/commonService";
import toaster from "../../../common/Toast/index";
import CommonBtn from "../../CommonBtn/CommonBtn";
import CustomTooltip from "../../CustomTooltip/CustomTooltip";
import "./ConnectWallet.scss";
import { bscTestnet } from "viem/chains";

type propTypes = {
  show?: boolean;
  handleClose?: any;
  handleRecentTxn?: any;
  admin?: boolean;
};

const ConnectWallet = (props: propTypes) => {
  const walletType = useSelector(
    (state: RootState) => state.connect.walletType
  );
  const defaultNetwork = useSelector(
    (state: RootState) => state.connect.networksDetails
  );
  const walletAddress = useSelector(
    (state: RootState) => state.connect.walletAddress
  );

  const { show, handleClose } = props;
  const dispatch: Dispatch<any> = useDispatch();
  const [wagmiClient, setWagmiClient] = useState<any>();
  const [show1, setshow1] = useState<any>(false);
  const [isValidNetwork, setIsValidNetwork] = useState<boolean>(false);

  const getDecimals = useCallback(
    async (value: any) => {
      // let values: any = NETWORKS.filter((items) => items?.chainType == value);
      let swapItAddress: any = await dispatch(
        callContractGetMethod("SwapIT", [], "master", false)
      );
      if (swapItAddress && swapItAddress != undefined) {
        let swapItDecimal: any = await dispatch(
          callContractGetMethod("decimals", [], "dynamic", false, swapItAddress)
        );
        if (swapItDecimal) {
          let swapItDecimals = 10 ** swapItDecimal;
          let obj: any = { ...value, swapItDecimals };
          await dispatch(
            saveNetworkDetails({
              networksDetails: obj,
            })
          );
        }
      }
    },
    [dispatch]
  );

  /**WEB3 MODAL SETUP */

  const { address, isDisconnected, connector, isConnected } = useAccount();
  const { disconnect } = useDisconnect();
  const { selectedNetworkId } = useWeb3ModalState();

  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetworkAsync } =useSwitchNetwork();

  //   const wallet = useSelector((state: any) => state.user.walletAddress)
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const prevSelectedNetworkIdRef: any = useRef(chain?.id ?? selectedNetworkId);
  const { open } = useWeb3Modal();

  const memoizedDisconnect = useCallback(async () => {
    console.log("1234", 1234);
    await dispatch(removeUserDetails());
    disconnect();
    // window.location.reload();
  }, [disconnect]);

  useEffect(() => {
    setNetworkDetails(chain?.id ?? selectedNetworkId);
    prevSelectedNetworkIdRef.current = chain?.id ?? selectedNetworkId;
    // connector?.switchChain?.(97);
    console.log("chain?.id", connector, connector?.getChainId(), chain?.id);
    // console.log("selectedNetworkId", selectedNetworkId);
  }, [chain?.id, walletAddress]);

  const setNetworkDetails = (value: any) => {
    try {
      let key = Number(value);
      if (key) {
        // console.log("key", value);
        let defaultNetwork = store.getState().connect?.networksDetails;
        if (prevSelectedNetworkIdRef.current != value) {
          switch (key) {
            case 97:
              dispatch(saveNetworkDetails({ networksDetails: NETWORKS[0] }));
              setIsValidNetwork(true);
              setTimeout(() => createInstance(), 2000);
              return true;
            case 11155111:
              dispatch(saveNetworkDetails({ networksDetails: NETWORKS[1] }));
              setIsValidNetwork(true);
              setTimeout(() => createInstance(), 2000);
              return true;
            case 421614:
              dispatch(saveNetworkDetails({ networksDetails: NETWORKS[2] }));
              setIsValidNetwork(true);
              setTimeout(() => createInstance(), 2000);
              return true;

            default:
              dispatch(saveNetworkDetails({ networksDetails: NETWORKS[0] }));
              console.log("1111111111111", 1111111111111);
              isConnected &&
                connector?.name != "WalletConnect" &&
                switchNetworkAsync?.(bscTestnet?.id);
              setIsValidNetwork(false);
              createInstance();
              return false;
          }
        } else {
          switch (key) {
            case 97:
              // getDecimals(NETWORKS[0])
              dispatch(saveNetworkDetails({ networksDetails: NETWORKS[0] }));
              setIsValidNetwork(true);
              createInstance();
              return true;
            case 11155111:
              dispatch(saveNetworkDetails({ networksDetails: NETWORKS[1] }));
              setIsValidNetwork(true);
              createInstance();
              return true;
            case 421614:
              dispatch(saveNetworkDetails({ networksDetails: NETWORKS[2] }));
              setIsValidNetwork(true);
              setTimeout(() => createInstance(), 2000);
              return true;

            default:
              dispatch(saveNetworkDetails({ networksDetails: NETWORKS[0] }));
              setIsValidNetwork(false);
              createInstance();
              return false;
          }
        }
      }
    } catch (error) {
      console.log("some error occured in setNetworkDetails");
    }
  };

  // useEffect(() => {
  //   console.log("selectedNetworkId", selectedNetworkId);
  // }, [selectedNetworkId]);

  useEffect(() => {
    if (address) {
      setIsConnecting(true);
      dispatch(addUserDetails({ walletAddress: address }));
      const debounceTimeout = setTimeout(() => {
        setIsConnecting(false);
      }, 1500);

      return () => clearTimeout(debounceTimeout);
    } else {
      dispatch(removeUserDetails());
    }
  }, [address, selectedNetworkId]);

  useEffect(() => {
    if (isDisconnected) {
      dispatch(removeUserDetails());
      createInstance();
      if (walletAddress) toaster.success("Wallet disconnected");
      // dispatch(
      //   addUserDetails({ walletType: "", walletAddress: "", isAdmin: false })
      // );
      memoizedDisconnect();
    }
  }, [isDisconnected]);
  return (
    <>
      <div className="tokenWalletbtn">
        {isValidNetwork ? (
          <w3m-network-button />
        ) : (
          <span>
            <CommonBtn
              className="ms-3 btnWrong"
              onClick={() => {
                open({ view: "Networks" });
              }}
              title="Wrong Network"
              icon={
                <CustomTooltip
                  placement="bottom"
                  icon={<InfoIcon />}
                  text="switch network in wallet or approve pending switch request"
                />
              }
            />
          </span>
        )}
        {walletAddress && !props?.admin ? (
          <Dropdown className="walletDrop connected">
            <Dropdown.Toggle>
              <WalletIcon />
              <span className="mobileHidden">
                {walletAddress
                  ? CommonService.custmizeAddress(walletAddress)
                  : "Connect"}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu renderOnMount={true}>
              {/* <Link
                to='profile'
                className="dropdown-item"
                // onClick={memoizedDisconnect}
              >
                Profile
              </Link> */}
              <Link
                to="#"
                className="dropdown-item"
                onClick={props?.handleRecentTxn}
              >
                Recent Transactions
              </Link>
              <Link
                to="#"
                className="dropdown-item"
                onClick={memoizedDisconnect}
              >
                Disconnect
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <div className="walletDrop not-connected">
            <button
              type="button"
              className="dropdown-toggle"
              onClick={() => {
                open();
              }}
            >
              <span className="mobileHidden">
                {walletAddress
                  ? CommonService.custmizeAddress(walletAddress)
                  : "Connect"}
              </span>{" "}
              <WalletIcon />
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ConnectWallet;
