import React, { memo, ReactNode } from 'react';
import { Table } from 'react-bootstrap';
import './CommonTable.scss';
import PerfectScrollbar from 'react-perfect-scrollbar'

type propTypes = {
    className?: string,
    children?: ReactNode,
}

const CommonTable = (props: propTypes) => {
    const { className, children } = props;
    return (
        <div className={`${className} common_table`}>
            <PerfectScrollbar>
                <Table>
                    {
                        children
                    }
                </Table>
            </PerfectScrollbar>
        </div>
    )
}

export default memo(CommonTable);
