import { Dispatch, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import { callApiGetMethod } from "../../app/actions/api.action";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../app/actions/contract.action";
import { setLoader } from "../../app/actions/loader.action";
import { RootState } from "../../app/store";
import SwapArrow from "../../assets/icons/swapArrow.svg";
import InputCustom from "../../component/common/Input/InputCustom";
import Toast from "../../component/common/Toast";
import CommonBtn from "../../component/ui/CommonBtn/CommonBtn";
import ConfirmSupplyModal from "../../component/ui/Modals/ConfirmSupplyModal/ConfirmSupplyModal";
import SelectToken from "../../component/ui/Modals/SelectToken/SelectToken";
import { DEFAULT_ADDRESS } from "../../constant";
import { liquidityHistory } from "../../features/swapAndLiquidity.slice";
import { CommonService } from "../../services/commonService";
import { ContractService } from "../../services/ContractService";

import { useAccount } from "wagmi";
import CustomOverlay from "../../component/common/CustomOverlay";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
type props = {
  slipPageData?: any;
  deadlineData?: any;
  selectedToImport?: any;
  data?: any;
  setFromSelected: any;
  setToSelected: any;
  fromSelected: any;
  toSelected: any;
  isRefreshed?: any;
};

export const YourLiquidity = ({
  slipPageData,
  deadlineData,
  selectedToImport,
  data,
  setFromSelected,
  setToSelected,
  fromSelected,
  toSelected,
  isRefreshed,
}: props) => {
  const [ApplySupply, setApplySupply] = useState(false);
  const setApplySupplyHandle = () => setApplySupply(true);
  const HandleSupply = () => setApplySupply(false);

  const dispatch: Dispatch<any> = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const defaultNetworks: any = useSelector(
    (state: RootState) => state.connect.networksDetails
  );
  const walletAddress: any = useSelector(
    (state: RootState) => state.connect.walletAddress
  );

  // const [fromSelected, setFromSelected] = useState<any>();
  // const [toSelected, setToSelected] = useState<any>();
  const [totalSupply, setTotalSupply] = useState<any>();
  const [sharePercentage, setSharePercentage] = useState<any>();
  const [inputType, setInputType] = useState<any>();
  const [toInput, setToInput] = useState<any>(0);
  const [reserveAmount, setReserveAmount] = useState<any>();
  const [fromInput, setFromInput] = useState<any>(0);
  const [isPairExist, setIsPairExist] = useState(true);
  const [tokenSelected, setTokenSelected] = useState<any>();
  const [tokenAmount, setTokenAmount] = useState<any>();
  const [amountTokenMin, setAmountTokenMin] = useState<any>();
  const [amountETHMin, setAmountETHMin] = useState<any>();
  const [ethAmount, setETHAmount] = useState<any>();
  const [fromTokenBalance, setFromTokenBalance] = useState<any>();
  const [toTokenBalance, setToTokenBalance] = useState<any>();
  const [tokenAtoTokenB, setTokenAtoTokenB] = useState<any>();
  const [tokenBtoTokenA, setTokenBtoTokenA] = useState<any>();
  const [allowanceTokenA, setAllowanceTokenA] = useState<any>();
  const [allowanceTokenB, setAllowanceTokenB] = useState<any>();
  const [isFromInsufficentBalance, setFromInsufficentBalance] = useState<any>();
  const [isToInsufficentBalance, setToInsufficentBalance] = useState<any>();
  const [showConnectWallet, setShowConnectWallet] = useState(false);

  const [showInputAmount, setShowInputAmount] = useState("");
  const [showOutputAmount, setShowOutputAmount] = useState("");
  const { connector } = useAccount();
  const { open } = useWeb3Modal();
  const { selectedNetworkId } = useWeb3ModalState();

  useEffect(() => {
    walletAddress && getTokenBalance();
  }, [isRefreshed, fromTokenBalance, toTokenBalance, walletAddress]);

  //show default selected dropdown based on current network and platform token
  useEffect(() => {
    if (defaultNetworks && defaultNetworks != null) {
      setTimeout(async () => await onIt(), 10);
    }
  }, [defaultNetworks]);

  const onIt = async () => {
    if (defaultNetworks) {
      let tokenList: any = await dispatch(
        callApiGetMethod(
          `GET_TOKEN`,
          {
            page: 1,
            limit: 10,
            chainType: defaultNetworks?.chainType,
          },
          false
        )
      );
      if (tokenList && tokenList?.data?.docs) {
        tokenList = tokenList?.data?.docs;
        let fromDefault, toDefault;
        if (!selectedToImport) {
          if (data) {
            fromDefault = tokenList.filter(
              (item: any) => item?.address == data?.tokenAddressA
            );
            toDefault = tokenList.filter(
              (item: any) => item?.address == data?.tokenAddressB
            );
          } else {
            fromDefault = tokenList.filter(
              (item: any) => item?.symbol == defaultNetworks?.symbol
            );
            toDefault = tokenList.filter(
              (item: any) => item.name.toLowerCase() == "swapIt".toLowerCase()
            );
          }
          if (fromDefault && fromDefault.length > 0) {
            setFromSelected(fromDefault[0]);
          }
          if (toDefault && toDefault.length > 0) {
            setToSelected(toDefault[0]);
          }
        }
      }
    }
  };

  useEffect(() => {
    if (selectedToImport) {
      setFromSelected(selectedToImport.fromSelected);
      setToSelected(selectedToImport.toSelected);
    }
  }, [selectedToImport]);

  useEffect(() => {
    if (defaultNetworks) {
      if (fromSelected != undefined && toSelected != undefined) {
        setValues();
      }
    }
  }, [toInput, fromInput]);

  const setValues = async () => {
    let decimals: number;
    let data: any = {};

    if (isPairExist) {
      if (inputType == "from") {
        if (fromInput) {
          decimals =
            fromSelected && fromSelected.decimals
              ? fromSelected.decimals
              : null;
          data.amount = CommonService.convertWithDecimal(fromInput, decimals);

          let amountOut = CommonService.toFixed(
            CommonService.divideBigNumber(
              CommonService.convertWithDecimal(
                data.amount,
                reserveAmount?.reserveOut
              ),
              reserveAmount?.reserveIn
            )
          );
          setToInput(
            CommonService.toFixed(
              CommonService.divideBigNumber(amountOut, toSelected?.decimals)
            )
          );
          setShowOutputAmount(
            CommonService.fixedToDecimal(
              CommonService.toFixed(
                CommonService.divideBigNumber(amountOut, toSelected?.decimals)
              ),
              Math.log10(toSelected?.decimals) - 2
            )
          );
          calculateShare(data.amount, amountOut);
        } else {
          setToInput("");
          resetInput();
        }
      } else if (inputType === "to") {
        if (toInput) {
          decimals =
            toSelected && toSelected.decimals ? toSelected.decimals : null;
          data.amount = CommonService.convertWithDecimal(toInput, decimals);
          let amountIn = CommonService.toFixed(
            CommonService.divideBigNumber(
              CommonService.convertWithDecimal(
                data?.amount,
                reserveAmount?.reserveIn
              ),
              reserveAmount?.reserveOut
            )
          );
          setFromInput(
            CommonService.toFixed(
              CommonService.divideBigNumber(amountIn, fromSelected?.decimals)
            )
          );
          setShowInputAmount(
            CommonService.fixedToDecimal(
              CommonService.toFixed(
                CommonService.divideBigNumber(amountIn, fromSelected?.decimals)
              ),
              Math.log10(fromSelected?.decimals) - 2
            )
          );
          calculateShare(amountIn, data.amount);
        } else {
          setFromInput("");
          resetInput();
        }
      }
      addLiquidityEth();
    } else {
      let decimals: number;
      let data: any = {};

      if (inputType === "to") {
        if (toInput) {
          decimals =
            toSelected && toSelected.decimals ? toSelected.decimals : null;
          data.amount = CommonService.toFixed(
            CommonService.convertWithDecimal(toInput, decimals)
          );
          setToInput(toInput);
        } else {
          setToInput("");
          resetInput();
        }
      } else if (inputType == "from") {
        if (fromInput) {
          decimals =
            fromSelected && fromSelected.decimals
              ? fromSelected.decimals
              : null;

          data.amount = CommonService.toFixed(
            CommonService.convertWithDecimal(fromInput, decimals)
          );

          setFromInput(fromInput);
        } else {
          setFromInput("");
          resetInput();
        }
      }
    }
  };

  //Input reset here
  const resetInput = () => {
    setSharePercentage("0");
    setTokenAtoTokenB("");
    setTokenBtoTokenA("");
  };

  //calculations of Share of Pool
  const calculateShare = (amountIn: any, amountOut: any) => {
    let share = 0;
    amountIn = CommonService.toFixed(amountIn);
    amountOut = CommonService.toFixed(amountOut);

    if (Number(amountIn) > 0 || Number(amountOut) > 0) {
      let liquidity: any = Math.trunc(
        Math.min(
          CommonService.divideBigNumber(
            CommonService.toFixed(
              CommonService.convertWithDecimal(amountIn, totalSupply)
            ),
            reserveAmount?.reserveIn
          ),
          CommonService.toFixed(
            CommonService.divideBigNumber(
              CommonService.toFixed(
                CommonService.convertWithDecimal(amountOut, totalSupply)
              ),
              reserveAmount?.reserveOut
            )
          )
        )
      );

      liquidity = CommonService.toFixed(liquidity);

      share = CommonService.fixedToDecimal(
        CommonService.toFixed(
          CommonService.divideBigNumber(
            CommonService.toFixed(
              CommonService.convertWithDecimal(liquidity, 100)
            ),
            Number(CommonService.addBigNumber(totalSupply, liquidity))
          )
        ),
        4
      );
    }
    setSharePercentage(share);
  };

  useEffect(() => {
    setIsPairExist(true);
    if (
      (toSelected != undefined || fromSelected != undefined) &&
      walletAddress
    ) {
      getTokenBalance();
    }
    if (defaultNetworks) {
      if (toSelected && fromSelected) {
        MultipleMethodForLiquidity();
      }
    }
  }, [toSelected, fromSelected]);

  useEffect(() => {
    if (defaultNetworks) {
      getCalculatedTokens();
    }
  }, [fromInput, toInput]);

  const MultipleMethodForLiquidity = async () => {
    await handlerToGetPairsAndReserves();
    await addLiquidityEth();
  };

  //get balance based on selection of coin type
  const getTokenBalance = async () => {
    const { ethereum } = window as any;
    let web3Insance: any = new Web3(ethereum);
    let currencyBalance: any = await web3Insance.eth.getBalance(walletAddress);
    if (fromSelected != undefined) {
      if (fromSelected && fromSelected?.type == "token") {
        const balance: any = await dispatch(
          callContractGetMethod(
            "balanceOf",
            [walletAddress],
            "dynamic",
            false,
            fromSelected?.address
          )
        );
        setFromTokenBalance(
          CommonService?.toFixed(
            CommonService.divideBigNumber(balance, fromSelected?.decimals)
          )
        );
      } else {
        setFromTokenBalance(
          CommonService?.toFixed(
            CommonService.divideBigNumber(
              currencyBalance,
              fromSelected?.decimals
            )
          )
        );
      }
    }

    if (toSelected != undefined) {
      if (toSelected && toSelected?.type == "token") {
        const balance: any = await dispatch(
          callContractGetMethod(
            "balanceOf",
            [walletAddress],
            "dynamic",
            false,
            toSelected?.address
          )
        );

        setToTokenBalance(
          CommonService?.toFixed(
            CommonService.divideBigNumber(balance, toSelected?.decimals)
          )
        );
      } else {
        let currencyBalance: any = await web3Insance.eth.getBalance(
          walletAddress
        );
        setToTokenBalance(
          CommonService?.toFixed(
            CommonService.divideBigNumber(currencyBalance, toSelected?.decimals)
          )
        );
      }
    }
  };

  //Liquidity to Eth(WETH)
  const addLiquidityEth = async () => {
    if (inputType !== "") {
      if (
        fromSelected &&
        fromSelected?.type == "token" &&
        toSelected &&
        toSelected?.type == "currency"
      ) {
        setTokenSelected(fromSelected?.address);
        let _tokenAmount = await CommonService.convertWithDecimal(
          fromInput,
          fromSelected?.decimals
        );
        await setTokenAmount(_tokenAmount);
        setAmountTokenMin(0);
        setETHAmount(
          CommonService.convertWithDecimal(toInput, toSelected?.decimals)
        );
        setAmountETHMin(0);
      } else if (
        fromSelected &&
        fromSelected?.type == "currency" &&
        toSelected &&
        toSelected?.type == "token"
      ) {
        setTokenSelected(toSelected?.address);
        let _tokenAmount = await CommonService.convertWithDecimal(
          toInput,
          toSelected?.decimals
        );
        await setTokenAmount(
          CommonService.convertWithDecimal(toInput, toSelected?.decimals)
        );
        setAmountTokenMin(0);
        setETHAmount(
          CommonService.convertWithDecimal(fromInput, fromSelected?.decimals)
        );
        setAmountETHMin(0);
      }
    }
  };
  //fucntion to calculate prices and pool share
  const getCalculatedTokens = async () => {
    await addLiquidityEth();

    let res, _res;
    if (Number(fromInput) > 0 && Number(toInput) > 0) {
      if (isPairExist) {
        res = CommonService.toFixed(
          CommonService.divideBigNumber(
            reserveAmount?.reserveIn * toSelected?.decimals,
            reserveAmount?.reserveOut * fromSelected?.decimals
          )
        );

        setTokenAtoTokenB(res);
        _res = CommonService.toFixed(
          CommonService.divideBigNumber(
            reserveAmount?.reserveOut * fromSelected?.decimals,
            reserveAmount?.reserveIn * toSelected?.decimals
          )
        );

        setTokenBtoTokenA(_res);
      } else {
        res = CommonService.toFixed(
          CommonService.divideBigNumber(fromInput, toInput)
        );

        setTokenAtoTokenB(res);
        _res = CommonService.toFixed(
          CommonService.divideBigNumber(toInput, fromInput)
        );

        setTokenBtoTokenA(_res);
        setSharePercentage(100);
      }
    } else {
      resetInput();
    }
  };

  //get pairs and reservers
  const handlerToGetPairsAndReserves = async () => {
    let temp: any = [];
    let reserveIn, reserveOut, res, _res;
    temp.push(toSelected?.address, fromSelected?.address);
    const result: any = await dispatch(
      callContractGetMethod("getPair", temp, "factory", false, "")
    );
    if (result && result !== DEFAULT_ADDRESS) {
      const getReserves: any = await dispatch(
        callContractGetMethod("getReserves", [], "dynamicPair", false, result)
      );

      const getTotalSupply: any = await dispatch(
        callContractGetMethod("totalSupply", [], "dynamicPair", false, result)
      );
      setTotalSupply(getTotalSupply);
      if (getReserves) {
        if (Number(fromSelected?.address) < Number(toSelected?.address)) {
          reserveIn = getReserves?._reserve0;
          reserveOut = getReserves?._reserve1;
        } else {
          reserveIn = getReserves?._reserve1;
          reserveOut = getReserves?._reserve0;
        }

        setReserveAmount({ reserveIn, reserveOut });
      }
      setIsPairExist(true);
    } else {
      setReserveAmount({ reserveIn, reserveOut });
      setIsPairExist(false);
    }
  };

  useEffect(() => {
    if (walletAddress) {
      checkAllowance(fromSelected).then((allow) => {
        setAllowanceTokenA(allow);
      });
      setFromInsufficentBalance(
        Number(fromInput) > Number(fromTokenBalance) ? true : false
      );
    }
  }, [fromSelected, fromInput]);

  useEffect(() => {
    if (walletAddress) {
      checkAllowance(toSelected).then((allow) => {
        setAllowanceTokenB(allow);
      });
      setToInsufficentBalance(
        Number(toInput) > Number(toTokenBalance) ? true : false
      );
    }
  }, [toSelected, toInput]);

  //Allowance and approval check here for Both TokenA and TokenB
  const checkAllowance = async (selected: any) => {
    if (selected && selected?.type == "token") {
      try {
        let contractAddress: any =
          defaultNetworks && defaultNetworks.routerAddress;
        const getAllowance: any = await dispatch(
          callContractGetMethod(
            "allowance",
            [walletAddress, contractAddress],
            "dynamic",
            false,
            selected?.address
          )
        );
        return getAllowance;
      } catch (error) {
        return error;
      }
    }
  };

  //Give approval here for Tokens
  const giveApproval = async (selected: any, allowValue: number) => {
    try {
      if (selected && selected?.type == "token") {
        dispatch(setLoader(true));
        let provider = await connector?.getProvider();
        let approve: any = await ContractService.giveTokenAllowance({
          provider,
          walletAddress,
          tokenAddress: selected?.address,
          allowanceValue: allowValue,
          contract: defaultNetworks.routerAddress,
        });
        dispatch(setLoader(false));
        return approve;
      }
    } catch (error: any) {
      if (error?.message) {
        dispatch(setLoader(false));
      }
    }
  };

  //Liquidity add here
  const addSupplyLiquidity = async () => {
    let _deadlineData = parseFloat(deadlineData);
    var now = new Date().getTime();
    let fromAmount = CommonService.convertWithDecimal(
      fromInput,
      fromSelected?.decimals
    );
    let toAmount = CommonService.convertWithDecimal(
      toInput,
      toSelected?.decimals
    );
    let timeValueOfDeadline: any = now / 1000 + _deadlineData * 60;
    let timeValue = Math.trunc(Number(timeValueOfDeadline));

    if (inputType !== "") {
      if (
        fromSelected &&
        fromSelected?.type == "token" &&
        toSelected &&
        toSelected?.type === "token"
      ) {
        let data = {
          fromSelected: fromSelected?.address,
          toSelected: toSelected?.address,
          fromAmount: fromAmount.toString(),
          toAmount: toAmount.toString(),
          amountFromMin: 0,
          amountToMin: 0,
          to: walletAddress,
          timeValueOfDeadline: timeValue.toString(),
        };
        liquidityTypeHandler("tokenToToken", inputType, data);
      } else if (
        (fromSelected &&
          fromSelected?.type == "token" &&
          toSelected &&
          toSelected?.type == "currency") ||
        (fromSelected &&
          fromSelected?.type == "currency" &&
          toSelected &&
          toSelected?.type == "token")
      ) {
        let dataForETH = {
          tokenSelected: tokenSelected,
          tokenAmount: tokenAmount.toString(),
          amountTokenMin: 0,
          amountETHMin: 0,
          to: walletAddress,
          timeValueOfDeadline: timeValue.toString(),
        };
        liquidityTypeHandler("tokenToEth", inputType, dataForETH);
      }
    }
  };

  const liquidityTypeHandler = async (
    type: string,
    inputType: string,
    data: any
  ) => {
    let method: string = "";
    switch (type) {
      case "tokenToToken":
        method = "addLiquidity";
        break;

      case "tokenToEth":
        method = "addLiquidityETH";
        break;
    }
    if (fromInput == "" && toInput == "") {
      Toast.error("Please enter valid amount");
    } else {
      let provider = await connector?.getProvider();
      let result: any;
      if (method == "addLiquidity") {
        result = await dispatch(
          callContractSendMethod(
            provider,
            method,
            Object.values(data),
            walletAddress,
            "router"
          )
        );
      } else if (method == "addLiquidityETH") {
        result = await dispatch(
          callContractSendMethod(
            provider,
            method,
            Object.values(data),
            walletAddress,
            "router",
            ethAmount
          )
        );
      }

      if (result && result?.status) {
        let data: any = {};
        data.txn_hash = result?.transactionHash;
        data.from = fromSelected?.symbol;
        data.to = toSelected?.symbol;
        data.amountOut = toInput;
        data.amountIn = fromInput;
        data.network = defaultNetworks?.networkName;
        data.type = "add";
        data.walletAddress = walletAddress;
        data.chainType = defaultNetworks?.chainType;
        //store txn hash on redux
        await dispatch(liquidityHistory(data));

        Toast.success("Liquidity added sucessfully");
        setFromInput("");
        setToInput("");
        setFromSelected("");
        setToSelected("");
        resetInput();
        setFromTokenBalance("");
      }
    }
  };

  const showApproveAndLiquidityButton = () => {
    if (defaultNetworks?.chainId != selectedNetworkId) {
      return (
        <Row className="align-items-center justify-content-between">
          <Col xs={12} sm>
            <CommonBtn
              title={"Switch Network"}
              onClick={() => open({ view: "Networks" })}
              type="button"
              className={` mainBtn mb-0 ${
                fromTokenBalance > 0 ? false : false
              }`}
              // disabled={fromTokenBalance > 0 ? false : true}
            />
          </Col>
        </Row>
      );
    } else
      return fromSelected != undefined &&
        toSelected != undefined &&
        Number(fromInput) > 0 &&
        Number(toInput) > 0 ? (
        <>
          <Row className="align-items-center justify-content-between">
            {Number(allowanceTokenA) <
              Number(
                CommonService.convertWithDecimal(
                  fromInput,
                  fromSelected?.decimals
                )
              ) &&
            !isFromInsufficentBalance &&
            !isToInsufficentBalance ? (
              <Col xs={12} sm>
                <CommonBtn
                  title={`Approve ${
                    fromSelected?.name ? fromSelected?.name : ""
                  }`}
                  type="button"
                  className=" mainBtn mb-0"
                  onClick={() => {
                    giveApproval(
                      fromSelected,
                      Number(
                        CommonService.convertWithDecimal(
                          fromInput,
                          fromSelected?.decimals
                        )
                      )
                    ).then(() => {
                      checkAllowance(fromSelected).then((allow) => {
                        setAllowanceTokenA(allow);
                      });
                    });
                  }}
                />
              </Col>
            ) : (
              ""
            )}
            {Number(allowanceTokenB) <
              Number(
                CommonService.convertWithDecimal(toInput, toSelected?.decimals)
              ) &&
            !isFromInsufficentBalance &&
            !isToInsufficentBalance ? (
              <Col xs={12} sm>
                <CommonBtn
                  title={`Approve ${toSelected?.name ? toSelected?.name : ""}`}
                  type="button"
                  className=" mainBtn mb-0"
                  onClick={() => {
                    giveApproval(
                      toSelected,
                      Number(
                        CommonService.convertWithDecimal(
                          toInput,
                          toSelected?.decimals
                        )
                      )
                    ).then(() => {
                      checkAllowance(toSelected).then((allow) => {
                        setAllowanceTokenB(allow);
                      });
                    });
                  }}
                />
              </Col>
            ) : (
              ""
            )}
          </Row>

          {isFromInsufficentBalance || isToInsufficentBalance ? (
            <CommonBtn
              title={"Insufficient Balance"}
              type="button"
              disabled={isFromInsufficentBalance || isToInsufficentBalance}
              className="btn-yellow mainBtn mb-0 btn-gray"
            />
          ) : Number(allowanceTokenA) <
              Number(
                CommonService.convertWithDecimal(
                  fromInput,
                  fromSelected?.decimals
                )
              ) ||
            Number(allowanceTokenB) <
              Number(
                CommonService.convertWithDecimal(toInput, toSelected?.decimals)
              ) ? (
            ""
          ) : (
            <CommonBtn
              title="SUPPLY"
              type="button"
              className="mainBtn mb-0"
              onClick={() => addSupplyLiquidity()}
            />
          )}
        </>
      ) : (
        <>
          <CommonBtn
            title={
              fromSelected && toSelected ? "Enter an amount" : "Select pair"
            }
            // title ={"Select Pair"}
            type="button"
            disabled={true}
            className="mainBtn mb-0"
          />
        </>
      );
  };

  useEffect(() => {
    setFromInput("");
    setToInput("");
    setFromTokenBalance("");
    setToTokenBalance("");
    resetInput();
  }, [fromSelected, toSelected]);

  return (
    <>
      <div className="SwapCardExchange">
        <Form action="#" className="cardForm">
          <Form.Group className="inputHandle">
            <div className="inputHandle_leftSide">
              <label>You Send</label>
              <SelectToken
                selected={fromSelected}
                disabled={toSelected}
                callback={setFromSelected}
              />
            </div>
            <div className="inputHandle_rightSide">
              {walletAddress && fromTokenBalance ? (
                <h6>
                  <span>Avail. Bal: </span>{" "}
                  {CommonService.cryptoDecimals(fromTokenBalance)}{" "}
                </h6>
              ) : null}
              <InputCustom
                type="text"
                className="mb-0 max-field"
                placeholder="0.0"
                //  value ={fromInput}

                value={
                  isPairExist
                    ? inputType === "from"
                      ? fromInput
                      : Number(toInput) > 0
                      ? showInputAmount
                      : ""
                    : fromInput
                }
                //    value ={inputType === 'from' ? fromInput: (Number(toInput) >0 ?showInputAmount:0)}

                step={"any"}
                name="amount"
                autoFocus={true}
                onChange={(e: any) => {
                  let value = e.target.value;

                  let validate = CommonService.allowOnlyNumberWithDecimals(
                    value,
                    fromSelected?.type,
                    fromSelected?.decimals
                  );
                  if (validate && fromSelected && toSelected) {
                    setFromInput(value);
                    setInputType("from");
                  }
                  setInputType("from");
                }}
                required
                // min={0}
                // maxlength={10}
              />
              {/* <p>$ 0.02341</p> */}
            </div>
            {fromSelected?.address != undefined ? (
              <Form.Label
                onClick={() => {
                  if (fromSelected && fromSelected.type == "currency") {
                    let _fromTokenBalance = fromTokenBalance - 0.005;
                    setFromInput(
                      CommonService.fixedToDecimal(_fromTokenBalance, 16)
                    );
                    setInputType("from");
                  } else {
                    setFromInput(
                      CommonService.fixedToDecimal(
                        fromTokenBalance,
                        Math.log10(fromSelected?.decimals) - 2
                      )
                    );
                    setInputType("from");
                  }
                }}
              >
                Max
              </Form.Label>
            ) : null}
          </Form.Group>
          {/* <div className="inputHandleBottom">
            <div className="inputHandleBottomLeft"></div>
            <div className="inputHandleBottomRight">
              {fromTokenBalance ? (
                <p>
                  Avail. Bal:{" "}
                  <span>{CommonService.cryptoDecimals(fromTokenBalance)} </span>
                </p>
              ) : null}
            </div>
          </div> */}
          <div className="spacing"></div>
          {/* swap btn */}
          <div className="SwapBtn mb-3">
            {/* <button type="button">
              <img src={SwapArrow} alt="icon" />
            </button> */}
          </div>
          {/* swap btn */}
          {/* <div className="inputHandleTop">
            <div className="inputHandleTopLeft">
              <p>To</p>
            </div>
          </div> */}
          <Form.Group className="inputHandle">
            <div className="inputHandle_leftSide">
              <label>You Send</label>
              <SelectToken
                selected={toSelected}
                callback={setToSelected}
                disabled={fromSelected}
              />
            </div>
            <div className="inputHandle_rightSide">
              {walletAddress && toTokenBalance ? (
                <h6>
                  <span>Avail. Bal: </span>{" "}
                  {CommonService.cryptoDecimals(toTokenBalance)}
                </h6>
              ) : null}
              <InputCustom
                type="text"
                className="mb-0 max-field"
                placeholder="0.0"
                smallTextClass="text-red"
                // value={toInput}
                // value ={inputType === 'to' ? toInput  :(Number(fromInput) >0?showOutputAmount :0)}
                value={
                  isPairExist
                    ? inputType === "to"
                      ? toInput
                      : Number(fromInput) > 0
                      ? showOutputAmount
                      : ""
                    : toInput
                }
                step={"any"}
                name="amount"
                autoFocus={true}
                onChange={(e: any) => {
                  let value = e.target.value;
                  let validate = CommonService.allowOnlyNumberWithDecimals(
                    value,
                    toSelected?.type,
                    toSelected?.decimals
                  );
                  if (validate && fromSelected && toSelected) {
                    setToInput(value);
                    setInputType("to");
                  }
                  setInputType("to");
                }}

                // smallText={(fromSelected != undefined && toSelected != undefined) && 'Please select Currency or token'}

                // min={0}11
                // maxlength={10}
              />
              {/* <p>$ 0.02341</p> */}
            </div>

            {toSelected?.address != undefined ? (
              <Form.Label
                onClick={async () => {
                  if (toSelected && toSelected.type == "currency") {
                    let _toTokenBalance = toTokenBalance - 0.005;
                    setToInput(
                      CommonService.fixedToDecimal(_toTokenBalance, 16)
                    );
                    setInputType("to");
                  } else {
                    setToInput(
                      CommonService.fixedToDecimal(
                        toTokenBalance,
                        Math.log10(toSelected?.decimals) - 2
                      )
                    );
                    setInputType("to");
                  }
                }}
              >
                Max
              </Form.Label>
            ) : null}
          </Form.Group>
          <div className="inputHandleBottom">
            <div className="inputHandleBottomLeft"></div>
            <div className="inputHandleBottomRight">
              {/* {toTokenBalance ? (
                <>
                  <p>
                    Avail. Bal:{" "}
                    <span>
                      {" "}
                      {CommonService.cryptoDecimals(toTokenBalance)}{" "}
                    </span>
                  </p>
                </>
              ) : null} */}
            </div>
          </div>
          <p className="titleBottom">
            Select Your Token/Currency before adding Liquidity.
          </p>
          {toSelected && fromSelected ? (
            <div className="Price-n-Pool">
              <p>Prices and Pool Share</p>
              <ul>
                <li>
                  <div className="d-table">
                    <p>
                      {tokenAtoTokenB ? (
                        <>
                          {CommonService.fixedToDecimal(tokenAtoTokenB)}{" "}
                          <CustomOverlay value={tokenAtoTokenB} />
                        </>
                      ) : (
                        "--"
                      )}
                    </p>

                    <span>
                      {fromSelected?.name ? fromSelected?.name : "--"} Per{" "}
                      {toSelected?.name ? toSelected?.name : "--"}{" "}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-table mx-auto">
                    <p>
                      {tokenBtoTokenA ? (
                        <>
                          {CommonService.fixedToDecimal(tokenBtoTokenA)}{" "}
                          <CustomOverlay value={tokenBtoTokenA} />
                        </>
                      ) : (
                        "--"
                      )}
                    </p>
                    <span>
                      {toSelected?.name ? toSelected?.name : "--"} Per{" "}
                      {fromSelected?.name ? fromSelected?.name : "--"}{" "}
                    </span>
                  </div>
                </li>
                <li>
                  <div className="d-table ms-auto">
                    <p>{sharePercentage ? sharePercentage : 0}%</p>{" "}
                    <span>Share of Pool</span>
                  </div>
                </li>
              </ul>
            </div>
          ) : (
            ""
          )}

          {walletAddress ? (
            showApproveAndLiquidityButton()
          ) : (
            <CommonBtn
              title="CONNECT WALLET"
              onClick={() => open()}
              className="bgdark mainBtn"
              type="button"
            />
          )}
        </Form>
      </div>

      {/* <ConnectWallet
        show={showConnectWallet}
        handleClose={() => setShowConnectWallet(false)}
      /> */}
      <ConfirmSupplyModal show={ApplySupply} onHide={HandleSupply} />
    </>
  );
};
