import { Outlet } from "react-router-dom";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import './MainLayout.scss';

const MainLayout = () => {
    return (
        <>
            <Header />
            <main className="mainHandle">
                <Outlet />
            </main>
            <Footer />
        </>
    )
}

export default MainLayout