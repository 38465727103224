import React, { Dispatch, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../../app/actions/contract.action";
import { setLoader } from "../../../../app/actions/loader.action";
import { RootState } from "../../../../app/store";
import { liquidityHistory } from "../../../../features/swapAndLiquidity.slice";
import { CommonService } from "../../../../services/commonService";
import { getRemoveLiquiditySignature } from "../../../../services/signatureService";
import CustomOverlay from "../../../common/CustomOverlay";
import Toast from "../../../common/Toast";
import CommonBtn from "../../CommonBtn/CommonBtn";
import CommonModal from "../CommonModal/CommonModal";
import "./RemoveToken.scss";
import { useAccount } from "wagmi";

const RemoveToken = ({
  showToken,
  tokenClose,
  deadline,
  liquidityData,
  getLiquidityList,
  slipPage,
}: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const { connector } = useAccount();
  const defaultNetworks: any = useSelector(
    (state: RootState) => state.connect.networksDetails
  );
  const walletAddress: any = useSelector(
    (state: RootState) => state.connect.walletAddress
  );
  const [vrsData, setVRSData] = useState<any>();
  const [token0Amount, setToken0Amount] = useState<any>();
  const [token1Amount, setToken1Amount] = useState<any>();
  const [lpAmountToRemove, setLPAmountToRemove] = useState<any>();
  const [deadlineValue, setDeadlineValue] = useState<any>();

  const [range, setRange] = useState(25);

  let _deadlineData = parseFloat(deadline);
  var now = new Date().getTime();
  let timeValueOfDeadline: any = now / 1000 + _deadlineData * 60;
  let timeValue = Math.trunc(Number(timeValueOfDeadline));

  const changeWidth = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRange(parseInt(event.target.value));
  };

  useEffect(() => {
    if (showToken) {
      updateRange(range);
    }
  }, [showToken, range]);

  const updateRange = async (value: any) => {
    let liqRemoveValue = CommonService.convertWithDecimal(
      liquidityData?.lpBalance,
      value
    );
    let lpRemoveAmount;
    if (value < 100) {
      lpRemoveAmount = parseInt(
        CommonService.toFixed(
          CommonService.divideBigNumber(liqRemoveValue, 100)
        )
      );
      lpRemoveAmount = CommonService.toFixed(lpRemoveAmount);
    } else {
      lpRemoveAmount = liquidityData?.lpBalance;
    }
    setLPAmountToRemove(lpRemoveAmount);
    let token0Amt = CommonService.toFixed(
      CommonService.divideBigNumber(
        CommonService.convertWithDecimal(
          lpRemoveAmount,
          liquidityData?.reserve0
        ),
        liquidityData?.getTotalSupply
      )
    );
    let token1Amt = CommonService.toFixed(
      CommonService.divideBigNumber(
        CommonService.convertWithDecimal(
          lpRemoveAmount,
          liquidityData?.reserve1
        ),
        liquidityData?.getTotalSupply
      )
    );

    setToken0Amount(token0Amt);
    setToken1Amount(token1Amt);
    setVRSData("");
  };

  const signTransaction = async () => {
    let provider = await connector?.getProvider();

    const userNonces: any = await dispatch(
      callContractGetMethod(
        "nonces",
        [walletAddress],
        "dynamicPair",
        false,
        liquidityData?.pair
      )
    );
    setDeadlineValue(timeValue);
    dispatch(setLoader(true));
    const vrs: any = await getRemoveLiquiditySignature({
      provider,
      walletAddress,
      pairAddress: liquidityData?.pair,
      liquidity: lpAmountToRemove.toString(),
      deadLine: timeValue,
      spender: defaultNetworks?.routerAddress,
      chainId: defaultNetworks?.chainId,
      nonces: userNonces,
    });
    setVRSData(vrs);
    if (!vrs._vs) {
      setVRSData("");
    }
    dispatch(setLoader(false));
  };

  const removeLiquidityList = async () => {
    let result: any;
    let provider = await connector?.getProvider();
    if (
      liquidityData?.nativeCurrencyTokenA ||
      liquidityData?.nativeCurrencyTokenB
    ) {
      let tokenAddress;
      if (liquidityData?.nativeCurrencyTokenA) {
        tokenAddress = liquidityData?.address1;
      } else {
        tokenAddress = liquidityData?.address0;
      }
      let removeLiqData = {
        token: tokenAddress,
        liquidity: lpAmountToRemove.toString(),
        amountTokenMin: 0,
        amountETHMin: 0,
        to: walletAddress,
        deadline: deadlineValue,
        approveMax: false,
        v: vrsData.v,
        r: vrsData.r,
        s: vrsData.s,
      };
      result = await dispatch(
        callContractSendMethod(
          provider,
          "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
          Object.values(removeLiqData),
          walletAddress,
          "router"
        )
      );
    } else {
      let removeLiqData = {
        tokenA: liquidityData?.address0,
        tokenB: liquidityData?.address1,
        liquidity: lpAmountToRemove.toString(),
        amountAMin: 0,
        amountBMin: 0,
        to: walletAddress,
        deadline: deadlineValue,
        approveMax: false,
        v: vrsData.v,
        r: vrsData.r,
        s: vrsData.s,
      };

      result = await dispatch(
        callContractSendMethod(
          provider,
          "removeLiquidityWithPermit",
          Object.values(removeLiqData),
          walletAddress,
          "router"
        )
      );
    }
    if (result && result?.status) {
      let data: any = {};
      data.txn_hash = result?.transactionHash;
      data.name0 = liquidityData?.name0;
      data.name1 = liquidityData?.name1;
      data.amountIn = token0Amount / liquidityData?.decimals0;
      data.amountOut = token1Amount / liquidityData?.decimals1;
      data.network = defaultNetworks?.networkName;
      data.type = "remove";
      data.walletAddress = walletAddress;
      data.chainType = defaultNetworks?.chainType;
      //store txn hash on redux
      await dispatch(liquidityHistory(data));
      Toast.success("Removed Liquidity Sucessfully");
      tokenClose();
      getLiquidityList();
    }
  };
  return (
    <>
      <CommonModal
        className="RemoveTokenModal"
        modalTitle="Remove Token"
        show={showToken}
        onHide={tokenClose}
      >
        <ul className="ValueDesign">
          <li>
            <p>Amount</p>{" "}
            <div className="right">
              <span>
                <strong>Detailed</strong>
              </span>
            </div>
          </li>
        </ul>
        <div className="CustomRange">
          <h2>{range}%</h2>
          <div className="CustomRangeHandle">
            <span className="Bar" style={{ width: `${range}%` }}></span>
            <Form.Range value={range} onChange={changeWidth} />
          </div>
          <Row>
            <Col>
              <button
                className={range == 25 ? "active" : ""}
                onClick={() => {
                  setRange(25);
                }}
              >
                <span className="texttitle">25%</span>
              </button>
            </Col>
            <Col>
              <button
                className={range == 50 ? "active" : ""}
                onClick={() => {
                  setRange(50);
                }}
              >
                <span className="texttitle">50%</span>
              </button>
            </Col>
            <Col>
              <button
                className={range == 75 ? "active" : ""}
                onClick={() => {
                  setRange(75);
                }}
              >
                <span className="texttitle">75%</span>
              </button>
            </Col>
            <Col>
              <button
                className={range == 100 ? "active" : ""}
                onClick={() => {
                  setRange(100);
                }}
              >
                <span className="texttitle">100%</span>
              </button>
            </Col>
          </Row>
        </div>
        <p className="mt-5">Receive</p>
        <ul className="ValueDesign pb-4">
          <li>
            <div className="left">
              <img src={liquidityData?.icon0} alt="" className="pairOne" />
              <p>
                {liquidityData?.nativeCurrencyTokenA
                  ? liquidityData?.nativeCurrencyTokenA
                  : liquidityData?.name0}
              </p>
            </div>
            <div className="right">
              <span>
                {token0Amount ? (
                  <>
                    {CommonService.cryptoDecimals(
                      CommonService?.toFixed(
                        CommonService.divideWithDecimal(
                          token0Amount,
                          liquidityData?.decimals0
                        )
                      )
                    )}
                    <CustomOverlay
                      value={CommonService?.toFixed(
                        CommonService.divideWithDecimal(
                          token0Amount,
                          liquidityData?.decimals0
                        )
                      )}
                    />
                  </>
                ) : (
                  0
                )}
              </span>
            </div>
          </li>
          <li>
            <div className="left">
              <img src={liquidityData?.icon1} alt="" className="pairOne" />
              <p>
                {liquidityData?.nativeCurrencyTokenB
                  ? liquidityData?.nativeCurrencyTokenB
                  : liquidityData?.name1}
              </p>
            </div>
            <div className="right">
              <span className="clr-green">
                {token1Amount ? (
                  <>
                    {CommonService.cryptoDecimals(
                      CommonService?.toFixed(
                        CommonService?.divideBigNumber(
                          token1Amount,
                          liquidityData?.decimals1
                        )
                      )
                    )}
                    <CustomOverlay
                      value={CommonService?.toFixed(
                        CommonService?.divideBigNumber(
                          token1Amount,
                          liquidityData?.decimals1
                        )
                      )}
                    />
                  </>
                ) : (
                  0
                )}
              </span>
            </div>
          </li>
        </ul>
        <hr />
        <Row className="pt-5">
          {token0Amount >= 0 && token1Amount >= 0 && vrsData ? (
            <Col>
              <CommonBtn title="Enable" className={`w-100  ${"disabled"}`} />
            </Col>
          ) : (
            <Col>
              <CommonBtn
                title="Enable"
                className={`w-100  ${""}`}
                onClick={signTransaction}
              />
            </Col>
          )}
          {token0Amount >= 0 && token1Amount >= 0 && vrsData ? (
            <Col>
              <CommonBtn
                title="Remove"
                className={`w-100 `}
                onClick={() => {
                  removeLiquidityList();
                }}
              />
            </Col>
          ) : (
            <Col>
              <CommonBtn title="Remove" className={`w-100  ${"disabled"}`} />
            </Col>
          )}
        </Row>
      </CommonModal>
    </>
  );
};
export default RemoveToken;
