import { Form } from "react-bootstrap";
import { allowOnlyString, CommonService } from "../../../services/commonService";
/**CUSTOM COMMON INPUT FIELD WITH DYNAMIC PROPS*/
const InputCustom = (props: any) => {

  /**RESTRICT USER TO ENTER e, E, +, -, . IN INPUT TYPE NUBER */
  const exceptThisSymbols = ["e", "E", "+", "-"];
  const onKeyDown = (e: any) => {
    if (props.disableDecimal) {
      exceptThisSymbols.push(".");
    }

    /**RESTRICT USER TO ENTER MORE THEN MAX LENGTH IN INPUT TYPE NUBER */
    return props.type === "number" ?
      (exceptThisSymbols.includes(e.key) || (e.key != 'Backspace' && props.maxlength && e.target.value.length === props.maxlength)) && e.preventDefault() :
      props.onlyChar ? !allowOnlyString(e.key) && e.preventDefault() : null;
  }

  const onPast=(e:any)=>{
    if(props.type=='number'){

    }else{
      return props.onlyChar ? e.preventDefault() : props.onChange
    }
  }

  return (
    <>
      <Form.Group
        className={`customInput ${props.className}`}
        controlId={props.controlId}
      >
        {props.label ? <label className={props.classLabel}>{props.label}</label> : ''}
        <Form.Control
          onFocus={props.onChange}
          autoFocus={props.autoFocus}
          disabled={props.disabled}
          type={props.type}
          id={props.id}
          name={props.name}
          value={props.value}
          onKeyDown={onKeyDown}
          placeholder={props.placeholder}
          onBlur={props.onBlur}
          onChange={props.onChange}
          maxLength={props.maxLength ? props.maxLength : ""}
          required={props.required}
          min={props.min}
          max={props.max}
          isInvalid={props.isInvalid}
          onPaste={(e) => onPast(e)}
          onWheel={props.onWheel}
          step={props.step ? props.step : "any"}
          autoComplete={props.onlyChar ? props.autoComplete : "off"}
          pattern="\S(.*\S)?"
          title={props.title ? props.title : "Blank space are not allowed"}
          onInvalid={props.onInvalid}
          onInput={props.onInput}
          className={props.inputtext}
        />

        {props.children}
        {props.smallText ? (
          <Form.Text id="" muted className={`small-text-form ${props.smallTextClass}`}  >
            {props.smallText}
          </Form.Text>
        ) : (
          ""
        )}
      </Form.Group>
    </>
  );
};
export default InputCustom;