import { Dispatch } from 'react';
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../app/store';
import { removeUserDetails } from '../../../../features/connect.slice';
import Toast from '../../../common/Toast';
import CommonBtn from "../../CommonBtn/CommonBtn";
import CommonModal from "../CommonModal/CommonModal";

const AdminLogoutModal = ({ showLogout, HideLogout, CancelClick, ConfirmClick }: any) => {
    const walletAddress = useSelector((state: RootState) => state.connect.walletAddress);
    const dispatch: Dispatch<any> = useDispatch();
    const navigate = useNavigate();

    const handleDisconnect = () => {
        if (walletAddress) {
            dispatch(removeUserDetails());
            navigate("/admin");
            Toast.success('Logout successfully');
        }
    }

    return (
        <>
            <CommonModal show={showLogout} onHide={HideLogout} modalTitle="Logout" className="Logout">
                <h3 className="py-5 my-4">
                    Are you sure you want to Logout.
                </h3>
                <Row>
                    <Col><CommonBtn title="Cancel" className=" w-100" onClick={CancelClick} /></Col>
                    <Col><CommonBtn title="Confirm" className=" w-100" onClick={handleDisconnect} /></Col>
                </Row>
            </CommonModal>
        </>
    )
}

export default AdminLogoutModal