import queryString from 'query-string';
import { Dispatch, useEffect, useRef, useState } from 'react';
import { Container, Nav, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { RootState } from '../../../app/store';
import Logo from '../../../assets/images/logo.png';
import {
    MenuFarms, MenuFarmsactive, MenuLiquidity, MenuLiquidityactive, MenuPools,
    MenuPoolsactive, MenuReferral, MenuSwap, MenuSwapactive
} from "../../../assets/svgIcons/svgIcons";
import { removeUserDetails } from '../../../features/connect.slice';
import ConnectWallet from '../../ui/Modals/ConnectWallet/ConnectWallet';
import RecentTransactions from '../../ui/Modals/RecentTransactions/RecentTransactions';
import YourWallet from '../../ui/Modals/YourWallet/YourWallet';
import Toast from '../Toast';
import './Header.scss';


const Header = () => {

    const dispatch: Dispatch<any> = useDispatch();
    const [show, setShow] = useState(false);
    const [WalletStatus, setWalletStatus] = useState(true);
    const [backdrop, setBackdrop] = useState(false);
    const [active, setActive] = useState(1);

    const [serachQuery, setSerachQuery] = useState<any>('');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [yourWallet, setyourWallet] = useState(false);
    const YourWalletShow = () => setyourWallet(true);
    const handleCloseWallet = () => setyourWallet(false);
    const [wagmiClient, setWagmiClient] = useState<any>();

    const walletAddress = useSelector((state: RootState) => state.connect.walletAddress);
    const walletType = useSelector((state: RootState) => state.connect.walletType);
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams()



    useEffect(() => {
        // execute on location change
        let params = queryString.parse(location.search);
        if (params.ref) {
            setSerachQuery(params)
        } else {
            if (serachQuery && serachQuery.ref) {
                setSearchParams(`?${new URLSearchParams(serachQuery)}`)
            }
        }
    }, [location]);

    const MenuList = [
        { id: 1, icon: <MenuSwap />, activeicon: <MenuSwapactive />, menu: "Swap", to: "/" },
        { id: 2, icon: <MenuLiquidity />, activeicon: <MenuLiquidityactive />, menu: "Liquidity", to: "liquidity" },
        { id: 3, icon: <MenuFarms />, activeicon: <MenuFarmsactive />, menu: "Farms", to: "farms" },
        { id: 4, icon: <MenuPools />, activeicon: <MenuPoolsactive />, menu: "Pools", to: "pools" },
        { id: 5, icon: walletAddress ? <MenuReferral /> : "", activeicon: walletAddress ? <MenuPoolsactive /> : '', menu: walletAddress ? "Referral" : "", to: walletAddress ? "referral" : "" },
    ]

    // Recent Transactions
    const [RecentModalShow, setRecentModalShow] = useState(false);
    const RecentModalHandle = () => setRecentModalShow(true);
    const RecentModalClose = () => setRecentModalShow(false);
    const myRefname: any = useRef(null);

    const handleSidebar = () => { // function for handling sidebar
        if (document.body.clientWidth < 991) {
            myRefname.current.click();
        }
    }


    const handleDisconnect = async () => {
        await dispatch(removeUserDetails());
        Toast.success(`${walletType == 'WALLET_CONNECT' ? "Wallet connect" : "Metamask"} disconnected successfully`);
        window.location.reload();
    }

    let navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [navigate]);

    return (
        <>
            <div onClick={handleSidebar} className={`sidebarBackdrop d-lg-none ${backdrop ? 'active' : ''}`} />
            <header className="header">
                <Navbar expand="lg">
                    <Container fluid>
                        <Link to="/" className="navbar-brand"><img src={Logo} alt="" /></Link>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                {MenuList?.map((item
                                    , index) => (
                                    <NavLink
                                        key={index}
                                        to={item.to}
                                        onClick={() => { handleSidebar(); setActive(item.id); }}
                                        className="nav-link">
                                        {active === item.id ? <>{item.activeicon}</> : <>{item.icon}</>}

                                        {item.menu}
                                    </NavLink>
                                ))}
                            </Nav>
                        </Navbar.Collapse>
                        <div className="header-action">
                            <ConnectWallet handleRecentTxn={RecentModalHandle} />
                            {/* <NetworkSelector /> */}
                            {/* <w3m-network-button />
                            {walletAddress
                                ?
                                <Dropdown className="walletDrop connected">
                                    <Dropdown.Toggle>
                                        <WalletIcon /><span className="mobileHidden">{walletAddress ? CommonService.custmizeAddress(walletAddress) : 'Connect'}</span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu renderOnMount={true}>
                                        <Link to="#" className="dropdown-item" onClick={() => RecentModalHandle()}>Recent Transactions</Link>
                                        <Link to="#" className="dropdown-item" onClick={() => { handleDisconnect() }}>Disconnect</Link>
                                    </Dropdown.Menu>
                                </Dropdown>
                                :
                                <div className="walletDrop not-connected">
                                    <button type="button" className="dropdown-toggle" onClick={handleShow}>
                                        <span className="mobileHidden">{walletAddress ? CommonService.custmizeAddress(walletAddress) : "Connect"}</span> <WalletIcon />
                                    </button>
                                </div>
                            } */}

                            <Navbar.Toggle onClick={() => setBackdrop(!backdrop)} aria-controls="basic-navbar-nav" ref={myRefname}>
                                <span className="menu-line"></span>
                                <span className="menu-line"></span>
                                <span className="menu-line"></span>
                            </Navbar.Toggle>
                        </div>
                    </Container>
                </Navbar>
            </header>
            {/* <ConnectWallet show={show} handleClose={handleClose} /> */}
            <RecentTransactions type="connectHistory" show={RecentModalShow} handleClose={RecentModalClose} />
            <YourWallet show={yourWallet} handleClose={handleCloseWallet} />
        </>
    )
}
export default Header


