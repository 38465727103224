import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import "./CustomTooltip.scss"
import { memo } from 'react'

const CustomTooltip = ({ placement, icon, text, className, toolclass }: any) => {
    return (
        <>
            <div className={`CustomTooltip ${className}`}>
                <OverlayTrigger
                    key={placement}
                    placement={placement}
                    // delay={{ hide: 450, show: 300 }}
                    overlay={
                        <Tooltip className={`tooltip ${toolclass}`}>
                            <strong>{text}</strong>
                        </Tooltip>
                    }
                >
                    <span>{icon}</span>
                </OverlayTrigger>
            </div>
        </>
    )
}

export default memo(CustomTooltip)