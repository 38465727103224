import { Dispatch, useEffect } from 'react';
import { Button, Form } from "react-bootstrap";
import Toast from "../../Toast";
import useCopyClipboard from "../../../../hooks/useCopyToClipboard";
import InputCustom from "../../Input/InputCustom";
import './SocialShare.scss';
import { CommonService } from "../../../../services/commonService";
import { CopyIcon, FacebookIcon, InstaIcon, TwitterIcon } from "../../../../assets/svgIcons/svgIcons";
import { RootState } from "../../../../app/store";
import { useDispatch, useSelector } from "react-redux";
import { callApiGetMethod } from "../../../../app/actions/api.action";

const socials = [
  {
    outlet: "LinkedIn",
    href: "https://www.instagram.com/",
    class: "instaIcon",
    label: "Share on Instagram",
    icon: <InstaIcon />,
  },
  {
    outlet: "Facebook",
    href: "https://www.facebook.com",
    class: "facebook",
    label: "Share on Facebook",
    icon: <FacebookIcon />,
  },
  {
    outlet: "Twitter",
    href: "https://twitter.com",
    class: "twitter",
    label: "Share on Twitter",
    icon: <TwitterIcon />,
  },
];

export const SocialShare = ({ socialLinks, url }: any) => {

  const userDetails: any = useSelector((state: RootState) => state.connect);
  const dispatch: Dispatch<any> = useDispatch();

  const [setCopied] = useCopyClipboard();
  const copy = (data: any, message?: string) => {
    setCopied(data);
    if (message) Toast.success(message);
  };

  return (
    <>
      {/* <Col md={6}> */}
      <div className="refer_box">

        <div>
          <h4>My Referral Link</h4>
          <Form.Group className='refer_input'>
            <Form.Control
              readOnly value={url}
              placeholder='' />
            <span className="copy_icon"
              onClick={() => copy(url, "Url copied to clipboard")}>
              <CopyIcon />
            </span>
          </Form.Group>
        </div>
        <div>


          <h4>Share</h4>
          <div className="social_links">

            <ul>
              {socialLinks
                .map((item: any) => {
                  item = socials.find((itm) => itm.outlet === item) ?? "";
                  return item;
                })
                .map(
                  (item: any, key: any) =>
                    item.href && (
                      <li key={key}>
                        <a
                          href={item.href + url}
                          rel="noreferrer"
                          target="popup"
                          title={item.label}
                          className={item.class}
                          onClick={() =>
                            window.open(
                              window.location.origin,
                              "popup",
                              "width=600,height=600,scrollbars=no,resizable=no"
                            )
                          }
                        >
                          {item.icon}
                        </a>
                      </li>
                    )
                )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
