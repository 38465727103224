import React, { memo, useEffect, useState } from 'react'
import PerfectScrollbar from "react-perfect-scrollbar";

import { Col, Row, Table } from 'react-bootstrap'
import PoolsTableCard from '../PoolsTableCard/PoolsTableCard'
import NoDataFound from '../../NodataFound'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import '../FarmsCard/PoolsCard.scss';

const PoolsPageBody = ({ data, listView }: any) => {
    const networkDetails = useSelector((state: RootState) => state.connect.networksDetails)
    const [closeAll, setCloseAll] = useState(false);

    const checkName = (name: string) => {
        if (name.toLocaleLowerCase() === "weth") {
            name = networkDetails?.chainType === "BSC" ? 'BNB' : "ETH";
        }
        return name;
    }

    const getDetails = async () => {

    }

    useEffect(() => {

        getDetails();
    }, [])

    return (
        data.length ?
            <div className='pools_page_cards'>
                {listView ?
                    <PerfectScrollbar>
                        <Table className="pools_content_outer_table">
                            <tbody>
                                {
                                    data?.map((item: any) => (
                                        // <tr>
                                        <>
                                            <PoolsTableCard
                                                nameReward={checkName(item.nameReward)}
                                                nameStaked={checkName(item.nameStaked)}
                                                icons={[item.iconStaked, item.iconReward,]}
                                                luniStaked={'0'}
                                                flexibleApy={'0'}
                                                lockedApr={'0'}
                                                lockedAprLuni={'0'}
                                                totalLocked={'0'}
                                                avgLockDuration={'0'}
                                                performanceFee={'0'}
                                                recentLuniProfit={'0'}
                                                unstakingFee={'0'}
                                                enabled={true}
                                                listView={listView}
                                                poolAddress={item.poolAddress}
                                                decimalsStaked={item.decimalsStaked}
                                                decimalsReward={item.decimalsReward}
                                                addressReward={item.addressReward}
                                                addressStaked={item.addressStaked}
                                                closeAll={closeAll}
                                                setCloseAll={setCloseAll}
                                            />
                                            <tr className='empty_row'></tr>
                                        </>
                                        // </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </PerfectScrollbar>
                    :
                    <Row>
                        {
                            data?.map((item: any) => (
                                <PoolsTableCard
                                    nameReward={checkName(item.nameReward)}
                                    nameStaked={checkName(item.nameStaked)}
                                    icons={[item.iconStaked, item.iconReward,]}
                                    luniStaked={'0'}
                                    flexibleApy={'0'}
                                    lockedApr={'0'}
                                    lockedAprLuni={'0'}
                                    totalLocked={'0'}
                                    avgLockDuration={'0'}
                                    performanceFee={'0'}
                                    recentLuniProfit={'0'}
                                    unstakingFee={'0'}
                                    enabled={true}
                                    listView={listView}
                                    poolAddress={item.poolAddress}
                                    decimalsStaked={item.decimalsStaked}
                                    decimalsReward={item.decimalsReward}
                                    addressReward={item.addressReward}
                                    addressStaked={item.addressStaked}
                                    closeAll={closeAll}
                                    setCloseAll={setCloseAll}
                                />
                            ))
                        }
                    </Row>}
            </div>
            : (
                <Table>
                    <tr> <td colSpan={8} className='no_record_found'>  <NoDataFound /></td></tr>
                </Table>
            )
    )
}

export default memo(PoolsPageBody);