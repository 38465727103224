import { useState } from 'react';



export const AddLiquidity = () => {

    // Heading Case
    const [HeadingCase, setHeadingCase] = useState("Default");
    const caseClick = (e: any) => {
        setHeadingCase(e);
    };

    const [TipTool, setTipTool] = useState<any>("right");

    return (
        <>
            test
        </>
    );
}
