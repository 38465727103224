import { Col, Container, Row } from "react-bootstrap";
import Lottie from "lottie-react";
import HomeLottie from '../../assets/lottie/coin_rev_v2.json';
import './Home.scss'
import SwapCard from './SwapCard/SwapCard';
import swapImagemode from '../../assets/images/swapImagemode.png';
const Home = () => {

  return (
    <>
      <section className="HomeHandle">
        <Container>
          <Row >
            <Col md={6}>
              <div className="HomeHandleLeft">
                <h1 className="h2">A <span>Trusted & Secure</span> Simple Mode!</h1>
                <p>Trade, earn, and win crypto on the most popular decentralized platform in the galaxy</p>
                {/* <div className="lottieOuter">
                  <Lottie
                    animationData={HomeLottie} 
                    loop={true}
                    style={{  width: 420 }}
                  />
                </div> */}
                <img className="swapBgImg" src={swapImagemode} alt="swap-image" />
              </div>
            </Col>
            <Col md={6}>
              <div className="HomeHandleRight">
                <SwapCard />
                {/* <img className="delete" src={Delete} alt="" /> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Home;
