import { Dispatch, useEffect, useState } from 'react'
import {
    OverlayTrigger,
    Popover,
} from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
    InfoIcon,
} from "../../assets/svgIcons/svgIcons";
import { addCustomSlippage, addDeadline } from '../../features/swapAndLiquidity.slice';
import { CommonService } from '../../services/commonService';


export const Setting = ({ setSlipPageData, setDeadline, setSlippageError, slippageError }: any) => {
    const dispatch: Dispatch<any> = useDispatch();

    const [manualSlippage, setManualSlippage] = useState<any>();
    //const [slippageError, setSlippageError] = useState(false);
    const [isInputFocused, setInputFocused] = useState(false);

    const [TipTool, setTipTool] = useState<any>("right");
    const [slippage, setSlippage] = useState<any>();
    const [txDeadline, setTxDeadline] = useState<any>('10');

    const settingsInfo: any = useSelector(
        (state: RootState) => state.settings
    );


    useEffect(() => {
        if (settingsInfo && (settingsInfo?.customSlippage)) {
            setSlippage(settingsInfo?.customSlippage);
        } else {
            setSlippage('0.1');
            dispatch(addCustomSlippage({ customSlippage: '0.1' }))
        }


        if (settingsInfo && settingsInfo?.txnDeadline) {
            setTxDeadline(settingsInfo?.txnDeadline);
        } else {
            setTxDeadline('10');
            dispatch(addDeadline({ txnDeadline: '10' }))

        }
        if (window.innerWidth < 1280) {
            setTipTool("auto");
        } else {
            setTipTool("right");
        }
        const handleResize = () => {
            if (window.innerWidth < 1280) {
                setTipTool("bottom");
            } else {
                setTipTool("right");
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const SlippageTolerance = [
        { title: "0.1" },
        { title: "0.5" },
        { title: "1.0" },
        // { title: "0.50" }
    ];
    const TxDeadline = [
        { title: "10" },
        { title: "15" },
        { title: "20" },
        { title: "25" }
    ];



    const Settingsopover = (
        <Popover className="commonPopover">
            <Popover.Body>
                <p>
                    Setting a high slippage tolerance can help transactions succeed, but you may not get such a good price. Use with caution.
                </p>
            </Popover.Body>
        </Popover>
    );

    const SettingTxDeadlinePopover = (
        <Popover className="commonPopover">
            <Popover.Body>
                <p>
                    Your transaction will revert if it is left confirming for longer than this time. </p>
            </Popover.Body>
        </Popover>
    );



    return (
        <>
            <div className="SwapCardSettings">

                <h4>
                    Tx Deadline (mins){" "}
                    <OverlayTrigger trigger="click" placement={TipTool} overlay={SettingTxDeadlinePopover}
                        rootCloseEvent="click"
                        rootClose
                    >
                        <button type="button">
                            <InfoIcon />
                        </button>
                    </OverlayTrigger>
                </h4>
                <ul>
                    {TxDeadline.map((item, index) => (
                        <li
                            key={index}
                            onClick={() => {
                                setTxDeadline(item?.title)
                                dispatch(addDeadline({ txnDeadline: item?.title }))
                            }}
                            className={txDeadline === item?.title ? "active" : ""}
                        // className="select_control_btn"
                        >
                            <button onClick={() => setDeadline(item)}><span className="texttitle">{item.title} Min</span></button>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
}
