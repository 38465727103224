import async from "async";
import { Dispatch, useEffect, useState } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import {
  callContractGetMethod,
  callContractSendMethod,
  validateAddress,
} from "../../../app/actions/contract.action";
import { RootState } from "../../../app/store";
import InputCustom from "../../../component/common/Input/InputCustom";
import Toast from "../../../component/common/Toast";
import CommonBtn from "../../../component/ui/CommonBtn/CommonBtn";
import { removeUserDetails } from "../../../features/connect.slice";
import { CommonService } from "../../../services/commonService";
import { getTokenPrice } from "../LiquidityAdmin/LiquidityDashboard/CreateToken/calculatePrice";
import "./Settings.scss";
import { useAccount } from "wagmi";

const Settings = () => {
  const { connector } = useAccount();
  const [feeToSetter, setFeeToSetter] = useState<any>("");
  const [feeto, setFeeTo] = useState<any>("");
  const [emissionRate, setEmissionRate] = useState<any>("");
  const [swapItReferral, setSwapItReferral] = useState<any>("");
  const [referralComission, setReferralComission] = useState<any>();
  const [devAddress, setDevAddress] = useState<any>("");
  const [feeAddress, setFeeAddress] = useState<any>("");
  const [rewardToken, setRewardToken] = useState<any>("");

  const [getSwapItPerBlock, setGetSwapItPerBlock] = useState<any>("");
  const [GetSwapItReferral, setGetSwapItReferral] = useState<any>("");
  const [referral_ComissionRate, setGetReferralComission] = useState<any>("");
  const [getDevAddress, setGetDevAddress] = useState<any>("");
  const [getFeeaddress, setGetFeeaddress] = useState<any>("");
  const [transferOwnerShip, setTransferOwnerShip] = useState<any>("");
  const [farmRewardAmount, setFarmRewardAmount] = useState<any>("");
  const [adminBalance, setAdminBalance] = useState<any>(0);
  const [userBalance, setUserBalance] = useState<any>(0);
  const [rewardAddress, setReardAddress] = useState<any>("");
  const [farmDecimal, setFarmDecimal] = useState<any>("");

  const [pool_transferOwnerShip, setPoolTransferOwnerShip] = useState<any>("");

  const dispatch: Dispatch<any> = useDispatch();
  const adminType = useSelector((state: RootState) => state.connect?.adminType);
  const walletAddress: any = useSelector(
    (state: RootState) => state.connect.walletAddress
  );
  const defaultNetworks: any = useSelector(
    (state: RootState) => state.connect.networksDetails
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let checkWallet: any = await dispatch(
      validateAddress(feeToSetter, "wallet")
    );
    if (checkWallet) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "setFeeToSetter",
          [feeToSetter],
          walletAddress,
          "factory"
        )
      );
      if (response?.status) {
        setFeeToSetter("");
        Toast.success("Fee to setter address updated successfully");
        dispatch(removeUserDetails());
      }
    } else {
      Toast.error("Please enter valid address");
    }
  };

  const transfer_ownership = async (e: any) => {
    e.preventDefault();
    let checkWallet: any = await dispatch(
      validateAddress(transferOwnerShip, "wallet")
    );
    if (checkWallet) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "transferOwnership",
          [transferOwnerShip],
          walletAddress,
          "master"
        )
      );
      if (response?.status) {
        setTransferOwnerShip("");
        Toast.success("Transfer ownership successfully");
        dispatch(removeUserDetails());
      }
    } else {
      Toast.error("Please enter valid address");
    }
  };

  const pool_transfer_ownership = async (e: any) => {
    e.preventDefault();
    let checkWallet: any = await dispatch(
      validateAddress(pool_transferOwnerShip, "wallet")
    );
    if (checkWallet) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "transferOwnership",
          [pool_transferOwnerShip],
          walletAddress,
          "smartChefFactory"
        )
      );
      if (response?.status) {
        setPoolTransferOwnerShip("");
        Toast.success("Transfer ownership successfully");
        dispatch(removeUserDetails());
      }
    } else {
      Toast.error("Please enter valid wallet address");
    }
  };
  const handleFeeTo = async (e: any) => {
    e.preventDefault();
    if (Web3.utils.isAddress(feeto)) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "setFeeTo",
          [feeto],
          walletAddress,
          "factory"
        )
      );
      if (response?.status) {
        setFeeTo("");
        Toast.success("FeeTo address updated successfully");
      }
    } else {
      Toast.error("Please enter valid address");
    }
  };

  const updatEmissionRate = async (e: any) => {
    e.preventDefault();

    if (emissionRate > 0) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "updateEmissionRate",
          Object.values({
            emissionRate: CommonService.convertWithDecimal(
              emissionRate,
              defaultNetworks?.swapItDecimals
            ),
          }),
          walletAddress,
          "master"
        )
      );
      if (response?.status) {
        setFeeTo("");
        Toast.success("FeeTo address updated successfully");
      }
    } else {
      Toast.error("Please enter the value greater-than zero");
    }
  };

  const setDev_Address = async (e: any) => {
    e.preventDefault();
    if (Web3.utils.isAddress(devAddress)) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "setDevAddress",
          Object.values({ devAddress }),
          walletAddress,
          "master"
        )
      );

      if (response && response?.status) {
        setFeeTo("");
        Toast.success("Dev address added successfully");
      }
    } else {
      Toast.error("Please enter valid address");
    }
  };

  const setFee_address = async (e: any) => {
    e.preventDefault();
    let checkWallet: any = await dispatch(
      validateAddress(feeAddress, "wallet")
    );
    if (checkWallet) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "setFeeAddress",
          Object.values({ feeAddress }),
          walletAddress,
          "master"
        )
      );
      if (response?.status) {
        setFeeTo("");
        Toast.success("FeeTo address updated successfully");
      }
    } else {
      Toast.error("Please enter valid address");
    }
  };

  const setReward_token = async (e: any) => {
    e.preventDefault();
    let checkWallet: any = await dispatch(
      validateAddress(rewardToken, "contract")
    );
    if (checkWallet) {
      let tokenPrice: any = await dispatch(getTokenPrice(rewardToken));
      if (tokenPrice) {
        let provider = await connector?.getProvider();
        let response: any = await dispatch(
          callContractSendMethod(
            provider,
            "setRewardToken",
            Object.values({ rewardToken }),
            walletAddress,
            "master"
          )
        );
        if (response?.status) {
          setRewardToken("");
          Toast.success("Reward Token updated successfully");
        }
      } else {
        Toast.error("Unable to fetch token price");
      }
    } else {
      Toast.error("Please enter valid address");
    }
  };
  const referral_Comission = async (e: any) => {
    e.preventDefault();

    if (referralComission > 0) {
      let provider = await connector?.getProvider();
      let referral_Comission = parseInt((referralComission * 100).toString());
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "setReferralCommissionRate",
          Object.values({ referral_Comission }),
          walletAddress,
          "master"
        )
      );
      if (response?.status) {
        setFeeTo("");
        Toast.success("FeeTo address updated successfully");
      }
    } else {
      Toast.error("Please enter the value greater-than zero");
    }
  };

  const swapIt_Referral = async (e: any) => {
    e.preventDefault();
    let checkWallet: any = await dispatch(
      validateAddress(swapItReferral, "contract")
    );
    if (checkWallet) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "setSwapItReferral",
          Object.values({ swapItReferral }),
          walletAddress,
          "master"
        )
      );
      if (response?.status) {
        setFeeTo("");
        Toast.success("FeeTo address updated successfully");
      }
    } else {
      Toast.error("Please enter valid address");
    }
  };

  useEffect(() => {
    if (walletAddress && defaultNetworks) {
      getAdminBalances();
    }
  }, [walletAddress, defaultNetworks]);

  const getAdminBalances = async () => {
    let rewardTokenAddress: any = await dispatch(
      callContractGetMethod("SwapIT", [], "master", false)
    );
    let rewardTokenDecimal: any = await dispatch(
      callContractGetMethod(
        "decimals",
        [],
        "dynamic",
        false,
        rewardTokenAddress
      )
    );
    setReardAddress(rewardTokenAddress);
    setFarmDecimal(rewardTokenDecimal);

    async.parallel([
      async function (callback: any) {
        let res: any = await dispatch(
          callContractGetMethod("feeToSetter", [], "factory", false)
        );
        setFeeToSetter(res);
      },
      async function (callback: any) {
        let res: any = await dispatch(
          callContractGetMethod("feeTo", [], "factory", false)
        );
        setFeeTo(res);
      },
      async function (callback: any) {
        let res: any = await dispatch(
          callContractGetMethod("SwapITPerBlock", [], "master", false)
        );
        setEmissionRate(parseInt(res) / defaultNetworks?.swapItDecimals);
      },
      async function (callback: any) {
        let res: any = await dispatch(
          callContractGetMethod("SwapITReferral", [], "master", false)
        );
        setSwapItReferral(res);
      },
      async function (callback: any) {
        let res: any = await dispatch(
          callContractGetMethod("referralCommissionRate", [], "master", false)
        );
        setReferralComission(parseInt(res) / 100);
      },
      async function (callback: any) {
        let res: any = await dispatch(
          callContractGetMethod("devAddress", [], "master", false)
        );
        setDevAddress(res);
      },
      async function (callback: any) {
        let res: any = await dispatch(
          callContractGetMethod("feeAddress", [], "master", false)
        );
        setFeeAddress(res);
      },
      async function (callback: any) {
        let res: any = await dispatch(
          callContractGetMethod("SwapIT", [], "master", false)
        );
        setRewardToken(res);
      },
      async function (callback: any) {
        let res: any = await dispatch(
          callContractGetMethod("owner", [], "master", false)
        );
        setTransferOwnerShip(res);
      },
      async function (callback: any) {
        let res: any = await dispatch(
          callContractGetMethod("owner", [], "smartChefFactory", false)
        );
        setPoolTransferOwnerShip(res);
      },
      async function (callback: any) {
        const farmBalance: any = await dispatch(
          callContractGetMethod(
            "balanceOf",
            [defaultNetworks?.masterChef],
            "dynamic",
            false,
            rewardTokenAddress
          )
        );
        setUserBalance(
          CommonService.toFixed(
            CommonService.divideBigNumber(farmBalance, 10 ** rewardTokenDecimal)
          )
        );
      },
      async function (callback: any) {
        const farm_Balance: any = await dispatch(
          callContractGetMethod(
            "balanceOf",
            [walletAddress],
            "dynamic",
            false,
            rewardTokenAddress
          )
        );
        setAdminBalance(
          CommonService.toFixed(
            CommonService.divideBigNumber(
              farm_Balance,
              10 ** rewardTokenDecimal
            )
          )
        );
      },
    ]);
  };

  const transferRawardTokens = async (e: any) => {
    e.preventDefault();
    let provider = await connector?.getProvider();
    let response: any = await dispatch(
      callContractSendMethod(
        provider,
        "transfer",
        [
          defaultNetworks?.masterChef,
          CommonService.convertWithDecimal(farmRewardAmount, 10 ** farmDecimal),
        ],
        walletAddress,
        "dynamic",
        "",
        rewardAddress
      )
    );
    if (response?.status) {
      Toast.success("Token transfer succesfully");
    }
  };

  return (
    <>
      <section className="settings">
        <Container fluid>
          {adminType == "all" ||
            adminType == "farm" ||
            adminType === "poolFarm" ||
            adminType === "liquidityFarm" ? (
            <>
              <Row className="align-items-center pb-5">
                <Col>
                  <h3>Farm Setting</h3>
                </Col>
              </Row>
              <Row>
                <Col sm={6} xl={4} className="mb-5">
                  <div className="settingsCard">
                    <p>Set Reward Token</p>
                    <Form onSubmit={(e: any) => setReward_token(e)}>
                      <Form.Group className="commonInput">
                        <InputCustom
                          type="text"
                          placeholder="Reward Token address"
                          value={rewardToken}
                          onChange={(e: any) => setRewardToken(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <CommonBtn
                        title="Submit"
                        type="submit"
                        className=""
                      />
                    </Form>
                  </div>
                </Col>
                <Col sm={6} xl={4} className="mb-5">
                  <div className="settingsCard">
                    <p>Update Reward Per Block</p>
                    <Form onSubmit={(e: any) => updatEmissionRate(e)}>
                      <Form.Group className="commonInput">
                        <InputCustom
                          type="number"
                          placeholder="Reward Per Block"
                          value={emissionRate}
                          onBlur={(e: any) => {
                            const { value } = e.target;
                            if (value == 0) {
                              setEmissionRate(1);
                            }
                          }}
                          onChange={(e: any) => {
                            const { value } = e.target;
                            if (CommonService.allowOnlyNumber(value, "")) {
                              if (value == 0) {
                                setEmissionRate(e.target.value);
                              } else {
                                setEmissionRate(e.target.value);
                              }
                            }
                          }}
                          required
                        />
                      </Form.Group>
                      <CommonBtn
                        title="Submit"
                        type="submit"
                        className=""
                      />
                    </Form>
                  </div>
                </Col>
                <Col sm={6} xl={4} className="mb-5">
                  <div className="settingsCard">
                    <p>Set swapIt Referral Contract</p>
                    <Form onSubmit={(e: any) => swapIt_Referral(e)}>
                      <Form.Group className="commonInput">
                        <InputCustom
                          type="text"
                          placeholder="swapIt Referral Contract Address"
                          value={swapItReferral}
                          onChange={(e: any) =>
                            setSwapItReferral(e.target.value)
                          }
                          required
                        />
                      </Form.Group>
                      <CommonBtn
                        title="Submit"
                        type="submit"
                        className=""
                      />
                    </Form>
                  </div>
                </Col>
                <Col sm={6} xl={4} className="mb-5">
                  <div className="settingsCard">
                    <p>Set Referral Comission Rate( %)</p>
                    <Form onSubmit={(e: any) => referral_Comission(e)}>
                      <Form.Group className="commonInput">
                        <InputCustom
                          type="number"
                          placeholder=" 0 < Comission Rate % <= 10"
                          value={referralComission}
                          maxlength={2}
                          onChange={(e: any) => {
                            if (
                              CommonService.allowOnlyNumber(e.target.value, "")
                            ) {
                              setReferralComission(e.target.value);
                            }
                          }}
                          required
                        />
                      </Form.Group>
                      <CommonBtn
                        title="Submit"
                        type="submit"
                        className=""
                      />
                    </Form>
                  </div>
                </Col>
                <Col sm={6} xl={4} className="mb-5">
                  <div className="settingsCard">
                    <p>Set Dev Address</p>
                    <Form onSubmit={(e: any) => setDev_Address(e)}>
                      <Form.Group className="commonInput">
                        <InputCustom
                          type="text"
                          placeholder="Dev Address"
                          value={devAddress}
                          onChange={(e: any) => setDevAddress(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <CommonBtn
                        title="Submit"
                        type="submit"
                        className=""
                      />
                    </Form>
                  </div>
                </Col>
                <Col sm={6} xl={4} className="mb-5">
                  <div className="settingsCard">
                    <p>Set Fee Address</p>
                    <Form onSubmit={(e: any) => setFee_address(e)}>
                      <Form.Group className="commonInput">
                        <InputCustom
                          type="text"
                          placeholder="Fee Address"
                          value={feeAddress}
                          onChange={(e: any) => setFeeAddress(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <CommonBtn
                        title="Submit"
                        type="submit"
                        className=""
                      />
                    </Form>
                  </div>
                </Col>
                <Col sm={6} xl={4} className="mb-5">
                  <div className="settingsCard">
                    <p>Farm Transfer Ownership</p>
                    <Form onSubmit={(e: any) => transfer_ownership(e)}>
                      <Form.Group className="commonInput">
                        <InputCustom
                          type="text"
                          placeholder="Update Ownership"
                          value={transferOwnerShip}
                          onChange={(e: any) =>
                            setTransferOwnerShip(e.target.value)
                          }
                          required
                        />
                      </Form.Group>
                      <CommonBtn
                        title="Submit"
                        type="submit"
                        className=""
                      />
                    </Form>
                  </div>
                </Col>
                <Col sm={6} xl={4} className="mb-5">
                  {/* <div className="settingsCard">
                    <p>Transfer Reward Tokens</p>
                    <Form onSubmit={(e: any) => transferRawardTokens(e)}>
                      <Form.Group className="commonInput">
                        <InputCustom
                          type="number"
                          placeholder="Enter amount"
                          value={farmRewardAmount}
                          onChange={(e: any) =>
                            setFarmRewardAmount(e.target.value)
                          }
                          maxlength={farmDecimal}
                          required
                        />
                        <div className="d-block mt-3">
                          <small>
                            Farm Balance:{" "}
                            {CommonService.cryptoDecimals(userBalance)}
                          </small>
                        </div>
                        <small>
                          Admin Balance:{" "}
                          {CommonService.cryptoDecimals(adminBalance)}
                        </small>
                      </Form.Group>
                      <CommonBtn
                        title="Submit"
                        type="submit"
                        className=""
                      />
                    </Form>
                  </div> */}
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          {adminType == "all" ||
            adminType == "liquidity" ||
            adminType === "liquidityPool" ||
            adminType === "liquidityFarm" ? (
            <>
              <Row className="align-items-center pb-5">
                <Col>
                  <h3>Swap/Liquidity Setting</h3>
                </Col>
                {/* <Col className="text-end"><CommonBtn title="Add Token" className="btn-small "/></Col> */}
              </Row>
              <Row>
                <Col sm={6} xl={4} className="mb-5">
                  <div className="settingsCard">
                    <p>Update Fee To Setter</p>
                    <Form onSubmit={(e: any) => handleSubmit(e)}>
                      <Form.Group className="commonInput">
                        <InputCustom
                          type="text"
                          placeholder="Swap/Liquidity Update Ownership"
                          value={feeToSetter}
                          onChange={(e: any) => setFeeToSetter(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <CommonBtn
                        title="Submit"
                        type="submit"
                        className=""
                      />
                    </Form>
                  </div>
                </Col>
                <Col sm={6} xl={4} className="mb-5">
                  <div className="settingsCard">
                    <p>Update Fee To</p>
                    <Form onSubmit={(e: any) => handleFeeTo(e)}>
                      <Form.Group className="commonInput">
                        <InputCustom
                          type="text"
                          placeholder="Update fee to"
                          value={feeto}
                          onChange={(e: any) => setFeeTo(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <CommonBtn
                        title="Submit"
                        type="submit"
                        className=""
                      />
                    </Form>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          {adminType === "all" ||
            adminType === "pool" ||
            adminType === "liquidityPool" ||
            adminType === "poolFarm" ? (
            <>
              <Row className="align-items-center pb-5">
                <Col>
                  <h3>Pool Setting</h3>
                </Col>
                {/* <Col className="text-end"><CommonBtn title="Add Token" className="btn-small "/></Col> */}
              </Row>
              <Row>
                <Col sm={6} xl={4} className="mb-5">
                  <div className="settingsCard">
                    <p>Pool Factory Transfer Ownership</p>
                    <Form onSubmit={(e: any) => pool_transfer_ownership(e)}>
                      <Form.Group className="commonInput">
                        <InputCustom
                          type="text"
                          placeholder="Pool Update Ownership"
                          value={pool_transferOwnerShip}
                          onChange={(e: any) =>
                            setPoolTransferOwnerShip(e.target.value)
                          }
                          required
                        />
                      </Form.Group>
                      <CommonBtn
                        title="Submit"
                        type="submit"
                        className=""
                      />
                    </Form>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </Container>
      </section>
    </>
  );
};
export default Settings;
