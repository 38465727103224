import { Dispatch, useEffect, useState } from "react";
import { Col, Popover, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import { Link, useLocation } from "react-router-dom";
import { RootState } from "../../../app/store";
import {
  BackArrow,
  RefreshIcon,
  SettingIcon,
  TimerRefreshIcon,
} from "../../../assets/svgIcons/svgIcons";
import RecentTransactions from "../../../component/ui/Modals/RecentTransactions/RecentTransactions";
import {
  addCustomSlippage,
  addDeadline,
} from "../../../features/swapAndLiquidity.slice";
import "../../Home/SwapCard/SwapCard.scss";
import { AddLiquidity } from "../AddLiquidity";
import { Default } from "../Default";
import { ImportPool } from "../ImportPool";
import { Setting } from "../Setting";
import { YourLiquidity } from "../YourLiquidity";

const LiquidityCard = () => {
  const location = useLocation();
  const dispatch: Dispatch<any> = useDispatch();

  const settingsInfo: any = useSelector((state: RootState) => state.settings);

  const [showTransactions, setShowTransactions] = useState(false);
  const [TipTool, setTipTool] = useState<any>("right");
  const [slippageError, setSlippageError] = useState(false);
  const [slipPageData, setSlipPageData] = useState<any>("");
  const [deadlineData, setDeadlineData] = useState<any>("");
  const [valueToImport, setValueToImport] = useState<any>("");

  const [fromSelected, setFromSelected] = useState<any>();
  const [toSelected, setToSelected] = useState<any>();

  useEffect(() => {
    if (settingsInfo && settingsInfo?.customSlippage) {
      setSlipPageData(settingsInfo?.customSlippage);
    } else {
      setSlipPageData("0.1");
      dispatch(addCustomSlippage({ customSlippage: "0.1" }));
    }
    if (settingsInfo && settingsInfo?.txnDeadline) {
      setDeadlineData(settingsInfo?.txnDeadline);
    } else {
      setDeadlineData("10");
      dispatch(addDeadline({ txnDeadline: "10" }));
    }
  }, []);

  // useEffect(() => {
  //     const handleResize = () => {
  //         if (window.innerWidth < 1280) {
  //             setTipTool("auto");
  //         } else {
  //             setTipTool("right");
  //         }
  //     };
  //     window.addEventListener("resize", handleResize);
  //     return () => {
  //         window.removeEventListener("resize", handleResize);
  //     };
  // }, []);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Heading Case
  const [HeadingCase, setHeadingCase] = useState("Default");
  const caseClick = (e: any) => {
    setHeadingCase(e);
  };

  const Rewardpopover = (
    <Popover className="commonPopover">
      <Popover.Body>
        Based on last 7 days' peformance. Does not account for impermanent loss
      </Popover.Body>
    </Popover>
  );

  let callbackSlippage = (data: any) => {
    setSlipPageData(data);
  };

  let callBackDeadline = (data: any) => {
    setDeadlineData(data?.title);
  };

  let selectedToImport = (data: any) => {
    setValueToImport(data);
    caseClick("YourLiquidity");
  };

  useEffect(() => {
    if (location && location.state != null) {
      caseClick("YourLiquidity");
    }
  }, []);

  //handler to rotate refreh icon
  const [rotate, setRotate] = useState(false);
  const rotateHandle = () => {
    setRotate(true);
    setTimeout(() => {
      setRotate(false);
    }, 500);
  };

  return (
    <>
      <div className="SwapCard">
        <div className="SwapCardHead">
          <Row>
            <Col xs={8} className="SwapCardHeadLeft">
              {(() => {
                switch (HeadingCase) {
                  case "Default":
                    return (
                      <>
                        <h4>Your Liquidity</h4>
                        <p>Trade tokens in an instant</p>
                      </>
                    );
                  case "Settings":
                    return (
                      <>
                        <div className="goToBack">
                          <div className="left">
                            <Link
                              to="#"
                              onClick={() => {
                                caseClick("YourLiquidity");
                              }}
                            >
                              <BackArrow />
                            </Link>
                          </div>
                          <div className="right">
                            <h4>Settings</h4>
                          </div>
                        </div>
                      </>
                    );
                  case "ImportPool":
                    return (
                      <>
                        <div className="goToBack">
                          <div className="left">
                            <Link
                              to="#"
                              onClick={() => {
                                caseClick("Default");
                              }}
                            >
                              <BackArrow />
                            </Link>
                          </div>
                          <div className="right">
                            <h4>Import Pool</h4>
                            <p>Import an existing pool</p>
                          </div>
                        </div>
                      </>
                    );
                  case "YourLiquidity":
                    return (
                      <>
                        <div className="goToBack">
                          <div className="left">
                            <Link
                              to="#"
                              onClick={() => {
                                caseClick("Default");
                              }}
                            >
                              <BackArrow />
                            </Link>
                          </div>
                          <div className="right">
                            <h4>Your Liquidity</h4>
                            <p>Trade tokens in an instant</p>
                          </div>
                        </div>
                      </>
                    );
                  case "AddLiquidity":
                    return (
                      <>
                        <div className="goToBack">
                          <div className="left">
                            <Link
                              to="#"
                              onClick={() => {
                                caseClick("Default");
                              }}
                            >
                              <BackArrow />
                            </Link>
                          </div>
                          <div className="right">
                            <h4>Add liquidity</h4>
                            <p>Receive LP tokens and earn 0.17% trading fees</p>
                          </div>
                        </div>
                      </>
                    );
                  default:
                    // code block
                    return null;
                }
              })()}
            </Col>
            {HeadingCase !== "Settings" && (
              <Col xs={4}>
                <div className="SwapCardHeadRight">
                  <button
                    className="settings"
                    onClick={() => {
                      caseClick("Settings");
                    }}
                    type="button"
                  >
                    <SettingIcon />
                  </button>
                  <button
                    className="timer"
                    onClick={() => setShowTransactions(true)}
                    type="button"
                  >
                    <TimerRefreshIcon />
                  </button>
                  <button
                    className={`refresh ${rotate ? "active" : ""}`}
                    type="button"
                    onClick={() => {
                      rotateHandle();
                    }}
                  >
                    <RefreshIcon />
                  </button>
                </div>
              </Col>
            )}
          </Row>
        </div>

        {(() => {
            // setHeadingCase("Default")
          switch (HeadingCase) {
            case "Default":
              return (
                <>
                  <Default
                    onClickLiquidity={() => {
                      caseClick("YourLiquidity");
                    }}
                    onClickImportPool={() => {
                      caseClick("ImportPool");
                    }}
                    deadline={deadlineData}
                    slipPage={slipPageData}
                    isRefreshed={rotate}
                  />
                </>
              );
            case "Settings":
              return (
                <Setting
                  setSlipPageData={callbackSlippage}
                  setDeadline={callBackDeadline}
                  setSlippageError={setSlippageError}
                  slippageError={slippageError}
                />
              );

            case "ImportPool":
              return <ImportPool onClickLiquidity={selectedToImport} />;

            case "AddLiquidity":
              return <AddLiquidity />;

            case "YourLiquidity":
              return (
                <YourLiquidity
                  isRefreshed={rotate}
                  slipPageData={slipPageData}
                  deadlineData={deadlineData}
                  selectedToImport={valueToImport}
                  data={location.state}
                  setFromSelected={setFromSelected}
                  setToSelected={setToSelected}
                  fromSelected={fromSelected}
                  toSelected={toSelected}
                />
              );

            default:
              // code block
              return null;
          }
        })()}
      </div>

      <RecentTransactions
        show={showTransactions}
        handleClose={() => setShowTransactions(false)}
        type={"liquidity"}
      />
    </>
  );
};
export default LiquidityCard;
