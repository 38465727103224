import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, redirect, useNavigate } from "react-router-dom"
import { RootState } from '../../../../app/store';
import Footer from "../../Admin/Footer/Footer"
import Header from "../../Admin/Header/Header"
import './AdminLayout.scss';

const AdminLayout = ({ type }: any) => {
    const adminStatus = useSelector((state: RootState) => state?.connect?.adminStatus);
    const adminType = useSelector((state: RootState) => state?.connect?.adminType);

    const [loaded, setLoaded] = useState<any>(false);

    const navigate = useNavigate();
    setTimeout(function () {
        setLoaded(true)
    }, 10);
    return (
        <>
            {loaded ?
                adminStatus ?
                    <>
                        <Header />
                        <main className="mainHandle admin">
                            <Outlet />
                        </main>
                        <Footer />
                    </>
                    : navigate("/admin") : ''}
        </>
    )
}

export default AdminLayout