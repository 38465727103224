import { Dispatch, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { callApiGetMethod } from "../../../../app/actions/api.action";
import { RootState } from "../../../../app/store";
import {
  ArrowDownIcon,
  BackArrow,
  SearchIconTwo
} from "../../../../assets/svgIcons/svgIcons";
import CommonModal from "../CommonModal/CommonModal";
import ImportedTokens from "../ImportedTokens/ImportedTokens";
import "./SelectToken.scss";
import { useDebounce } from 'use-debounce';
import Lottie from 'lottie-react';
import EmptyLottie from '../../../../assets/lottie/empty-file.json';
import PerfectScrollbar from 'react-perfect-scrollbar'



type propTypes = {
  selected?: any;
  callback?: any;
  type?: string;
  disabled: any;
  className?: any;
};

const SelectToken = ({ selected, callback, disabled, className, type }: propTypes) => {
  const localTokenList = useSelector((state: any) => state.token.tokenInfo);
  const dispatch: Dispatch<any> = useDispatch();
  const defaultNetworks = useSelector(
    (state: RootState) => state.connect.networksDetails
  );

  const handleClose = () => {
    setShow(false);
    setSearchFilter("");
    setIsTokenImported(false);
    setTokensToView(false);
  };
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const [tokenList, setTokenList] = useState<any>();
  const [searchFilter, setSearchFilter] = useState<any>();
  const [searchQueryDeBounce] = useDebounce(searchFilter, 500);
  const [TokensToView, setTokensToView] = useState(false);
  const [isTokenImported, setIsTokenImported] = useState(false);

  const TokenHandle = () => {
    setTokensToView(true);
  };

  useEffect(() => {
    handleClose();
  }, [selected]);



  useEffect(() => {
    if (show) {
      setIsTokenImported(false);
      getTokenList();
    }
  }, [show, searchQueryDeBounce]);

  const getTokenList = async () => {

    const tokenList: any = await dispatch(
      callApiGetMethod(`GET_TOKEN`, {
        page: 1,
        limit: 10,
        chainType: defaultNetworks?.chainType,
        search: searchFilter
      }, false)
    );
    if (tokenList && tokenList?.data?.docs) {
      if (localTokenList && localTokenList.length > 0 && searchFilter !== "") {
        let _tokenList: any = localTokenList?.filter((item: any) => (item?.address == searchFilter) || ((item?.name).toLowerCase() == searchFilter.toLowerCase()) || ((item?.name).toLowerCase()).match((searchFilter).toLowerCase()));
       
         if (_tokenList && _tokenList.length > 0) {
          _tokenList.new = false;
         setIsTokenImported(true);
         setTokenList(_tokenList);
        } else {
          setIsTokenImported(false);
          let mergeArray: any = [..._tokenList, ...tokenList?.data?.docs];
          setTokenList(mergeArray);
        }

       

      } else {
        let mergeArray: any = [...localTokenList, ...tokenList?.data?.docs];
        setTokenList(mergeArray);

      }
    }
  };

  return (
    <>
      <button className={`CoinPicker ${className}`} onClick={handleShow} type="button">
        {selected && selected.symbol ? (
          <>
            {" "}
            <img src={selected.icon} alt="icon" /> <span>{selected.symbol}</span>
          </>
        ) : (
          <><span>Select</span></>
        )}{" "}
        <ArrowDownIcon />
      </button>

      <CommonModal
        className="SelectTokenModal"
        modalTitle={
          TokensToView ? (
            <>Select a Token</>
          ) : (
            <>
              <Link
                to="#"
                className="BackToTokens"
                onClick={() => {
                  setTokensToView(true);
                }}
              >
              </Link>{" "}
              Import Token
            </>
          )
        }
        show={show}
        onHide={handleClose}
      >

        {
          TokensToView ? null : <Form.Group className="SelectTokenModalSearch_input">

            <Form.Control
              value={searchFilter}
              placeholder="Search name or paste token address"
              onChange={(e) => {
                setSearchFilter(e.target.value);
              }}
              type="text"
            />
            <button type="button" className="right_icon">
              <SearchIconTwo />
            </button>
          </Form.Group>
        }
        <PerfectScrollbar>

          <ul className="SelectTokenModalTokenList">
            {tokenList && tokenList?.length > 0 ?
              tokenList?.map((data: any, index: any) => (
                <>
                  {data && data?.new ? (
                    <>
                      <ImportedTokens
                        onClick={
                          TokenHandle

                        }
                        tokenList={data}
                        callback={() => {
                          handleClose();
                          callback(data);
                        }}
                        isTokenExist={isTokenImported}
                      />
                    </>
                  ) : (
                    <>

                      {disabled?.symbol !== data.symbol && data?.chainType == defaultNetworks?.chainType ?
                        <li key={index}>
                          <Link
                            to="#"
                            onClick={(e) => {
                              callback(data);
                            }}
                          >
                            <div className="left">
                              <div className="thumb">
                                <img src={data.icon} alt="icon" />
                              </div>
                              <div className="content">
                                <h5>{data.name}</h5>
                                <span>{data.symbol}</span>

                              </div>
                            </div>
                            <div className="right">
                              {/* <span>{data.value}</span> */}
                              {data?.isCreatedByUser ? <span> {isTokenImported? "Already ":null}Added by user</span> : null}
                            </div>
                          </Link>
                        </li> : null}

                    </>

                  )}
                </>
              )) : <li className="text-center pt-5">
                <div className="no-transaction">
                  <h4>No Token Found</h4>
                  <div className="Lottie">
                    <Lottie
                      animationData={EmptyLottie}
                      loop={true}
                      style={{ width: 192 }}
                    />
                  </div>
                </div>
              </li>}
          </ul>

        </PerfectScrollbar>
      </CommonModal>

    </>
  );
};

export default SelectToken;
