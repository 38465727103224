import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from "react-router-dom"
import { RootState } from '../../../../app/store';
import './AdminLayout.scss';

const FarmPrivate = (props: any) => {
    const adminStatus = useSelector((state: RootState) => state?.connect?.adminStatus);
    const adminType = useSelector((state: RootState) => state?.connect?.adminType);
    const [loaded, setLoaded] = useState<any>(false);

    const navigate = useNavigate();
    setTimeout(function () {
        setLoaded(true);
    }, 10);

    return (
        <>
            {
                loaded ?
                    (adminStatus && adminType === "farm" || adminType === "all" || adminType === 'poolFarm' || adminType =='liquidityFarm') ?
                        <>
                            <Outlet />
                        </>
                        : navigate("/admin") : ''
            }
        </>

    )
}


export default FarmPrivate;