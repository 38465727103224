import { Col, Container, Row } from "react-bootstrap";
import liquidityVideo from '../../assets/videos/liquidityVideo.mp4';
import '../Home/Home.scss';
import './Liquidity.scss';
import LiquidityCard from "./LiquidityCard/LiquidityCard";

const Liquidity = () => {
    return (
        <>
            <section className="HomeHandle LiquidityHandle">
                <Container>
                    <Row>
                        <Col md={6}>
                            <div className="HomeHandleLeft">
                                <h1 className="h2">A <span>Trusted & Secure</span> Simple Mode!</h1>
                                <p>Trade, earn, and win crypto on the most popular decentralized platform in the galaxy</p>
                                {/* <img className="MainBg" src={liquidity_PosterImage} alt="" /> */}
                                <video className="MainBg"
                                    autoPlay
                                    controls
                                    muted
                                    loop
                                    width="100%"
                                    height="auto"
                                >
                                    <source
                                        src={liquidityVideo}
                                    />
                                </video>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="HomeHandleRight">
                                {/* <SwapCard/> */}
                                <LiquidityCard />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Liquidity