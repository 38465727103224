import React, { Dispatch, useCallback, useEffect, useState } from "react";
import { Container, Dropdown, Form } from "react-bootstrap";
import {
  FarmTypeIcon,
  FilterIcon,
  GridViewIcon,
  ListViewIcon,
  SearchIcon,
  SortIcon,
} from "../../assets/svgIcons/svgIcons";
import CommonBtn from "../../component/ui/CommonBtn/CommonBtn";
import PoolsBanner from "./PoolsBanner/PoolsBanner";
import "./PoolsPage.scss";
import PoolsPageBody from "./PoolsPageBody/PoolsPageBody";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { useDebounce } from "use-debounce";
import { callApiGetMethod } from "../../app/actions/api.action";
import { LIMIT } from "../../constant";
import Paginations from "../../component/common/Pagination/Pagination";
import { useWeb3ModalEvents } from "@web3modal/react";
import { useWeb3ModalState } from "@web3modal/wagmi/react";

const PoolsPage = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const defaultNetworks = useSelector(
    (state: RootState) => state.connect.networksDetails
  );
  const [listView, setListView] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [tokenListData, setTokenListData] = useState<any>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryDeBounce] = useDebounce(searchQuery, 1000);
  const [onlyStaked, setOnlyStaked] = useState(false);
  const walletAddress: any = useSelector(
    (state: RootState) => state.connect.walletAddress
  );

  const modalstate = useWeb3ModalState();
  const getFarmAndPoolTokens = async (
    loading: boolean = false,
    page: number = 1
  ) => {
    const tokenList: any = await dispatch(
      callApiGetMethod(
        "GET_POOLS_LIST",
        {
          page,
          limit: LIMIT,
          search: searchQuery,
          chainType: defaultNetworks?.chainType,
          onlyStaked,
          walletAddress,
        },
        loading
      )
    );
    if (tokenList && tokenList?.data?.docs) {
      setTokenListData(tokenList?.data?.docs);
      await setTotalData(tokenList?.data?.totalDocs);
    }
  };

  const onPageChanged = useCallback(
    (event: any, page: number) => {
      event.preventDefault();
      getFarmAndPoolTokens(true, page);
      setCurrentPage(page);
    },
    [getFarmAndPoolTokens]
  );

  useEffect(() => {
    if (modalstate?.open) {
      setTokenListData([]);
    } else
      if (defaultNetworks) {
        setTimeout(() => {
          getFarmAndPoolTokens();
        }, 2000);
      }
  }, [defaultNetworks?.chainId, modalstate?.open]);

  useEffect(() => {
    if (defaultNetworks) {
      getFarmAndPoolTokens();
    }
  }, [searchQueryDeBounce, onlyStaked]);

  return (
    <div className="pools_page">
      <PoolsBanner />
      <section className="pools_content">
        <Container>
          <div className="pools_content_header">
            <div className="pools_content_header_left">
              <Form.Group className="search_input">
                <div className="search_input_inner">
                  <Form.Control
                    placeholder="Search Pool"
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                  <span className="right_icon">
                    <SearchIcon />
                  </span>
                </div>
              </Form.Group>
              <div className="pool_view_type">
                <CommonBtn
                  onClick={() => setListView(true)}
                  type="button"
                  className={`bordred-green ${listView ? "active" : "lightborderGradient"}`}
                  title={<ListViewIcon />}
                />
                <CommonBtn
                  onClick={() => setListView(false)}
                  type="button"
                  className={`bordred-green ${listView ? "lightborderGradient" : "active"}`}
                  title={<GridViewIcon />}
                />
              </div>
            </div>
            <div className="pools_content_header_right">
              {/* {walletAddress ? <Form.Group className='staked_check'>
                                <Form.Check
                                    type="switch"
                                    id="staked"
                                    label="Staked Only"
                                    onChange={(e) => setOnlyStaked(e.target.checked)}
                                    checked={onlyStaked}
                                />
                            </Form.Group> : ''} */}
              {/* <Form.Group className='pools_dropdown pool_sort'>
                                <Dropdown className="pools_dropdown_inner">
                                    <Dropdown.Toggle>
                                        <SortIcon /> Sort
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align={'end'} renderOnMount={true}>
                                        <Link to="#" className="dropdown-item">Hot</Link>
                                        <Link to="#" className="dropdown-item">APR</Link>
                                        <Link to="#" className="dropdown-item">Multiplier</Link>
                                        <Link to="#" className="dropdown-item">Earned</Link>
                                        <Link to="#" className="dropdown-item">Liquidity</Link>
                                        <Link to="#" className="dropdown-item">Latest</Link>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                            <Form.Group className='pools_dropdown pool_type'>
                                <Dropdown className="pools_dropdown_inner">
                                    <Dropdown.Toggle>
                                        <FarmTypeIcon /> Farm Type
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align={'end'} renderOnMount={true}>
                                        <Form.Check
                                            type="switch"
                                            id="booster"
                                            label="Booster Available"
                                        />
                                        <Form.Check
                                            type="switch"
                                            id="stableswap"
                                            label="Stableswap"
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group>
                            <Form.Group className='pools_dropdown pool_filter'>
                                <Dropdown className="pools_dropdown_inner">
                                    <Dropdown.Toggle>
                                        <FilterIcon /> Filter
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu align={'end'} renderOnMount={true}>
                                        <Link to="#" className="dropdown-item">Live</Link>
                                        <Link to="#" className="dropdown-item">Finished</Link>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Group> */}
            </div>
          </div>
          <div className="pools_content_body">
            <PoolsPageBody data={tokenListData} listView={listView} />
            {/* {tokenListData.length ? (
              <PoolsPageBody data={tokenListData} listView={listView} />
            ) : (
              ""
            )} */}
          </div>
          <div className="pagination-wrapper">
            {totalData > LIMIT ? (
              <Paginations
                totalRecords={totalData}
                pageLimit={LIMIT}
                pageNeighbours={2}
                onPageChanged={onPageChanged}
                currentPage={currentPage}
              />
            ) : (
              ""
            )}
          </div>
        </Container>
      </section>
    </div>
  );
};

export default PoolsPage;
