import React from 'react';
import { Container } from "react-bootstrap";
import './Dashbaord.scss'
import Logo from '../../../assets/images/logo.png';

const Dashboard = () => {
  return (
    <>
      <section className="dashboard">
        <Container fluid>
          <div>
            <img src={Logo} alt="Logo" />
            <h1 className="h2">Welcome To Swapit</h1>
          </div>
        </Container>
      </section>
    </>
  )
}

export default Dashboard