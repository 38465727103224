import { callContractGetMethod } from "../../../../../app/actions/contract.action";
import { DEFAULT_ADDRESS } from "../../../../../constant";
import { CommonService } from "../../../../../services/commonService";
import { Dispatch } from "react";
import { useDispatch } from "react-redux";
import { storeInstance } from "../../../../../services/axios";

export const getPrice = async (pairInfo: any) => {
  try {
    let defaultNetworks = storeInstance.getState()?.connect.networksDetails;
    let tokenAddress = pairInfo?.token_1;
    let referenceCoinAddress = pairInfo?.token_0;
    let oracleAddress = defaultNetworks?.tokenOracle;
    let referenceCoinReserve = pairInfo?._reserve0;
    let tokenReserve = pairInfo?._reserve1;
    if (pairInfo?.referenceCoinPosition === 1) {
      tokenAddress = pairInfo?.token_0;
      referenceCoinAddress = pairInfo?.token_1;
      referenceCoinReserve = pairInfo?._reserve1;
      tokenReserve = pairInfo?._reserve0;
    }

    if (pairInfo?.isBaseCurrency) {
      oracleAddress = defaultNetworks?.nativeOracle;
    }
    let tokenDecimals: any = await storeInstance.dispatch(
      callContractGetMethod("decimals", [], "dynamic", false, tokenAddress)
    );
    let referenceCoinDecimals: any = await storeInstance.dispatch(
      callContractGetMethod(
        "decimals",
        [],
        "dynamic",
        false,
        referenceCoinAddress
      )
    );

    let referenceCoinPrice: any = await storeInstance.dispatch(
      callContractGetMethod("latestAnswer", [], "oracle", false, oracleAddress)
    );

    let amount = Number(1 * 10 ** tokenDecimals);

    let amountOut = CommonService.divideBigNumber(
      CommonService.convertWithDecimal(
        997,
        CommonService.convertWithDecimal(amount, referenceCoinReserve)
      ),
      1000 * tokenReserve + 997 * amount
    );
    return referenceCoinPrice * (amountOut / 10 ** referenceCoinDecimals);
  } catch (error) {
    throw error;
  }
};

export const checkToken = async (address: any) => {
  let defaultNetworks = storeInstance.getState()?.connect.networksDetails;
  try {
    if (
      address?.toLowerCase() == defaultNetworks?.referenceCoin.toLowerCase()
    ) {
      let referenceCoinPrice: any = await storeInstance.dispatch(
        callContractGetMethod(
          "latestAnswer",
          [],
          "oracle",
          false,
          defaultNetworks?.tokenOracle
        )
      );
      return referenceCoinPrice;
    }
    if (address?.toLowerCase() == defaultNetworks?.wethAddress.toLowerCase()) {
      let referenceCoinPrice: any = await storeInstance.dispatch(
        callContractGetMethod(
          "latestAnswer",
          [],
          "oracle",
          false,
          defaultNetworks?.nativeOracle
        )
      );
      return referenceCoinPrice;
    } else {
      return null;
    }
  } catch (error) {
    console.log("error", error);
    throw error;
  }
};

export const getTokenPrice = (address: any) => {
  return (dispatch: Dispatch<any> = useDispatch(), getState: any) =>
    new Promise(async (resolve, reject) => {
      try {
        let _checkToken = await checkToken(address);
        if (_checkToken) {
          resolve(_checkToken);
          return false;
        }
        let defaultNetworks = getState()?.connect.networksDetails;
        let pair: any = await dispatch(
          callContractGetMethod(
            "getPair",
            [address, defaultNetworks?.referenceCoin],
            "factory",
            false
          )
        );
        if (pair != undefined) {
          if (pair === DEFAULT_ADDRESS) {
            pair = await dispatch(
              callContractGetMethod(
                "getPair",
                [address, defaultNetworks?.wethAddress],
                "factory",
                false
              )
            );
            if (pair === DEFAULT_ADDRESS) {
              resolve(false);
            } else {
              const result: any = await getReserves(
                pair,
                address,
                defaultNetworks?.wethAddress,
                true
              );
              const price: any = await getPrice(result.reserves);
              resolve(price);
            }
          } else {
            const result: any = await getReserves(
              pair,
              address,
              defaultNetworks?.referenceCoin,
              false
            );

            const price: any = await getPrice(result.reserves);
            resolve(price);
          }
        } else {
          resolve(false);
        }
      } catch (error) {
        reject(error);
      }
    });
};

export const getReserves = async (
  pair: string,
  address: string,
  referenceCoin: string,
  isBaseCurrency: boolean = false
) => {
  try {
    const reserves: any = await storeInstance.dispatch(
      callContractGetMethod("getReserves", [], "dynamicPair", false, pair)
    );
    if (reserves != undefined) {
      let token_0 = referenceCoin;
      let token_1 = address;
      reserves.referenceCoinPosition = 0;

      if (Number(address) < Number(referenceCoin)) {
        token_0 = address;
        token_1 = referenceCoin;
        reserves.referenceCoinPosition = 1;
      }
      reserves.token_0 = token_0;
      reserves.token_1 = token_1;
      reserves.isBaseCurrency = isBaseCurrency;
      return {
        status: true,
        reserves,
      };
    }
  } catch (error) {
    console.log('getReserves')
    throw error;
  }
};
