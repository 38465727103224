export const DEFAULT_ADDRESS = process.env.REACT_APP_DEFAULT_ADDRESS;
export const API_HOST = process.env.REACT_APP_API_HOST;
export const REFERRAL_URL = process.env.REACT_APP_URL;

export const CHAIN_ID = process.env.REACT_APP_CHAIN_ID;
export const CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME;
export const RPC_URL = process.env.REACT_APP_RPC_URL;
export const CURRENCY_NAME = process.env.REACT_APP_CURRENCY_NAME;
export const CURRENCY_SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
export const CURRENCY_DECIMALS = process.env.REACT_APP_CURRENCY_DECIMALS;
export const BLOCK_EXPLORER = process.env.REACT_APP_EXPLORE_URL;
export const CHAIN_ICON = process.env.REACT_APP_ICON_URL;

//Networks
export const HEX_CHAIN_ID_BSC = process.env.REACT_APP_NETWORK_ID_BSC;
export const HEX_CHAIN_ID_ETH = process.env.REACT_APP_NETWORK_ID_ETH;
export const HEX_CHAIN_ID_ARBITRUM= process.env.REACT_APP_NETWORK_ID_ARBITRUM;

export const CHAIN_ID_BSC = process.env.REACT_APP_CHAIN_ID_BSC;
export const BSC_RPC_URL = process.env.REACT_APP_BSC_RPC_URL;
export const BSC_CONTRACT_ADDRESS = process.env.REACT_APP_BSC_CONTRACT_ADDRESS;
export const BSC_URL = process.env.REACT_APP_BSC_URL;

export const CHAIN_ID_ETH = process.env.REACT_APP_CHAIN_ID_ETH;
export const INFURA_RPC = process.env.REACT_APP_INFURA_RPC;
export const ETH_CONTRACT_ADDRESS = process.env.REACT_APP_ETH_CONTRACT_ADDRESS;
export const ETH_URL = process.env.REACT_APP_ETH_URL;

export const CHAIN_ID_ARBITRUM = process.env.REACT_APP_CHAIN_ID_ARBITRUM;
export const ARBITRUM_RPC = process.env.REACT_APP_ARBITRUM_RPC;
export const ARBITRUM_CONTRACT_ADDRESS = process.env.REACT_APP_ARBITRUM_CONTRACT_ADDRESS;
export const ARBITRUM_URL = process.env.REACT_APP_ARBITRUM_URL;

// Contract Addresses of Sepolia
export const ETH_WETH_CONTRACT = process.env.REACT_APP_ETH_WETH_CONTRACT;
export const ETH_SWAPIT_ROUTER_CONTRACT =process.env.REACT_APP_ETH_SWAPIT_ROUTER_CONTRACT;
export const ETH_SWAPIT_FACTORY_CONTRACT =process.env.REACT_APP_ETH_SWAPIT_FACTORY_CONTRACT;
export const ETH_SWAPIT_MASTERCHEF_CONTRACT =process.env.REACT_APP_ETH_SWAPIT_MASTERCHEF_CONTRACT;
export const ETH_SMART_CHEF_FACTORY =process.env.REACT_APP_ETH_SMART_CHEF_FACTORY;
export const ETH_SWAPIT_TOKEN = process.env.REACT_APP_ETH_SWAPIT_TOKEN;
export const ETH_REFERRAL_CONTRACT =  process.env.REACT_APP_ETH_REFERRAL_CONTRACT;
export const ETH_USDT_ADDRESS = process.env.REACT_APP_ETH_USDT_ADDRESS;
export const ETH_ORACLE_ADDRESS = process.env.REACT_APP_ETH_ORACLE_ADDRESS;
export const ETH_USDT_ORACLE_ADDRESS =  process.env.REACT_APP_ETH_USDT_ORACLE_ADDRESS;
export const ETH_ASSUMED_BLOCK_CREATION_TIME = Number( process.env.REACT_APP_ETH_ASSUMED_BLOCK_CREATION_TIME);

// Contract Addresses of BSC
export const BSC_WETH_CONTRACT = process.env.REACT_APP_BSC_WETH_CONTRACT;
export const BSC_SWAPIT_ROUTER_CONTRACT =
  process.env.REACT_APP_BSC_SWAPIT_ROUTER_CONTRACT;
export const BSC_SWAPIT_FACTORY_CONTRACT =
  process.env.REACT_APP_BSC_SWAPIT_FACTORY_CONTRACT;
export const BSC_SWAPIT_MASTERCHEF_CONTRACT =
  process.env.REACT_APP_BSC_SWAPIT_MASTERCHEF_CONTRACT;
export const BSC_SMART_CHEF_FACTORY =
  process.env.REACT_APP_BSC_SMART_CHEF_FACTORY;
export const BSC_SWAPIT_TOKEN = process.env.REACT_APP_BSC_SWAPIT_TOKEN;
export const BSC_REFERRAL_CONTRACT =
  process.env.REACT_APP_BSC_REFERRAL_CONTRACT;
export const BSC_BUSD_ADDRESS = process.env.REACT_APP_BSC_BUSD_ADDRESS;
export const BSC_BNB_ORACLE_ADDRESS =
  process.env.REACT_APP_BSC_BNB_ORACLE_ADDRESS;
export const BSC_BUSD_ORACLE_ADDRESS =
  process.env.REACT_APP_BSC_BUSD_ORACLE_ADDRESS;
export const BSC_ASSUMED_BLOCK_CREATION_TIME = Number(
  process.env.REACT_APP_BSC_ASSUMED_BLOCK_CREATION_TIME
);

// Contract Addresses of ARBITRUM
export const ARBITRUM_WETH_CONTRACT = process.env.REACT_APP_ARBITRUM_WETH_CONTRACT;
export const ARBITRUM_SWAPIT_ROUTER_CONTRACT =
  process.env.REACT_APP_ARBITRUM_SWAPIT_ROUTER_CONTRACT;
export const ARBITRUM_SWAPIT_FACTORY_CONTRACT =
  process.env.REACT_APP_ARBITRUM_SWAPIT_FACTORY_CONTRACT;
export const ARBITRUM_SWAPIT_MASTERCHEF_CONTRACT =
  process.env.REACT_APP_ARBITRUM_SWAPIT_MASTERCHEF_CONTRACT;
export const ARBITRUM_SMART_CHEF_FACTORY =
  process.env.REACT_APP_ARBITRUM_SMART_CHEF_FACTORY;
export const ARBITRUM_SWAPIT_TOKEN = process.env.REACT_APP_ARBITRUM_SWAPIT_TOKEN;
export const ARBITRUM_REFERRAL_CONTRACT =
  process.env.REACT_APP_ARBITRUM_REFERRAL_CONTRACT;
export const ARBITRUM_USDT_ADDRESS = process.env.REACT_APP_ARBITRUM_USDT_ADDRESS;
export const ARBITRUM_ETH_ORACLE_ADDRESS =
  process.env.REACT_APP_ARBITRUM_ETH_ORACLE_ADDRESS;
export const ARBITRUM_USDT_ORACLE_ADDRESS =
  process.env.REACT_APP_ARBITRUM_USDT_ORACLE_ADDRESS;
export const ARBITRUM_ASSUMED_BLOCK_CREATION_TIME = Number(
  process.env.REACT_APP_ARBITRUM_ASSUMED_BLOCK_CREATION_TIME
);

export const URL = `https://qa.swapittoken.com`;

export const WALLET_TYPE = {
  METAMASK: "METAMASK",
  WALLET_CONNECT: "WALLET_CONNECT",
  TRUST_WALLET: "TRUST_WALLET",
};

export const NETWORKS_DETAILS = {
  NETWORKDETAILS: "NETWORKDETAILS",
};

export const ACTION_TYPES = {
  SET_LOADING: "SET_LOADING",
};

export const NETWORK_TYPES = {
  BSC: "BSC",
  ETH: "ETH",
};

export const NETWORKS = [
  {
    networkName: "BSC Testnet",
    symbol: "BNB",
    chainId: CHAIN_ID_BSC!,
    chainHex: HEX_CHAIN_ID_BSC!,
    icon: "https://assets-cdn.trustwallet.com/blockchains/smartchain/info/logo.png",
    rpc: BSC_RPC_URL,
    routerAddress: BSC_SWAPIT_ROUTER_CONTRACT!,
    factoryAddress: BSC_SWAPIT_FACTORY_CONTRACT!,
    wethAddress: BSC_WETH_CONTRACT!,
    explorerUrl: BSC_URL,
    masterChef: BSC_SWAPIT_MASTERCHEF_CONTRACT,
    decimals: 18,
    usdDecimals: 10 ** 8,
    chainType: "BSC",
    smartChefFactory: BSC_SMART_CHEF_FACTORY,
    swapItToken: BSC_SWAPIT_TOKEN,
    referralContract: BSC_REFERRAL_CONTRACT,
    referenceCoin: BSC_BUSD_ADDRESS,
    nativeOracle: BSC_BNB_ORACLE_ADDRESS,
    tokenOracle: BSC_BUSD_ORACLE_ADDRESS,
  },
  {
    networkName: "Sepolia Testnet",
    symbol: "ETH",
    chainId: CHAIN_ID_ETH!,
    chainHex: HEX_CHAIN_ID_ETH!,
    icon: "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=023",
    rpc: INFURA_RPC,
    routerAddress: ETH_SWAPIT_ROUTER_CONTRACT!,
    factoryAddress: ETH_SWAPIT_FACTORY_CONTRACT!,
    wethAddress: ETH_WETH_CONTRACT!,
    explorerUrl: ETH_URL,
    masterChef: ETH_SWAPIT_MASTERCHEF_CONTRACT,
    decimals: 18,
    usdDecimals: 10 ** 8,
    chainType: "ETH",
    smartChefFactory: ETH_SMART_CHEF_FACTORY,
    swapItToken: ETH_SWAPIT_TOKEN,
    referralContract: ETH_REFERRAL_CONTRACT,
    referenceCoin: ETH_USDT_ADDRESS,
    nativeOracle: ETH_ORACLE_ADDRESS,
    tokenOracle: ETH_USDT_ORACLE_ADDRESS,
  },
  {
    networkName: "Arbitrum Testnet",
    symbol: "ARBTRM",
    chainId: CHAIN_ID_ARBITRUM!,
    chainHex: HEX_CHAIN_ID_ARBITRUM!,
    icon: "https://cryptologos.cc/logos/ethereum-eth-logo.png?v=023",
    rpc: ARBITRUM_RPC,
    routerAddress: ARBITRUM_SWAPIT_ROUTER_CONTRACT!,
    factoryAddress: ARBITRUM_SWAPIT_FACTORY_CONTRACT!,
    wethAddress: ARBITRUM_WETH_CONTRACT!,
    explorerUrl: ARBITRUM_URL,
    masterChef: ARBITRUM_SWAPIT_MASTERCHEF_CONTRACT,
    decimals: 18,
    usdDecimals: 10 ** 8,
    chainType: "ARBTRM",
    smartChefFactory: ARBITRUM_SMART_CHEF_FACTORY,
    swapItToken: ARBITRUM_SWAPIT_TOKEN,
    referralContract: ARBITRUM_REFERRAL_CONTRACT,
    referenceCoin: ARBITRUM_USDT_ADDRESS,
    nativeOracle: ARBITRUM_ETH_ORACLE_ADDRESS,
    tokenOracle: ARBITRUM_USDT_ORACLE_ADDRESS,
  },
];

export const LIMIT = 10;
export const PROJECT_ID = 'babce404b37c5df404a52acc530181e7';
