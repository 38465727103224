import { Dispatch, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/store";
import rewardIcon from "../../assets/images/rewardIcon.png";
import "./ReferalPage.scss";

import { useLocation, useNavigate } from "react-router-dom";
import { callContractGetMethod } from "../../app/actions/contract.action";
import { SocialShare } from "../../component/common/SocialShare/SocialShare/SocialShare";
import { callApiGetMethod } from "../../app/actions/api.action";
import { REFERRAL_URL } from "../../constant";
import CommonBtn from "../../component/ui/CommonBtn/CommonBtn";
import { CommonService } from "../../services/commonService";
import CustomOverlay from "../../component/common/CustomOverlay";

const ReferalPage = () => {
  const userDetails: any = useSelector((state: RootState) => state.connect);
  const dispatch: Dispatch<any> = useDispatch();
  const navigate: any = useNavigate();
  const walletAddress: any = useSelector(
    (state: RootState) => state.connect.walletAddress
  );
  const [referralCommision, setReferralCommision] = useState<any>();
  const [referralsCount, setReferralsCount] = useState<any>();
  const [userInsventInfo, setUserInsventInfo] = useState<any>();
  const [rewardSymbol, setRewardSymbol] = useState<any>("");
  const [showFullRefferralCommision, setShowFullRefferralCommision] =
    useState<any>();

  const location: any = useLocation();
  const defaultNetworks = useSelector(
    (state: RootState) => state.connect.networksDetails
  );

  useEffect(() => {
    if (location.pathname.split("/").includes("socialShare")) {
      navigate("/?wallet=" + location.pathname.split("/")[2]);
    }
  }, [location]);

  const getUserInvestmentInfo = async (loading: boolean = true) => {
    const result: any = await dispatch(
      callApiGetMethod(
        "GET_USER_INVESTMENT",
        { chainType: defaultNetworks?.chainType, userAddress: walletAddress },
        loading
      )
    );
    if (result && result?.success) {
      setUserInsventInfo(result?.userInvestData);
      referralData();
    }
  };

  useEffect(() => {
    if (defaultNetworks && walletAddress) {
      getUserInvestmentInfo();
    } else {
      setUserInsventInfo("");
    }
  }, [walletAddress]);

  const referralData = async () => {
    let res: any = await dispatch(
      callContractGetMethod(
        "totalReferralCommissions",
        [walletAddress],
        "referral",
        false
      )
    );
    let rewardToken: any = await dispatch(
      callContractGetMethod("SwapIT", [], "master", false)
    );
    let rewardTokenDecimals: any = await dispatch(
      callContractGetMethod("decimals", [], "dynamic", false, rewardToken)
    );
    let rewardTokenSymbol: any = await dispatch(
      callContractGetMethod("symbol", [], "dynamic", false, rewardToken)
    );
    setRewardSymbol(rewardTokenSymbol);
    setShowFullRefferralCommision(
      CommonService.toFixed(res / 10 ** rewardTokenDecimals)
    );

    setReferralCommision(
      CommonService.cryptoDecimals(
        CommonService.divideBigNumber(res, 10 ** rewardTokenDecimals)
      )
    );
    let result: any = await dispatch(
      callContractGetMethod(
        "referralsCount",
        [walletAddress],
        "referral",
        false
      )
    );
    setReferralsCount(result);
  };

  return (
    <section className="refer_page">
      <Container>
        <Row>
          <Col md={7}>
            <div className="refer_page_left">
              <h2>
                Time To <span>Connect</span>
              </h2>
              <h3>
                Invite Your Friends To Earn Rewards From Their SwapIt Staking
                Rewards
              </h3>
              <p>
                Every direct referral provides 1% income on SwapIt farm staking
                reward claims. Every indirect referral provides an additional 1%
                referral reward. Users can receive these rewards on their
                indirect referrals for up to the 3-rd level. Referral rewards
                are paid in SwapIt tokens.
              </p>
              <img src={rewardIcon} alt="" className="refer_img" />
            </div>
          </Col>
          <Col md={5}>
            {userInsventInfo ? (
              <>
                <SocialShare
                  socialLinks={[
                    "Facebook",
                    "LinkedIn",
                    "Email",
                    "Whatsapp",
                    "Pinterest",
                    "Twitter",
                  ]}
                  url={REFERRAL_URL + "farms?ref=" + userDetails?.walletAddress}
                />
                <div className="refer_box_wrap">
                  <div className="refer_box">
                    <h4>Total referrals</h4>
                    <p>{referralsCount ? referralsCount : "0.00"}</p>
                  </div>
                  <div className="refer_box">
                    <h4>Total Referral Commission</h4>
                    <p>
                      {referralCommision ? referralCommision : "0.00"}{" "}
                      {rewardSymbol}{" "}
                      <CustomOverlay value={showFullRefferralCommision} />
                    </p>
                  </div>
                </div>
              </>
            ) : walletAddress ? (
              <div className="refer_page_left no_investment">
                <h2>
                  Sorry! <span>No investments.</span>
                </h2>
                <p>
                  Start investing and earn rewards by inviting your friends.
                </p>
                <CommonBtn
                  className="mt-5"
                  role="link"
                  to="/farms"
                  type="button"
                  title="Start Investing"
                />
              </div>
            ) : (
              <div className="refer_page_left no_investment">
                <h2>
                  Sorry! <span>No wallet connected.</span>
                </h2>
                <p>
                  Connect your wallet and earn rewards by inviting your friends.
                </p>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ReferalPage;
