import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { InfoIcon } from '../../../assets/svgIcons/svgIcons';
import './index.scss';


const CustomOverlay = ({ value, btnClassName }: any) => {
  const [TipTool, setTipTool] = useState<any>("right");
  useEffect(() => {

    if (window.innerWidth < 1500) {
      setTipTool("bottom");
    } else {
      setTipTool("right");
    }
    const handleResize = () => {
      if (window.innerWidth < 1500) {
        setTipTool("bottom");
      } else {
        setTipTool("right");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <OverlayTrigger
      trigger={["hover", "hover"]}
      delay={{ show: 250, hide: 400 }}
      placement={TipTool}
      overlay={(
        <Popover className="newPopover">
          <Popover.Body>
            {value}
          </Popover.Body>

        </Popover>
      )}
      rootClose
    >
      <button type="button" className={`globalPopbtn ms-2 ${btnClassName}`}>
        <InfoIcon />
      </button>
    </OverlayTrigger>
  )
}
export default CustomOverlay;