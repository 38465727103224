import { useEffect, useState } from 'react';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';

import './CommonDateTimePicker.scss';

const CommonDateTimePicker = ({ blockNumberDate, label, subtitle, setTimeStamp, getEndBlock, inputtext }: any) => {
    const [time, setTime] = useState<any>();
    useEffect(() => {
        if (Date.parse(blockNumberDate)) {
            selectTime('');
        }
    }, [blockNumberDate]);

    const selectTime = (e: any) => {
        if (e && e) {
            setTime(new Date(e ? e : new Date()))
        } else if (blockNumberDate) {
            setTime(blockNumberDate)
        } else {
            setTime(null)
        }
    }
    useEffect(() => {
        if (time != null && time != undefined) {
            getDate();
        } else if (setTimeStamp) {
            setTimeStamp(0);
        } else {
            getEndBlock(0);
        }
        
    }, [time])
    const getDate = async () => {

        if ((setTimeStamp != undefined && setTimeStamp != null) || (getEndBlock != undefined && getEndBlock != null)) {
            if (setTimeStamp) {
                await setTimeStamp(time?.getTime());
            } else {
                await getEndBlock(time?.getTime());
            }
        }
    }
        return (
        <>
            <div className="common_datetime">
                <label>{label}</label>
                <DateTimePicker disableClock={true} onChange={(e: any) => selectTime(e)} value={time} format="y-MM-dd  h:mm:ss a" disabled={inputtext} />
                <small className="mt-4 d-block">{subtitle}</small>
            </div>
        </>
    )
}

export default CommonDateTimePicker