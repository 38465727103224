import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICustomToken } from "../interfaces/swapAndLiquidity.interface";

const initialState: ICustomToken = {
  tokenInfo: []
};
const customTokenSlice = createSlice({
  name: "customToken",
  initialState,
  reducers: {
    addTokenInfo(state: any, actions: PayloadAction<any>) {
      state.tokenInfo.push(actions.payload);
    }
  }

});

export const { addTokenInfo } = customTokenSlice.actions;
export default customTokenSlice.reducer;
