import { CommonService } from "../../../../services/commonService";
import CustomOverlay from "../../../common/CustomOverlay";
import CommonTable from "../../CommonTable/CommonTable";
import CommonModal from "../CommonModal/CommonModal";
import "./APRcalculator.scss";

const APRcalculator = ({
  show,
  onHide,
  rewardTokenPrice,
  apr,
  nameReward,
  decimalsReward,
}: any) => {
  return (
    <>
      <CommonModal
        show={show}
        onHide={onHide}
        modalTitle="Return on Invest"
        className="APRCal"
      >
        <CommonTable className="h-auto  mt-5">
          <thead>
            <tr>
              <th>Duration</th>
              <th>ROI</th>
              <th>{`${
                nameReward ? nameReward?.toUpperCase() : "SWAPIT"
              } PER $1000`}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1d</td>
              <td>
                {apr ? CommonService.cryptoDecimals(apr / 365) : 0} %
                <CustomOverlay
                  value={apr ? CommonService?.toFixed(apr / 365) : 0}
                />
              </td>
              <td>
                {apr
                  ? CommonService.toFixed(
                      CommonService.cryptoDecimals(
                        ((apr / 365) * 1000 * 10 ** 8) /
                          (100 * rewardTokenPrice)
                      )
                    )
                  : 0}{" "}
                <CustomOverlay
                  value={CommonService?.toFixed(
                    apr
                      ? ((apr / 365) * 1000 * 10 ** 8) /
                          (100 * rewardTokenPrice)
                      : 0
                  )}
                />
              </td>
            </tr>
            <tr>
              <td>7d</td>
              <td>
                {apr ? CommonService.cryptoDecimals((apr / 365) * 7) : 0} %
                <CustomOverlay
                  value={apr ? CommonService.toFixed((apr / 365) * 7) : 0}
                />
              </td>
              <td>
                {apr
                  ? CommonService.toFixed(
                      CommonService.cryptoDecimals(
                        ((apr / 365) * 7000 * 10 ** 8) /
                          (100 * rewardTokenPrice)
                      )
                    )
                  : 0}{" "}
                <CustomOverlay
                  value={CommonService?.toFixed(
                    apr
                      ? ((apr / 365) * 7000 * 10 ** 8) /
                          (100 * rewardTokenPrice)
                      : 0
                  )}
                />
              </td>
            </tr>
            <tr>
              <td>30d</td>
              <td>
                {apr ? CommonService.cryptoDecimals((apr / 365) * 30) : 0} %
                <CustomOverlay
                  value={apr ? CommonService.toFixed((apr / 365) * 30) : 0}
                />
              </td>
              <td>
                {apr
                  ? CommonService.toFixed(
                      CommonService.cryptoDecimals(
                        ((apr / 365) * 30000 * 10 ** 8) /
                          (100 * rewardTokenPrice)
                      )
                    )
                  : 0}{" "}
                <CustomOverlay
                  value={CommonService?.toFixed(
                    apr
                      ? ((apr / 365) * 30000 * 10 ** 8) /
                          (100 * rewardTokenPrice)
                      : 0
                  )}
                />
              </td>
            </tr>
            <tr>
              <td>365d(APR)</td>
              <td>
                {apr ? CommonService.cryptoDecimals(apr) : 0}%{" "}
                <CustomOverlay value={apr ? CommonService.toFixed(apr) : 0} />
              </td>
              <td>
                {apr
                  ? CommonService.toFixed(
                      CommonService.cryptoDecimals(
                        (apr * 1000 * 10 ** 8) / (100 * rewardTokenPrice)
                      )
                    )
                  : 0}{" "}
                <CustomOverlay
                  value={CommonService?.toFixed(
                    apr ? (apr * 1000 * 10 ** 8) / (100 * rewardTokenPrice) : 0
                  )}
                />
              </td>
            </tr>
          </tbody>
        </CommonTable>
        <p>
          Calculated based on current rates. Compounding once daily. Rates are
          estimates provided for your convenience only, and by no means
          represent guaranteed returns.
        </p>
      </CommonModal>
    </>
  );
};

export default APRcalculator;
