import { Col, Container, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import Fb from '../../../assets/icons/fb.png';
import Lunilogo from '../../../assets/icons/logo.png';
import Telegram from '../../../assets/icons/tele.png';
import Youtube from '../../../assets/icons/utube.png';
import Logo from '../../../assets/images/logo.png';
import './Footer.scss';

import Gmail from '../../../assets/icons/gmail.png';

import Twitter from '../../../assets/icons/twitter.png';


const Footer = () => {
  return (
    <>
      <footer className="footer">
        <Container>
          <div className="footerTop">
            <Row className="align-items-end">
              <Col md={4} lg={4} xl={5} className="footerInfo">
                <Link to="/"><img src={Logo} alt="logo" /></Link>
                <p>SwapIT is a hyper-deflationary token Stealth Launching on the Binance Smart Chain</p>
              </Col>
              <Col md={5} lg={5} xl={5} className="footerQuickLinks">
                <div className="quick">
                  <h5>Quick Links</h5>
                  <ul>
                    <li><NavLink to="/">Swap</NavLink></li>
                    <li><NavLink to="/liquidity">Liquidity</NavLink></li>
                    <li><NavLink to="/farms">Farms</NavLink></li>
                    <li><NavLink to="/pools">Pools</NavLink></li>
                    <li><NavLink to="referral">Referral</NavLink></li>
                  </ul>
                </div>
              </Col>
              <Col md={3} lg={2} xl={2} className="footerQuickLinks">
                <div className="d-table mx-auto ms-md-auto">
                  <h5>Network Chains</h5>
                  <ul>
                    <li><span>Ethereum</span></li>
                    <li><span>Binance</span></li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
          <div className="footerCopyRight">
            <Row className="align-items-center">
              <Col sm={5} md={6}>
                <ul>
                  <li><Link to="www.gmail.com" target="_blank"><img src={Gmail} alt="" /></Link></li>
                  <li><Link to="https://www.facebook.com/" target="_blank"><img src={Fb} alt="" /></Link></li>
                  <li><Link to="https://www.youtube.com" target="_blank"><img src={Youtube} alt="" /></Link></li>
                  <li><Link to="https://twitter.com" target="_blank"><img src={Twitter} alt="" /></Link></li>
                  <li><Link to="https://t.me" target="_blank"><img src={Telegram} alt="" /></Link></li>
                </ul>
              </Col>
              <Col sm={7} md={6} className="text-sm-end">
                <p>© Copyright 2024 Swapit. All Rights Reserved.</p>
              </Col>
            </Row>
          </div>
        </Container>
      </footer >
    </>
  )
}

export default Footer