import { Dispatch, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../../app/actions/contract.action";
import InputCustom from "../../../common/Input/InputCustom";
import { useDispatch, useSelector } from "react-redux";
import CommonBtn from "../../CommonBtn/CommonBtn";
import CommonModal from "../CommonModal/CommonModal";
import "./StakeTokensModal.scss";
import { RootState } from "../../../../app/store";
import { CommonService } from "../../../../services/commonService";
import Toast from "../../../common/Toast";
import { DEFAULT_ADDRESS } from "../../../../constant";
import Web3 from "web3";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { ContractService } from "../../../../services/ContractService";
import { useAccount } from "wagmi";

const StakeTokensModal = ({
  pid,
  type,
  name,
  lpToken,
  lpTokenDecimals,
  callback,
}: any) => {
  const { connector } = useAccount();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const location = useLocation();

  const walletAddress: any = useSelector(
    (state: RootState) => state.connect.walletAddress
  );
  const dispatch: Dispatch<any> = useDispatch();
  const [userBalance, setUserBalance] = useState<any>(0);

  const [amount, setAmount] = useState<any>("");
  const [referralAddress, setreferralAddress] = useState<any>("");

  const [lpStaked, setLpStaked] = useState<any>(0);

  const getDetails = async () => {
    const balance: any = await dispatch(
      callContractGetMethod(
        "balanceOf",
        [walletAddress],
        "dynamic",
        false,
        lpToken
      )
    );
    setUserBalance(
      CommonService.toFixed(
        CommonService.divideBigNumber(
          balance.toString(),
          lpTokenDecimals.toString()
        )
      )
    );
    let userInfo: any = await dispatch(
      callContractGetMethod("userInfo", [pid, walletAddress], "master", false)
    );
    setLpStaked(
      CommonService.divideBigNumber(
        userInfo?.amount.toString(),
        lpTokenDecimals.toString()
      )
    );
  };

  useEffect(() => {
    if (show && walletAddress) {
      getDetails();
    }
    setAmount("");
  }, [show, walletAddress]);

  useEffect(() => {
    // execute on location change
    let params = queryString.parse(location.search);
    if (params.ref && show) {
      setreferralAddress(params.ref);
    }
  }, [location, show]);

  const handleAction = async (e: any) => {
    if (type === "Emergency Withdraw") {
      onSubmit(e);
    } else {
      handleShow();
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (walletAddress) {
      let totalAmount = CommonService.convertWithDecimal(
        amount,
        lpTokenDecimals
      );
      let provider = await connector?.getProvider();
      if (type === "Stake") {
        if (parseFloat(amount) === 0) {
          Toast.error("Please enter valid amount");
          return false;
        }
        if (Number(amount) > Number(userBalance)) {
          Toast.error("Sorry you don't have enough balance");
          return false;
        }
        let referral: any = DEFAULT_ADDRESS;
        if (referralAddress) {
          if (Web3.utils.isAddress(referralAddress)) {
            referral = referralAddress;
          } else {
            Toast.error("Please enter valid referral address");
            return false;
          }
        }
        let response: any = await dispatch(
          callContractSendMethod(
            provider,
            "deposit",
            [
              pid,
              totalAmount,
              referral == walletAddress ? DEFAULT_ADDRESS : referral,
            ],
            walletAddress,
            "master",
            "",
            lpToken
          )
        );
        if (response?.status) {
          handleClose();
          callback();
          Toast.success("Staked! Your funds have been staked in the farm");
        }
      } else if (type === "Unstake") {
        if (parseFloat(amount) === 0) {
          Toast.error("Please enter valid amount");
          return false;
        }
        let response: any = await dispatch(
          callContractSendMethod(
            provider,
            "withdraw",
            [pid, totalAmount],
            walletAddress,
            "master",
            "",
            lpToken
          )
        );
        if (response?.status) {
          handleClose();
          callback();
          Toast.success(
            "Unstaked! Your funds have been transferred to your wallet"
          );
        }
      } else if (type === "Emergency Withdraw") {
        let response: any = await dispatch(
          callContractSendMethod(
            provider,
            "emergencyWithdraw",
            [pid],
            walletAddress,
            "master",
            "",
            lpToken
          )
        );
        if (response?.status) {
          handleClose();
          callback();
          Toast.success(
            "Withdraw! Your funds have been transferred to your wallet"
          );
        }
      }
    } else {
      Toast.error("Please connect your wallet first");
    }
  };
  const setInputAmount = (e: any) => {
    let convertedvalue = CommonService.allowOnlyNumberWithDecimals(
      e.target.value,
      "stake",
      lpTokenDecimals
    );

    if (convertedvalue) {
      setAmount(e.target.value);
    }
  };
  return (
    <>
      <CommonBtn
        onClick={handleAction}
        title={type}
        className={`enable_btn w-auto d-inline-flex ${
          type === "Stake"
            ? ""
            : type === "Unstake"
            ? "border-gradienttextWhite"
            : "bordred-red"
        }`}
      />
      <CommonModal
        className="StakeToken"
        modalTitle={`${type} LP Tokens`}
        show={show}
        onHide={handleClose}
      >
        <Form onSubmit={onSubmit}>
          <div className="py-5 w-100">
            <Row>
              <Col>
                <p>{type}</p>
              </Col>
              <Col>
                {" "}
                <p className="text-end">{name + " LP"}</p>
              </Col>
            </Row>
            <Form.Group className="inputHandle">
              <InputCustom
                className="mb-0 max-field amount"
                required
                value={amount}
                onChange={(e: any) => setInputAmount(e)}
                type="text"
                step="any"
                label={""}
                placeholder="0.00"
                smallText={
                  userBalance > 0 || type !== "Stake" ? (
                    ""
                  ) : (
                    <>
                      <span className="text-danger">No token to stake</span>
                    </>
                  )
                }
              />
              {type === "Stake" || type === "Unstake" ? (
                <Form.Label
                  onClick={() => {
                    setAmount(
                      type === "Stake"
                        ? CommonService.toFixed(userBalance)
                        : CommonService.toFixed(lpStaked)
                    );
                  }}
                >
                  Max
                </Form.Label>
              ) : null}
            </Form.Group>
            <hr />
            <p className="StakeToken-Bal-stake" title={userBalance}>
              Balance: {CommonService.toFixed(userBalance)}
            </p>
            <p
              className="StakeToken-Bal-stake"
              title={CommonService.toFixed(lpStaked)}
            >
              Staked: {CommonService.toFixed(lpStaked)}{" "}
            </p>
          </div>
          <hr className="mb-4 pb-2" />
          <Row>
            <Col>
              <CommonBtn
                title="Cancel"
                className="w-100 "
                onClick={handleClose}
                type="button"
              />
            </Col>
            <Col>
              <CommonBtn title="Confirm" className="w-100 " type="submit" />
            </Col>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};
export default StakeTokensModal;
