import Lottie from "lottie-react";
import { Dispatch, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApiPostMethod } from '../../app/actions/api.action';
import { callContractGetMethod } from '../../app/actions/contract.action';
import { RootState } from '../../app/store';
import EmptyLottie from "../../assets/lottie/empty-file.json";
import CustomOverlay from '../../component/common/CustomOverlay';
import Toast from '../../component/common/Toast';
import CommonBtn from "../../component/ui/CommonBtn/CommonBtn";
import SelectToken from '../../component/ui/Modals/SelectToken/SelectToken';
import { CommonService } from '../../services/commonService';

export const ImportPool = ({ onClickLiquidity }: any) => {
    const dispatch: Dispatch<any> = useDispatch();

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [fromSelected, setFromSelected] = useState<any>();
    const [toSelected, setToSelected] = useState<any>();
    const [token0Selected, setToken0Selected] = useState<any>();
    const [token1Selected, setToken1Selected] = useState<any>();
    const [lpAddress, setLpAddress] = useState<any>();
    const [userLPAmount, setUserLPAmount] = useState<any>(0);
    const [lpDecimals, setLpDecimals] = useState<any>(0);
    const [isHavingLiquidity, setHavingLiquidity] = useState<any>(false);
    const walletAddress: any = useSelector((state: RootState) => state.connect.walletAddress);
    const defaultNetworks = useSelector((state: RootState) => state.connect.networksDetails);

    // Heading Case
    const [HeadingCase, setHeadingCase] = useState("Default");
    const caseClick = (e: any) => {
        setHeadingCase(e);
    };
    useEffect(() => {
        if (fromSelected && toSelected) {
            setHavingLiquidity(false);
            findPair();
            // onClickLiquidity(selected);
        }
    }, [fromSelected, toSelected])
    const findPair = async () => {
        let temp: any = [];
        temp.push(toSelected?.address, fromSelected?.address);
        let pairAddress: any;
        try {
            pairAddress = await dispatch(
                callContractGetMethod("getPair", temp, "factory", false, "", false)
            );
            setLpAddress(pairAddress)
            if (Number(fromSelected?.address) < Number(toSelected?.address)) {
                setToken0Selected(fromSelected);
                setToken1Selected(toSelected);
            } else {

                setToken0Selected(toSelected);
                setToken1Selected(fromSelected);
            }
        } catch (error) {
            Toast.error('Pair does not exists');
            reset();
        }
        if (pairAddress) {
            try {
                const userLPBalance: any = await dispatch(
                    callContractGetMethod("balanceOf", [walletAddress], "dynamicPair", false, pairAddress, false)
                );
                const lpDecimal: any = await dispatch(
                    callContractGetMethod("decimals", [], "dynamicPair", false, pairAddress, false)
                );
                setUserLPAmount(userLPBalance);
                setLpDecimals(10 ** lpDecimal);
                setHavingLiquidity(true);
            } catch (error) {
                Toast.error('User has not added liquidity for this pair.');
                reset();
            }

        }

    }
    const reset = () => {
        setLpAddress('')
        setHavingLiquidity(true);
        setToken0Selected('');
        setToken1Selected('');
        setUserLPAmount(0);
    }

    const findPairsOfLiquidity = async () => {
        let data = { chainType: defaultNetworks?.chainType, pair: lpAddress, userAddress: walletAddress, token0: token0Selected?.address, token1: token1Selected?.address }
        const res: any = await dispatch(callApiPostMethod('FINDPAIR', data));

    }


    return (
        <>
            <div className="SwapCardImportPool">

                {/* <button className="importBtn" > */}
                <SelectToken
                    selected={fromSelected}
                    disabled={toSelected}
                    callback={setFromSelected}
                    className="importBtn"
                />
                {/* </button> */}


                {/* <button className="importBtn" > */}
                <SelectToken
                    selected={toSelected}
                    disabled={fromSelected}
                    callback={setToSelected}
                    className="importBtn"
                />
                {/* </button> */}
                <p className="titleBottom">
                    Select Your Token To Find Your Liquidity
                </p>
            </div>

            {isHavingLiquidity ? (userLPAmount > 0 ?
                <>
                    <div className="SwapCardImportPool pt-5">
                        <button className='importBtn custom'>
                            <div className="left">
                                <img src={token0Selected?.icon} alt="" className="pairOne" />
                                <img src={token1Selected?.icon} alt="" className="pairTwo" />
                                <span>{token0Selected?.symbol}/{token1Selected?.symbol}</span>
                            </div>
                            <div className="right">
                                <span>Your LP: <span className='p-0'>{CommonService.fixedToDecimal(CommonService.toFixed((CommonService.divideBigNumber(userLPAmount, lpDecimals))), 2)}</span></span>
                                <CustomOverlay btnClassName="ms-5" value={CommonService.toFixed(CommonService.divideBigNumber(userLPAmount, lpDecimals))} />
                            </div>
                        </button>

                    </div>
                    <div className="mt-auto">
                        <CommonBtn title="Update Pair" className=" w-100" onClick={findPairsOfLiquidity}
                            type="button"
                        />
                    </div>
                </>
                :
                <>
                    <div className="SwapCardNoLiquidityFound bordred">
                        <h4>No Pair Found</h4>
                        <div className="Lottie">
                            <Lottie animationData={EmptyLottie} loop={true} style={{ width: 192 }} />
                        </div>
                        <CommonBtn title="CREATE PAIR" className="" onClick={() => onClickLiquidity({ fromSelected, toSelected })}
                            type="button"
                        />
                    </div>
                </>
            ) : ''
            }
        </>
    );
}


