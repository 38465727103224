import { createSlice } from "@reduxjs/toolkit";
import { ILoaderSlice } from "../interfaces/loader.interface";

const initialState: ILoaderSlice = {
  loader: null,

};
const loaderSlice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    loading(state, actions) {
      state.loader = actions.payload.loader;
    },
  },
});

export const { loading } = loaderSlice.actions;
export default loaderSlice.reducer;
