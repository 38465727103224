import Lottie from "lottie-react";
import moment from "moment";
import { Dispatch, useEffect, useState } from "react";
import { Col, Form, Popover, Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Web3 from "web3";
import { callApiGetMethod } from "../../../app/actions/api.action";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../app/actions/contract.action";
import { RootState } from "../../../app/store";
import SwapArrow from "../../../assets/icons/swapArrow.svg";
import PulseLottie from "../../../assets/lottie/pulse.json";
import {
  BackArrow,
  InfoIcon,
  RefreshIcon,
  SettingIcon,
  TimerRefreshIcon,
} from "../../../assets/svgIcons/svgIcons";
import CustomOverlay from "../../../component/common/CustomOverlay";
import InputCustom from "../../../component/common/Input/InputCustom";
import Toast from "../../../component/common/Toast";
import CommonBtn from "../../../component/ui/CommonBtn/CommonBtn";
import ConnectWallet from "../../../component/ui/Modals/ConnectWallet/ConnectWallet";
import RecentTransactions from "../../../component/ui/Modals/RecentTransactions/RecentTransactions";
import SelectToken from "../../../component/ui/Modals/SelectToken/SelectToken";
import { DEFAULT_ADDRESS } from "../../../constant";
import {
  addDeadline,
  addSwapCustomSlippage,
  swapHistory,
} from "../../../features/swapAndLiquidity.slice";
import { CommonService } from "../../../services/commonService";
import "./SwapCard.scss";
import { useAccount } from "wagmi";
import { useWeb3Modal, useWeb3ModalState } from "@web3modal/wagmi/react";

const BigNumber = require("big-number");

const SwapCard = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const defaultNetworks = useSelector(
    (state: RootState) => state?.connect?.networksDetails
  );

  const settingsInfo: any = useSelector((state: RootState) => state.settings);

  const walletAddress = useSelector(
    (state: any) => state.connect.walletAddress
  );

  const [slippage, setSlippage] = useState<any>("");
  const [txDeadline, setTxDeadline] = useState<any>("10");
  const [show, setShow] = useState(false);
  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);

  const [CommonCase, setCommonCase] = useState("Default");

  const [fromSelected, setFromSelected] = useState<any>();
  const [toSelected, setToSelected] = useState<any>();
  const [fromInput, setFromInput] = useState<any>(0);
  const [toInput, setToInput] = useState<any>(0);
  const [reserveAmount, setReserveAmount] = useState<any>();

  const [inputType, setInputType] = useState<any>("");
  const [isPairExist, setIsPairExist] = useState(true);
  const [isEnoughReserve, setIsEnoughReserve] = useState(true);

  const [allowance, setAllowance] = useState<any>();
  const [fromTokenBalance, setFromTokenBalance] = useState<any>();
  const [toTokenBalance, setToTokenBalance] = useState<any>();

  const [isPriceShow, setIsPriceShow] = useState(true);
  const [isEnoughBalance, setIsEnoughBalance] = useState(true);
  const [valueSwap, setIsValueSwap] = useState(false);

  const [manualSlippage, setManualSlippage] = useState<any>();

  const [slippageError, setSlippageError] = useState(false);
  const [isInputFocused, setInputFocused] = useState(false);
  const [isEnoughInput, setIsEnoughInput] = useState(true);

  const [showInputAmount, setShowInputAmount] = useState("");
  const [showOutputAmount, setShowOutputAmount] = useState("");

  const { connector } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState();
  const { open } = useWeb3Modal();

  const caseClick = (e: any) => {
    setCommonCase(e);
  };
  const [TipTool, setTipTool] = useState<any>("right");

  //show default selected dropdown based on current network and platform token

  useEffect(() => {
    if (defaultNetworks) {
      setTimeout(async () => await onIt(), 1000);
      getTokenBalance();
    }
  }, [defaultNetworks?.chainId]);

  const onIt = async () => {
    if (defaultNetworks) {
      let tokenList: any = await dispatch(
        callApiGetMethod(
          `GET_TOKEN`,
          {
            page: 1,
            limit: 10,
            chainType: defaultNetworks?.chainType,
          },
          false
        )
      );

      if (tokenList && tokenList?.data?.docs) {
        tokenList = tokenList?.data?.docs;
        let fromDefault = tokenList.filter(
          (item: any) => item.symbol == defaultNetworks?.symbol
        );

        if (fromDefault && fromDefault.length > 0) {
          setFromSelected(fromDefault[0]);
        }
        let toDefault = tokenList.filter(
          (item: any) => item.name.toLowerCase() == "SwapIt".toLowerCase()
        );
        if (toDefault && toDefault.length > 0) {
          setToSelected(toDefault[0]);
        }
      }
    }
  };

  useEffect(() => {
    if (window.innerWidth < 1280) {
      setTipTool("bottom");
    } else {
      setTipTool("right");
    }
    const handleResize = () => {
      if (window.innerWidth < 1280) {
        setTipTool("bottom");
      } else {
        setTipTool("right");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (settingsInfo && settingsInfo?.swapCustomSlippage) {
      setSlippage(settingsInfo?.swapCustomSlippage);
    } else {
      setSlippage("0.1");
      dispatch(addSwapCustomSlippage({ swapCustomSlippage: "0.1" }));
    }
    setSlippageError(false);

    if (settingsInfo && settingsInfo?.txnDeadline) {
      setTxDeadline(settingsInfo?.txnDeadline);
    } else {
      setTxDeadline("10");
      dispatch(addDeadline({ txnDeadline: "10" }));
    }
  }, [CommonCase]);

  const LowRiskpopover = (
    <Popover className="commonPopover low-risk">
      <Popover.Body>
        <p>
          Risk scan results are provided by a third party{" "}
          <Link to="#" className="clr-green">
            <u>AvengerDAO</u>
          </Link>
        </p>
        <p>
          It is a tool for indicative purposes only to allow users to check the
          reference risk level of a BCIO Chain Smart Contract. Please do your
          own research - interactions with any BCIO Chain Smart Contract is at
          your own risk.
        </p>
        <p>
          Learn more about risk rating{" "}
          <Link to="#" className="clr-green">
            <u>Here.</u>
          </Link>
        </p>
      </Popover.Body>
    </Popover>
  );
  const MinimumReceivedpopover = (
    <Popover className="commonPopover">
      <Popover.Body>
        Your transaction will revert if there is a large, unfavorable price
        movement before it is confirmed.
      </Popover.Body>
    </Popover>
  );
  const PriceImpactpopover = (
    <Popover className="commonPopover">
      <Popover.Body>
        <p>
          <strong className="clr-green">AMM:</strong> The difference between the
          market price and estimated price due to trade size.{" "}
        </p>
        <p>
          <strong className="clr-green">MM:</strong> No slippage against quote
          from market maker
        </p>
      </Popover.Body>
    </Popover>
  );
  const ProviderFeepopover = (
    <Popover className="commonPopover provider-fee">
      <Popover.Body>
        <p>
          <strong className="clr-green">AMM:</strong> For each trade a 0.25% fee
          is paid
        </p>
        <ul>
          <li>0.17% to LP token holders</li>
          <li>0.0225% to the Treasury</li>
          <li>0.0575% towards Lnatics buyback and burn</li>
        </ul>
        <p>
          <strong className="clr-green">MM:</strong> SwapItSwap does not charge
          any fees for trades. However, the market makers charge an implied fee
          of 0.05% (non-stablecoin) / 0.01% (stablecoin) factored into the
          quotes provided by them.
        </p>
      </Popover.Body>
    </Popover>
  );
  const Routepopover = (
    <Popover className="commonPopover">
      <Popover.Body>
        <p>
          Routing through these tokens resulted in the best price for your
          trade.
        </p>
      </Popover.Body>
    </Popover>
  );
  const Settingsopover = (
    <Popover className="commonPopover">
      <Popover.Body>
        <p>
          Setting a high slippage tolerance can help transactions succeed, but
          you may not get such a good price. Use with caution.
        </p>
      </Popover.Body>
    </Popover>
  );

  const SettingTxDeadlinePopover = (
    <Popover className="commonPopover">
      <Popover.Body>
        <p>
          Your transaction will revert if it is left confirming for longer than
          this time.{" "}
        </p>
      </Popover.Body>
    </Popover>
  );
  const SwapNLiquidity = [
    { title: "Default" },
    { title: "Standard(5)" },
    { title: "Fast(5)" },
    { title: "Instant(7)" },
  ];
  const SlippageTolerance = [
    { title: "0.1" },
    { title: "0.5" },
    { title: "1.0" },
  ];
  const TxDeadline = [
    { title: "10" },
    { title: "15" },
    { title: "20" },
    { title: "25" },
  ];

  //swap handler functions

  //use to calculate amount based on to and from fields

  useEffect(() => {
    commonFunctionCalculations();
  }, [toInput, fromInput]);

  const commonFunctionCalculations = () => {
    let decimals: number;
    let data: any = {};
    setIsEnoughBalance(true);
    setIsEnoughReserve(true);
    //get calculated amount in and amount out based on exact swaps

    if (inputType === "to") {
      if (Number(toInput) && Number(toInput) > 0 && isPairExist) {
        //if both token and navtive token are not selected
        if (
          fromSelected?.address === undefined ||
          toSelected?.address === undefined
        ) {
          setFromInput(0);
        } else {
          decimals =
            toSelected && toSelected.decimals ? toSelected.decimals : null;
          data.amount = CommonService.convertWithDecimal(toInput, decimals);
          if (Number(data.amount) > Number(reserveAmount?.reserveOut)) {
            setFromInput("");
            setIsEnoughReserve(false);
          } else {
            setIsEnoughReserve(true);
            //view msg whenever price impact is >100
            let _priceImpact = viewPriceImpact();
            if (_priceImpact > 15) {
              setIsEnoughReserve(false);
            } else {
              setIsEnoughReserve(true);
            }

            let amountIn: any =
              Number(
                CommonService.toFixed(
                  CommonService.divideBigNumber(
                    CommonService.toFixed(
                      CommonService.toFixed(
                        CommonService.convertWithDecimal(
                          reserveAmount?.reserveIn,
                          data?.amount
                        )
                      ) * 1000
                    ),
                    CommonService.toFixed(
                      CommonService.toFixed(
                        CommonService.subtractBigNumber(
                          reserveAmount?.reserveOut,
                          data?.amount
                        )
                      ) * 997
                    )
                  )
                )
              ) + 1;
            setFromInput(
              CommonService.toFixed(amountIn / fromSelected?.decimals)
            );
            setShowInputAmount(
              CommonService.fixedToDecimal(
                CommonService.toFixed(
                  CommonService.divideBigNumber(
                    amountIn,
                    fromSelected?.decimals
                  )
                ),
                Math.log10(fromSelected?.decimals) - 2
              )
            );
          }
          setIsEnoughBalance(true);
        }
      } else {
        setFromInput("");
        setIsEnoughReserve(true);
      }
    } else if (inputType == "from") {
      if (Number(fromInput) && Number(fromInput) > 0 && isPairExist) {
        //if both token and navtive token are not selected
        if (
          fromSelected?.address === undefined ||
          toSelected?.address === undefined
        ) {
          setToInput("");
        } else {
          let _priceImpact = viewPriceImpact();
          if (_priceImpact > 15) {
            setIsEnoughReserve(false);
          } else {
            setIsEnoughReserve(true);
          }
          if (Number(fromInput) > Number(fromTokenBalance)) {
            setIsEnoughBalance(false);
          } else {
            setIsEnoughBalance(true);
          }
          decimals =
            fromSelected && fromSelected.decimals
              ? fromSelected.decimals.toString()
              : null;

          data.amount = CommonService.convertWithDecimal(fromInput, decimals);

          let amountOut: any = CommonService.toFixed(
            CommonService.divideBigNumber(
              CommonService.toFixed(
                997 *
                CommonService.toFixed(
                  CommonService.convertWithDecimal(
                    data.amount,
                    reserveAmount?.reserveOut
                  )
                )
              ),
              Number(
                CommonService.toFixed(
                  1000 * CommonService.toFixed(reserveAmount?.reserveIn)
                )
              ) + Number(CommonService.toFixed(997 * data.amount))
            )
          );
          setToInput(CommonService.toFixed(amountOut / toSelected?.decimals));
          setShowOutputAmount(
            CommonService.fixedToDecimal(
              CommonService.toFixed(
                CommonService.divideBigNumber(amountOut, toSelected?.decimals)
              ),
              Math.log10(toSelected?.decimals) - 2
            )
          );
        }
      } else {
        setToInput("");
      }
    }
  };
  useEffect(() => {
    walletAddress && getTokenBalance();
  }, [walletAddress]);

  useEffect(() => {
    setIsPairExist(true);
    if (walletAddress) {
      getTokenBalance();
    }
    if (defaultNetworks) {
      if (
        toSelected &&
        toSelected?.address &&
        fromSelected &&
        fromSelected?.address &&
        valueSwap == false
      ) {
        handlerToGetPairsAndReserves();
        setFromInput("");
        setToInput("");
      }
    }

    setIsValueSwap(false);
  }, [toSelected, fromSelected, valueSwap]);

  useEffect(() => {
    if (walletAddress && isPairExist) {
      setAllowance("");
      checkAllowance();
    }
  }, [isPairExist, fromSelected, toSelected]);

  // automatic values Update of Calculations(refresh)
  let interval;
  useEffect(() => {
    const interval = setInterval(() => {
      handlerToGetPairsAndReserves();
      commonFunctionCalculations();
    }, 1000);
    return () => clearInterval(interval);
  }, [reserveAmount, toInput, fromInput, showInputAmount]);

  //get pairs and reserves
  const handlerToGetPairsAndReserves = async () => {
    let temp: any = [];
    let toAddress: any =
      toSelected && toSelected.address !== DEFAULT_ADDRESS
        ? toSelected?.address
        : defaultNetworks?.wethAddress;
    let fromAddress: any =
      fromSelected && fromSelected.address !== DEFAULT_ADDRESS
        ? fromSelected?.address
        : defaultNetworks?.wethAddress;
    temp.push(toAddress, fromAddress);

    const pairAddress: any = await dispatch(
      callContractGetMethod("getPair", temp, "factory", false, "")
    );
    if (pairAddress && pairAddress !== DEFAULT_ADDRESS) {
      const getReserves: any = await dispatch(
        callContractGetMethod(
          "getReserves",
          [],
          "dynamicPair",
          false,
          pairAddress
        )
      );
      if (getReserves) {
        let reserveIn, reserveOut;
        if (Number(fromSelected?.address) < Number(toSelected?.address)) {
          reserveIn = getReserves._reserve0;
          reserveOut = getReserves._reserve1;
        } else {
          reserveIn = getReserves._reserve1;
          reserveOut = getReserves._reserve0;
        }
        setReserveAmount({ reserveIn, reserveOut });
      }
      setIsPairExist(true);
    } else {
      setIsPairExist(false);
    }
  };

  //get balance based on selection of coin type
  const getTokenBalance = async () => {
    const { ethereum } = window as any;
    let web3Insance: any = new Web3(ethereum);
    if (fromSelected != undefined) {
      if (fromSelected && fromSelected?.type == "token") {
        const balance: any = await dispatch(
          callContractGetMethod(
            "balanceOf",
            [walletAddress],
            "dynamic",
            false,
            fromSelected?.address
          )
        );
        if (balance) {
          setFromTokenBalance(
            CommonService.toFixed(
              CommonService.divideBigNumber(balance, fromSelected?.decimals)
            )
          );
        }
      } else {
        let currencyBalance: any = await web3Insance.eth.getBalance(
          walletAddress
        );
        setFromTokenBalance(
          CommonService.toFixed(
            CommonService.divideBigNumber(
              currencyBalance,
              fromSelected?.decimals
            )
          )
        );
      }
    }

    if (toSelected != undefined) {
      if (toSelected && toSelected?.type == "token") {
        const balance: any = await dispatch(
          callContractGetMethod(
            "balanceOf",
            [walletAddress],
            "dynamic",
            false,
            toSelected?.address
          )
        );
        if (balance) {
          setToTokenBalance(
            CommonService.divideBigNumber(balance, toSelected?.decimals)
          );
        }
      } else {
        let currencyBalance: any = await web3Insance.eth.getBalance(
          walletAddress
        );
        setToTokenBalance(
          CommonService.divideBigNumber(currencyBalance, toSelected?.decimals)
        );
      }
    }
  };

  //handler to swap tokens/currencies based on selected pair types
  const handlerToSwapTokens = (e: any) => {
    e.preventDefault();
    if (
      (Number(fromInput) || Number(toInput)) <= 0 ||
      (fromSelected?.address || toSelected?.address) == undefined
    ) {
      return Toast.error("Please enter valid amount");
    }
    let data: any = {};
    if (inputType !== "") {
      if (
        fromSelected &&
        fromSelected?.type == "token" &&
        toSelected &&
        toSelected?.type === "token"
      ) {
        swapTypeHandler("tokenToToken", inputType, data);
      } else if (
        fromSelected &&
        fromSelected?.type == "token" &&
        toSelected &&
        toSelected?.type === "currency"
      ) {
        swapTypeHandler("tokenToEth", inputType, data);
      } else if (
        fromSelected &&
        fromSelected?.type == "currency" &&
        toSelected &&
        toSelected?.type === "token"
      ) {
        swapTypeHandler("ethToToken", inputType, data);
      }
    }
  };

  const swapTypeHandler = async (
    type: string,
    inputType: string,
    data: any
  ) => {
    let toAddress: any =
      toSelected && toSelected.address !== DEFAULT_ADDRESS
        ? toSelected?.address
        : defaultNetworks?.wethAddress;
    let fromAddress: any =
      fromSelected && fromSelected.address !== DEFAULT_ADDRESS
        ? fromSelected?.address
        : defaultNetworks?.wethAddress;
    let currentTime = moment().unix();
    let method: string = "";
    let value: any = "";
    switch (type) {
      case "tokenToToken":
        if (inputType == "from") {
          data.amountIn = CommonService.convertWithDecimal(
            fromInput,
            fromSelected?.decimals
          );
          data.amountOutMin = CommonService.subtractBigNumber(
            CommonService.toFixed(
              Number(
                CommonService.convertWithDecimal(toInput, toSelected?.decimals)
              )
            ),
            CommonService.divideBigNumber(
              CommonService.convertWithDecimal(
                slippage,
                CommonService.convertWithDecimal(toInput, toSelected?.decimals)
              ),
              100
            )
          );
        } else {
          data.amountOut = CommonService.convertWithDecimal(
            toInput,
            toSelected?.decimals
          );
          data.amountInMax = CommonService.addBigNumber(
            CommonService.toFixed(
              Number(
                CommonService.convertWithDecimal(
                  fromInput,
                  fromSelected?.decimals
                )
              )
            ),
            CommonService.divideBigNumber(
              CommonService.convertWithDecimal(
                slippage,
                CommonService.convertWithDecimal(
                  fromInput,
                  fromSelected?.decimals
                )
              ),
              100
            )
          );
        }
        method =
          inputType && inputType == "from"
            ? "swapExactTokensForTokens"
            : "swapTokensForExactTokens";

        break;
      case "tokenToEth":
        if (inputType == "from") {
          data.amountIn = CommonService.convertWithDecimal(
            fromInput,
            fromSelected?.decimals
          );
          data.amountOutMin = CommonService.subtractBigNumber(
            CommonService.toFixed(
              Number(
                CommonService.convertWithDecimal(toInput, toSelected?.decimals)
              )
            ),
            CommonService.divideBigNumber(
              CommonService.convertWithDecimal(
                slippage,
                CommonService.convertWithDecimal(toInput, toSelected?.decimals)
              ),
              100
            )
          );
        } else {
          data.amountOut = CommonService.convertWithDecimal(
            toInput,
            toSelected?.decimals
          );
          data.amountInMax = CommonService.addBigNumber(
            CommonService.toFixed(
              Number(
                CommonService.convertWithDecimal(
                  fromInput,
                  fromSelected?.decimals
                )
              )
            ),
            CommonService.divideBigNumber(
              CommonService.convertWithDecimal(
                slippage,
                CommonService.convertWithDecimal(
                  fromInput,
                  fromSelected?.decimals
                )
              ),
              100
            )
          );
        }

        method =
          inputType && inputType == "from"
            ? "swapExactTokensForETH"
            : "swapTokensForExactETH";
        break;
      case "ethToToken":
        if (inputType == "from") {
          value = CommonService.convertWithDecimal(
            fromInput,
            fromSelected?.decimals
          );
          data.amountOutMin = CommonService.subtractBigNumber(
            CommonService.toFixed(
              Number(
                CommonService.convertWithDecimal(toInput, toSelected?.decimals)
              )
            ),
            CommonService.divideBigNumber(
              CommonService.convertWithDecimal(
                slippage,
                CommonService.convertWithDecimal(toInput, toSelected?.decimals)
              ),
              100
            )
          );
        } else {
          value = CommonService.convertWithDecimal(
            fromInput,
            fromSelected?.decimals
          );
          data.amountOut = CommonService.convertWithDecimal(
            toInput,
            toSelected?.decimals
          );
        }
        method =
          inputType && inputType == "from"
            ? "swapExactETHForTokens"
            : "swapETHForExactTokens";
        break;
    }

    //common keys and pairs
    data.path = [fromAddress, toAddress];
    data.to = walletAddress;
    data.deadline = (
      currentTime + Number(CommonService.getSeconds(txDeadline))
    ).toString();
    let provider = await connector?.getProvider();
    let result: any = await dispatch(
      callContractSendMethod(
        provider,
        method,
        Object.values(data),
        walletAddress,
        "router",
        value
      )
    );

    if (result && result.status) {
      let data: any = {};
      data.txn_hash = result?.transactionHash;
      data.symbol = fromSelected?.symbol;
      data.amountOut = toInput;
      data.network = defaultNetworks?.networkName;
      data.walletAddress = walletAddress;
      data.chainType = defaultNetworks?.chainType;
      data.toSelectedSymbol = toSelected?.symbol;
      //store txn hash on redux
      await dispatch(swapHistory(data));

      Toast.success("Transaction confirmed");
      setFromInput("");
      setToInput("");
      setShowInputAmount("");
      setShowOutputAmount("");
      getTokenBalance();
    }
  };

  //check token allowance while selection of from token and show approve button based on allowance
  const checkAllowance = async () => {
    if (
      fromSelected &&
      fromSelected?.type === "token" &&
      toSelected &&
      toSelected?.address
    ) {
      let contractAddress: any =
        defaultNetworks && defaultNetworks.routerAddress;

      const getAllowance: any = await dispatch(
        callContractGetMethod(
          "allowance",
          [walletAddress, contractAddress],
          "dynamic",
          false,
          fromSelected?.address
        )
      );
      if (Number(getAllowance) > 0) {
        setAllowance(getAllowance);
      }
    }
  };

  //function to get allowance and approval to contract
  const handlerToManageAllowance = async (e: any) => {
    e.preventDefault();
    let contractAddress = defaultNetworks && defaultNetworks.routerAddress;
    const getAllowance: any = await dispatch(
      callContractGetMethod(
        "allowance",
        [contractAddress, walletAddress],
        "dynamic",
        false,
        fromSelected?.address
      )
    );
    let allowanceValue: any = CommonService.convertWithDecimal(
      fromInput,
      fromSelected?.decimals
    );
    if (getAllowance <= Number(allowanceValue)) {
      setAllowance(getAllowance);
      let maxlimit = BigNumber(10).power(40);
      maxlimit = maxlimit.toString();
      CommonService.convertWithDecimal(fromInput, fromSelected?.decimals);
      let provider = await connector?.getProvider();
      let allowanceRes: any = await dispatch(
        callContractSendMethod(
          provider,
          "approve",
          [contractAddress, maxlimit],
          walletAddress,
          "dynamic",
          "0",
          fromSelected?.address
        )
      );
      if (allowanceRes && allowanceRes.status) {
        Toast.success("Transaction Confirmed");
        checkAllowance();
        // setAllowance(getAllowance);
        return false;
      }
    }
  };

  // view swap and approve button
  const showSwapAndApproveButton = () => {
    if (defaultNetworks?.chainId != selectedNetworkId) {
      return (
        <Row className="align-items-center justify-content-between">
          <Col xs={12} sm>
            <CommonBtn
              title={"Switch Network"}
              onClick={() => open({ view: "Networks" })}
              type="button"
              className={` mainBtn mb-0 ${fromTokenBalance > 0 ? false : false
                }`}
            // disabled={fromTokenBalance > 0 ? false : true}
            />
          </Col>
        </Row>
      );
    } else if (fromSelected && fromSelected?.decimals != undefined) {
      if (
        allowance != undefined &&
        fromSelected?.type === "token" &&
        Number(fromInput) > 0 &&
        Number(allowance) <=
        Number(
          CommonService.convertWithDecimal(fromInput, fromSelected?.decimals)
        ) &&
        isPairExist &&
        Number(fromTokenBalance) > 0 &&
        isEnoughReserve
      ) {
        return (
          <Row className="align-items-center justify-content-between">
            <Col xs={12} sm>
              <CommonBtn
                title={"Approve"}
                onClick={(e: any) => handlerToManageAllowance(e)}
                type="button"
                className={` mainBtn mb-0 ${fromTokenBalance > 0 ? false : "disabled"
                  }`}
                disabled={fromTokenBalance > 0 ? false : true}
              />
            </Col>
          </Row>
        );
      } else {
        if (Number(fromInput) > 0 || Number(toInput) > 0) {
          return (
            <Row className="align-items-center justify-content-between">
              <Col xs={12} sm>
                <CommonBtn
                  title={
                    isPairExist
                      ? isEnoughBalance
                        ? isEnoughReserve
                          ? "Swap"
                          : "Price Impact too high"
                        : "Insufficient Balance"
                      : "Insufficient liquidity for this trade."
                  }
                  onClick={(e: any) => handlerToSwapTokens(e)}
                  disabled={
                    isPairExist
                      ? isEnoughBalance
                        ? isEnoughReserve
                          ? false
                          : true
                        : true
                      : true
                  }
                  type="button"
                  className={` mainBtn mb-0 ${isPairExist
                    ? isEnoughBalance
                      ? isEnoughReserve
                        ? false
                        : "disabled"
                      : "disabled"
                    : "disabled"
                    }`}
                />
              </Col>
            </Row>
          );
        } else {
          return (
            <Row className="align-items-center justify-content-between">
              <Col xs={12} sm>
                <CommonBtn
                  title={
                    isPairExist
                      ? "Enter an amount"
                      : "Insufficient liquidity for this trade."
                  }
                  disabled={isPairExist ? false : true}
                  type="button"
                  className={`${!isPairExist ? "disabled" : ""}  mainBtn mb-0`}
                />
              </Col>
            </Row>
          );
        }
      }
    } else {
      return (
        <Row className="align-items-center justify-content-between">
          <Col xs={12} sm>
            <CommonBtn
              title={"Enter an amount"}
              type="button"
              className="mainBtn mb-0"
            />
          </Col>
        </Row>
      );
    }
  };

  //view function to get min and max sold based on types
  const viewMinAndReceivedAmount = () => {
    if (fromSelected?.decimals === "" || toSelected?.decimals === "") {
      return 0;
    }

    if (inputType == "from") {
      if (toSelected?.decimals === "" || toSelected?.decimals === undefined) {
        return 0;
      }

      let minReceived =
        Number(
          CommonService.convertWithDecimal(toInput, toSelected?.decimals)
        ) -
        Number(
          CommonService.divideBigNumber(
            slippage *
            Number(
              CommonService.convertWithDecimal(toInput, toSelected?.decimals)
            ),
            100
          )
        );

      minReceived = CommonService.toFixed(
        Number(CommonService.divideBigNumber(minReceived, toSelected?.decimals))
      );

      return (
        <>
          {CommonService.cryptoDecimals(minReceived)} {toSelected?.symbol}
          <CustomOverlay value={minReceived} />
        </>
      );
    } else {
      if (
        fromSelected?.decimals === "" ||
        fromSelected?.decimals === undefined ||
        fromInput < 0
      ) {
        return 0;
      }
      let maxSold = CommonService.addBigNumber(
        CommonService.toFixed(
          Number(
            CommonService.convertWithDecimal(fromInput, fromSelected?.decimals)
          )
        ),
        CommonService.divideBigNumber(
          CommonService.convertWithDecimal(
            slippage,
            CommonService.convertWithDecimal(fromInput, fromSelected?.decimals)
          ),
          100
        )
      );

      maxSold = CommonService.toFixed(
        CommonService.divideBigNumber(maxSold, fromSelected?.decimals)
      );
      return (
        <>
          {CommonService.cryptoDecimals(maxSold)} {fromSelected?.symbol}
          <CustomOverlay value={maxSold} />
        </>
      );
    }
  };
  // view selected token/currency price per token/currency
  const viewPriceOfSelectedCurrency = () => {
    if (fromSelected?.decimals === "" || toSelected?.decimals === "") {
      return 0;
    }
    if (reserveAmount === "" || reserveAmount === undefined) {
      return 0;
    }
    if (!isPriceShow) {
      let _amount = Number(
        CommonService.convertWithDecimal(1, fromSelected?.decimals)
      );

      let amountOut: any = CommonService.toFixed(
        CommonService.divideBigNumber(
          CommonService.toFixed(
            997 *
            CommonService.toFixed(
              CommonService.convertWithDecimal(
                _amount,
                reserveAmount?.reserveOut
              )
            )
          ),
          Number(
            CommonService.toFixed(
              1000 * CommonService.toFixed(reserveAmount?.reserveIn)
            )
          ) + Number(CommonService.toFixed(997 * _amount))
        )
      );

      amountOut = CommonService.toFixed(
        Math.abs(CommonService.divideBigNumber(amountOut, toSelected?.decimals))
      );

      return (
        <>
          1 {fromSelected?.symbol}
          <span>
            ~{CommonService.cryptoDecimals(amountOut)}
            &nbsp; {toSelected?.symbol}
          </span>
          <CustomOverlay value={amountOut} />
        </>
      );
    } else {
      let _amount = Number(
        CommonService.convertWithDecimal(1, toSelected?.decimals)
      );

      let amountIn: any =
        Number(
          CommonService.divideBigNumber(
            CommonService.toFixed(
              CommonService.convertWithDecimal(
                reserveAmount?.reserveIn,
                _amount
              )
            ) * 1000,
            (reserveAmount?.reserveOut - _amount) * 997
          )
        ) + 1;

      let _price: any = CommonService.toFixed(
        Math.abs(
          CommonService.divideBigNumber(amountIn, fromSelected?.decimals)
        )
      );
      return (
        <>
          1 {toSelected?.symbol}
          <span>
            ~{CommonService.cryptoDecimals(_price)}&nbsp;
            {fromSelected?.symbol}
          </span>
          <CustomOverlay value={_price} />
        </>
      );
    }
  };

  const viewPriceImpact = () => {
    if (fromSelected?.decimals === "" || toSelected?.decimals === "") {
      return 0;
    }
    if (reserveAmount === "" || reserveAmount === undefined) {
      return 0;
    }
    const defaultValue = 0.01;
    const { reserveIn, reserveOut } = reserveAmount;
    const k = CommonService.convertWithDecimal(reserveIn, reserveOut);
    const initMrktPriceOfInwrtOut = Number(
      CommonService.divideBigNumber(reserveIn, reserveOut)
    );
    let currentReserveOfFrom;
    let currentReserveOfTo;

    if (inputType == "from") {
      currentReserveOfFrom =
        Number(reserveIn) +
        Number(
          CommonService.convertWithDecimal(fromInput, fromSelected?.decimals)
        );
      currentReserveOfTo = CommonService.divideBigNumber(
        k,
        currentReserveOfFrom
      );
    } else {
      currentReserveOfTo =
        Number(reserveOut) +
        Number(CommonService.convertWithDecimal(toInput, toSelected?.decimals));
      currentReserveOfFrom = CommonService.divideBigNumber(
        k,
        currentReserveOfTo
      );
    }

    const finalMrktPriceOfInwrtOut = Number(
      CommonService.divideBigNumber(currentReserveOfFrom, currentReserveOfTo)
    );

    let priceImpact: any =
      CommonService.divideBigNumber(
        finalMrktPriceOfInwrtOut - initMrktPriceOfInwrtOut,
        initMrktPriceOfInwrtOut
      ) * 100;
    return Math.abs(priceImpact);
  };

  //handler to swap values

  const swapToAndFromValues = () => {
    if (
      (fromSelected?.address || fromInput) &&
      (toSelected?.address || toInput)
    ) {
      setIsValueSwap(true);
      setInputType(inputType === "from" ? "to" : "from");
      setToSelected(fromSelected);
      setToInput(fromInput);
      setFromSelected(toSelected);
      setFromInput(toInput);
    }
    setIsValueSwap(false);
  };

  //handler to rotate refresh icon
  const [rotate, setRotate] = useState(false);
  const rotateHandle = () => {
    setRotate(true);
    setTimeout(() => {
      setRotate(false);
    }, 500);
  };
  return (
    <>
      <div className="SwapCard">
        <div className="SwapCardHead">
          <Row>
            <Col xs={8} className="SwapCardHeadLeft">
              {(() => {
                switch (CommonCase) {
                  case "Default":
                    return (
                      <>
                        <h4>Swap</h4>
                        <p>Trade tokens in an instant</p>
                      </>
                    );
                  case "Settings":
                    return (
                      <>
                        <div className="goToBack">
                          <div className="left">
                            <Link
                              to="#"
                              onClick={() => {
                                caseClick("Default");
                              }}
                            >
                              <BackArrow />
                            </Link>
                          </div>
                          <div className="right">
                            <h4>Settings</h4>
                          </div>
                        </div>
                      </>
                    );
                  default:
                    // code block
                    return null;
                }
              })()}
            </Col>
            {CommonCase === "Default" && (
              <Col xs={4}>
                <div className="SwapCardHeadRight">
                  <button
                    className="settings"
                    onClick={() => {
                      caseClick("Settings");
                    }}
                  >
                    <SettingIcon />
                  </button>
                  <button
                    onClick={() => setShowTransactions(true)}
                    className={`timer `}
                  >
                    <TimerRefreshIcon />
                  </button>
                  <button
                    className={`refresh ${rotate ? "active" : ""}`}
                    type="button"
                    onClick={() => {
                      rotateHandle();
                      handlerToGetPairsAndReserves();
                      getTokenBalance();
                    }}
                  >
                    <RefreshIcon />
                  </button>
                </div>
              </Col>
            )}
          </Row>
        </div>
        {(() => {
          switch (CommonCase) {
            case "Default":
              return (
                <>
                  <div className="SwapCardExchange">
                    <Form action="#" className="cardForm">
                      <Form.Group className="inputHandle">
                        <div className="inputHandle_leftSide">
                          <label>You Send</label>
                          <SelectToken
                            type="from"
                            selected={fromSelected}
                            disabled={toSelected}
                            callback={setFromSelected}
                          />
                        </div>
                        <div className="inputHandle_rightSide">
                          {walletAddress && fromTokenBalance ? (
                            <h6>
                              <span>Avail. Bal: </span>{" "}
                              {CommonService.cryptoDecimals(fromTokenBalance)}
                            </h6>
                          ) : null}
                          <InputCustom
                            type="text"
                            className="mb-0 max-field"
                            placeholder="0.0"
                            value={
                              inputType === "from"
                                ? fromInput
                                : Number(toInput) > 0
                                  ? showInputAmount
                                  : ""
                            }
                            step={"any"}
                            name="amount"
                            autoFocus={true}
                            onChange={(e: any) => {
                              if (e.type == "focus") {
                                setInputType("from");
                              }
                              let value = e.target.value;
                              let validate =
                                CommonService.allowOnlyNumberWithDecimals(
                                  value,
                                  fromSelected?.type,
                                  fromSelected?.decimals
                                );
                              if (validate) {
                                setFromInput(value);
                                setInputType("from");
                              }
                              setInputType("from");
                            }}
                            required
                          />
                          {/* <p>$ 0.02341</p> */}
                        </div>
                        {walletAddress ? (
                          <>
                            {fromSelected?.address != undefined ? (
                              <Form.Label
                                onClick={() => {
                                  if (
                                    fromSelected &&
                                    fromSelected.type == "currency"
                                  ) {
                                    let _fromBalance = fromTokenBalance - 0.005;
                                    setFromInput(
                                      CommonService.fixedToDecimal(
                                        _fromBalance,
                                        Math.log10(fromSelected?.decimals) - 2
                                      )
                                    );
                                    setInputType("from");
                                  } else {
                                    setFromInput(
                                      CommonService.fixedToDecimal(
                                        fromTokenBalance,
                                        Math.log10(fromSelected?.decimals) - 2
                                      )
                                    );
                                    setInputType("from");
                                  }
                                }}
                              >
                                Max
                              </Form.Label>
                            ) : null}
                          </>
                        ) : null}
                      </Form.Group>
                      <div className="spacing"></div>
                      <div className="SwapBtn">
                        {/* <Lottie
                          animationData={PulseLottie}
                          loop={true}
                          style={{ width: 48, height: 48 }}
                        /> */}
                        <button type="button" onClick={swapToAndFromValues}>
                          <img src={SwapArrow} alt="icon" />
                        </button>
                      </div>
                      <Form.Group className="inputHandle">
                        <div className="inputHandle_leftSide">
                          <label>You Receive</label>
                          <SelectToken
                            type="to"
                            selected={toSelected}
                            callback={setToSelected}
                            disabled={fromSelected}
                          />
                        </div>
                        <div className="inputHandle_rightSide">
                          {walletAddress && toTokenBalance ? (
                            <h6>
                              <span>Avail. Bal: </span>{" "}
                              {CommonService.cryptoDecimals(toTokenBalance)}
                            </h6>
                          ) : null}
                          <InputCustom
                            type="text"
                            className="mb-0 max-field"
                            placeholder="0.0"
                            value={
                              inputType === "to"
                                ? toInput
                                : Number(fromInput) > 0
                                  ? showOutputAmount
                                  : ""
                            }
                            step={"any"}
                            name="amount"
                            autoFocus={true}
                            onChange={(e: any) => {
                              if (e.type == "focus") {
                                setInputType("to");
                              }

                              let value = e.target.value;

                              let validate =
                                CommonService.allowOnlyNumberWithDecimals(
                                  value,
                                  toSelected?.type,
                                  toSelected?.decimals
                                );
                              if (validate) {
                                setToInput(e.target.value);
                                setInputType("to");
                              }
                              setInputType("to");
                            }}
                            required
                          />
                          {/* <p>$ 0.02341</p> */}
                        </div>
                      </Form.Group>
                      {/* <div className="inputHandleBottom">
                        <div className="inputHandleBottomLeft"></div>
                        <div className="inputHandleBottomRight">
                          {toTokenBalance ? (
                            <>
                              <p>
                                Avail. Bal:{" "}
                                <span>
                                  {" "}
                                  {CommonService.cryptoDecimals(
                                    toTokenBalance
                                  )}{" "}
                                </span>
                              </p>
                            </>
                          ) : null}
                        </div>
                      </div> */}
                      <div className="conversion">
                        <ul className="ValuesDesign">
                          {Number(fromInput) > 0 &&
                            Number(toInput) > 0 &&
                            isPairExist &&
                            fromSelected?.address &&
                            toSelected?.address &&
                            isEnoughReserve ? (
                            <li>
                              <p>Price</p>{" "}
                              <div className="right">
                                <span>{viewPriceOfSelectedCurrency()}</span>{" "}
                                <button
                                  className="reload"
                                  type="button"
                                  onClick={() => {
                                    viewPriceOfSelectedCurrency();
                                    setIsPriceShow(!isPriceShow);
                                  }}
                                >
                                  <RefreshIcon />
                                </button>
                              </div>
                            </li>
                          ) : null}

                          <li>
                            <p>
                              Slippage Tolerance{" "}
                              <Link
                                className="edit"
                                to="#"
                                onClick={() => {
                                  caseClick("Settings");
                                }}
                              >
                                <u>Edit</u>
                              </Link>
                            </p>{" "}
                            <div className="right">
                              <span>{slippage} %</span>
                            </div>
                          </li>
                        </ul>
                      </div>

                      {walletAddress ? (
                        showSwapAndApproveButton()
                      ) : (
                        <CommonBtn
                          title="CONNECT WALLET"
                          onClick={() => open()}
                          className="bgdark mainBtn"
                          type="button"
                        />
                      )}
                      {Number(fromInput) > 0 &&
                      Number(toInput) > 0 &&
                      isPairExist &&
                      fromSelected?.address &&
                      toSelected?.address &&
                      isEnoughReserve ? (
                        <div className="Results">
                          <ul className="ValuesDesign">
                            <li>
                              <p>
                                {inputType == "to"
                                  ? "Maximum Sold"
                                  : "Minimum Received"}

                                <OverlayTrigger
                                  trigger="click"
                                  placement={TipTool}
                                  overlay={MinimumReceivedpopover}
                                  rootCloseEvent="click"
                                  rootClose
                                >
                                  <button type="button">
                                    <InfoIcon />
                                  </button>
                                </OverlayTrigger>
                              </p>{" "}
                              <div className="right">
                                <span>{viewMinAndReceivedAmount()}</span>
                              </div>
                            </li>
                            <li>
                              <p>
                                Price Impact{" "}
                                <OverlayTrigger
                                  trigger="click"
                                  placement={TipTool}
                                  overlay={PriceImpactpopover}
                                  rootCloseEvent="click"
                                  rootClose
                                >
                                  <button type="button">
                                    <InfoIcon />
                                  </button>
                                </OverlayTrigger>
                              </p>{" "}
                              <div className="right">
                                <span>
                                  {viewPriceImpact() < 0.01
                                    ? "<0.01"
                                    : viewPriceImpact() > 15
                                    ? ">15"
                                    : CommonService.fixedToDecimal(
                                        CommonService.toFixed(
                                          viewPriceImpact()
                                        ),
                                        2
                                      )}
                                  %
                                  <CustomOverlay
                                    value={CommonService.toFixed(
                                      viewPriceImpact()
                                    )}
                                  />
                                </span>
                              </div>
                            </li>
                            <li>
                              <p>
                                Liquidity Provider Fee{" "}
                                <OverlayTrigger
                                  trigger="click"
                                  placement={TipTool}
                                  overlay={ProviderFeepopover}
                                  rootCloseEvent="click"
                                  rootClose
                                >
                                  <button type="button">
                                    <InfoIcon />
                                  </button>
                                </OverlayTrigger>
                              </p>{" "}
                              <div className="right">
                                <span>
                                  {CommonService.fixedToDecimal(
                                    CommonService.toFixed(
                                      fromInput * (0.3 / 100)
                                    ),
                                    8
                                  )}{" "}
                                  {fromSelected?.symbol}
                                  <CustomOverlay
                                    value={CommonService.toFixed(
                                      fromInput * (0.3 / 100)
                                    )}
                                  />
                                </span>
                              </div>
                            </li>
                            <li>
                              <p>
                                Route{" "}
                                <OverlayTrigger
                                  trigger="click"
                                  placement={TipTool}
                                  overlay={Routepopover}
                                  rootCloseEvent="click"
                                  rootClose
                                >
                                  <button type="button">
                                    <InfoIcon />
                                  </button>
                                </OverlayTrigger>
                              </p>{" "}
                              <div className="right">
                                <span>
                                  {fromSelected?.symbol} To {toSelected?.symbol}
                                </span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </Form>
                  </div>
                </>
              );
            case "Settings":
              return (
                <>
                  <div className="SwapCardSettings">
                    <h4>
                      Slippage Tolerance{" "}
                      <OverlayTrigger
                        trigger="click"
                        placement={TipTool}
                        overlay={Settingsopover}
                        rootCloseEvent="click"
                        rootClose
                      >
                        <button type="button">
                          <InfoIcon />
                        </button>
                      </OverlayTrigger>
                    </h4>
                    <ul>
                      {SlippageTolerance.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setSlippage(item?.title);
                            dispatch(
                              addSwapCustomSlippage({
                                swapCustomSlippage: item?.title,
                              })
                            );
                            setInputFocused(false);
                          }}
                          className={slippage === item?.title ? "active" : ""}
                        >
                          <button>
                            <span className="texttitle">{item.title} %</span>
                          </button>
                        </li>
                      ))}
                      <li
                        onClick={() => {
                          dispatch(
                            addSwapCustomSlippage({
                              swapCustomSlippage: manualSlippage,
                            })
                          );
                          setSlippage(manualSlippage);
                          setInputFocused(true);
                        }}
                        className={"active"}
                      >
                        <>
                          <input
                            type="text"
                            value={slippage}
                            onChange={(e: any) => {
                              const { value } = e.target;
                              if (
                                CommonService.allowOnlyNumber(value, "setting")
                              ) {
                                if (Number(value) > 50) {
                                  dispatch(
                                    addSwapCustomSlippage({
                                      swapCustomSlippage:
                                        value > 50 ? 50 : value,
                                    })
                                  );
                                  setSlippageError(true);
                                  setSlippage(50);
                                } else {
                                  dispatch(
                                    addSwapCustomSlippage({
                                      swapCustomSlippage:
                                        value > 50 ? 50 : value,
                                    })
                                  );
                                  setSlippage(value);
                                  setSlippageError(false);
                                }
                              }
                            }}
                          />
                          %
                        </>
                      </li>
                    </ul>
                    <p className="text-danger common-error">
                      {slippageError
                        ? "Slippage percentage can not be greater than 50%*"
                        : null}
                    </p>
                    <h4>
                      Tx Deadline (mins){" "}
                      <OverlayTrigger
                        trigger="click"
                        placement={TipTool}
                        overlay={SettingTxDeadlinePopover}
                        rootCloseEvent="click"
                        rootClose
                      >
                        <button type="button">
                          <InfoIcon />
                        </button>
                      </OverlayTrigger>
                    </h4>
                    <ul>
                      {TxDeadline.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setTxDeadline(item?.title);
                            dispatch(addDeadline({ txnDeadline: item?.title }));
                          }}
                          className={txDeadline === item?.title ? "active" : ""}
                        >
                          <button>
                            <span className="texttitle">{item.title} Min</span>
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              );
            default:
              // code block
              return null;
          }
        })()}
      </div>
      {/* <ConnectWallet
        show={showConnectWallet}
        handleClose={() => setShowConnectWallet(false)}
      /> */}
      <RecentTransactions
        show={showTransactions}
        handleClose={() => setShowTransactions(false)}
        type="swap"
      />
    </>
  );
};
export default SwapCard;
