
import { loading } from "../../features/loader.slice";
import { AppDispatch } from "../store";

export const setLoader = (data: boolean) => async (dispatch: AppDispatch, getState: any) => {
    try {
        dispatch(loading({ loader: data }));
    } catch (error: any) {
    }
};
