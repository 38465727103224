import queryString from 'query-string';
import { Dispatch, useEffect, useState } from 'react';
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { callContractGetMethod, callContractSendMethod } from '../../../../../app/actions/contract.action';
import { RootState } from '../../../../../app/store';
import InputCustom from '../../../../../component/common/Input/InputCustom';
import Toast from '../../../../../component/common/Toast';
import CommonBtn from '../../../../../component/ui/CommonBtn/CommonBtn';
import CommonModal from '../../../../../component/ui/Modals/CommonModal/CommonModal';
import { CommonService } from '../../../../../services/commonService';
import './TableCardModal.scss';
import { useAccount } from 'wagmi';

const TableCardModal = ({ type, index, item, APIStatus, setAPIStatus }: any) => {
    const { connector } = useAccount()
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const location = useLocation();

    const walletAddress: any = useSelector((state: RootState) => state.connect.walletAddress);
    const dispatch: Dispatch<any> = useDispatch();
    const [userBalance, setUserBalance] = useState<any>(0);
    const [adminBalance, setAdminBalance] = useState<any>(0);
    const [amount, setAmount] = useState<any>('');
    const [referralAddress, setreferralAddress] = useState<any>('');

    const getDetails = async () => {
        const balance: any = await dispatch(callContractGetMethod("balanceOf", [item?.poolAddress], "dynamic", false, item?.addressReward));
        const adminBalance: any = await dispatch(callContractGetMethod("balanceOf", [walletAddress], "dynamic", false, item?.addressReward));

        setAdminBalance(CommonService.toFixed(CommonService.divideBigNumber(adminBalance.toString(), item?.decimalsReward.toString())))
        setUserBalance(CommonService.toFixed(CommonService.divideBigNumber(balance.toString(), item?.decimalsReward.toString())));
    }

    useEffect(() => {
        if (show && walletAddress) {
            getDetails();
        }
        setAmount('')
    }, [show, walletAddress])

    useEffect(() => {
        // execute on location change
        let params = queryString.parse(location.search);
        if (params.ref && show) {
            setreferralAddress(params.ref);
        }
    }, [location, show]);

    const handleAction = async (e: any) => {
        if (type === "Emergency Withdraw") {
            onSubmit(e);
        }
        else {
            handleShow();
        }
    }
    // Code for auto open Tansfer modal
    useEffect(() => {
        if (APIStatus && index == 0) {
            handleAction(type);
        }
    }, [APIStatus]);
    const handleClose = () => {
        setShow(false);
        setAPIStatus(false)
    }
    const onSubmit = async (e: any) => {
        e.preventDefault();
        if (parseInt(adminBalance) >= parseInt(amount) && amount > 0) {
            let provider = await connector?.getProvider()
            let multiplyWithDecimal = CommonService.convertWithDecimal(amount, item?.decimalsReward);
            let response: any = await dispatch(callContractSendMethod(provider, "transfer", [item?.poolAddress, multiplyWithDecimal], walletAddress, "dynamic", '', item?.addressReward));
            if (response?.status) {
                handleClose();
            }
        } else {
            Toast.error("You don't have enough balance.");
        }
    }
    return (<>
        <CommonBtn onClick={handleAction} title={type} className={`enable_btn  btn-small`} />
        <CommonModal className="StakeToken" modalTitle={!APIStatus ? `${type} Tokens` : `Alert`} show={show} onHide={handleClose} centered>
            {APIStatus ? (
                <p>
                    {/* <div className="modal-header"> */}
                    <h3 className="modal-title pt-5 text-center">Don't forgot to transfer rewards in pool</h3>
                    {/* </div> */}
                </p>
            ) : (
                <Form onSubmit={(e: any) => onSubmit(e)}>
                    <div className="py-5 w-100">
                        <Row>
                            <Col><p>{type}</p></Col >
                        </Row >
                        <Form.Group className="inputHandle">
                            <InputCustom className="mb-0 max-field amount" required value={amount} onChange={(e: any) => {
                                let convertVal = CommonService.allowOnlyNumber(e.target.value, '');
                                if (convertVal) {
                                    setAmount(e.target.value);
                                }
                            }}
                                type="text" step="any" label={""} placeholder="0.00"
                            />
                        </Form.Group>
                        <hr />

                        <p className="StakeToken-Bal-stake" title={userBalance}>Pool Balance: {CommonService.cryptoDecimals(userBalance)}</p>
                        <p className="StakeToken-Bal-stake" title={adminBalance}>Admin Balance: {CommonService.cryptoDecimals(adminBalance)} </p>
                    </div >

                    <hr className="mb-4 pb-2" />
                    <Row>
                        <Col><CommonBtn title="Cancel" className="w-100 btn-yellow" onClick={handleClose} type="button" /></Col>
                        <Col><CommonBtn title="Confirm" className="w-100 " type='submit' /></Col>
                    </Row>
                </Form >
            )}

        </CommonModal>
    </>
    )
}
export default TableCardModal
