import { Dispatch, useEffect, useState } from 'react';
import CommonModal from "../CommonModal/CommonModal";
import './AddTokenModal.scss';

import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import Web3 from 'web3';
import { callApiGetMethod } from '../../../../app/actions/api.action';
import { RootState } from '../../../../app/store';
import InputCustom from '../../../common/Input/InputCustom';
import Toast from '../../../common/Toast';
import CommonBtn from "../../CommonBtn/CommonBtn";

const AddTokenModal = ({ show, onHide, addToken }: any) => {
    const defaultNetworks = useSelector((state: RootState) => state.connect.networksDetails);
    const [tokenAddress, setTokenAddress] = useState<any>("");
    const [tokenDetails, setTokendetails] = useState<any>();
    const [tsetTokenstatus, setTokenstatus] = useState<any>(false);

    const dispatch: Dispatch<any> = useDispatch();

    const handleInput = (e: any) => {
        setTokenAddress(e.target.value);
    }

    const getTokenDetails = async (loading: boolean = true) => {
        if (Web3.utils.isAddress(tokenAddress)) {
            const tokenList: any = await dispatch(callApiGetMethod('GET_TOKEN_DETAILS', { chainType: defaultNetworks?.chainType, address: tokenAddress }, loading));
            if (tokenList?.success) {
                setTokenstatus(true);
                setTokendetails(tokenList?.data);
            } else {
                setTokendetails('');
            }
        } else {
            setTokendetails('');
            Toast.error('Please enter valid address');
        }
    }
    useEffect(() => {
        if (!show) {
            setTokenAddress('');
            setTokendetails('');
            setTokenstatus(false)
        }

    }, [show]);

    return (
        <>
            <CommonModal show={show} onHide={onHide} modalTitle="Add Token" className="AddToken">
                <h3 className="pb-4">
                    Token Address
                </h3>
                <Form.Group className="commonInput">
                    <InputCustom autoComplete='off' type="text" name="tokenAddress" value={tokenAddress} onChange={(e: any) => handleInput(e)} inputtext={tsetTokenstatus ? 'disabled' : false} />

                    {tokenDetails ? (
                        <>
                            <div className="pt-5"></div>
                            <hr />
                            <div className='ValuesDesign'>
                                <ul>
                                    <li><p>Name:</p> <div className="right"><span>{tokenDetails?.name}</span></div></li>
                                    <li><p>Network:</p> <div className="right"><span>{tokenDetails?.chainType}</span></div></li>
                                    <li><p>Decimals:</p> <div className="right"><span>{Math.log10((tokenDetails?.decimals))}</span></div></li>
                                    <li><p>Symbol:</p> <div className="right"><span>{tokenDetails?.symbol}</span></div></li>
                                    <li><p>Type:</p> <div className="right"><span>{tokenDetails?.type}</span></div></li>
                                    <li><p>Icon:</p> <div className="right"><span>{<img src={tokenDetails?.icon} alt="" />}</span></div></li>
                                </ul>
                            </div>
                            <hr />
                        </>
                    ) : ""}

                    <div className="d-inline-flex align-items-center mx-auto py-5">
                        <div className=""><img src={defaultNetworks?.icon} alt="" /></div>
                        <div className="right text-start ps-3">
                            <span>Network</span>
                            <p className="m-0"><strong>{defaultNetworks?.networkName}</strong></p>
                        </div>
                    </div>
                    <div className="d-block">
                        {
                            tokenDetails ? (
                                <CommonBtn title="Add Token" type="submit" className="" onClick={() => addToken(tokenAddress)} />
                            ) : (
                                <CommonBtn title="Get Details" type="submit" className="" onClick={() => getTokenDetails()} />
                            )
                        }

                    </div>
                </Form.Group>

            </CommonModal>
        </>
    )
}

export default AddTokenModal