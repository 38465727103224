
import { API_URL } from "../actionsType";
import { setLoader } from "./loader.action";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { apiCallGet, apiCallPost, manageErrorConnection } from "../../services/axios";

export const callApiGetMethod = (method: string, parms: any = {}, loading = false) => {
    return (dispatch: Dispatch<any> = useDispatch(), getState: any) =>
        new Promise(async (resolve, reject) => {
            if (loading) 
            dispatch(setLoader(true));
            let token: any = "";
            let walletAddress: any;

            Object.keys(parms).forEach(key => parms[key] === undefined || parms[key] === null || parms[key] === '' ? delete parms[key] : {});
            apiCallGet(API_URL[method], parms, true, token, walletAddress)
                .then((result) => {
                    if (loading) dispatch(setLoader(false));
                    resolve(result);
                })
                .catch((err) => {
                    manageErrorConnection(err)
                    if (err?.response?.status === 401) {
                        setTimeout(function () {
                        }, 2000);
                    }
                    if (loading) dispatch(setLoader(false));
                    reject(err);
                });
        });
};

export const callApiPostMethod = (method: string, data: any = {}, parms: any = {}, showtoaster: boolean = true) => {
    return (dispatch: Dispatch<any> = useDispatch(), getState: any) =>
        new Promise(async (resolve, reject) => {
            dispatch(setLoader(true));
            Object.keys(parms).forEach(key => parms[key] === undefined || parms[key] === null || parms[key] === '' ? delete parms[key] : {});
            let token = getState()?.user?.userInfo?.token;
            let walletAddresses = getState()?.user?.walletAddress;
            apiCallPost(API_URL[method], data, parms, showtoaster, token, data.walletAddress)
                .then((result) => {
                    dispatch(setLoader(false));
                    resolve(result);
                })
                .catch((err) => {
                    manageErrorConnection(err)
                    if (err?.response?.status === 401) {
                        setTimeout(function () {
                        }, 2000);
                    }
                    dispatch(setLoader(false));

                    reject(err);
                });
        });
};