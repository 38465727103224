import React from "react";
export function MenuSwap() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_48255" data-name="Path 48255" d="M25,15A10,10,0,1,1,15,5,10,10,0,0,1,25,15ZM10,17.75h8l-1.931,1.685a.75.75,0,0,0,.986,1.13l3.438-3A.75.75,0,0,0,20,16.25H10a.75.75,0,0,0,0,1.5Zm4-8.243a.75.75,0,0,1-.072,1.058L12,12.25h8a.75.75,0,0,1,0,1.5H10a.75.75,0,0,1-.493-1.315l3.437-3A.75.75,0,0,1,14,9.507Z" transform="translate(-5 -5)" fill="#63698B" fillRule="evenodd" />
    </svg>
  );
}
export function MenuLiquidity() {
  return (
    <svg id="Group_51281" data-name="Group 51281" xmlns="http://www.w3.org/2000/svg" width="19.5" height="20.75" viewBox="0 0 19.5 20.75">
      <path id="Path_48256" data-name="Path 48256" d="M20.293,5.293C20,5.586,20,6.057,20,7V20c0,.943,0,1.414.293,1.707S21.057,22,22,22s1.414,0,1.707-.293S24,20.943,24,20V7c0-.943,0-1.414-.293-1.707S22.943,5,22,5,20.586,5,20.293,5.293Z" transform="translate(-5.25 -5)" fill="#63698B" />
      <path id="Path_48257" data-name="Path 48257" d="M13,10c0-.943,0-1.414.293-1.707S14.057,8,15,8s1.414,0,1.707.293S17,9.057,17,10V20c0,.943,0,1.414-.293,1.707S15.943,22,15,22s-1.414,0-1.707-.293S13,20.943,13,20Z" transform="translate(-5.25 -5)" fill="#63698B" />
      <path id="Path_48258" data-name="Path 48258" d="M6.293,12.293C6,12.586,6,13.057,6,14v6c0,.943,0,1.414.293,1.707S7.057,22,8,22s1.414,0,1.707-.293S10,20.943,10,20V14c0-.943,0-1.414-.293-1.707S8.943,12,8,12,6.586,12,6.293,12.293Z" transform="translate(-5.25 -5)" fill="#63698B" />
      <path id="Path_48259" data-name="Path 48259" d="M6,24.25a.75.75,0,0,0,0,1.5H24a.75.75,0,0,0,0-1.5Z" transform="translate(-5.25 -5)" fill="#63698B" />
    </svg>
  );
}
export function MenuFarms() {
  return (
    <svg id="Group_51282" data-name="Group 51282" xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
      <path id="Path_48260" data-name="Path 48260" d="M24,15c0,5.523-2.072,10-4.629,10H11.657a6.519,6.519,0,0,0,2.2-2.724A17.806,17.806,0,0,0,15.257,15a17.806,17.806,0,0,0-1.4-7.276A6.518,6.518,0,0,0,11.657,5h7.714C21.928,5,24,9.477,24,15Z" transform="translate(-6 -5)" fill="#63698B" />
      <path id="Path_48261" data-name="Path 48261" d="M7.31,8.134A16.845,16.845,0,0,0,6,15a16.845,16.845,0,0,0,1.31,6.866c.823,1.778,1.854,2.634,2.8,2.634s1.981-.856,2.8-2.634A16.845,16.845,0,0,0,14.229,15a16.845,16.845,0,0,0-1.31-6.866C12.1,6.356,11.064,5.5,10.114,5.5S8.134,6.356,7.31,8.134ZM8.571,15c0,3.314.691,6,1.543,6,.787,0,1.436-2.29,1.531-5.25H10.629a.75.75,0,1,1,0-1.5h1.017C11.55,11.29,10.9,9,10.114,9,9.262,9,8.571,11.686,8.571,15Z" transform="translate(-6 -5)" fill="#63698B" fillRule="evenodd" />
    </svg>
  );
}
export function MenuPools() {
  return (
    <svg id="Group_51283" data-name="Group 51283" xmlns="http://www.w3.org/2000/svg" width="21.5" height="17.5" viewBox="0 0 21.5 17.5">
      <path id="Path_48262" data-name="Path 48262" d="M10.624,7.449A11.543,11.543,0,0,1,15,6.25a11.543,11.543,0,0,1,4.376,1.2l2.969,1.188c.955.382,1.728.691,2.258.969a3.212,3.212,0,0,1,.729.493,1.182,1.182,0,0,1,0,1.8,3.212,3.212,0,0,1-.729.493c-.531.278-1.3.587-2.258.969l-2.969,1.188A11.544,11.544,0,0,1,15,15.75a11.544,11.544,0,0,1-4.376-1.2L7.655,13.363c-.955-.382-1.728-.691-2.258-.969a3.211,3.211,0,0,1-.729-.493,1.182,1.182,0,0,1,0-1.8A3.21,3.21,0,0,1,5.4,9.606c.531-.278,1.3-.588,2.258-.969Z" transform="translate(-4.25 -6.25)" fill="#63698B" />
      <path id="Path_48263" data-name="Path 48263" d="M5.5,14.441h0l0,0,.025.021c.024.02.063.052.118.094.109.084.278.208.508.357a12.224,12.224,0,0,0,2.1,1.073l2.808,1.123c2.025.81,2.874,1.138,3.934,1.138s1.909-.328,3.934-1.138l2.808-1.123a12.222,12.222,0,0,0,2.1-1.073c.23-.149.4-.273.508-.357.054-.042.094-.074.118-.094l.025-.021,0,0,0,0a.75.75,0,0,1,1,1.118L25,15l.5.558h0l0,0,0,0-.014.012-.045.038c-.038.032-.092.076-.162.129-.139.108-.343.256-.609.429A13.72,13.72,0,0,1,22.3,17.381L19.491,18.5l-.116.046A11.544,11.544,0,0,1,15,19.75a11.544,11.544,0,0,1-4.376-1.2l-.116-.046L7.7,17.381a13.719,13.719,0,0,1-2.364-1.207c-.267-.173-.47-.321-.609-.429-.07-.054-.124-.1-.162-.129l-.045-.038-.014-.012,0,0,0,0h0L5,15l-.5.559a.75.75,0,0,1,1-1.118m0,0Zm0,4a.75.75,0,0,0-1.057.059Zm0,0,0,0,.025.021c.024.02.063.052.118.094.109.084.278.208.508.357a12.231,12.231,0,0,0,2.1,1.073l2.808,1.123c2.025.81,2.874,1.138,3.934,1.138s1.909-.328,3.934-1.138l2.808-1.123a12.229,12.229,0,0,0,2.1-1.073c.23-.149.4-.273.508-.357.054-.042.094-.074.118-.094l.025-.021,0,0h0a.75.75,0,0,1,1,1.118l-.484-.54.484.54h0l0,0,0,0-.014.012-.045.038c-.038.032-.092.076-.162.13-.139.108-.343.256-.609.429A13.72,13.72,0,0,1,22.3,21.381L19.491,22.5l-.116.046A11.544,11.544,0,0,1,15,23.75a11.544,11.544,0,0,1-4.376-1.2l-.116-.046L7.7,21.381a13.719,13.719,0,0,1-2.364-1.207c-.267-.173-.47-.321-.609-.429-.07-.054-.124-.1-.162-.13l-.045-.038-.014-.012,0,0,0,0h0L5,19l-.5.559A.75.75,0,0,1,4.442,18.5" transform="translate(-4.25 -6.25)" fill="#63698B" fillRule="evenodd" />
    </svg>
  );
}
export function MenuReferral() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_48264" data-name="Path 48264" d="M6.464,23.535C7.929,25,10.286,25,15,25s7.071,0,8.535-1.465S25,19.714,25,15s0-7.071-1.465-8.536S19.714,5,15,5,7.929,5,6.464,6.464,5,10.286,5,15,5,22.071,6.464,23.535ZM17.75,19a.75.75,0,0,1-.75.75H13a.75.75,0,0,1,0-1.5h4A.75.75,0,0,1,17.75,19ZM19,15.75a.75.75,0,0,0,0-1.5H11a.75.75,0,0,0,0,1.5ZM21.75,11a.75.75,0,0,1-.75.75H9a.75.75,0,0,1,0-1.5H21A.75.75,0,0,1,21.75,11Z" transform="translate(-5 -5)" fill="#63698B" fillRule="evenodd" />
    </svg>
  );
}

export function MenuSwapactive() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <defs>
        <linearGradient id="linear-gradient" x1="0.732" y1="-0.328" x2="0.12" y2="1.326" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fed32d" />
          <stop offset="0.27" stop-color="#e39d26" />
          <stop offset="0.62" stop-color="#c5601e" />
          <stop offset="0.87" stop-color="#b33a19" />
          <stop offset="1" stop-color="#ac2c18" />
        </linearGradient>
      </defs>
      <path id="Path_48255" data-name="Path 48255" d="M25,15A10,10,0,1,1,15,5,10,10,0,0,1,25,15ZM10,17.75h8l-1.931,1.685a.75.75,0,0,0,.986,1.13l3.438-3A.75.75,0,0,0,20,16.25H10a.75.75,0,0,0,0,1.5Zm4-8.243a.75.75,0,0,1-.072,1.058L12,12.25h8a.75.75,0,0,1,0,1.5H10a.75.75,0,0,1-.493-1.315l3.437-3A.75.75,0,0,1,14,9.507Z" transform="translate(-5 -5)" fill-rule="evenodd" fill="url(#linear-gradient)" />
    </svg>
  );
}

export function MenuLiquidityactive() {
  return (
    <svg id="Group_51281" data-name="Group 51281" xmlns="http://www.w3.org/2000/svg" width="19.5" height="20.75" viewBox="0 0 19.5 20.75">
      <defs>
        <linearGradient id="linear-gradient" x1="0.732" y1="-0.328" x2="0.12" y2="1.326" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fed32d" />
          <stop offset="0.27" stop-color="#e39d26" />
          <stop offset="0.62" stop-color="#c5601e" />
          <stop offset="0.87" stop-color="#b33a19" />
          <stop offset="1" stop-color="#ac2c18" />
        </linearGradient>
      </defs>
      <path id="Path_48256" data-name="Path 48256" d="M20.293,5.293C20,5.586,20,6.057,20,7V20c0,.943,0,1.414.293,1.707S21.057,22,22,22s1.414,0,1.707-.293S24,20.943,24,20V7c0-.943,0-1.414-.293-1.707S22.943,5,22,5,20.586,5,20.293,5.293Z" transform="translate(-5.25 -5)" fill="url(#linear-gradient)" />
      <path id="Path_48257" data-name="Path 48257" d="M13,10c0-.943,0-1.414.293-1.707S14.057,8,15,8s1.414,0,1.707.293S17,9.057,17,10V20c0,.943,0,1.414-.293,1.707S15.943,22,15,22s-1.414,0-1.707-.293S13,20.943,13,20Z" transform="translate(-5.25 -5)" fill="url(#linear-gradient)" />
      <path id="Path_48258" data-name="Path 48258" d="M6.293,12.293C6,12.586,6,13.057,6,14v6c0,.943,0,1.414.293,1.707S7.057,22,8,22s1.414,0,1.707-.293S10,20.943,10,20V14c0-.943,0-1.414-.293-1.707S8.943,12,8,12,6.586,12,6.293,12.293Z" transform="translate(-5.25 -5)" fill="url(#linear-gradient)" />
      <path id="Path_48259" data-name="Path 48259" d="M6,24.25a.75.75,0,0,0,0,1.5H24a.75.75,0,0,0,0-1.5Z" transform="translate(-5.25 -5)" fill="url(#linear-gradient)" />
    </svg>
  );
}

export function MenuFarmsactive() {
  return (
    <svg id="Group_51282" data-name="Group 51282" xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20">
      <defs>
        <linearGradient id="linear-gradient" x1="0.732" y1="-0.328" x2="0.12" y2="1.326" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fed32d" />
          <stop offset="0.27" stop-color="#e39d26" />
          <stop offset="0.62" stop-color="#c5601e" />
          <stop offset="0.87" stop-color="#b33a19" />
          <stop offset="1" stop-color="#ac2c18" />
        </linearGradient>
      </defs>
      <path id="Path_48260" data-name="Path 48260" d="M24,15c0,5.523-2.072,10-4.629,10H11.657a6.519,6.519,0,0,0,2.2-2.724A17.806,17.806,0,0,0,15.257,15a17.806,17.806,0,0,0-1.4-7.276A6.518,6.518,0,0,0,11.657,5h7.714C21.928,5,24,9.477,24,15Z" transform="translate(-6 -5)" fill="url(#linear-gradient)" />
      <path id="Path_48261" data-name="Path 48261" d="M7.31,8.134A16.845,16.845,0,0,0,6,15a16.845,16.845,0,0,0,1.31,6.866c.823,1.778,1.854,2.634,2.8,2.634s1.981-.856,2.8-2.634A16.845,16.845,0,0,0,14.229,15a16.845,16.845,0,0,0-1.31-6.866C12.1,6.356,11.064,5.5,10.114,5.5S8.134,6.356,7.31,8.134ZM8.571,15c0,3.314.691,6,1.543,6,.787,0,1.436-2.29,1.531-5.25H10.629a.75.75,0,1,1,0-1.5h1.017C11.55,11.29,10.9,9,10.114,9,9.262,9,8.571,11.686,8.571,15Z" transform="translate(-6 -5)" fill-rule="evenodd" fill="url(#linear-gradient)" />
    </svg>
  );
}

export function MenuPoolsactive() {
  return (
    <svg id="Group_51283" data-name="Group 51283" xmlns="http://www.w3.org/2000/svg" width="21.5" height="17.5" viewBox="0 0 21.5 17.5">
      <defs>
        <linearGradient id="linear-gradient" x1="0.732" y1="-0.328" x2="0.12" y2="1.326" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fed32d" />
          <stop offset="0.27" stop-color="#e39d26" />
          <stop offset="0.62" stop-color="#c5601e" />
          <stop offset="0.87" stop-color="#b33a19" />
          <stop offset="1" stop-color="#ac2c18" />
        </linearGradient>
      </defs>
      <path id="Path_48262" data-name="Path 48262" d="M10.624,7.449A11.543,11.543,0,0,1,15,6.25a11.543,11.543,0,0,1,4.376,1.2l2.969,1.188c.955.382,1.728.691,2.258.969a3.212,3.212,0,0,1,.729.493,1.182,1.182,0,0,1,0,1.8,3.212,3.212,0,0,1-.729.493c-.531.278-1.3.587-2.258.969l-2.969,1.188A11.544,11.544,0,0,1,15,15.75a11.544,11.544,0,0,1-4.376-1.2L7.655,13.363c-.955-.382-1.728-.691-2.258-.969a3.211,3.211,0,0,1-.729-.493,1.182,1.182,0,0,1,0-1.8A3.21,3.21,0,0,1,5.4,9.606c.531-.278,1.3-.588,2.258-.969Z" transform="translate(-4.25 -6.25)" fill="url(#linear-gradient)" />
      <path id="Path_48263" data-name="Path 48263" d="M5.5,14.441h0l0,0,.025.021c.024.02.063.052.118.094.109.084.278.208.508.357a12.224,12.224,0,0,0,2.1,1.073l2.808,1.123c2.025.81,2.874,1.138,3.934,1.138s1.909-.328,3.934-1.138l2.808-1.123a12.222,12.222,0,0,0,2.1-1.073c.23-.149.4-.273.508-.357.054-.042.094-.074.118-.094l.025-.021,0,0,0,0a.75.75,0,0,1,1,1.118L25,15l.5.558h0l0,0,0,0-.014.012-.045.038c-.038.032-.092.076-.162.129-.139.108-.343.256-.609.429A13.72,13.72,0,0,1,22.3,17.381L19.491,18.5l-.116.046A11.544,11.544,0,0,1,15,19.75a11.544,11.544,0,0,1-4.376-1.2l-.116-.046L7.7,17.381a13.719,13.719,0,0,1-2.364-1.207c-.267-.173-.47-.321-.609-.429-.07-.054-.124-.1-.162-.129l-.045-.038-.014-.012,0,0,0,0h0L5,15l-.5.559a.75.75,0,0,1,1-1.118m0,0Zm0,4a.75.75,0,0,0-1.057.059Zm0,0,0,0,.025.021c.024.02.063.052.118.094.109.084.278.208.508.357a12.231,12.231,0,0,0,2.1,1.073l2.808,1.123c2.025.81,2.874,1.138,3.934,1.138s1.909-.328,3.934-1.138l2.808-1.123a12.229,12.229,0,0,0,2.1-1.073c.23-.149.4-.273.508-.357.054-.042.094-.074.118-.094l.025-.021,0,0h0a.75.75,0,0,1,1,1.118l-.484-.54.484.54h0l0,0,0,0-.014.012-.045.038c-.038.032-.092.076-.162.13-.139.108-.343.256-.609.429A13.72,13.72,0,0,1,22.3,21.381L19.491,22.5l-.116.046A11.544,11.544,0,0,1,15,23.75a11.544,11.544,0,0,1-4.376-1.2l-.116-.046L7.7,21.381a13.719,13.719,0,0,1-2.364-1.207c-.267-.173-.47-.321-.609-.429-.07-.054-.124-.1-.162-.13l-.045-.038-.014-.012,0,0,0,0h0L5,19l-.5.559A.75.75,0,0,1,4.442,18.5" transform="translate(-4.25 -6.25)" fill-rule="evenodd" fill="url(#linear-gradient)" />
    </svg>
  );
}

export function MenuReferralactive() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <defs>
        <linearGradient id="linear-gradient" x1="0.732" y1="-0.328" x2="0.12" y2="1.326" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#fed32d" />
          <stop offset="0.27" stop-color="#e39d26" />
          <stop offset="0.62" stop-color="#c5601e" />
          <stop offset="0.87" stop-color="#b33a19" />
          <stop offset="1" stop-color="#ac2c18" />
        </linearGradient>
      </defs>
      <path id="Path_48264" data-name="Path 48264" d="M6.464,23.535C7.929,25,10.286,25,15,25s7.071,0,8.535-1.465S25,19.714,25,15s0-7.071-1.465-8.536S19.714,5,15,5,7.929,5,6.464,6.464,5,10.286,5,15,5,22.071,6.464,23.535ZM17.75,19a.75.75,0,0,1-.75.75H13a.75.75,0,0,1,0-1.5h4A.75.75,0,0,1,17.75,19ZM19,15.75a.75.75,0,0,0,0-1.5H11a.75.75,0,0,0,0,1.5ZM21.75,11a.75.75,0,0,1-.75.75H9a.75.75,0,0,1,0-1.5H21A.75.75,0,0,1,21.75,11Z" transform="translate(-5 -5)" fill-rule="evenodd" fill="url(#linear-gradient)" />
    </svg>
  );
}
export function WalletIcon() {
  return (
    <svg id="Group_145569" data-name="Group 145569" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <circle id="Ellipse_2289" data-name="Ellipse 2289" cx="20" cy="20" r="20" fill="#fff" />
      <g id="Group_145570" data-name="Group 145570" transform="translate(9.786 11.318)">
        <path id="Path_47595" data-name="Path 47595" d="M24.393,11c-.059,0-.124,0-.19,0H21.566a3.919,3.919,0,1,0,0,7.835H24.2c.067,0,.131,0,.19,0a1.833,1.833,0,0,0,1.719-1.7c0-.063,0-.131,0-.193V12.9c0-.063,0-.131,0-.193A1.833,1.833,0,0,0,24.393,11Zm-3.06,4.959a1.045,1.045,0,1,0-1.007-1.045A1.026,1.026,0,0,0,21.333,15.962Z" transform="translate(-4.439 -5.777)" fill="#161716" fillRule="evenodd" />
        <path id="Path_47596" data-name="Path 47596" d="M24.763,20.626a.23.23,0,0,1,.231.29,4.236,4.236,0,0,1-1.077,1.913,4.922,4.922,0,0,1-3,1.293,38.179,38.179,0,0,1-4.631.16H14.082a38.18,38.18,0,0,1-4.631-.16,4.922,4.922,0,0,1-3-1.293,4.922,4.922,0,0,1-1.293-3A38.159,38.159,0,0,1,5,15.2v-.118a38.159,38.159,0,0,1,.16-4.631,4.921,4.921,0,0,1,1.293-3,4.921,4.921,0,0,1,3-1.293A38.159,38.159,0,0,1,14.082,6h2.207a38.159,38.159,0,0,1,4.631.16,4.922,4.922,0,0,1,3,1.293,4.236,4.236,0,0,1,1.077,1.913.23.23,0,0,1-.231.29H22.127a5.485,5.485,0,1,0,0,10.969Zm-14.54-1.567a.784.784,0,0,1-.784-.784V12.007a.784.784,0,1,1,1.567,0v6.268A.784.784,0,0,1,10.223,19.059Z" transform="translate(-5 -6)" fill="#161716" fillRule="evenodd" />
      </g>
    </svg>
  );
}
export function CommonBG() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="1920" height="1007" viewBox="0 0 1920 1007">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_52317" data-name="Rectangle 52317" width="1920" height="1007" fill="#fff" />
        </clipPath>
        <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.5" gradientUnits="objectBoundingBox">
          <stop offset="0" stop-color="#00d800" stop-opacity="0.271" />
          <stop offset="1" stop-color="#00de00" stop-opacity="0" />
        </radialGradient>
      </defs>
      <g id="bg" clip-path="url(#clip-path)">
        <g id="Group_145787" data-name="Group 145787">
          <ellipse id="Ellipse_9443" data-name="Ellipse 9443" cx="451.5" cy="448.5" rx="451.5" ry="448.5" transform="translate(1404 17)" opacity="0.3" fill="url(#radial-gradient)" />
          <ellipse id="Ellipse_9441" data-name="Ellipse 9441" cx="428" cy="389.5" rx="428" ry="389.5" transform="translate(440 420)" opacity="0.3" fill="url(#radial-gradient)" />
          <ellipse id="Ellipse_9434" data-name="Ellipse 9434" cx="361.5" cy="329.5" rx="361.5" ry="329.5" transform="translate(308 -114)" opacity="0.26" fill="url(#radial-gradient)" />
          <ellipse id="Ellipse_9435" data-name="Ellipse 9435" cx="429.5" cy="427" rx="429.5" ry="427" transform="translate(-227 118)" opacity="0.3" fill="url(#radial-gradient)" />
        </g>
      </g>
    </svg>
  );
}
export function SettingIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_48282" data-name="Path 48282" d="M17.872,5.152A5.6,5.6,0,0,0,16,5a5.6,5.6,0,0,0-1.872.152A2.064,2.064,0,0,0,12.98,6.235a2.338,2.338,0,0,0-.152.863,1.6,1.6,0,0,1-.838,1.353,1.806,1.806,0,0,1-1.662.008,2.716,2.716,0,0,0-.869-.308,2.216,2.216,0,0,0-1.568.4A5.007,5.007,0,0,0,6.815,10a4.856,4.856,0,0,0-.8,1.6,1.917,1.917,0,0,0,.42,1.479,2.584,2.584,0,0,0,.717.556,1.536,1.536,0,0,1,0,2.722,2.586,2.586,0,0,0-.717.556,1.917,1.917,0,0,0-.42,1.479,4.856,4.856,0,0,0,.8,1.6,5.006,5.006,0,0,0,1.076,1.453,2.217,2.217,0,0,0,1.568.4,2.716,2.716,0,0,0,.869-.308,1.807,1.807,0,0,1,1.662.008,1.6,1.6,0,0,1,.838,1.353,2.338,2.338,0,0,0,.152.863,2.065,2.065,0,0,0,1.148,1.082A5.6,5.6,0,0,0,16,25a5.6,5.6,0,0,0,1.872-.152,2.065,2.065,0,0,0,1.148-1.082,2.338,2.338,0,0,0,.152-.863,1.6,1.6,0,0,1,.838-1.353,1.806,1.806,0,0,1,1.662-.008,2.716,2.716,0,0,0,.869.308,2.216,2.216,0,0,0,1.568-.4A5.006,5.006,0,0,0,25.185,20a4.857,4.857,0,0,0,.8-1.6,1.918,1.918,0,0,0-.42-1.479,2.586,2.586,0,0,0-.717-.556,1.536,1.536,0,0,1,0-2.722,2.585,2.585,0,0,0,.717-.556,1.917,1.917,0,0,0,.42-1.479,4.857,4.857,0,0,0-.8-1.6,5.006,5.006,0,0,0-1.076-1.453,2.216,2.216,0,0,0-1.568-.4,2.715,2.715,0,0,0-.869.308,1.806,1.806,0,0,1-1.662-.008A1.6,1.6,0,0,1,19.172,7.1a2.338,2.338,0,0,0-.152-.863A2.064,2.064,0,0,0,17.872,5.152ZM16,18a3.1,3.1,0,0,0,3.182-3A3.1,3.1,0,0,0,16,12a3.1,3.1,0,0,0-3.182,3A3.1,3.1,0,0,0,16,18Z" transform="translate(-6 -5)" fill="#63698B" fillRule="evenodd" />
    </svg>
  );
}
export function TimerRefreshIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_48281" data-name="Path 48281" d="M22,12A10,10,0,1,1,2,12a.714.714,0,0,1,1.429,0A8.584,8.584,0,1,0,7.271,4.857h.443a.714.714,0,0,1,0,1.429H5.571a.714.714,0,0,1-.714-.714V3.429a.714.714,0,0,1,1.429,0V3.8A10,10,0,0,1,22,12Zm-3.571,0A6.429,6.429,0,1,1,12,5.572,6.429,6.429,0,0,1,18.429,12Zm-3.889.834-1.825-1.216V8.429a.714.714,0,0,0-1.429,0V12a.714.714,0,0,0,.318.594l2.143,1.429a.714.714,0,0,0,.793-1.189Z" transform="translate(-2 -2)" fill="#63698B" />
    </svg>
  );
}
export function RefreshIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
      <path id="Path_48280" data-name="Path 48280" d="M15,25A10,10,0,1,0,5,15,10,10,0,0,0,15,25ZM19.728,9a.75.75,0,0,0-1.5,0v1.023a5.665,5.665,0,0,0-7.3.715,5.955,5.955,0,0,0,0,8.287,5.668,5.668,0,0,0,8.144,0,5.934,5.934,0,0,0,1.633-4.874.75.75,0,0,0-1.489.182A4.434,4.434,0,0,1,18,17.977a4.168,4.168,0,0,1-6,0,4.455,4.455,0,0,1,0-6.191,4.166,4.166,0,0,1,4.883-.822h-.559a.75.75,0,1,0,0,1.5h2.652a.75.75,0,0,0,.75-.75Z" transform="translate(-5 -5)" fill="#63698B" fillRule="evenodd" />
    </svg>
  );
}
export function ArrowDownIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="5" viewBox="0 0 9 5">
      <g id="Group_145577" data-name="Group 145577" transform="translate(9) rotate(90)">
        <g id="_15" data-name="15" transform="translate(0 0)">
          <path id="Path_7808" data-name="Path 7808" d="M.628,9a.624.624,0,0,1-.58-.4.656.656,0,0,1,.137-.7l3.31-3.4L.185,1.1a.659.659,0,0,1,0-.913.615.615,0,0,1,.887,0L4.818,4.045a.656.656,0,0,1,0,.906L1.072,8.807A.616.616,0,0,1,.628,9Z" transform="translate(0 0)" fill="#fff" />
        </g>
      </g>
    </svg>
  );
}
export function ExchangeArrowIcon() {
  return (
    <svg id="Group_145580" data-name="Group 145580" xmlns="http://www.w3.org/2000/svg" width="22.442" height="22.442" viewBox="0 0 22.442 22.442">
      <path id="Path_48285" data-name="Path 48285" d="M13.221,2A11.221,11.221,0,1,0,24.442,13.221,11.225,11.225,0,0,0,13.221,2Zm-1.2,17a.845.845,0,0,1-.067.325.878.878,0,0,1-.46.46.8.8,0,0,1-.651,0,.871.871,0,0,1-.269-.18L7.162,16.195a.841.841,0,1,1,1.189-1.189l1.975,1.975V7.442a.842.842,0,1,1,1.683,0V19Zm7.249-7.552a.84.84,0,0,1-1.189,0L16.105,9.473v9.538a.842.842,0,1,1-1.683,0V7.442a.845.845,0,0,1,.067-.325.878.878,0,0,1,.46-.46.793.793,0,0,1,.64,0,.871.871,0,0,1,.269.18l3.411,3.411a.86.86,0,0,1,0,1.2Z" transform="translate(-2 -2)" fill="#00bc00" />
    </svg>
  );
}
export function InfoIcon() {
  return (
    <svg id="Component_5_13" data-name="Component 5 – 13" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <path id="Path_48286" data-name="Path 48286" d="M8,0a8,8,0,1,0,8,8A8.009,8.009,0,0,0,8,0ZM8,14.545A6.545,6.545,0,1,1,14.545,8,6.553,6.553,0,0,1,8,14.545Z" fill="#63698B" />
      <path id="Path_48287" data-name="Path 48287" d="M145.927,70a1.006,1.006,0,1,0,.925,1A.967.967,0,0,0,145.927,70Zm0,3.51a.725.725,0,0,0-.694.752v4.512a.7.7,0,1,0,1.387,0V74.262A.725.725,0,0,0,145.927,73.51Z" transform="translate(-137.927 -66.763)" fill="#63698B" />
    </svg>
  );
}
export function SearchIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={22} height={23.21}><path fill="#F8C72B" d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z" /></svg>
  )
}
export function ListViewIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="23.985" height="18.196" viewBox="0 0 23.985 18.196">
      <g id="Group_146426" data-name="Group 146426" transform="translate(0 -73.862)">
        <path id="Path_48808" data-name="Path 48808" d="M2.481,73.862a2.481,2.481,0,1,0,2.481,2.481A2.481,2.481,0,0,0,2.481,73.862Zm0,6.617A2.481,2.481,0,1,0,4.963,82.96,2.481,2.481,0,0,0,2.481,80.479Zm0,6.617a2.481,2.481,0,1,0,2.481,2.481A2.481,2.481,0,0,0,2.481,87.1ZM9.1,78H22.331a1.654,1.654,0,1,0,0-3.308H9.1A1.654,1.654,0,1,0,9.1,78Zm13.233,3.308H9.1a1.654,1.654,0,0,0,0,3.308H22.331a1.654,1.654,0,0,0,0-3.308Zm0,6.617H9.1a1.654,1.654,0,1,0,0,3.308H22.331a1.654,1.654,0,1,0,0-3.308Z" fill="#fff" />
      </g>
    </svg>
  )
}
export function GridViewIcon() {
  return (
    <svg id="Group_146427" data-name="Group 146427" xmlns="http://www.w3.org/2000/svg" width="21.595" height="21.595" viewBox="0 0 21.595 21.595">
      <path id="Path_48809" data-name="Path 48809" d="M4.5,0H.9A.9.9,0,0,0,0,.9V4.5a.9.9,0,0,0,.9.9H4.5a.9.9,0,0,0,.9-.9V.9A.9.9,0,0,0,4.5,0Zm0,8.1H.9A.9.9,0,0,0,0,9v3.6a.9.9,0,0,0,.9.9H4.5a.9.9,0,0,0,.9-.9V9A.9.9,0,0,0,4.5,8.1Zm0,8.1H.9a.9.9,0,0,0-.9.9v3.6a.9.9,0,0,0,.9.9H4.5a.9.9,0,0,0,.9-.9V17.1A.9.9,0,0,0,4.5,16.2ZM12.6,0H9a.9.9,0,0,0-.9.9V4.5a.9.9,0,0,0,.9.9h3.6a.9.9,0,0,0,.9-.9V.9A.9.9,0,0,0,12.6,0Zm0,8.1H9a.9.9,0,0,0-.9.9v3.6a.9.9,0,0,0,.9.9h3.6a.9.9,0,0,0,.9-.9V9A.9.9,0,0,0,12.6,8.1Zm0,8.1H9a.9.9,0,0,0-.9.9v3.6a.9.9,0,0,0,.9.9h3.6a.9.9,0,0,0,.9-.9V17.1A.9.9,0,0,0,12.6,16.2ZM20.7,0H17.1a.9.9,0,0,0-.9.9V4.5a.9.9,0,0,0,.9.9h3.6a.9.9,0,0,0,.9-.9V.9A.9.9,0,0,0,20.7,0Zm0,8.1H17.1a.9.9,0,0,0-.9.9v3.6a.9.9,0,0,0,.9.9h3.6a.9.9,0,0,0,.9-.9V9A.9.9,0,0,0,20.7,8.1Zm0,8.1H17.1a.9.9,0,0,0-.9.9v3.6a.9.9,0,0,0,.9.9h3.6a.9.9,0,0,0,.9-.9V17.1A.9.9,0,0,0,20.7,16.2Z" fill="#fff" />
    </svg>
  )
}
export function DownArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.528" height="6.264" viewBox="0 0 12.528 6.264">
      <path id="Icon_ionic-md-arrow-dropup" data-name="Icon ionic-md-arrow-dropup" d="M12.528,6.264,6.264,0,0,6.264Z" transform="translate(12.528 6.264) rotate(180)" fill="#fff" />
    </svg>
  )
}
export function RocketIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.986" height="15.986" viewBox="0 0 15.986 15.986">
      <g id="Group_146284" data-name="Group 146284" transform="translate(0 0)">
        <path id="Path_48719" data-name="Path 48719" d="M4.066,4.317l-1.385.107a1.236,1.236,0,0,0-.942.554L.142,7.394A.856.856,0,0,0,.725,8.712l1.268.2A11.355,11.355,0,0,1,4.066,4.317Zm3.011,9.675.2,1.268a.855.855,0,0,0,1.318.583l2.416-1.6a1.236,1.236,0,0,0,.554-.942l.107-1.385a11.355,11.355,0,0,1-4.591,2.073ZM6.6,13.113a.8.8,0,0,0,.132-.011,9.176,9.176,0,0,0,1.9-.539L3.423,7.351a9.175,9.175,0,0,0-.539,1.9.817.817,0,0,0,.23.711l2.908,2.908A.82.82,0,0,0,6.6,13.113Zm8.111-6.026A12.627,12.627,0,0,0,15.967.794a.821.821,0,0,0-.775-.775Q14.842,0,14.491,0A12.373,12.373,0,0,0,8.9,1.275,12.723,12.723,0,0,0,3.837,6.447a.471.471,0,0,1,.043.038l5.62,5.62a.47.47,0,0,1,.038.043,12.723,12.723,0,0,0,5.172-5.061ZM9.295,3.379a2.342,2.342,0,1,1-.686,1.656,2.344,2.344,0,0,1,.686-1.656Z" transform="translate(0 0)" fill="gold" />
        <path id="Path_48720" data-name="Path 48720" d="M9.961,118.692a1.4,1.4,0,1,0-.412-.993A1.407,1.407,0,0,0,9.961,118.692ZM.481,125.837a.467.467,0,0,0,.331-.137l1.529-1.529a.468.468,0,1,0-.662-.662L.15,125.038a.468.468,0,0,0,.331.8Zm3.262-.927a.468.468,0,0,0-.662,0l-2.94,2.94a.468.468,0,1,0,.662.662l2.94-2.94A.468.468,0,0,0,3.743,124.91Zm.739,1.4L2.953,127.84a.468.468,0,1,0,.662.662l1.529-1.529a.468.468,0,0,0-.662-.662Z" transform="translate(-0.004 -112.663)" fill="gold" />
      </g>
    </svg>
  )
}
export function SettingsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.987" height="15.986" viewBox="0 0 15.987 15.986">
      <g id="Group_146281" data-name="Group 146281" transform="translate(0.005 -0.007)">
        <path id="Path_48718" data-name="Path 48718" d="M7.449,8.479l-.873-.841a.468.468,0,1,0-.649.674l1.2,1.16a.467.467,0,0,0,.656-.006L10.057,7.2a.468.468,0,1,0-.662-.662L7.449,8.479ZM8.02.007a1.079,1.079,0,0,1,.733.315l.959.96a.132.132,0,0,0,.14.038L11.164.969a1.082,1.082,0,0,1,1.325.765l.351,1.311a.132.132,0,0,0,.1.1l1.312.351a1.084,1.084,0,0,1,.765,1.325l-.352,1.311a.134.134,0,0,0,.038.14l.96.96a1.082,1.082,0,0,1,0,1.529l-.96.96a.134.134,0,0,0-.038.14l.352,1.311a1.084,1.084,0,0,1-.765,1.325l-1.312.351a.132.132,0,0,0-.1.1l-.351,1.311a1.082,1.082,0,0,1-1.325.765l-1.311-.351a.132.132,0,0,0-.14.038l-.959.96a1.084,1.084,0,0,1-1.53,0l-.96-.96a.131.131,0,0,0-.14-.038l-1.312.351a1.082,1.082,0,0,1-1.325-.765l-.351-1.311a.132.132,0,0,0-.1-.1L1.722,12.5a1.083,1.083,0,0,1-.765-1.325l.352-1.311a.132.132,0,0,0-.038-.14l-.96-.96a1.082,1.082,0,0,1,0-1.529l.96-.96a.132.132,0,0,0,.037-.14L.957,4.823A1.083,1.083,0,0,1,1.722,3.5l1.311-.351a.132.132,0,0,0,.1-.1l.351-1.311A1.082,1.082,0,0,1,4.812.969l1.312.351a.13.13,0,0,0,.14-.038l.96-.96A1.079,1.079,0,0,1,7.956.007ZM7.988,11.484A3.485,3.485,0,1,1,11.472,8a3.485,3.485,0,0,1-3.485,3.485Z" fill="#2196f3" fillRule="evenodd" />
      </g>
    </svg>
  )
}
export function SearchIconTwo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23.212" viewBox="0 0 22 23.212">
      <g id="Group_145775" data-name="Group 145775" transform="translate(-0.984)">
        <path id="Path_48319" data-name="Path 48319" d="M22.65,21.143,17.226,15.4a9.447,9.447,0,0,0,2.158-6.025A9.3,9.3,0,0,0,10.184,0a9.3,9.3,0,0,0-9.2,9.372,9.3,9.3,0,0,0,9.2,9.372,8.987,8.987,0,0,0,5.271-1.7l5.465,5.789a1.185,1.185,0,0,0,1.7.034,1.24,1.24,0,0,0,.033-1.729ZM10.184,2.445a6.872,6.872,0,0,1,6.8,6.927,6.872,6.872,0,0,1-6.8,6.927,6.872,6.872,0,0,1-6.8-6.927,6.872,6.872,0,0,1,6.8-6.927Z" fill="gold" />
      </g>
    </svg>
  )
}
export function ArrowRightBold() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="12" viewBox="0 0 6 12">
      <path id="Icon_ionic-md-arrow-dropup" data-name="Icon ionic-md-arrow-dropup" d="M12,6,6,0,0,6Z" transform="translate(6) rotate(90)" fill="#fff" />
    </svg>
  )
}
export function CheckIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="10.5" viewBox="0 0 12.5 10.5">
      <path id="Path_49430" data-name="Path 49430" d="M12.493.935a.75.75,0,0,1,.072,1.058l-7.857,9a.75.75,0,0,1-1.13,0L.435,7.393a.75.75,0,1,1,1.13-.986L4.143,9.36l7.292-8.353A.75.75,0,0,1,12.493.935Z" transform="translate(-0.25 -0.75)" fill="#fff" fillRule="evenodd" />
    </svg>
  )
}
export function RightArrowThin() {
  return (
    <svg id="Group_145577" data-name="Group 145577" xmlns="http://www.w3.org/2000/svg" width="5" height="9" viewBox="0 0 5 9">
      <g id="_15" data-name="15" transform="translate(0 0)">
        <path id="Path_7808" data-name="Path 7808" d="M.628,9a.624.624,0,0,1-.58-.4.656.656,0,0,1,.137-.7l3.31-3.4L.185,1.1a.659.659,0,0,1,0-.913.615.615,0,0,1,.887,0L4.818,4.045a.656.656,0,0,1,0,.906L1.072,8.807A.616.616,0,0,1,.628,9Z" transform="translate(0 0)" fill="#fff" />
      </g>
    </svg>
  )
}
export function BackArrow() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="16" viewBox="0 0 21 16">
      <g id="Group_145788" data-name="Group 145788" transform="translate(-75.5 -112.75)">
        <path id="Path_48337" data-name="Path 48337" d="M107.274,233.972H89.953a1.486,1.486,0,0,1,0-2.972h17.321a1.486,1.486,0,0,1,0,2.972Z" transform="translate(-12.228 -111.736)" fill="#fff" />
        <path id="Path_48338" data-name="Path 48338" d="M84.563,128.75a1.535,1.535,0,0,1-1.01-.372l-7.578-6.6a1.34,1.34,0,0,1,.015-2.06l7.786-6.6a1.554,1.554,0,0,1,2.1.1,1.338,1.338,0,0,1-.108,1.972l-6.581,5.581,6.388,5.566a1.338,1.338,0,0,1,.079,1.973A1.529,1.529,0,0,1,84.563,128.75Z" fill="#fff" />
      </g>
    </svg>
  )
}
export const SortIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.795" height="33.795" viewBox="0 0 33.795 33.795">
      <g id="Group_144414" data-name="Group 144414" transform="translate(0)">
        <path id="Path_45783" data-name="Path 45783" d="M33.289,20.985A16.893,16.893,0,1,1,20.983.506,16.895,16.895,0,0,1,33.289,20.985h0Z" fill="#00bc00" />
        <g id="Group_144418" data-name="Group 144418" transform="translate(6.08 5.977)">
          <g id="Group_144417" data-name="Group 144417" transform="translate(2 2)">
            <path id="Path_45788" data-name="Path 45788" d="M9.4,14.75,7.352,16.8V2.892a.892.892,0,1,0-1.784,0V16.8L3.523,14.75a.892.892,0,1,0-1.261,1.261L5.83,19.579a.893.893,0,0,0,1.261,0l3.568-3.568A.892.892,0,1,0,9.4,14.75Z" transform="translate(-2 -2)" fill="#fff" />
            <path id="Path_45789" data-name="Path 45789" d="M10.892,5.784h8.92a.892.892,0,0,0,0-1.784h-8.92a.892.892,0,0,0,0,1.784Z" transform="translate(-2.864 -2.216)" fill="#fff" />
            <path id="Path_45790" data-name="Path 45790" d="M19.812,8h-8.92a.892.892,0,0,0,0,1.784h8.92a.892.892,0,0,0,0-1.784Z" transform="translate(-2.864 -2.648)" fill="#fff" />
            <path id="Path_45791" data-name="Path 45791" d="M17.136,12H10.892a.892.892,0,0,0,0,1.784h6.244a.892.892,0,0,0,0-1.784Z" transform="translate(-2.864 -3.08)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
  )
}
export const FarmTypeIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.795" height="33.795" viewBox="0 0 33.795 33.795">
      <g id="Group_144414" data-name="Group 144414" transform="translate(0)">
        <path id="Path_45783" data-name="Path 45783" d="M33.289,20.985A16.893,16.893,0,1,1,20.983.506,16.895,16.895,0,0,1,33.289,20.985h0Z" fill="#00bc00" />
        <g id="Group_146257" data-name="Group 146257" transform="translate(8.906 -6.783)">
          <path id="Path_48717" data-name="Path 48717" d="M15.657,26.646a1.066,1.066,0,0,0-.1-.086,1.1,1.1,0,0,0-1.413.155l-.336.336a1.6,1.6,0,0,0-.288.394l-.208.4V17.907a1.1,1.1,0,0,0-.885-1.125,1.066,1.066,0,0,0-1.221,1.066v9.98l-.144-.315a1.6,1.6,0,0,0-.315-.453l-.352-.352a1.1,1.1,0,0,0-1.413-.155,1.066,1.066,0,0,0-.139,1.6l2.665,2.665a1.066,1.066,0,0,0,1.508,0h0l2.665-2.665a1.066,1.066,0,0,0-.026-1.508Zm-8.514-7.47L4.515,16.542a1.066,1.066,0,0,0-1.508,0h0L.341,19.208a1.1,1.1,0,0,0-.155,1.413,1.066,1.066,0,0,0,1.6.139l.363-.363a1.6,1.6,0,0,0,.384-.618l.144-.331v10.07a1.1,1.1,0,0,0,.9,1.125,1.066,1.066,0,0,0,1.237-1.066V19.448l.107.3a1.6,1.6,0,0,0,.378.6l.384.384a1.066,1.066,0,0,0,1.508,0q.031-.031.059-.065a1.109,1.109,0,0,0-.1-1.493Z" transform="translate(0)" fill="#fff" />
        </g>
      </g>
    </svg>
  )
}
export const FilterIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.795" height="33.795" viewBox="0 0 33.795 33.795">
      <g id="Group_144414" data-name="Group 144414" transform="translate(33.795 0) rotate(90)">
        <path id="Path_45783" data-name="Path 45783" d="M33.289,20.985A16.893,16.893,0,1,1,20.983.506,16.895,16.895,0,0,1,33.289,20.985h0Z" fill="#00bc00" />
        <g id="Group_144415" data-name="Group 144415" transform="translate(7.795 3.184)">
          <g id="Layer_15" data-name="Layer 15" transform="translate(1.997 4.793)">
            <path id="Path_45786" data-name="Path 45786" d="M15.222,22.634a.8.8,0,0,0,.8-.8V10.791a3.052,3.052,0,1,0-1.594,0V21.837A.8.8,0,0,0,15.222,22.634ZM13.764,7.867a1.458,1.458,0,1,1,1.458,1.45,1.458,1.458,0,0,1-1.458-1.45Z" transform="translate(-4.063 -4.793)" fill="#fff" />
            <path id="Path_45787" data-name="Path 45787" d="M4.247,16.662v5.18a.8.8,0,1,0,1.594,0v-5.18a3.036,3.036,0,0,0,0-5.865V5.617a.8.8,0,0,0-1.594,0V10.8a3.036,3.036,0,0,0,0,5.865Zm.8-4.391a1.458,1.458,0,1,1-1.45,1.458,1.458,1.458,0,0,1,1.45-1.458Z" transform="translate(-1.997 -4.798)" fill="#fff" />
          </g>
        </g>
      </g>
    </svg>
  )
}
export const WalletIcon2 = () => {
  return (
    <svg id="Group_145570" data-name="Group 145570" xmlns="http://www.w3.org/2000/svg" width="21.677" height="18.282" viewBox="0 0 21.677 18.282">
      <path id="Path_47595" data-name="Path 47595" d="M24.393,11c-.059,0-.124,0-.19,0H21.566a3.919,3.919,0,1,0,0,7.835H24.2c.067,0,.131,0,.19,0a1.833,1.833,0,0,0,1.719-1.7c0-.063,0-.131,0-.193V12.9c0-.063,0-.131,0-.193A1.833,1.833,0,0,0,24.393,11Zm-3.06,4.959a1.045,1.045,0,1,0-1.007-1.045A1.026,1.026,0,0,0,21.333,15.962Z" transform="translate(-4.439 -5.777)" fill="#00bc00" fillRule="evenodd" />
      <path id="Path_47596" data-name="Path 47596" d="M24.763,20.626a.23.23,0,0,1,.231.29,4.236,4.236,0,0,1-1.077,1.913,4.922,4.922,0,0,1-3,1.293,38.179,38.179,0,0,1-4.631.16H14.082a38.18,38.18,0,0,1-4.631-.16,4.922,4.922,0,0,1-3-1.293,4.922,4.922,0,0,1-1.293-3A38.159,38.159,0,0,1,5,15.2v-.118a38.159,38.159,0,0,1,.16-4.631,4.921,4.921,0,0,1,1.293-3,4.921,4.921,0,0,1,3-1.293A38.159,38.159,0,0,1,14.082,6h2.207a38.159,38.159,0,0,1,4.631.16,4.922,4.922,0,0,1,3,1.293,4.236,4.236,0,0,1,1.077,1.913.23.23,0,0,1-.231.29H22.127a5.485,5.485,0,1,0,0,10.969Zm-14.54-1.567a.784.784,0,0,1-.784-.784V12.007a.784.784,0,1,1,1.567,0v6.268A.784.784,0,0,1,10.223,19.059Z" transform="translate(-5 -6)" fill="#00bc00" fillRule="evenodd" />
    </svg>
  )
}
export const RefreshIcon2 = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g transform="translate(10, 10)">
        <path id="Path_45792" data-name="Path 45792" d="M22.988,14.667a1.2,1.2,0,0,0-1.374,1.022A7.752,7.752,0,0,1,19.4,20.07,7.87,7.87,0,0,1,8.268,8.94,7.766,7.766,0,0,1,12.51,6.76a7.491,7.491,0,0,1,2.237-.067,7.767,7.767,0,0,1,2.986,1l-1.576.269a1.211,1.211,0,0,0,.2,2.4,1.223,1.223,0,0,0,.206-.017l4.226-.723a1.21,1.21,0,0,0,.989-1.4l-.724-4.226a1.211,1.211,0,1,0-2.386.409l.19,1.112a10.2,10.2,0,0,0-3.839-1.241,9.811,9.811,0,0,0-2.94.09,10.146,10.146,0,0,0-5.526,2.85A10.291,10.291,0,0,0,21.111,21.782a10.16,10.16,0,0,0,2.9-5.741,1.211,1.211,0,0,0-1.022-1.374Z" transform="translate(-3.547 -3)" fill="#00bc00" />
      </g>
    </svg>
  )
}
export const CopyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <defs>
      <linearGradient id="linear-gradient" x1="0.732" y1="-0.328" x2="0.12" y2="1.326" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fed32d" />
        <stop offset="0.27" stop-color="#e39d26" />
        <stop offset="0.62" stop-color="#c5601e" />
        <stop offset="0.87" stop-color="#b33a19" />
        <stop offset="1" stop-color="#ac2c18" />
      </linearGradient>
    </defs>
    <g id="Group_146410" data-name="Group 146410" transform="translate(-2 -2)">
      <path id="Path_48801" data-name="Path 48801" d="M18.548,2h-9.1A3.456,3.456,0,0,0,6,5.452V6H5.452A3.456,3.456,0,0,0,2,9.452v9.1A3.456,3.456,0,0,0,5.452,22h9.1a3.446,3.446,0,0,0,3.406-3h.594A3.456,3.456,0,0,0,22,15.548V5.452A3.456,3.456,0,0,0,18.548,2ZM20,15.548A1.454,1.454,0,0,1,18.548,17H18V9.452A3.456,3.456,0,0,0,14.548,6H8V5.452A1.454,1.454,0,0,1,9.452,4h9.1A1.454,1.454,0,0,1,20,5.452Z" fill="url(#linear-gradient)" />
    </g>
  </svg>
)
export const InstaIcon = () => (
  <svg id="Component_277_1" data-name="Component 277 – 1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <linearGradient id="linear-gradient" x1="0.967" y1="0.292" x2="0" y2="0.633" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fed32d" />
        <stop offset="0.27" stop-color="#e39d26" />
        <stop offset="0.62" stop-color="#c5601e" />
        <stop offset="0.87" stop-color="#b33a19" />
        <stop offset="1" stop-color="#ac2c18" />
      </linearGradient>
    </defs>
    <path id="Ellipse_9450_-_Outline" data-name="Ellipse 9450 - Outline" d="M20,1a19.005,19.005,0,0,0-7.4,36.507A19.005,19.005,0,0,0,27.4,2.493,18.88,18.88,0,0,0,20,1m0-1A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" fill="url(#linear-gradient)" />
    <g id="Group_146411" data-name="Group 146411" transform="translate(8.669 8.66)">
      <path id="Path_48802" data-name="Path 48802" d="M146.767,131H138.1a7.008,7.008,0,0,0-7,7v8.664a7.008,7.008,0,0,0,7,7h8.664a7.008,7.008,0,0,0,7-7V138A7.008,7.008,0,0,0,146.767,131Zm4.472,15.676a4.485,4.485,0,0,1-4.481,4.481h-8.664a4.485,4.485,0,0,1-4.481-4.481v-8.664a4.485,4.485,0,0,1,4.481-4.481h8.664a4.485,4.485,0,0,1,4.481,4.481Z" transform="translate(-131.1 -131)" fill="#fff" />
      <path id="Path_48803" data-name="Path 48803" d="M197.9,192.1a5.8,5.8,0,1,0,5.8,5.8A5.808,5.808,0,0,0,197.9,192.1Zm0,9.317a3.52,3.52,0,1,1,3.52-3.52A3.523,3.523,0,0,1,197.9,201.417Z" transform="translate(-186.566 -186.557)" fill="#fff" />
      <circle id="Ellipse_9451" data-name="Ellipse 9451" cx="0.98" cy="0.98" r="0.98" transform="translate(16.288 4.402) rotate(-9.25)" fill="#fff" />
    </g>
  </svg>
)
export const FacebookIcon = () => (
  <svg id="Component_278_1" data-name="Component 278 – 1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <linearGradient id="linear-gradient" x1="0.967" y1="0.292" x2="0" y2="0.633" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fed32d" />
        <stop offset="0.27" stop-color="#e39d26" />
        <stop offset="0.62" stop-color="#c5601e" />
        <stop offset="0.87" stop-color="#b33a19" />
        <stop offset="1" stop-color="#ac2c18" />
      </linearGradient>
    </defs>
    <path id="Path_49522" data-name="Path 49522" d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" fill="none" />
    <path id="Path_49522_-_Outline" data-name="Path 49522 - Outline" d="M20,1a19.005,19.005,0,0,0-7.4,36.507A19.005,19.005,0,0,0,27.4,2.493,18.88,18.88,0,0,0,20,1m0-1A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" fill="url(#linear-gradient)" />
    <path id="Path_48804" data-name="Path 48804" d="M49.676,35.145H46.01V48.578H40.454V35.145H37.812V30.424h2.642V27.369c0-2.185,1.038-5.606,5.6-5.606l4.115.017v4.583H47.188A1.131,1.131,0,0,0,46.01,27.65v2.778h4.152l-.485,4.717Z" transform="translate(-24.491 -14.404)" fill="#fff" />
  </svg>
)
export const TwitterIcon = () => (
  <svg id="Component_279_1" data-name="Component 279 – 1" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
    <defs>
      <linearGradient id="linear-gradient" x1="0.967" y1="0.292" x2="0" y2="0.633" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#fed32d" />
        <stop offset="0.27" stop-color="#e39d26" />
        <stop offset="0.62" stop-color="#c5601e" />
        <stop offset="0.87" stop-color="#b33a19" />
        <stop offset="1" stop-color="#ac2c18" />
      </linearGradient>
    </defs>
    <path id="Ellipse_9453_-_Outline" data-name="Ellipse 9453 - Outline" d="M20,1a19.005,19.005,0,0,0-7.4,36.507A19.005,19.005,0,0,0,27.4,2.493,18.88,18.88,0,0,0,20,1m0-1A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" fill="url(#linear-gradient)" />
    <path id="Path_48805" data-name="Path 48805" d="M48.748,36.3a9.679,9.679,0,0,1-2.786.763A4.864,4.864,0,0,0,48.1,34.383a9.711,9.711,0,0,1-3.081,1.177,4.855,4.855,0,0,0-8.267,4.425,13.771,13.771,0,0,1-10-5.069,4.856,4.856,0,0,0,1.5,6.477,4.817,4.817,0,0,1-2.2-.607v.062A4.854,4.854,0,0,0,29.944,45.6a4.87,4.87,0,0,1-2.191.084,4.856,4.856,0,0,0,4.532,3.369A9.793,9.793,0,0,1,25.1,51.064,13.8,13.8,0,0,0,46.341,39.441c0-.21,0-.42-.014-.627A9.838,9.838,0,0,0,48.748,36.3Z" transform="translate(-16.319 -22.018)" fill="#fff" />
  </svg>
)
export const ErrorIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="41.802" height="37.892" viewBox="0 0 41.802 37.892">
      <g id="Group_146479" data-name="Group 146479" transform="translate(0 -23.946)">
        <path id="Path_48813" data-name="Path 48813" d="M204.7,27.105l14.593,25.276a6.307,6.307,0,0,1-5.465,9.457H199.235l-5.046-18.946,5.046-18.946A6.3,6.3,0,0,1,204.7,27.105Z" transform="translate(-178.335)" fill="#3b4145" />
        <path id="Path_48814" data-name="Path 48814" d="M15.436,27.105.843,52.381a6.307,6.307,0,0,0,5.465,9.457H20.9V23.946A6.3,6.3,0,0,0,15.436,27.105Z" fill="#525a61" />
        <path id="Path_48815" data-name="Path 48815" d="M275.093,82.049,260.5,56.774a3.8,3.8,0,0,0-3.172-1.9L269.81,87.721h2a3.784,3.784,0,0,0,3.28-5.672Z" transform="translate(-236.32 -28.407)" fill="#ffb751" />
        <path id="Path_48816" data-name="Path 48816" d="M64.7,82.021a4.249,4.249,0,0,1,.436,1.887,3.558,3.558,0,0,1-3.265,3.785H34.691a3.784,3.784,0,0,1-3.281-5.672L46,56.745a3.8,3.8,0,0,1,3.28-1.9l.109,0a3.245,3.245,0,0,1,2.721,1.9Z" transform="translate(-28.383 -28.378)" fill="#ffd764" />
        <path id="Path_48817" data-name="Path 48817" d="M256,354.131v4.205a2.1,2.1,0,1,0,0-4.205Z" transform="translate(-235.099 -303.227)" fill="#3b4145" />
        <path id="Path_48818" data-name="Path 48818" d="M232.348,354.131c.232,0,.421.941.421,2.1s-.188,2.1-.421,2.1a2.1,2.1,0,1,1,0-4.205Z" transform="translate(-211.447 -303.227)" fill="#525a61" />
        <path id="Path_48819" data-name="Path 48819" d="M256,132.646v15.56a2.1,2.1,0,0,0,2.1-2.1V134.749A2.1,2.1,0,0,0,256,132.646Z" transform="translate(-235.099 -99.825)" fill="#3b4145" />
        <path id="Path_48820" data-name="Path 48820" d="M232.348,132.646c.232,0,.421.941.421,2.1V146.1c0,1.161-.188,2.1-.421,2.1a2.1,2.1,0,0,1-2.1-2.1V134.749A2.1,2.1,0,0,1,232.348,132.646Z" transform="translate(-211.447 -99.825)" fill="#525a61" />
      </g>
    </svg>
  )
}
export const PlusIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height='50' viewBox="0 0 24 24"><path fill="#fff" d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm4-9H13V8a1,1,0,0,0-2,0v3H8a1,1,0,0,0,0,2h3v3a1,1,0,0,0,2,0V13h3a1,1,0,0,0,0-2Z" /></svg>
)
export const PaginationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12.528" height="6.264" viewBox="0 0 12.528 6.264">
    <path id="Icon_ionic-md-arrow-dropup" data-name="Icon ionic-md-arrow-dropup" d="M12.528,0,6.264,6.264,0,0Z" transform="translate(12.528 6.264) rotate(180)" fill="#00bc00" />
  </svg>
)
export const PencilIcon = () => (
  <svg height="18px" version="1.1" viewBox="0 0 18 18" width="18px" xmlns="http://www.w3.org/2000/svg"><title /><desc /><defs /><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" strokeWidth="1"><g fill="#fff" id="Core" transform="translate(-213.000000, -129.000000)"><g id="create" transform="translate(213.000000, 129.000000)"><path d="M0,14.2 L0,18 L3.8,18 L14.8,6.9 L11,3.1 L0,14.2 L0,14.2 Z M17.7,4 C18.1,3.6 18.1,3 17.7,2.6 L15.4,0.3 C15,-0.1 14.4,-0.1 14,0.3 L12.2,2.1 L16,5.9 L17.7,4 L17.7,4 Z" id="Shape" /></g></g></g></svg>
)
export const ExclamationIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_629_1167)">
      <path d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346625 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9976 5.879 15.154 3.84555 13.6542 2.34578C12.1545 0.846002 10.121 0.00238211 8 0ZM8 14.545C6.70553 14.545 5.44012 14.1611 4.3638 13.442C3.28748 12.7228 2.44859 11.7006 1.95321 10.5047C1.45784 9.30872 1.32822 7.99274 1.58076 6.72313C1.8333 5.45353 2.45666 4.28732 3.37199 3.37199C4.28732 2.45665 5.45353 1.8333 6.72314 1.58076C7.99274 1.32822 9.30872 1.45783 10.5047 1.95321C11.7006 2.44858 12.7228 3.28747 13.442 4.36379C14.1611 5.44011 14.545 6.70552 14.545 8C14.5429 9.73519 13.8526 11.3987 12.6257 12.6257C11.3987 13.8526 9.7352 14.5429 8 14.545Z" fill="white" />
      <path d="M7.83859 12.8029C8.04047 12.8192 8.24258 12.7742 8.41846 12.6737C8.59434 12.5733 8.73581 12.4221 8.82436 12.24C8.9129 12.0578 8.94441 11.8532 8.91475 11.6528C8.88509 11.4525 8.79563 11.2657 8.6581 11.117C8.52056 10.9684 8.34136 10.8646 8.14392 10.8195C7.94648 10.7743 7.74001 10.7898 7.55151 10.8639C7.36302 10.938 7.20128 11.0673 7.08746 11.2348C6.97365 11.4024 6.91304 11.6003 6.91359 11.8029C6.90465 12.0579 6.99686 12.3061 7.17012 12.4934C7.34339 12.6807 7.58367 12.792 7.83859 12.8029ZM7.83859 9.29288C8.03021 9.28482 8.21084 9.20116 8.34091 9.06022C8.47099 8.91927 8.53991 8.73254 8.53259 8.54088L8.53259 4.02888C8.54619 3.92985 8.53841 3.82905 8.50978 3.73327C8.48116 3.63749 8.43234 3.54895 8.36664 3.47362C8.30093 3.39828 8.21986 3.33789 8.12886 3.29651C8.03786 3.25513 7.93906 3.23371 7.8391 3.23371C7.73913 3.23371 7.64033 3.25513 7.54933 3.29651C7.45834 3.33789 7.37725 3.39828 7.31154 3.47362C7.24583 3.54895 7.19704 3.63749 7.16841 3.73327C7.13979 3.82905 7.13201 3.92985 7.1456 4.02888L7.1456 8.54088C7.13828 8.73237 7.20707 8.91897 7.33693 9.05988C7.46679 9.2008 7.64715 9.28457 7.83859 9.29288Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0_629_1167">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export const LinkIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" fill='#FFFFFF' width="54" height="54" viewBox="0 0 54 54">
    <g id="Group_106543" data-name="Group 106543" transform="translate(0 0.001)">
      <path id="Path_49414" data-name="Path 49414" d="M39,54H9a9,9,0,0,1-9-9V15A9,9,0,0,1,9,6H21a3,3,0,0,1,0,6H9a3,3,0,0,0-3,3V45a3,3,0,0,0,3,3H39a3,3,0,0,0,3-3V33a3,3,0,0,1,6,0V45a9,9,0,0,1-9,9ZM21,36a3,3,0,0,1-2.121-5.121L43.758,6H33a3,3,0,1,1,0-6H51a2.991,2.991,0,0,1,2.1.853h0L53.1.858l0,0,0,0,0,0,0,0,.007.006.007.006,0,0,0,0L53.141.9,53.147.9h0A2.992,2.992,0,0,1,54,3V21a3,3,0,0,1-6,0V10.242L23.121,35.121A2.988,2.988,0,0,1,21,36Z" />
    </g>
  </svg>
)
export const CalculatorIcon = () => (

  <svg width="256" height="256" viewBox="0 0 256 256" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M133.477 133.477H1.40674V25.4967C1.40674 12.2138 12.214 1.40659 25.4969 1.40659H133.477V133.477ZM12.6467 122.237H122.237V12.6466H25.4969C18.41 12.6466 12.6467 18.4099 12.6467 25.4967V122.237Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
    <path d="M67.4418 97.7755C64.3395 97.7755 61.8218 95.2578 61.8218 92.1555V42.7276C61.8218 39.6254 64.3395 37.1076 67.4418 37.1076C70.544 37.1076 73.0618 39.6254 73.0618 42.7276V92.1583C73.0618 95.2606 70.544 97.7755 67.4418 97.7755Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
    <path d="M92.1558 73.0616H42.7279C39.6257 73.0616 37.1079 70.5438 37.1079 67.4416C37.1079 64.3394 39.6257 61.8216 42.7279 61.8216H92.1586C95.2609 61.8216 97.7786 64.3394 97.7786 67.4416C97.7786 70.5438 95.2609 73.0616 92.1558 73.0616Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
    <path d="M254.307 133.477H122.237V1.40659H230.22C243.5 1.40659 254.307 12.2138 254.307 25.4967V133.477ZM133.477 122.237H243.067V25.4967C243.067 18.4099 237.304 12.6466 230.22 12.6466H133.477V122.237Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
    <path d="M212.989 73.0616H163.555C160.453 73.0616 157.935 70.5438 157.935 67.4416C157.935 64.3394 160.453 61.8216 163.555 61.8216H212.989C216.091 61.8216 218.609 64.3394 218.609 67.4416C218.609 70.5438 216.091 73.0616 212.989 73.0616Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
    <path d="M133.477 254.307H25.4969C12.214 254.307 1.40674 243.499 1.40674 230.219V122.237H133.477V254.307ZM12.6467 133.477V230.219C12.6467 237.303 18.41 243.067 25.4969 243.067H122.237V133.477H12.6467Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
    <path d="M49.9665 211.37C48.5277 211.37 47.0918 210.822 45.9931 209.723C43.7985 207.529 43.7985 203.971 45.9931 201.776L80.9439 166.823C83.1357 164.628 86.696 164.628 88.8906 166.823C91.0852 169.017 91.0852 172.575 88.8906 174.77L53.9398 209.723C52.8439 210.819 51.4024 211.37 49.9665 211.37Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
    <path d="M84.9172 211.37C83.4785 211.37 82.0398 210.822 80.9439 209.723L45.9931 174.767C43.7985 172.572 43.7985 169.015 45.9931 166.82C48.1877 164.625 51.748 164.625 53.9398 166.82L88.8906 201.774C91.0852 203.968 91.0852 207.526 88.8906 209.72C87.7947 210.819 86.356 211.37 84.9172 211.37Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
    <path d="M230.22 254.307H122.237V122.237H254.307V230.219C254.307 243.499 243.5 254.307 230.22 254.307ZM133.477 243.067H230.22C237.304 243.067 243.067 237.303 243.067 230.219V133.477H133.477V243.067Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
    <path d="M212.989 193.892H163.555C160.453 193.892 157.935 191.374 157.935 188.272C157.935 185.169 160.453 182.652 163.555 182.652H212.989C216.091 182.652 218.609 185.169 218.609 188.272C218.609 191.374 216.091 193.892 212.989 193.892Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
    <path d="M188.333 174.668H188.207C185.105 174.668 182.587 172.151 182.587 169.048C182.587 165.946 185.105 163.428 188.207 163.428C191.309 163.428 193.889 165.946 193.889 169.048C193.889 172.151 191.438 174.668 188.333 174.668Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
    <path d="M188.333 213.115H188.207C185.105 213.115 182.587 210.597 182.587 207.495C182.587 204.393 185.105 201.875 188.207 201.875C191.309 201.875 193.889 204.393 193.889 207.495C193.889 210.597 191.438 213.115 188.333 213.115Z" fill="#63698B" stroke="#63698B" strokeWidth="6" />
  </svg>
)

export const WarningIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height='20' enable-background="new 0 0 47.5 47.5" viewBox="0 0 47.5 47.5" id="warning"><defs><clipPath id="a"><path d="M0 38h38V0H0v38Z" /></clipPath></defs><g clip-path="url(#a)" transform="matrix(1.25 0 0 -1.25 0 47.5)"><path fill="#ffcc4d" d="M0 0c-1.842 0-2.654 1.338-1.806 2.973l15.609 30.055c.848 1.635 2.238 1.635 3.087 0L32.499 2.973C33.349 1.338 32.536 0 30.693 0H0Z" transform="translate(3.653 2)" /><path fill="#231f20" d="M0 0c0 1.302.961 2.108 2.232 2.108 1.241 0 2.233-.837 2.233-2.108v-11.938c0-1.271-.992-2.108-2.233-2.108-1.271 0-2.232.807-2.232 2.108V0Zm-.187-18.293a2.422 2.422 0 0 0 2.419 2.418 2.422 2.422 0 0 0 2.419-2.418 2.422 2.422 0 0 0-2.419-2.419 2.422 2.422 0 0 0-2.419 2.419" transform="translate(16.769 26.34)" /></g></svg>
)