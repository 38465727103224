import { Dispatch, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { callContractGetMethod } from "../../../app/actions/contract.action";
import { RootState } from "../../../app/store";
import Logo from "../../../assets/images/logo.png";
import NetworkSelector from "../../../component/common/NetworkSelector/Index";
import Toast from "../../../component/common/Toast";
import CommonBtn from "../../../component/ui/CommonBtn/CommonBtn";
import ConnectWallet from "../../../component/ui/Modals/ConnectWallet/ConnectWallet";
import { adminStatus } from "../../../features/connect.slice";
import "./Login.scss";

const Login = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const walletAddress = useSelector(
    (state: RootState) => state.connect.walletAddress
  );
  const _adminStatus = useSelector(
    (state: RootState) => state?.connect?.adminStatus
  );

  setTimeout(function () {
    if (_adminStatus) {
      navigate("/admin/dashboard");
    }
  }, 10);

  const adminLogin = async () => {
    if (walletAddress) {
      let feeToSetterAddress: any = await dispatch(
        callContractGetMethod(
          "feeToSetter",
          [],
          "factory",
          false,
          walletAddress
        )
      );
      let smartChefFactory: any = await dispatch(
        callContractGetMethod(
          "owner",
          [],
          "smartChefFactory",
          false,
          walletAddress
        )
      );
      let masterChef: any = await dispatch(
        callContractGetMethod("owner", [], "master", false, walletAddress)
      );

      if (
        feeToSetterAddress?.toLowerCase() === walletAddress.toLowerCase() &&
        masterChef?.toLowerCase() === walletAddress.toLowerCase() &&
        smartChefFactory?.toLowerCase() === walletAddress.toLowerCase()
      ) {
        await dispatch(adminStatus({ adminStatus: true, adminType: "all" }));
        Toast.success("Login successfully");
        navigate("/admin/dashboard");
      } else if (
        feeToSetterAddress?.toLowerCase() === walletAddress.toLowerCase() &&
        smartChefFactory?.toLowerCase() === walletAddress.toLowerCase()
      ) {
        await dispatch(
          adminStatus({ adminStatus: true, adminType: "liquidityPool" })
        );
        Toast.success("Login successfully");
        navigate("/admin/dashboard");
      } else if (
        feeToSetterAddress?.toLowerCase() === walletAddress.toLowerCase() &&
        masterChef?.toLowerCase() === walletAddress.toLowerCase()
      ) {
        await dispatch(
          adminStatus({ adminStatus: true, adminType: "liquidityFarm" })
        );
        Toast.success("Login successfully");
        navigate("/admin/dashboard");
      } else if (
        smartChefFactory?.toLowerCase() === walletAddress.toLowerCase() &&
        masterChef?.toLowerCase() === walletAddress.toLowerCase()
      ) {
        await dispatch(
          adminStatus({ adminStatus: true, adminType: "poolFarm" })
        );
        Toast.success("Login successfully");
        navigate("/admin/dashboard");
      } else if (
        feeToSetterAddress?.toLowerCase() === walletAddress.toLowerCase()
      ) {
        await dispatch(
          adminStatus({ adminStatus: true, adminType: "liquidity" })
        );
        Toast.success("Login successfully");
        navigate("/admin/dashboard");
      } else if (
        smartChefFactory?.toLowerCase() === walletAddress?.toLowerCase()
      ) {
        await dispatch(adminStatus({ adminStatus: true, adminType: "pool" }));
        Toast.success("Login successfully");
        navigate("/admin/dashboard");
      } else if (masterChef?.toLowerCase() === walletAddress.toLowerCase()) {
        await dispatch(adminStatus({ adminStatus: true, adminType: "farm" }));
        Toast.success("Login successfully");
        navigate("/admin/dashboard");
      } else {
        Toast.error("Please login with admin address");
      }
      window.location.reload();
    } else {
      setShow(true);
    }
  };
  return (
    <>
      <div></div>
      <section className="Login">
        <Container>
          <Row className="justify-content-center">
            <Col sm={8} lg={6}>
              <div className="LoginCard">
                <div className="pb-4">
                  <img src={Logo} alt="Logo" />
                </div>
                <hr />
                <h1 className="h2 pt-4">
                  Welcome Back <span className="clr-green">Admin</span>
                </h1>
                {/* <NetworkSelector /> */}
                {/* <w3m-network-button /> */}
                <ConnectWallet admin={true} />

                {/* <p className="wltAddress">{walletAddress}</p> */}
                {/* <div className="walletDrop not-connected">
                                    <button type="button" className="dropdown-toggle" onClick={handleShow}>
                                        <span className="mobileHidden">{walletAddress ? CommonService.custmizeAddress(walletAddress) : "Connect"}</span> <WalletIcon />
                                    </button>
                                </div> */}
                <CommonBtn
                  title={walletAddress ? "Login" : "Connect"}
                  className="w-100 btn-yellow mt-4"
                  role="link"
                  onClick={adminLogin}
                />
                {/* <ConnectWallet show={show} handleClose={handleClose} /> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <div></div>
    </>
  );
};

export default Login;
