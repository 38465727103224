// import BigNumber from "big-number";
import moment from "moment";
import copy from "copy-to-clipboard";
import Toast from "../component/common/Toast";
import { NETWORKS } from "../constant";
import JSBI from "jsbi";
import web3 from "web3";
import { callContractGetMethod } from "../app/actions/contract.action";
import { useDispatch } from "react-redux";
import { Dispatch } from "react";

const BigNumber = require("big-number");
const Decimal = require("decimal.js");
const BigValue = require('bignumber.js');

// const convertWithDecimal = (value: any, decimal: any) => {
//   if (value > 0) {
//     if (isInt(value)) {
//       value = parseInt(value.toString().split(".")[0]);
//       value = toFixed(value);
//       value = BigNumber(value);
//       value = BigNumber(value).multiply(decimal);

//     } else {

//       value = value * decimal;
//       value = toFixed(value);
//       value = parseInt(value.toString().split(".")[0]);
//       value = toFixed(value);
//       value = BigNumber(value);
//     }

//     return value.toString();
//   } else {
//     return 0;
//   }
// };

// Number.prototype.noExponents = function () {
//   var data = String(this).split(/[eE]/);;;;;;;;;
//   if (data.length == 1) return data[0];

//   var z = "",
//     sign = this < 0 ? "-" : "",
//     str = data[0].replace(".", ""),
//     mag = Number(data[1]) + 1;

//   if (mag < 0) {
//     z = sign + "0.";
//     while (mag++) z += "0";
//     return z + str.replace(/^\-/, "");
//   }
//   mag -= str.length;
//   while (mag--) z += "0";
//   return str + z;
// };


const divideBigNumber = (value: any, decimal: number, suffixes = false) => {
  if (decimal == 0) {
    return "0"
  } else {
    let x = new BigValue(value?.toString());
    let y = new BigValue(decimal?.toString());
    let z = x.dividedBy(y);
    return suffixes ? intToSuffixes(parseInt(z.toString())) : z.toString();
  }

};



const divideBigNumberWithoutDecimal = (
  value: any,
  decimal: number,
  suffixes = false
) => {


  if (decimal == 0) {
    return "0"
  } else {
    let x = new Decimal(value.toString());
    let y = new Decimal(decimal.toString());
    let z = x.dividedBy(y);
    z = z.toString().split(".")[0];

    return suffixes ? intToSuffixes(parseInt(z.toString())) : z.toString();
  }

};

const divideBigNumberWithE = (value: any, decimal: number, suffixes = true) => {
  if (decimal == 0) {
    return "0";
  } else {
    if (value > 0) {
      value = value.noExponents();
      value = BigNumber(value).divide(decimal).toString();
      return suffixes ? intToSuffixes(parseInt(value)) : value;
    } else {
      return "0";
    }
  }

};

const toFixed = (x: any) => {
  if (Math.abs(x) < 1.0) {
    var e = parseInt(x.toString().split("e-")[1]);
    if (e) {
      x *= Math.pow(10, e - 1);
      x = "0." + new Array(e).join("0") + x.toString().substring(2);
    }
  } else {
    var e = parseInt(x.toString().split("+")[1]);
    if (e > 20) {
      e -= 20;
      x /= Math.pow(10, e);
      x += new Array(e + 1).join("0");
    }
  }
  return x;
};

const isInt = (n: any) => {
  return n % 1 === 0;
};

const getError = (error: any) => {
  let errorMsg =
    error && error.message ? error.message : "Something went wrong";
  if (errorMsg.indexOf("Internal JSON-RPC error") > -1) {
    let msg = errorMsg.replace("Internal JSON-RPC error.", "");
    msg = JSON.parse(msg);
    msg.message = msg.message === "header not found"
      ? "Binance server is not responding please try again"
      : msg.message.indexOf("err:") > -1 || msg.message.indexOf("execution reverted:") > -1
        ? msg.message.split(":")[msg.message.split(":").length - 1]
        : msg.message;
    let convertMsg = msg?.message.replace(/['‘’"“”]/g, '');
    if (convertMsg == ' from address balance is low') {
      return 'Due to a lack of reward balance in the pool, you cannot stake/harvest.'
    }
    return msg.message;
  } else if (errorMsg.indexOf("INVALID_ARGUMENT") > -1) {

    return errorMsg.split("(")[0];
  } else if (errorMsg.indexOf("MetaMask Tx Signature") > -1) {

    let msg = errorMsg.replace("MetaMask Tx Signature:", "");
    return msg;
  } else {

    if (errorMsg.indexOf("execution reverted:") > -1) {
      let msg = errorMsg.split(":");
      msg = msg[2].split("{");

      msg = msg[0].includes("from' address balance is low") ? 'Due to a lack of reward balance in the pool, you cannot stake/harvest.' : msg[0].split("/n")[0];
      return msg
    } else {
      return errorMsg;
    }


  }
};

const custmizeAddress = (address: string) => {
  let firstFive = address?.substring(0, 3);
  let lastFour = address?.substr(address.length - 2);
  return firstFive + "..." + lastFour;
};

const getCoinType = (type: any) => {
  type = type.toLowerCase();
  type = type === "defi" ? 1 : type === "chain" ? 2 : type === "nft" ? 3 : 0;
  return type;
};

const fixedToDecimal = (value: any, decimals = 4) => {
  let re = new RegExp('^-?\\d+(?:\\.\\d{0,' + decimals + '})?');
  value =
    value && parseFloat(value) > 0 ?
      value.toString().match(re)[0]
      // ? decimals === 2
      //   ? value.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0] :
      //   decimals == 8 ?
      //     value.toString().match(/^-?\d+(?:\.\d{0,8})?/)[0]
      //     : value.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]
      : 0;
  return value;
};

const secondsToDays = (sec: any) => {
  var seconds = parseInt(sec, 10);
  var days = Math.floor(seconds / (3600 * 24));
  seconds -= days * 3600 * 24;
  var hrs = Math.floor(seconds / 3600);
  seconds -= hrs * 3600;
  var mnts = Math.floor(seconds / 60);
  seconds -= mnts * 60;
  let result = "";
  if (days > 0) {
    result = result + days + " days ";
  }
  if (hrs > 0) {
    result = result + hrs + " Hrs ";
  }
  if (mnts > 0) {
    result = result + mnts + " Minutes ";
  }
  if (seconds > 0) {
    result = result + seconds + " Seconds";
  }
  return result;
};

const calculateTimeLeft = async (timestamp: any) => {
  let timeleft;
  let data: any = new Date(timestamp * 1000);
  let betTime: any = moment.utc(data);
  let leftDays = moment.duration(moment(betTime).diff(moment())) as any;
  let days = parseInt(leftDays.asDays());
  let _hours: any = leftDays.asHours() % 24;
  let hrs = parseInt(_hours);
  let min = parseInt(leftDays.asMinutes()) % 60;
  let sec = parseInt(leftDays.asSeconds()) % 60;
  if (days >= 0 && hrs >= 0 && min >= 0 && sec >= 0) {
    if (days > 0) {
      timeleft = days + " d : " + hrs + "h : " + min + "m : " + sec + "s";
    } else if (hrs > 0) {
      timeleft = hrs + "h : " + min + "m : " + sec + "s";
    } else if (min > 0) {
      timeleft = min + "m : " + sec + "s";
    } else if (sec > 0) {
      timeleft = sec + "s";
    }
  } else {
    timeleft = "Ended";
  }
  return timeleft;
};

const getLeftTime = (IcoEndTime: any) => {
  let data = IcoEndTime * 1000;
  let timeLeft = '';
  let endTime = moment.utc(data);
  let leftDays: any = moment.duration(endTime.diff(moment()));
  let days = parseInt(leftDays.asDays()) < 0 ? 0 : parseInt(leftDays.asDays());
  let _hours: any = leftDays.asHours() % 24;

  let hrs = parseInt(_hours) < 0 ? 0 : parseInt(_hours);
  let min =
    parseInt(leftDays.asMinutes()) % 60 < 0
      ? 0
      : parseInt(leftDays.asMinutes()) % 60;
  let sec =
    parseInt(leftDays.asSeconds()) % 60 < 0
      ? 0
      : parseInt(leftDays.asSeconds()) % 60;
  timeLeft = days > 0 ? timeLeft + `${days} days` : '';
  timeLeft = hrs > 0 ? timeLeft + ` ${hrs} hrs` : '';
  timeLeft = min > 0 ? timeLeft + ` ${min} min` : '';
  timeLeft = sec >= 0 ? timeLeft + ` ${sec} sec` : '';
  return timeLeft;
};

const intToSuffixes = (num: any, fixed = 2) => {
  if (num === null) {
    return null;
  } // terminate early
  if (num === 0) {
    return "0";
  } // terminate early
  fixed = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
  let b: any = num.toPrecision(2).split("e"), // get power
    k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
    c =
      k < 1
        ? num.toFixed(0 + fixed)
        : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
    d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
    e = d + ["", "K", "M", "B", "T"][k]; // append power
  return e;
};

const formatUrl = (url: string, params: any) => {
  params =
    params && Object.keys(params).length > 0
      ? `?${new URLSearchParams(params).toString()}`
      : ``;
  return `${url}${params}`;
};

//type is use for  token/currency
const allowOnlyNumber = (value: any, type: any) => {
  let regex, decimal;
  if (type === "currency") {
    regex = /^(\d{0,8}|\d{0,8}\.\d{0,18})$/gm;
  } else if (type === "token") {
    regex = /^(\d{0,8}|\d{0,8}\.\d{0,8})$/gm;
  } else if (type === "setting") {
    regex = /^(\d{0,2}|\d{0,2}\.\d{0,2})$/gm;
  } else {
    regex = /^(\d{0,8}|\d{0,8}\.\d{0,8})$/gm;
  }

  let re = new RegExp(regex);
  if (re.test(value)) {
    return true;
  } else {
    return false;
  }
};

//type is use for  token/currency
const allowOnlyNumberWithDecimals = (value: any, type: any, decimals: any) => {
  let re;
  let decimalValue = ((Math.log10(decimals)) - 2);
  if (type === "currency") {
    re = new RegExp('^(\\d{0,8}|\\d{0,8}\\.\\d{0,' + decimalValue + '})$', 'gm');
  } else if (type === "token") {
    re = new RegExp('^(\\d{0,8}|\\d{0,8}\\.\\d{0,' + decimalValue + '})$', 'gm');
  } else if (type === "stake") {
    re = new RegExp('^(\\d{0,8}|\\d{0,8}\\.\\d{0,' + Math.log10(decimals) + '})$', 'gm');
  } else if (type === "setting") {
    re = new RegExp("^(\\d{0,2}|\\d{0,2}\\.\\d{0,2})", 'gm');
  } else {
    re = new RegExp("^(\\d{0,8}|\\d{0,8}\\.\\d{0,8})", 'gm');
  }
  if (re.test(value)) {
    return true;
  } else {
    return false;
  }
};

/**ALLOW ONLY STRING */
export const allowOnlyString = (inputString: string) => {
  let res = /^[a-zA-Z]+$/.test(inputString);
  return res;
};

const getSeconds = (value: any) => {
  let data: any = 60 * value;
  return parseInt(data);
};

const setItemForSession = (key: string, value: any) => {
  localStorage.setItem(key, value);
};
const getItemFromSession = (key: any) => {
  return localStorage.getItem(key);
};
const copyToClipboard = (copyText: any) => {
  copy(copyText);
  Toast.success("Copied to clipboard");
};

export const cryptoDecimals = (inValue: any) => {

  let value = toFixed(Number(inValue));

  if (!value || value == 0) {
    return 0.0;
  } else if ((value > 0 && value <= 9) || (value < 0 && value >= -9)) {
    return toCustomFixed(value, 5);
  } else if ((value > 9 && value <= 99) || (value < -9 && value >= -99)) {
    return toCustomFixed(value, 4);
  } else if ((value > 99 && value <= 999) || (value < -99 && value >= -999)) {
    return toCustomFixed(value, 3);
  } else if (
    (value > 999 && value <= 9999) ||
    (value < -999 && value >= -9999)
  ) {
    return toCustomFixed(value, 2);
  } else if (value > 9999 || value < -9999) {
    return toCustomFixed(value, 0);
  }
};

const toCustomFixed = (num: any, fixed: number) => {
  const re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
  return num.toString().match(re).length ? num.toString().match(re)[0] : 0
};

function numberToString(arg: any) {
  if (typeof arg === "string") {
    if (!arg.match(/^-?[0-9.]+$/)) {
      throw new Error(
        "while converting number to string, invalid number value '" +
        arg +
        "', should be a number matching (^-?[0-9.]+)."
      );
    }
    return arg;
  } else if (typeof arg === "number") {
    return String(arg);
  } else if (
    typeof arg === "object" &&
    arg.toString &&
    (arg.toTwos || arg.dividedToIntegerBy)
  ) {
    if (arg.toPrecision) {
      return String(arg.toPrecision());
    } else {
      // eslint-disable-line
      return arg.toString(10);
    }
  }
  throw new Error(
    "while converting number to string, invalid number value '" +
    arg +
    "' type " +
    typeof arg +
    "."
  );
}
// Function to convert into wei
function toWei(input: any, unit: any) {
  var ether = numberToString(input); // eslint-disable-line
  var base = unit;
  var baseLength = base.length - 1 || 1;
  if (ether === ".") {
    throw new Error(
      "[ethjs-unit] while converting number " + input + " to wei, invalid value"
    );
  }

  // Is it negative?
  var negative = ether.substring(0, 1) === "-";

  if (negative) {
    ether = ether.substring(1);
  }
  // Split it into a whole and fractional part
  var comps = ether.split("."); // eslint-disable-line
  if (comps.length > 2) {
    throw new Error(
      "[ethjs-unit] while converting number " +
      input +
      " to wei,  too many decimal points"
    );
  }
  var whole = comps[0],
    fraction = comps[1]; // eslint-disable-line
  if (!whole) {
    whole = "0";
  }
  if (!fraction) {
    fraction = "0";
  }
  if (fraction.length > baseLength) {
    throw new Error(
      "[ethjs-unit] while converting number " +
      input +
      " to wei, too many decimal places"
    );
  }
  while (fraction.length < baseLength) {
    fraction += "0";
  }

  if (!parseInt(whole)) {
    return fraction.replace(/^0*(?=[1-9])/g, '');
  }
  if (negative) {
    return "-" + whole + fraction;
  }
  return whole + fraction;
}



function fromWei(input: any, unit: any) {
  if (!input) return "0";

  let str = "";

  if (Math.sign(input) !== Math.sign(unit)) str += "-";

  const numer = Math.abs(input);
  const denom = Math.abs(unit);

  str += Math.floor(numer / denom);
  let rem = numer % denom;
  if (!rem) return str;
  str += ".";

  const map = new Map();
  while (rem !== 0) {
    map.set(rem, str.length);
    rem *= 10;
    str += Math.floor(rem / denom);
    rem %= denom;

    if (map.has(rem)) {
      const idx = map.get(rem);
      return str.slice(0, idx) + `(${str.slice(idx)})`;
    }
  }
  return str;
}


/** Divide with Decimal*/
export const divideWithDecimal = (value: any, decimal: any) => {
  if (decimal == 0) {
    return 0;
  } else {
    if (parseInt(value) > 0 && decimal) {
      const newDecimal = decimal.toString().length - 1;
      const decimalBigN = JSBI.BigInt(newDecimal)
      const convertedDecimal = JSBI.exponentiate(JSBI.BigInt(10), decimalBigN);

      return fromWei(value, String(convertedDecimal))

    }
    return 0;
  }
};

/**CONVERT NUMBER WITH DECIMALS FOR CONTRACT CALL */
export const convertWithDecimalInternally = (value: any, decimal: any) => {
  const decimalBigN = JSBI.BigInt(decimal);
  const convertedDecimal = JSBI.exponentiate(JSBI.BigInt(10), decimalBigN);
  return toWei(value, String(convertedDecimal));
};



/** Multiply with big numbers */
const multiplyTwoBigDigits = (valueOne: any, valueTwo: any) => {
  const a = JSBI.BigInt(valueOne);
  const b = JSBI.BigInt(valueTwo);
  const result = JSBI.multiply(a, b);
  return String(result);
};

//multiplyBigDigitsWithDecimals
export const multiplyBigDigitsWithDecimals = (
  valueOne: string,
  valueTwo: string
) => {
  valueOne = valueOne.toString();
  valueTwo = valueTwo.toString();
  let a: any;
  let b: any;
  let decimalLengthA: any = 0;
  let decimalLengthB: any = 0;
  if (valueOne.includes(".")) {
    a = convertWithDecimalInternally(valueOne, valueOne.split(".")[1].length);
    decimalLengthA = valueOne.split(".")[1].length;
  } else {
    a = valueOne;
  }
  if (valueTwo.includes(".")) {
    b = convertWithDecimalInternally(valueTwo, valueTwo.split(".")[1].length);
    decimalLengthB = valueTwo.split(".")[1].length;
  } else {
    b = valueTwo;
  }

  let decimalLength = decimalLengthA + decimalLengthB;
  let result = multiplyTwoBigDigits(a, b);


  if (
    result.substring(0, result.length - decimalLength).length &&
    result.substring(result.length - decimalLength).length
  ) {
    result =
      result.substring(0, result.length - decimalLength) +
      "." +
      result.substring(result.length - decimalLength);
  } else if (!result.substring(0, result.length - decimalLength).length) {
    // eslint-disable-next-line
    result = "0" + "." + result.substring(result.length - decimalLength);
  }
  return result;
};


const convertWithDecimal = (valueOne: any, valueTwo: any) => {


  valueOne = valueOne.toString();
  valueTwo = valueTwo.toString();
  let a: any;
  let b: any;
  let decimalLengthA: any = 0;
  let decimalLengthB: any = 0;
  if (valueOne.includes(".")) {
    a = convertWithDecimalInternally(valueOne, valueOne.split(".")[1].length);
    decimalLengthA = valueOne.split(".")[1].length;
  } else {
    a = valueOne;
  }
  if (valueTwo.includes(".")) {
    b = convertWithDecimalInternally(valueTwo, valueTwo.split(".")[1].length);
    decimalLengthB = valueTwo.split(".")[1].length;
  } else {
    b = valueTwo;
  }

  let decimalLength = decimalLengthA + decimalLengthB;
  let result = multiplyTwoBigDigits(a, b);

  if (
    result.substring(0, result.length - decimalLength).length &&
    result.substring(result.length - decimalLength).length
  ) {
    result =
      result.substring(0, result.length - decimalLength)

  } else if (!result.substring(0, result.length - decimalLength).length) {
    // eslint-disable-next-line
    result = "0"
  }
  return (result)
};


const subtractBigNumber = (num1: any, num2: any) => {
  num1 = Math.trunc(num1);
  num2 = Math.trunc(num2);

  num1 = JSBI.BigInt(num1);
  num2 = JSBI.BigInt(num2);
  return JSBI.subtract(num1, num2).toString();
};
const addBigNumber = (num1: any, num2: any) => {
  num1 = Math.trunc(num1);
  num2 = Math.trunc(num2);
  num1 = JSBI.BigInt(num1);
  num2 = JSBI.BigInt(num2);
  return JSBI.add(num1, num2).toString();
};

//remove prefixed zeros while enter an input
const removePrefixedZeros = (value: any) => {
  if (value.charAt(0) == 0) {
    value = value.substring(1);
  } else if (value.charAt(0) == '.') {
    value = "0" + value;
  }
  return value;
}




export const CommonService = {
  convertWithDecimal,
  toFixed,
  getError,
  custmizeAddress,
  getCoinType,
  fixedToDecimal,
  secondsToDays,
  calculateTimeLeft,
  getLeftTime,
  intToSuffixes,
  divideBigNumber,
  formatUrl,
  divideBigNumberWithE,
  allowOnlyNumber,
  allowOnlyNumberWithDecimals,
  allowOnlyString,
  getSeconds,
  setItemForSession,
  getItemFromSession,
  copyToClipboard,
  divideBigNumberWithoutDecimal,
  isInt,
  cryptoDecimals,
  subtractBigNumber,
  addBigNumber,
  divideWithDecimal,
  multiplyBigDigitsWithDecimals,
  removePrefixedZeros,
};
