import React, { ChangeEvent, Dispatch, useEffect, useRef, useState } from 'react'
import { Container, Dropdown, Nav, Navbar } from "react-bootstrap"
import { CheckIcon } from "../../../../assets/svgIcons/svgIcons";
import Logo from '../../../../assets/images/logo.png'
import { Link, NavLink } from "react-router-dom";
import './Header.scss';
import BSC from '../../../../assets/icons/bnb2.png';
import ETH from '../../../../assets/icons/eth2.png';
import Poly from '../../../../assets/icons/polygon.png';
import MetaIcon from '../../../../assets/icons/metamask_icon.png';
import DisconnectAdminModal from "../../../ui/Modals/DisconnectAdminModal/DisconnectAdminModal";
import AdminLogoutModal from "../../../ui/Modals/AdminLogoutModal/AdminLogoutModal";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import { removeUserDetails, saveNetworkDetails } from '../../../../features/connect.slice';
import { CommonService } from '../../../../services/commonService';
import Toast from '../../Toast';
import ConnectWallet from '../../../ui/Modals/ConnectWallet/ConnectWallet';
import { useNavigate } from "react-router-dom";
import NetworkSelector from '../../NetworkSelector/Index';

const Header = () => {

    const dispatch: Dispatch<any> = useDispatch();

    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const navigate = useNavigate();

    const [LiquidityAdmin, setLiquidityAdmin] = useState(false);

    const [showLogout, setshowLogout] = useState(false);
    const [saveNetworkDetail, setSaveNetworkDetail] = useState<any>();

    const handleLogout = () => setshowLogout(true);
    const closeLogout = () => setshowLogout(false);

    const defaultNetworks = useSelector((state: RootState) => state.connect.networksDetails)
    const adminType = useSelector((state: RootState) => state.connect.adminType)

    const walletAddress = useSelector((state: RootState) => state.connect.walletAddress);
    const [backdrop, setBackdrop] = useState(false);

    const handleSidebar = () => {
        // function for handling sidebar
        if (document.body.clientWidth < 991) {
            myRefname.current.click();
        }
    }
    const myRefname: any = useRef(null);
    const handleDisconnect = () => {
        if (walletAddress) {
            dispatch(removeUserDetails());
            navigate("/admin");
            setShow(false);
        } else {
            setShow1(true);
        }
        Toast.success('Logout successfully');
    }

    return (
        <>
            <div onClick={handleSidebar} className={`sidebarBackdrop d-lg-none ${backdrop ? 'active' : ''}`} />
            <header className="header admin">
                <Navbar expand="xs">
                    <Container fluid>
                        <Link to="dashboard" className="navbar-brand"><img src={Logo} alt="" /></Link>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ms-auto">
                                <NavLink
                                    to="/admin/dashboard"
                                    onClick={() => { handleSidebar() }}
                                    className="nav-link"
                                >
                                    Dashboard
                                </NavLink>
                                {(adminType === 'all' || adminType === 'liquidity' || adminType === 'liquidityFarm' || adminType === 'liquidityPool') ?
                                    <>
                                        <NavLink
                                            to="tokens"
                                            onClick={() => { handleSidebar() }}
                                            className="nav-link"
                                        >
                                            Tokens
                                        </NavLink>
                                    </> : ""
                                }
                                {(adminType === 'all' || adminType === 'farm' || adminType === 'liquidityFarm' || adminType === 'poolFarm')
                                    ?
                                    <>
                                        <NavLink
                                            to="/admin/farms"
                                            onClick={() => { handleSidebar() }}
                                            className="nav-link"
                                        >
                                            Farms
                                        </NavLink>
                                    </>
                                    : ""}
                                {(adminType === 'all' || adminType === 'pool' || adminType === 'liquidityPool' || adminType === 'poolFarm')
                                    ?
                                    <>
                                        <NavLink
                                            to="/admin/pool"
                                            onClick={() => { handleSidebar() }}
                                            className="nav-link"
                                        >
                                            Pools
                                        </NavLink>
                                    </>
                                    : ""}

                                <NavLink
                                    to="settings"
                                    onClick={() => { handleSidebar() }}
                                    className="nav-link"
                                >
                                    Settings
                                </NavLink>
                                <NavLink
                                    to="#"
                                    onClick={() => { handleSidebar(); handleLogout(); }}
                                    className="nav-link"
                                >
                                    Logout
                                </NavLink>
                            </Nav>
                        </Navbar.Collapse>
                        <div className="header-action ms-lg-auto">
                            <ConnectWallet admin={true}/>
                            {/* <NetworkSelector />
                            <div className="walletDrop">
                                <button type="button" className="dropdown-toggle" onClick={() => { walletAddress ? setShow(true) : setShow1(true) }}>

                                    <span className="mobileHidden">{walletAddress ? CommonService.custmizeAddress(walletAddress) : "Connect"}</span> <img src={MetaIcon} alt="" />
                                </button>
                            </div> */}
                            <Navbar.Toggle onClick={() => setBackdrop(!backdrop)} aria-controls="basic-navbar-nav" ref={myRefname}>
                                <span className="menu-line"></span>
                                <span className="menu-line"></span>
                                <span className="menu-line"></span>
                            </Navbar.Toggle>
                        </div>
                    </Container>
                </Navbar>
            </header>
            {/* <ConnectWallet show={show1} handleClose={() => setShow1(false)} /> */}
            <DisconnectAdminModal show={show} onHide={() => setShow(false)} DisconectClick={handleDisconnect} />
            <AdminLogoutModal showLogout={showLogout} HideLogout={closeLogout} CancelClick={closeLogout} ConfirmClick={closeLogout} />
        </>
    )
}
export default Header