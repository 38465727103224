export const API_URL: any = {
    ADMIN_LOGIN: '/admin/login',
    GET_TOKEN: "/token/get-tokens",
    ADD_TOKEN: '/admin/add-token',
    GET_TOKEN_DETAILS: '/admin/get-token-details',
    GET_LIQUIDITY: '/liquidity/get-liquidity-pairs',
    REMOVE_LIQUIDITY: '/liquidity/remove-liquidity-pairs',
    GET_ADMIN_TOKEN: '/admin/get-tokens',
    DELETE_TOKEN: '/admin/remove-token',
    GET_FARM_TOKENS: 'admin/get-farm-data',
    GET_FARM_STATUS: 'admin/get-farm-status',
    GET_FARM_POOL_TOKENS: 'admin/get-farm-pool-data',
    GET_FARM_POOL_STATUS: 'admin/get-farm-pool-status',
    FINDPAIR: '/liquidity/add-liquidity-pair',
    GET_FARM_TOKENS_LIST: 'farm/get-farm-data',
    GET_POOL_TOKEN: 'admin/get-pool-data',
    GET_USER_INVESTMENT: '/farm/get-user-investment',
    GET_POOLS_LIST: 'pool/get-pool-data',
}

