import { useState } from 'react';
import { Col, Container, Row } from "react-bootstrap";

import CreateToken from './CreateToken/CreateToken';
import TableCard from "./TableCard/TableCard";

const PoolAndFarm = ({ type }: any) => {
    const [updateData, setUpdateData] = useState<any>();
    const [APIStatus, setAPIStatus] = useState(false);

    const update_Data = (item: any) => {
        setUpdateData(item);
    }
    return (
        <>
            <section className="">
                <Container fluid>
                    <Row>
                        <Col xxl={6}>
                            <CreateToken type={type} updateData={updateData} update_Data={update_Data} setAPIStatus={setAPIStatus} />
                        </Col>
                        <Col xxl={6} className="mt-5 mt-xxl-0">
                            <TableCard type={type} update_Data={update_Data} APIStatus={APIStatus} setAPIStatus={setAPIStatus}/>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PoolAndFarm