import async from "async";
import moment from "moment";
import { Dispatch, memo, useEffect, useState } from "react";
import { Col, Popover, Table } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../app/actions/contract.action";
import { RootState } from "../../../app/store";
import { CalculatorIcon, DownArrow } from "../../../assets/svgIcons/svgIcons";
import CustomOverlay from "../../../component/common/CustomOverlay";
import Toast from "../../../component/common/Toast";
import CommonBtn from "../../../component/ui/CommonBtn/CommonBtn";
import APRcalculator from "../../../component/ui/Modals/APRcalculator/APRcalculator";
import StakeTokensModal from "../../../component/ui/Modals/StakeTokens/StakeTokensModal";
import { CommonService } from "../../../services/commonService";
import { getTokenPrice } from "../../Admin/LiquidityAdmin/LiquidityDashboard/CreateToken/calculatePrice";
import "../FarmsCard/FarmsCard.scss";
import "./FarmsTableCard.scss";
import {
  BSC_ASSUMED_BLOCK_CREATION_TIME,
  ETH_ASSUMED_BLOCK_CREATION_TIME,
} from "../../../constant";
import { useAccount } from "wagmi";

type propTypes = {
  pid: string;
  name: string;
  multiplier: string;
  earned: string;
  aprOld: string;
  aprNew: string;
  yieldBooster: string;
  totalLiquidity: string;
  enabled: boolean;
  icons: string[];
  tokenAddressA: string;
  tokenAddressB: string;
  tokenDecimalA: string;
  tokenDecimalB: string;
  lpToken: string;
  allocPoint: any;
  lpTokenDecimals: string;
  listView: boolean;
  closeAll: boolean;
  setCloseAll: any;
  swapItPerBlock: any;
  rewardToken: any;
  rewardTokenSymbol: string;
  onClickLiquidity: any;
};

const FarmsTableCard = ({
  pid,
  lpToken,
  rewardTokenSymbol,
  lpTokenDecimals,
  tokenAddressA,
  tokenAddressB,
  tokenDecimalA,
  tokenDecimalB,
  icons,
  name,
  allocPoint,
  listView,
  closeAll,
  setCloseAll,
  swapItPerBlock,
  rewardToken,
  onClickLiquidity,
}: propTypes) => {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const yieldTooltip = (
    <Popover className="commonPopover yieldTooltip">
      <Popover.Body>
        <p>
          Boost multiplier is calculated based on the staking conditions from
          both Farms and fixed-term CAKE syrup pool and will be automatically
          updated upon user actions.
        </p>
        <Link to="#">Learn More</Link>
      </Popover.Body>
    </Popover>
  );

  const { connector } = useAccount()
  const [active, setActive] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  const networkDetails: any = useSelector(
    (state: RootState) => state.connect.networksDetails
  );
  const [apr, setApr] = useState<any>(0);
  const [totalLiquidityInUsd, setTotalLiquidityInUsd] = useState(0);
  const [userInfo, setUserInfo] = useState<any>("");
  const [luniEarned, setLuniEarned] = useState<any>(0);
  const [lpStaked, setLpStaked] = useState<any>(0);
  const [rewardTokenPrice, setRewardTokenPrice] = useState<any>(0);
  const [can_Harvest, setCanHarvest] = useState<any>();

  const [depositeFee, setDepositeFee] = useState<any>();
  const walletAddress: any = useSelector(
    (state: RootState) => state.connect.walletAddress
  );

  const [nextHarvestUntil, setnextHarvestUntil] = useState<any>(null);

  useEffect(() => {
    if (networkDetails && rewardToken && swapItPerBlock) {
      getDetails();
    }
  }, [walletAddress, networkDetails, rewardToken, swapItPerBlock]);

  useEffect(() => {
    setActive(false);
  }, [closeAll]);

  const openView = async (active: any) => {
    await setCloseAll(!closeAll);
    setActive(active);
  };

  const getDetails = async () => {
    async.parallel([
      async function (callback: any) {
        let lpTokenBalanceTokenA: any = await dispatch(
          callContractGetMethod(
            "balanceOf",
            [lpToken],
            "dynamic",
            false,
            tokenAddressA
          )
        );
        let lpTokenBalanceTokenB: any = await dispatch(
          callContractGetMethod(
            "balanceOf",
            [lpToken],
            "dynamic",
            false,
            tokenAddressB
          )
        );

        let lpTokentotalSupply: any = await dispatch(
          callContractGetMethod("totalSupply", [], "dynamic", false, lpToken)
        );
        lpTokentotalSupply = lpTokentotalSupply;
        let masterChefBalanceLpToken: any = await dispatch(
          callContractGetMethod(
            "balanceOf",
            [networkDetails?.masterChef],
            "dynamic",
            false,
            lpToken
          )
        );
        masterChefBalanceLpToken =
          parseInt(masterChefBalanceLpToken) > 0 ? masterChefBalanceLpToken : 0;
        let rewardPrice: any = await dispatch(getTokenPrice(rewardToken));

        setRewardTokenPrice(rewardPrice);
        let token0Price: any = await dispatch(getTokenPrice(tokenAddressA));
        let token1Price: any = await dispatch(getTokenPrice(tokenAddressB));

        if (token0Price && token1Price && rewardPrice) {
          let lpTokenPriceInUsd: any = CommonService.toFixed(
            CommonService.divideBigNumber(
              CommonService.addBigNumber(
                CommonService.toFixed(
                  CommonService.divideBigNumber(
                    CommonService.toFixed(
                      CommonService.convertWithDecimal(
                        lpTokenBalanceTokenA,
                        token0Price
                      )
                    ),
                    parseInt(tokenDecimalA)
                  )
                ),
                CommonService.toFixed(
                  CommonService.divideBigNumber(
                    CommonService.toFixed(
                      CommonService.convertWithDecimal(
                        lpTokenBalanceTokenB,
                        token1Price
                      )
                    ),
                    parseInt(tokenDecimalB)
                  )
                )
              ),
              parseFloat(lpTokentotalSupply)
            )
          );
          lpTokenPriceInUsd = CommonService.divideBigNumber(
            lpTokenPriceInUsd,
            networkDetails?.usdDecimals
          );
          let totalLiquidityInUsd: any = CommonService.toFixed(
            CommonService.multiplyBigDigitsWithDecimals(
              CommonService.toFixed(lpTokenPriceInUsd),
              CommonService.toFixed(masterChefBalanceLpToken)
            )
          );
          let totalAllocPoint: any = await dispatch(
            callContractGetMethod(
              "totalAllocPoint",
              [],
              "master",
              false,
              walletAddress
            )
          );

          setTotalLiquidityInUsd(totalLiquidityInUsd);

          if (totalLiquidityInUsd > 0) {
            let apr: any =
              CommonService.toFixed(
                swapItPerBlock *
                (86400 /
                  (networkDetails?.chainType == "BSC"
                    ? BSC_ASSUMED_BLOCK_CREATION_TIME
                    : ETH_ASSUMED_BLOCK_CREATION_TIME)) *
                365 *
                rewardPrice *
                100 *
                allocPoint
              ) /
              CommonService.toFixed(
                totalLiquidityInUsd *
                networkDetails?.usdDecimals *
                networkDetails?.swapItDecimals *
                totalAllocPoint
              );
            setApr(apr);
          } else {
            setApr(0);
          }
        } else {
          setApr(0);
        }
      },
      async function (callback: any) {
        if (walletAddress && networkDetails) {
          let pendingSwapIt: any = await dispatch(
            callContractGetMethod(
              "pendingSwapIT",
              [pid, walletAddress],
              "master",
              false
            )
          );

          setLuniEarned(
            CommonService.toFixed(
              CommonService.divideBigNumber(
                pendingSwapIt,
                networkDetails?.swapItDecimals
              )
            )
          );
          let userInfo: any = await dispatch(
            callContractGetMethod(
              "userInfo",
              [pid, walletAddress],
              "master",
              false
            )
          );
          setUserInfo(userInfo);
          setLpStaked(
            CommonService.toFixed(
              CommonService.divideBigNumber(
                userInfo?.amount,
                parseInt(lpTokenDecimals)
              )
            )
          );
        }
      },
      async function (callback: any) {
        let _depositFee: any = await dispatch(
          callContractGetMethod("poolInfo", [pid], "master", false)
        );
        setDepositeFee([_depositFee]);
      },
    ]);
  };

  let myInterval: any;
  useEffect(() => {
    clearTimeout(myInterval);
    myInterval = setInterval(getHarvestTime, 1000);
    return () => clearTimeout(myInterval);
  }, [userInfo]);

  const getHarvestTime = async () => {
    if (luniEarned > 0 && moment().isBefore(userInfo.nextHarvestUntil * 1000)) {
      let nextHarvestUntil = await CommonService.getLeftTime(
        userInfo.nextHarvestUntil
      );
      setnextHarvestUntil(nextHarvestUntil);
    } else {
      setnextHarvestUntil(null);
    }
  };

  const harvest = async () => {
    if (walletAddress) {
      let canHarvest: any = await await dispatch(
        callContractGetMethod("canHarvest", [pid, walletAddress], "master")
      );
      if (canHarvest) {
        let provider = await connector?.getProvider()
        let response: any = await dispatch(
          callContractSendMethod(provider, "withdraw", [pid, 0], walletAddress, "master")
        );
        if (response?.status) {
          getDetails();
          Toast.success(
            "Harvested! Your swapIt earnings have been sent to your wallet!"
          );
        }
      } else {
        Toast.error("Can not harvest at this moment");
      }
    } else {
      Toast.error("Please connect your wallet first");
    }
  };
  useEffect(() => {
    walletAddress && getCanHarvest();
  });

  const getCanHarvest = async () => {
    let canHarvest: any = await dispatch(
      callContractGetMethod("canHarvest", [pid, walletAddress], "master")
    );
    setCanHarvest(canHarvest);
  };

  return (
    <>
      {listView ? (
        <>
          <tr className={`farms_table_card ${active ? "active" : ""}`}>
            <td />
            <td>
              <div className="farms_table_card_name">
                <div className="farms_table_card_name_img">
                  {icons &&
                    icons?.map((icon, index) => (
                      <span key={index}>
                        <img src={icon} alt="" />
                      </span>
                    ))}
                </div>
                <div className="farms_table_card_detail">
                  <h4>{name}</h4>
                  <p>Stake, Earn & More!</p>
                </div>
              </div>
            </td>
            <td>
              {walletAddress ? (
                <div className="farms_table_card_detail">
                  <h4>
                    {CommonService?.cryptoDecimals(lpStaked)}{" "}
                    <CustomOverlay value={lpStaked} />
                  </h4>
                  <p>Staked</p>
                </div>
              ) : (
                ""
              )}
            </td>
            {/* <td>
                          <div className="farms_table_card_detail">
                                                        <h4>{earned}</h4>
                                                        <p>Earned</p>
                                                    </div>
                        </td> */}
            <td>
              <div className="farms_table_card_detail">
                <h4>
                  {/* <span className="text-decoration-line-through">{aprOld}</span> */}
                  <span className="textOrange">
                    Up to {apr ? CommonService.cryptoDecimals(apr) : 0}%{" "}
                    <CustomOverlay value={apr > 0 ? apr : 0} />
                  </span>
                </h4>
                <p>
                  APR{" "}
                  <button
                    className="calculatorBtn"
                    onClick={() => {
                      setShow(true);
                    }}
                  >
                    <CalculatorIcon />{" "}
                  </button>
                </p>
              </div>
            </td>
            <td>
              <div className="farms_table_card_detail">
                <h4>Deposit Fee</h4>
                <p>
                  {depositeFee &&
                    depositeFee?.map((data: any, index: any) => (
                      <span key={index}>
                        {data?.depositFeeBP ? data?.depositFeeBP / 100 : 0} %
                      </span>
                    ))}
                </p>
              </div>
            </td>
            <td>
              <div className="farms_table_card_detail">
                <h4>
                  ${CommonService.cryptoDecimals(totalLiquidityInUsd)}
                  <CustomOverlay
                    value={CommonService.fixedToDecimal(totalLiquidityInUsd, 8)}
                  />{" "}
                </h4>
                <p>Liquidity</p>
              </div>
            </td>
            <td>
              <div className="farms_table_card_detail">
                <h4>
                  {CommonService.cryptoDecimals((allocPoint / 100) * 10)} X
                </h4>
                <p>Multiplier</p>
              </div>
            </td>
            <td className="text-end">
              <CommonBtn
                title={<DownArrow />}
                onClick={() => openView(!active)}
                className={`${active ? "active" : ""
                  }  show_more_btn`}
              />
            </td>
            <td />
          </tr>
          {active && (
            <>
              <tr
                className={`farms_table_card_more_detail ${walletAddress ? "wallet_address" : ""
                  }`}
              >
                <td></td>
                <td>
                  {walletAddress ? (
                    <div className="farms_table_card_detail">
                      <h4>
                        {CommonService.cryptoDecimals(luniEarned)}{" "}
                        <CustomOverlay value={luniEarned} />
                      </h4>
                      <p>
                        {rewardTokenSymbol} Earned
                        <CommonBtn
                          title="Harvest"
                          className={`textorangeBtn farms_table_card_action ms-3 ${luniEarned > 0 && can_Harvest ? true : "disabled"
                            }
                                        }`}
                          onClick={harvest}
                        />
                      </p>
                      {nextHarvestUntil ? (
                        <p>You can harvest after {nextHarvestUntil}</p>
                      ) : (
                        ""
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </td>
                {/* <td>
                              <CommonBtn title={<><span><RocketIcon /></span>Boosted</>} className='farms_table_card_btn yellow_btn' />
                            </td> */}
                {/* <td>
                              <CommonBtn title={<><span><SettingIcon /></span>Core</>} className='farms_table_card_btn blue_btn' />
                            </td> */}
                <td>
                  <CommonBtn
                    title={`Get ${name} LP Token`}
                    className="textorangeBtn farms_table_card_action"
                    onClick={() =>
                      onClickLiquidity({
                        tokenAddressA,
                        tokenAddressB,
                      })
                    }
                  />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="text-end">
                  <CommonBtn
                    title="View Contract"
                    className="textorangeBtn farms_table_card_action"
                    onClick={() =>
                      window.open(
                        networkDetails.explorerUrl +
                        "address/" +
                        networkDetails.masterChef
                      )
                    }
                  />
                </td>
                <td />
              </tr>
              {walletAddress ? (
                <tr className="farms_table_card_more_detail">
                  {/* <td colSpan={3}>
                              <div className="farms_table_card_detail">
                                                                <p>
                                                                    <Link to="/pools" className='farms_table_card_action'>Go to Pool</Link>
                                                                </p>
                                                            </div>
                            </td> */}
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td>
                    {" "}
                    {lpStaked > 0 ? (
                      <StakeTokensModal
                        type="Unstake"
                        pid={pid}
                        icons={icons}
                        name={name}
                        lpToken={lpToken}
                        lpTokenDecimals={lpTokenDecimals}
                        callback={getDetails}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td className="text-end">
                    {/* {lpStaked > 0 ? (
                      <StakeTokensModal
                        type="Unstake"
                        pid={pid}
                        icons={icons}
                        name={name}
                        lpToken={lpToken}
                        lpTokenDecimals={lpTokenDecimals}
                        callback={getDetails}
                      />
                    ) : (
                      ""
                    )} */}
                    {allocPoint > 0 ? (
                      <StakeTokensModal
                        type="Stake"
                        pid={pid}
                        icons={icons}
                        name={name}
                        lpToken={lpToken}
                        lpTokenDecimals={lpTokenDecimals}
                        callback={getDetails}
                      />
                    ) : (
                      ""
                    )}
                  </td>
                  <td />
                </tr>
              ) : (
                ""
              )}
            </>
          )}
        </>
      ) : (
        <Col lg={4} md={6} key={pid}>
          <div className="farms_card">
            <div className="farms_card_header">
              <div className="farms_card_header_left">
                <div className="d-flex align-items-center">
                  <div className="farms_card_img">
                    {icons?.map((icon, index) => (
                      <span key={index}>
                        <img src={icon} alt="" />
                      </span>
                    ))}
                  </div>
                  <div className="farms_card_detail">
                    <h4>{name}</h4>
                    <p>Stake, Earn & More!</p>
                  </div>
                </div>
                {/* <div className='farms_card_header_left_content'>
                                            <CommonBtn title={<><span><RocketIcon /></span>Boosted</>} className='farms_table_card_btn yellow_btn' />
                                            <CommonBtn title={<><span><SettingIcon /></span>Core</>} className='farms_table_card_btn blue_btn' />
                                        </div> */}
              </div>
              <div className="farms_card_header_right">
                <div className="farms_card_detail text-end">
                  <h4>{(allocPoint / 100) * 10}X</h4>
                  <p>Multiplier</p>
                </div>
              </div>
            </div>
            <div className="farms_card_body">
              {walletAddress ? (
                <div className="farms_card_detail">
                  <p>Staked</p>
                  <h4>
                    {CommonService.cryptoDecimals(lpStaked)}{" "}
                    <CustomOverlay value={lpStaked} />
                  </h4>
                </div>
              ) : (
                ""
              )}
              {/* <div className="farms_card_detail">
                                <p>Earned</p>
                                <h4>{earned}</h4>
                            </div> */}
              <div className="farms_card_detail">
                <p>APR</p>
                <h4>
                  {/* <span className="text-decoration-line-through">{aprOld}
                                        </span> */}
                  <span className="green_text">
                    {" "}
                    Upto {CommonService.cryptoDecimals(apr)}%{" "}
                    <CustomOverlay value={apr ? apr : "0"} />
                  </span>
                </h4>
              </div>
              {walletAddress ? (
                <div className="farms_card_detail">
                  <p>
                    {rewardTokenSymbol} Earned{" "}
                    <CommonBtn
                      title="Harvest"
                      className={`textorangeBtn farms_table_card_action ms-3 ${luniEarned > 0 ? true : "disabled"
                        }`}
                      onClick={harvest}
                    />
                    {nextHarvestUntil ? (
                      <p>You can harvest after {nextHarvestUntil}</p>
                    ) : (
                      ""
                    )}
                  </p>
                  <h4>
                    {CommonService.cryptoDecimals(luniEarned)}{" "}
                    <CustomOverlay value={luniEarned} />
                  </h4>
                </div>
              ) : (
                ""
              )}
              <div className="farms_card_detail align-items-center"></div>
            </div>
            <div className="farms_card_footer">
              <div className="farms_card_detail">
                <p>Total Liquidity</p>
                <h4>
                  ${CommonService.cryptoDecimals(totalLiquidityInUsd)}{" "}
                  <CustomOverlay value={totalLiquidityInUsd} />
                </h4>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <CommonBtn
                  title={`Get ${name} LP info`}
                  className="textorangeBtn farms_table_card_action"
                  onClick={() =>
                    onClickLiquidity({ tokenAddressA, tokenAddressB })
                  }
                />
                <CommonBtn
                  title="View Contract"
                  className="textorangeBtn farms_table_card_action"
                  onClick={() =>
                    window.open(
                      networkDetails.explorerUrl +
                      "address/" +
                      networkDetails.masterChef
                    )
                  }
                />
              </div>
              {walletAddress ? (
                <div className="enabled_disabled">
                  {lpStaked > 0 ? (
                    <StakeTokensModal
                      type="Unstake"
                      pid={pid}
                      icons={icons}
                      name={name}
                      lpToken={lpToken}
                      lpTokenDecimals={lpTokenDecimals}
                      callback={getDetails}
                    />
                  ) : (
                    ""
                  )}
                  &nbsp; &nbsp;
                  {allocPoint > 0 ? (
                    <StakeTokensModal
                      type="Stake"
                      pid={pid}
                      icons={icons}
                      name={name}
                      lpToken={lpToken}
                      lpTokenDecimals={lpTokenDecimals}
                      callback={getDetails}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Col>
      )}
      <APRcalculator
        show={show}
        onHide={handleClose}
        rewardTokenPrice={rewardTokenPrice}
        name={name}
        apr={apr}
        decimalsReward={networkDetails?.swapItDecimals}
      />
    </>
  );
};

export default memo(FarmsTableCard);
