
import { useSelector } from "react-redux";
import "./style.scss"

function LoaderComponent() {
  const loader = useSelector((state: any) => state?.loader?.loader);

  if (loader) {
    return (
      <div className="overlayloader">
        <section><span className="loader-10"></span></section>
      </div>
    );
  } else {
    return <> </>;
  }
}

export default LoaderComponent;
