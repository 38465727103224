import axios from "axios";
import { RESPONSES } from "../app/actions/constants";
import { API_HOST } from "../constant";
import { CommonService } from "./commonService";
import Toast from "../component/common/Toast";
import { store } from "../app/store";
export const storeInstance = store;

axios.defaults.baseURL = API_HOST;
axios.interceptors.request.use(
  (config) => {
    let walletAddress = storeInstance.getState().connect?.walletAddress;
    let chainType = storeInstance.getState().connect?.networksDetails.chainType;

    if (walletAddress) {
      config.headers["x-auth-address"] = walletAddress;
      config.headers["x-auth-chain"] = chainType;
    }

    config.headers["Access-Control-Allow-Origin"] = "*";
    return config;
  },
  (error) => {
    return error;
  }
);

axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (!error.response) {
      Toast.error("Server not responding. please try again later.");
    } else {
      return error
    }
  }
)


export const manageErrorConnection = (err: any) => {

  if (err.response && err.response.status >= 400 && err.response.status <= 502) {
    if (err.response.data?.errors) {
      Toast.error(err.response.data?.errors[0].msg);
    } else if (err.response.data?.message) {
      Toast.error(err.response.data?.message);
    } else {
      Toast.error(err.response.data.msg);
    }
    return Promise.reject(new Error('Bad status code'))
  } else if (err.code === 'ECONNREFUSED') {
    Toast.error('ECONNREFUSED');
    return 'nevermind'
  } else {
    Toast.error(err);
    return Promise.reject(err)
  }
}

function handleSuccess(res: any) {
  if (!res) return false;
  if (res.status === RESPONSES.SUCCESS || res.status === RESPONSES.CREATED) {
    res?.data?.message && Toast.success(res?.data.message);
  }
  else {
  //res?.data?.message && Toast.info(res?.data.message)
    res?.data?.message ? Toast.info(res?.data.message) : Toast.info(res.response.data.message) 
  }
}

export const apiCallPost = (
  url: any,
  data: any,
  params = {},
  showToast = false,
  token = '',
  walletAddress = ''
) =>
  new Promise((resolve, reject) => {
    axios
      .post(CommonService.formatUrl(url, params), data)
      .then((res) => {
        showToast && handleSuccess(res);
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiCallPostMultiPart = (
  url: any,
  data: any,
  params = {},
  showToast = false,
  token = '',
  walletAddress = ''
) =>
  new Promise((resolve, reject) => {
    axios.post(CommonService.formatUrl(url, params), data, {
      headers: {
        'Content-Type': `multipart/form-data`
      }
    }).then((res) => {
      showToast && handleSuccess(res);
      resolve(res?.data);
    })
      .catch((error) => {
        reject(error);
      });
  });

export const apiCallGet = (
  url: any,
  params:any = {},
  showToast = false,
  token = '',
  walletAddress = ''
) =>
  new Promise((resolve, reject) => {
    axios
      .get(CommonService.formatUrl(url, params))
      .then((res: any) => {
        showToast && handleSuccess(res);
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiCallPut = (
  url: any,
  data: any,
  params = {},
  showToast = false,
) =>
  new Promise((resolve, reject) => {
    axios
      .put(CommonService.formatUrl(url, params), data)
      .then((res) => {
        showToast && handleSuccess(res);
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiCallPatch = (
  url: any,
  data: any,
  params = {},
  showToast = false,
) =>
  new Promise((resolve, reject) => {
    axios
      .patch(CommonService.formatUrl(url, params), data)
      .then((res) => {
        showToast && handleSuccess(res);
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const apiCallDelete = (
  url: any,
  params = {},
  showToast = false,
) =>
  new Promise((resolve, reject) => {
    axios
      .delete(CommonService.formatUrl(url, params))
      .then((res) => {
        showToast && handleSuccess(res);
        resolve(res?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const openNewTab = (
  url: any,
  params: any,
) =>
  new Promise((resolve) => {
    window.open(`${CommonService.formatUrl(API_HOST + url, params)}`, '_blank')
  });