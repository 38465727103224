import Lottie from 'lottie-react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';
import EmptyLottie from '../../../../assets/lottie/empty-file.json';
import { LinkIcon } from '../../../../assets/svgIcons/svgIcons';
import CommonBtn from '../../CommonBtn/CommonBtn';
import TabsComponent from '../../Tabs/Tabs';
import CommonModal from '../CommonModal/CommonModal';
import './RecentTransactions.scss';

type propTypes = {
    show?: boolean,
    handleClose?: any,
    type?: string
}

const RecentTransactions = ({ show, handleClose, type }: propTypes) => {
    const walletAddress = useSelector(
        (state: any) => state.connect.walletAddress
    );
    const _swapHistory: any = useSelector((state: RootState) => state?.settings?.swapHistory);
    const _liquidityHistory = useSelector((state: RootState) => state?.settings?.liquidityHistory);
    const defaultNetworks = useSelector(
        (state: RootState) => state.connect.networksDetails
    );

    let swapHistory = _swapHistory.filter((item: any) => item?.walletAddress === walletAddress && item?.chainType === defaultNetworks?.chainType)
    let liquidityHistory = _liquidityHistory.filter((item: any) => item?.walletAddress === walletAddress && item?.chainType === defaultNetworks?.chainType)

    return (
        <CommonModal className={`RecentTranSactionModal ${type == 'swap' || 'liquidity' ? "auto" : ""}`} modalTitle="Recent Transactions" show={show} onHide={handleClose}>
            <div className="RecentTranSactionModalInner">
                {swapHistory && type == 'swap' && swapHistory.length > 0
                    ? <>
                        <ul className="RecentTranSactionModalInnerListing">
                            {swapHistory?.reverse()?.map((item: any, index: number) => (
                                <li key={index}><strong>Swap {index + 1} &nbsp; </strong> <a target={"_blank"} href={`${defaultNetworks && defaultNetworks?.explorerUrl ? `${defaultNetworks?.explorerUrl}tx/${item?.txn_hash}` :
                                    null}`}> {item?.symbol} for min. <strong>{item?.amountOut}</strong> {item?.toSelectedSymbol} <LinkIcon /> </a></li>
                            ))}
                        </ul>
                    </>
                    : liquidityHistory && type == 'liquidity' && liquidityHistory?.length > 0
                        ?
                        <>
                            <ul className="RecentTranSactionModalInnerListing">
                                {liquidityHistory?.reverse()?.map((item: any, index: number) => (
                                    <>
                                        {item?.type == "add" ?
                                            <li key={index}>
                                                <a target={"_blank"} href={`${defaultNetworks && defaultNetworks?.explorerUrl ? `${defaultNetworks?.explorerUrl}tx/${item?.txn_hash}` : null}`}> Liquidity
                                                    <strong>added</strong> for {Number(item?.amountIn).toFixed(2)} {item?.from} and
                                                    <strong>{Number(item?.amountOut).toFixed(2)} </strong>{item?.to} for {item?.network}
                                                    <LinkIcon />
                                                </a>
                                            </li> :
                                            <li key={index}>
                                                <a target={"_blank"} href={`${defaultNetworks && defaultNetworks?.explorerUrl ? `${defaultNetworks?.explorerUrl}tx/${item?.txn_hash}` : null}`}>Receive
                                                    <strong>{Number(item?.amountIn).toFixed(2)}</strong> {item?.name0} and
                                                    <strong>{Number(item?.amountOut).toFixed(2)}</strong> {item?.name1} after liquidity
                                                    <strong>removal</strong> for {item?.network}
                                                    <LinkIcon />
                                                </a>
                                            </li>
                                        }
                                    </>
                                ))}
                            </ul>
                        </>
                        : type == "connectHistory" ? <TabsComponent /> :
                            <>
                                <div className="no-transaction">
                                    <h4>No Recent Transactions</h4>
                                    <div className="Lottie">
                                        <Lottie
                                            animationData={EmptyLottie}
                                            loop={true}
                                            style={{ width: 192 }}
                                        />
                                    </div>
                                    <CommonBtn title="CONTINUE" className="" onClick={handleClose} />
                                </div>
                            </>
                }
            </div>
        </CommonModal>
    )
}

export default RecentTransactions
