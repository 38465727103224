import { Dispatch, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../../app/actions/contract.action";
import { RootState } from "../../../../app/store";
import { WarningIcon } from "../../../../assets/svgIcons/svgIcons";
import { CommonService } from "../../../../services/commonService";
import InputCustom from "../../../common/Input/InputCustom";
import Toast from "../../../common/Toast";
import CommonBtn from "../../CommonBtn/CommonBtn";
import CommonModal from "../CommonModal/CommonModal";
import "./StakeTokensModal.scss";
import { useAccount } from "wagmi";

const StakeTokensModal = ({
  poolAddress,
  type,
  name,
  addressStaked,
  decimalsStaked,
  callback,
  addressReward,
  rewardEarned,
  decimalsReward,
}: any) => {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const walletAddress: any = useSelector(
    (state: RootState) => state.connect.walletAddress
  );
  const dispatch: Dispatch<any> = useDispatch();
  const [userBalance, setUserBalance] = useState<any>(0);
  const [amount, setAmount] = useState<any>("");
  const [staked, setstaked] = useState<any>(0);
  const [rewardPoolBalance, setRewardPoolBalance] = useState<any>(0);
  const { connector } = useAccount();

  const getDetails = async () => {
    const balance: any = await dispatch(
      callContractGetMethod(
        "balanceOf",
        [walletAddress],
        "dynamic",
        false,
        addressStaked,
        true,
        "dtsketokenmodalgetDetails"
      )
    );
    setUserBalance(
      CommonService.divideBigNumber(
        balance.toString(),
        decimalsStaked.toString()
      )
    );
    let userInfo: any = await dispatch(
      callContractGetMethod(
        "userInfo",
        [walletAddress],
        "dynamicSmartChef",
        false,
        poolAddress
      )
    );
    setstaked(
      CommonService.toFixed(userInfo?.amount / parseInt(decimalsStaked))
    );
  };

  useEffect(() => {
    if (show && walletAddress) {
      getDetails();
    }
    setAmount("");
  }, [show, walletAddress]);

  const handleAction = async (e: any) => {
    if (type === "Emergency Withdraw") {
      onSubmit(e);
    } else {
      handleShow();
    }
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();

    if (walletAddress) {
      let totalAmount = CommonService.convertWithDecimal(
        amount,
        decimalsStaked
      );
      let provider = await connector?.getProvider();
      if (type === "Stake") {
        if (parseFloat(amount) === 0) {
          Toast.error("Please enter valid amount");
          return false;
        }
        if (parseFloat(amount) > parseFloat(userBalance)) {
          Toast.error("Sorry you don't have enough balance");
          return false;
        }
        let response: any = await dispatch(
          callContractSendMethod(
            provider,
            "deposit",
            [totalAmount],
            walletAddress,
            "dynamicSmartChef",
            "",
            poolAddress,
            addressStaked
          )
        );
        if (response?.status) {
          handleClose();
          callback();
          Toast.success("Staked! Your funds have been staked in the pool");
        }
      } else if (type === "Unstake") {
        if (parseFloat(amount) === 0) {
          Toast.error("Please enter valid amount");
          return false;
        }
        let response: any;

        if (addressReward !== addressStaked) {
          if (rewardEarned > rewardPoolBalance) {
            response = await dispatch(
              callContractSendMethod(
                provider,
                "emergencyWithdraw",
                [],
                walletAddress,
                "dynamicSmartChef",
                "",
                poolAddress
              )
            );
          } else {
            response = await dispatch(
              callContractSendMethod(
                provider,
                "withdraw",
                [totalAmount],
                walletAddress,
                "dynamicSmartChef",
                "",
                poolAddress,
                addressStaked
              )
            );
          }
        } else {
          if (
            Number(staked) + Number(rewardEarned) >
            Number(rewardPoolBalance)
          ) {
            response = await dispatch(
              callContractSendMethod(
                provider,
                "emergencyWithdraw",
                [],
                walletAddress,
                "dynamicSmartChef",
                "",
                poolAddress
              )
            );
          } else {
            response = await dispatch(
              callContractSendMethod(
                provider,
                "withdraw",
                [totalAmount],
                walletAddress,
                "dynamicSmartChef",
                "",
                poolAddress,
                addressStaked
              )
            );
          }
        }

        if (response?.status) {
          handleClose();
          callback();
          Toast.success(
            "Unstaked! Your funds have been transferred to your wallet"
          );
        }
      } else if (type === "Emergency Withdraw") {
        let response: any = await dispatch(
          callContractSendMethod(
            provider,
            "emergencyWithdraw",
            [],
            walletAddress,
            "dynamicSmartChef",
            "",
            poolAddress,
            addressStaked
          )
        );
        if (response?.status) {
          handleClose();
          callback();
          Toast.success(
            "Withdraw! Your funds have been transferred to your wallet"
          );
        }
      }
    } else {
      Toast.error("Please connect your wallet first");
    }
  };
  const setInputAmount = (e: any) => {
    let convertedvalue = CommonService.allowOnlyNumberWithDecimals(
      e.target.value,
      "stake",
      decimalsStaked
    );
    if (convertedvalue) {
      setAmount(e.target.value);
    }
  };

  useEffect(() => {
    addressReward && getPoolRewardBalance();
  }, [addressReward]);
  const getPoolRewardBalance = async () => {
    const balance: any = await dispatch(
      callContractGetMethod(
        "balanceOf",
        [poolAddress],
        "dynamic",
        false,
        addressReward,
        true,
        "getPoolRewardBalance"
      )
    );
    const devidedValue = CommonService.divideWithDecimal(
      balance,
      decimalsReward
    );
    setRewardPoolBalance(devidedValue);
  };

  return (
    <>
      <CommonBtn
        onClick={handleAction}
        title={type}
        className={`enable_btn w-auto d-inline-flex ${
          type === "Stake"
            ? ""
            : type === "Unstake"
            ? "border-gradienttextWhite"
            : "bordred-red"
        }`}
      />
      <CommonModal
        className="StakeToken"
        modalTitle={`${type} ${name} Tokens`}
        show={show}
        onHide={handleClose}
        rewardPoolBalance={rewardPoolBalance}
        rewardEarned={rewardEarned}
        staked={staked}
      >
        {Number(rewardEarned) > Number(rewardPoolBalance) ||
        Number(staked) + Number(rewardEarned) > Number(rewardPoolBalance) ? (
          <p className="note_text">
            <strong>
              <WarningIcon />
            </strong>{" "}
            Pool does not have enough reward tokens at the moment. Be sure to
            confirm as rewards earned will be lost
          </p>
        ) : (
          ""
        )}
        <Form onSubmit={onSubmit}>
          <div className="py-5 w-100">
            <Row>
              <Col>
                <p>{type}</p>
              </Col>
              <Col>
                {" "}
                <p className="text-end">{name + ""}</p>
              </Col>
            </Row>
            <Form.Group className="inputHandle">
              <InputCustom
                className="mb-0 max-field amount"
                required
                value={
                  rewardEarned > rewardPoolBalance ||
                  Number(staked) + Number(rewardEarned) >
                    Number(rewardPoolBalance)
                    ? CommonService.toFixed(staked)
                    : amount
                }
                onChange={(e: any) => setInputAmount(e)}
                type="text"
                step="any"
                label={""}
                placeholder="0.00"
                disabled={
                  rewardEarned > rewardPoolBalance ||
                  Number(staked) + Number(rewardEarned) >
                    Number(rewardPoolBalance)
                    ? true
                    : false
                }
                smallText={
                  userBalance > 0 || type !== "Stake" ? (
                    ""
                  ) : (
                    <>
                      <span className="text-danger">No token to stake</span>
                    </>
                  )
                }
              />
              {rewardEarned > rewardPoolBalance ||
              Number(staked) + Number(rewardEarned) >
                Number(rewardPoolBalance) ? (
                ""
              ) : (
                <>
                  {type === "Stake" || type === "Unstake" ? (
                    <Form.Label
                      onClick={() => {
                        setAmount(
                          type === "Stake"
                            ? CommonService.toFixed(userBalance)
                            : CommonService.toFixed(staked)
                        );
                      }}
                    >
                      Max
                    </Form.Label>
                  ) : null}
                </>
              )}
            </Form.Group>
            <hr />

            <p className="StakeToken-Bal-stake" title={userBalance}>
              Balance:{CommonService.toFixed(userBalance)}
            </p>
            <p
              className="StakeToken-Bal-stake"
              title={CommonService.toFixed(staked)}
            >
              Staked: {CommonService.toFixed(staked)}{" "}
            </p>
            {/* <p className="StakeToken-Bal-stake" title={staked}>
              Staked: {staked}{" "}
            </p> */}
          </div>
          <hr className="mb-4 pb-2" />
          <Row>
            <Col>
              <CommonBtn
                title="Cancel"
                className="w-100 btn-yellow"
                onClick={handleClose}
                type="button"
              />
            </Col>
            <Col>
              <CommonBtn title="Confirm" className="w-100 " type="submit" />
            </Col>
          </Row>
        </Form>
      </CommonModal>
    </>
  );
};
export default StakeTokensModal;
