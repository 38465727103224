import async from "async";
import { Dispatch, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAccount } from "wagmi";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../app/actions/contract.action";
import { RootState } from "../../../app/store";
import {
  CalculatorIcon,
  DownArrow,
  RefreshIcon2,
} from "../../../assets/svgIcons/svgIcons";
import CustomOverlay from "../../../component/common/CustomOverlay";
import Toast from "../../../component/common/Toast";
import CommonBtn from "../../../component/ui/CommonBtn/CommonBtn";
import APRcalculator from "../../../component/ui/Modals/APRcalculator/APRcalculator";
import StakeTokensModal from "../../../component/ui/Modals/StakeTokens/StakeTokensModalPool";
import {
  BSC_ASSUMED_BLOCK_CREATION_TIME,
  ETH_ASSUMED_BLOCK_CREATION_TIME,
} from "../../../constant";
import { CommonService } from "../../../services/commonService";
import { callWeb3 } from "../../../services/ContractService";
import { getTokenPrice } from "../../Admin/LiquidityAdmin/LiquidityDashboard/CreateToken/calculatePrice";
import "./PoolsTableCard.scss";

type propTypes = {
  icons: string[];
  luniStaked: string;
  flexibleApy: string;
  lockedApr: string;
  lockedAprLuni: string;
  totalLocked: string;
  avgLockDuration: string;
  performanceFee: string;
  recentLuniProfit: string;
  unstakingFee: string;
  enabled: any;
  listView: boolean;
  nameReward: string;
  nameStaked: string;
  decimalsReward: string;
  poolAddress: string;
  decimalsStaked: string;
  addressReward: string;
  addressStaked: string;
  closeAll: boolean;
  setCloseAll: any;
};

const PoolsTableCard = ({
  listView,
  icons,
  decimalsStaked,
  addressStaked,
  addressReward,
  decimalsReward,
  poolAddress,
  nameReward,
  nameStaked,
  luniStaked,
  closeAll,
  setCloseAll,
  flexibleApy,
  lockedApr,
  lockedAprLuni,
  totalLocked,
  avgLockDuration,
  performanceFee,
  recentLuniProfit,
  unstakingFee,
  enabled,
}: propTypes) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const [active, setActive] = useState(false);
  const [totalStaked, setTotalStaked] = useState(0);
  const networkDetails = useSelector(
    (state: RootState) => state.connect.networksDetails
  );
  const walletAddress: any = useSelector(
    (state: RootState) => state.connect.walletAddress
  );
  const [rewardEarned, setRewardEarned] = useState(0);
  const [maxStake, setMaxStake] = useState<any>("");
  const [endsIn, setEndsIn] = useState<any>(0);
  const [apy, setApy] = useState<any>(0);
  const [currentTimeBlock, setCurrentTimeBlock] = useState<any>();
  const [endBlock, setEndBlock] = useState<any>();
  const [rewardTokenPrice, setRewardTokenPrice] = useState<any>(0);
  const [poolStartBlock, setPoolStartBlock] = useState<number>(0);
  const { connector } = useAccount();

  useEffect(() => {
    if (networkDetails) {
      getDetails();
    }
  }, [walletAddress, networkDetails]);

  useEffect(() => {
    setActive(false);
  }, [closeAll]);

  const openView = async (active: any) => {
    await setCloseAll(!closeAll);
    setActive(active);
  };


  // useEffect(() => {
  //   let interval = setInterval(() => { getDetails() }, 10000);
  //   return () => clearInterval(interval);
  // }, []);

  const getDetails = async () => {
    async.parallel([
      async function (callback: any) {
        let stakeTokenBalance: any = await dispatch(
          callContractGetMethod(
            "balanceOf",
            [poolAddress],
            "dynamic",
            false,
            addressStaked,true,"getdetailsMethod"
          )
        );

        let stakeTokenPrice: any = await dispatch(getTokenPrice(addressStaked));
        if (stakeTokenBalance && stakeTokenPrice) {
          let liquidityOfStakeToken: any = CommonService.toFixed(
            CommonService.toFixed(
              stakeTokenBalance / parseInt(decimalsStaked)
            ) * stakeTokenPrice
          );
          // if (liquidityOfStakeToken > 0) {
          let rewardTokenPrice: any = await dispatch(
            getTokenPrice(addressReward)
          );
          setRewardTokenPrice(rewardTokenPrice);
          let rewardPerBlock: any = await dispatch(
            callContractGetMethod(
              "rewardPerBlock",
              [],
              "dynamicSmartChef",
              false,
              poolAddress
            )
          );

          let totalValueOfRewardsInYear = CommonService.toFixed(
            parseInt(
              (
                86400 /
                (networkDetails?.chainType == "BSC"
                  ? BSC_ASSUMED_BLOCK_CREATION_TIME
                  : ETH_ASSUMED_BLOCK_CREATION_TIME)
              ).toString()
            ) *
              365 *
              (rewardPerBlock / parseInt(decimalsReward)) *
              rewardTokenPrice
          );
          if (liquidityOfStakeToken > 0) {
            let apy = CommonService.toFixed(
              (totalValueOfRewardsInYear / liquidityOfStakeToken) * 100
            );
            setApy(apy);
          } else {
            setApy(0);
          }
        }
      },
      async function (callback: any) {
        let poolLimitPerUser: any = await dispatch(
          callContractGetMethod(
            "poolLimitPerUser",
            [],
            "dynamicSmartChef",
            false,
            poolAddress
          )
        );
        setMaxStake(poolLimitPerUser);
        let web3Instance: any = await callWeb3();
        let latestBlock = await new web3Instance.eth.getBlockNumber();
        let bonusEndBlock: any = await dispatch(
          callContractGetMethod(
            "bonusEndBlock",
            [],
            "dynamicSmartChef",
            false,
            poolAddress
          )
        );
        if (latestBlock && latestBlock != undefined) {
          setCurrentTimeBlock(latestBlock);
        }

        setEndBlock(bonusEndBlock);

        let poolStartBlock: any = await dispatch(
          callContractGetMethod(
            "startBlock",
            [],
            "dynamicSmartChef",
            false,
            poolAddress
          )
        );
        if (poolStartBlock && poolStartBlock != undefined) {
          setPoolStartBlock(Number(poolStartBlock));
        }
        let compareBlock: boolean = await compareBlockNumber();
        if (compareBlock) {
          setEndsIn(poolStartBlock - parseInt(latestBlock));
        } else {
          setEndsIn(parseInt(bonusEndBlock) - parseInt(latestBlock));
        }
      },
      async function (callback: any) {
        if (walletAddress) {
          let userInfo: any = await dispatch(
            callContractGetMethod(
              "userInfo",
              [walletAddress],
              "dynamicSmartChef",
              false,
              poolAddress
            )
          );
          setTotalStaked(
            CommonService.toFixed(
              CommonService.divideBigNumber(
                userInfo?.amount,
                parseInt(decimalsStaked)
              )
            )
          );
          let pendingReward: any = await dispatch(
            callContractGetMethod(
              "pendingReward",
              [walletAddress],
              "dynamicSmartChef",
              false,
              poolAddress
            )
          );

          setRewardEarned(
            CommonService.toFixed(
              CommonService.divideBigNumber(
                pendingReward,
                parseInt(decimalsReward)
              )
            )
          );
        }
      },
    ]);
  };

  const harvest = async () => {
    if (walletAddress) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "withdraw",
          [0],
          walletAddress,
          "dynamicSmartChef",
          "",
          poolAddress
        )
      );
      if (response?.status) {
        getDetails();
        Toast.success(
          `Harvested! Your ${nameReward} earnings have been sent to your wallet!`
        );
      }
    } else {
      Toast.error("Please connect your wallet first");
    }
  };

  // function to check is current block is > start block
  const compareBlockNumber = () => {
    if (currentTimeBlock < poolStartBlock) {
      //starts in
      return true;
    } else {
      // ENds in
      return false;
    }
  };

  const [rotate, setRotate] = useState(false);
  const rotateHandle = () => {
    setRotate(true);
    setTimeout(() => {
      setRotate(false);
    }, 500);
  };

  return listView ? (
    <>
      {/* // <PerfectScrollbar>
    //   <Table className="pools_content_outer_table">
    //     <tbody>
    //       <tr> */}
      {/* <td className={`pools_table_card ${active ? "active" : ""}`}> */}
      {/* <Table>
          <tbody> */}
      <tr className={`pools_table_card ${active ? "active" : ""}`}>
        <td />
        <td>
          <div className="pools_table_card_name">
            <div className="pools_table_card_name_img">
              {icons?.map((icon, index) => (
                <span key={index}>
                  <img src={icon} alt="" />
                </span>
              ))}
            </div>
            <div className="pools_table_card_detail">
              <h4>Earn {nameReward}</h4>
              <p>Stake {nameStaked} </p>
            </div>
          </div>
        </td>
        <td>
          <div className="pools_table_card_detail">
            <h4>
              {CommonService.cryptoDecimals(totalStaked)}{" "}
              <CustomOverlay value={totalStaked} />
            </h4>
            <p>{nameStaked} Staked</p>
          </div>
        </td>
        <td>
          <div className="pools_table_card_detail">
            <h4>
              {CommonService.cryptoDecimals(apy)} %{" "}
              <CustomOverlay value={CommonService.cryptoDecimals(apy)} />
            </h4>
            {/* <p>Flexible APR</p> */}
            <p>
              Flexible APR{" "}
              <button
                className="calculatorBtn"
                onClick={() => {
                  setShow(true);
                }}
              >
                <CalculatorIcon />{" "}
              </button>
            </p>
          </div>
        </td>
        <td>
          <div className="pools_table_card_detail">
            <h4>
              {CommonService.cryptoDecimals(rewardEarned)}{" "}
              <CustomOverlay value={rewardEarned} />
            </h4>
            <p>
              {nameReward} Earned{" "}
              <CommonBtn
                title="Harvest"
                className={`textorangeBtn ms-3 farms_table_card_action ${
                  rewardEarned > 0 ? true : "disabled"
                }`}
                onClick={harvest}
              />
            </p>
          </div>
        </td>
        {/* <td></td> */}
        <td>
          <div className="pools_table_card_action_btns">
            <CommonBtn
              title={<RefreshIcon2 />}
              // onClick={getDetails}
              className={`refresh_btn  ${rotate ? "active" : ""}`}
              onClick={() => {
                rotateHandle();
                getDetails();
              }}
            />

            <CommonBtn
              title={<DownArrow />}
              onClick={() => openView(!active)}
              className={`${active ? "active" : ""}  show_more_btn`}
            />
          </div>
        </td>
        <td />
      </tr>
      {active && (
        <>
          <tr
            className={`pools_table_card_more_detail ${
              walletAddress ? "wallet_address" : ""
            }`}
          >
            <td />
            <td>
              {maxStake > 0 ? (
                <div className="pools_table_card_detail">
                  <h4>
                    {maxStake / parseInt(decimalsStaked)} {nameStaked}
                  </h4>
                  <p>Max stake per user</p>
                </div>
              ) : (
                ""
              )}
            </td>
            <td>
              <div className="pools_table_card_detail">
                <p>{compareBlockNumber() ? "Starts In" : "Ends In "}</p>
                <h4>{endsIn > 0 ? `${endsIn} blocks ` : "Ended"}</h4>
              </div>
            </td>
            {/* <td>
              <div className="pools_table_card_detail">
                <h4>{performanceFee}</h4>
                                                            <p>Performance Fee</p>
              </div>
            </td> */}
            <td>
              {/* <CommonBtn title='View Tutorial' className='pools_table_card_action' /> */}
            </td>
            {/* <td></td> */}
            <td>
              <CommonBtn
                title="See Token Info"
                className="textorangeBtn pools_table_card_action"
                onClick={() =>
                  window.open(
                    networkDetails.explorerUrl + "address/" + addressStaked
                  )
                }
              />
            </td>
            <td className="text-end">
              <CommonBtn
                title="View Contract"
                onClick={() =>
                  window.open(
                    networkDetails.explorerUrl + "address/" + poolAddress
                  )
                }
                className="textorangeBtn pools_table_card_action"
              />
            </td>
            <td />
          </tr>
          {walletAddress ? (
            <tr className="pools_table_card_more_detail">
              <td />
              <td colSpan={5} className="text-end">
                &nbsp; &nbsp;
                {compareBlockNumber() ? null : (
                  <>
                    {totalStaked > 0 ? (
                      <StakeTokensModal
                        type="Unstake"
                        icons={icons}
                        name={nameStaked}
                        addressStaked={addressStaked}
                        decimalsStaked={decimalsStaked}
                        callback={getDetails}
                        poolAddress={poolAddress}
                        addressReward={addressReward}
                        rewardEarned={rewardEarned}
                        decimalsReward={decimalsReward}
                      />
                    ) : (
                      ""
                    )}
                    &nbsp; &nbsp;
                    {endBlock > currentTimeBlock ? (
                      <StakeTokensModal
                        type="Stake"
                        icons={icons}
                        name={nameStaked}
                        addressStaked={addressStaked}
                        decimalsStaked={decimalsStaked}
                        callback={getDetails}
                        poolAddress={poolAddress}
                      />
                    ) : (
                      ""
                    )}
                  </>
                )}
              </td>
              <td />
            </tr>
          ) : (
            ""
          )}
        </>
      )}
      {/* </tbody>
        </Table> */}
      {/* </td> */}
      {/* //     </tr>
      //   </tbody>
      // </Table> */}
      <APRcalculator
        show={show}
        onHide={handleClose}
        rewardTokenPrice={rewardTokenPrice}
        apr={apy}
        nameReward={nameReward}
        decimalsReward={decimalsReward}
      />
      {/* // </PerfectScrollbar> */}
    </>
  ) : (
    <Col xl={4} md={6}>
      <div className="pools_card">
        <div className="pools_card_header">
          <div className="pools_card_header_left">
            <div className="d-flex align-items-center">
              <div className="pools_card_img">
                {icons?.map((icon, index) => (
                  <span key={index}>
                    <img src={icon} alt="" />
                  </span>
                ))}
              </div>
              <div className="pools_card_detail">
                <h4>Earn {nameReward}</h4>
                <p>Stake {nameStaked}</p>
              </div>
            </div>
          </div>
          <div className="pools_card_header_right">
            <CommonBtn
              title={<RefreshIcon2 />}
              // onClick={getDetails}
              // className="refresh_btn "
              className={`refresh_btn  ${rotate ? "active" : ""}`}
              onClick={() => {
                rotateHandle();
                getDetails();
              }}
            />
          </div>
        </div>
        <div className="pools_card_body">
          {/* <div className="pools_card_detail unstaking_fee">
                            <h4 className='text-decoration-underline'>{unstakingFee}unstaking fee if withdrawn within 72h</h4>
                            <OverlayTrigger trigger="click" placement="auto" overlay={unstakingFeeTooltip}
                                rootCloseEvent="click"
                                rootClose
                            >
                                <button className='info_icon'><InfoIcon /></button>
                            </OverlayTrigger>
                        </div> */}
          <div className="pools_card_detail">
            <p>{nameStaked} Staked</p>
            <h4 className="value_text">
              {CommonService.cryptoDecimals(totalStaked)}{" "}
              <CustomOverlay value={totalStaked} />
            </h4>
          </div>
          <div className="pools_card_detail">
            <p>Flexible APR</p>
            <h4 className="value_text">
              {CommonService.cryptoDecimals(apy)} %{" "}
              <CustomOverlay value={apy} />
            </h4>
          </div>
          <div className="pools_card_detail">
            <p>
              {nameReward} Earned{" "}
              <CommonBtn
                title="Harvest"
                // className="ms-3 farms_table_card_action"
                className={`textorangeBtn ms-3 farms_table_card_action ${
                  rewardEarned > 0 ? true : "disabled"
                }`}
                onClick={harvest}
              />
            </p>
            <h4 className="value_text">
              {CommonService.cryptoDecimals(rewardEarned)}{" "}
              <CustomOverlay value={rewardEarned} />
            </h4>
          </div>

          {maxStake > 0 ? (
            <div className="pools_card_detail_yellow">
              <p>Max stake per user</p>
              <h4 className="value_text">
                {maxStake / parseInt(decimalsStaked)} {nameStaked}
              </h4>
            </div>
          ) : (
            ""
          )}
          <div className="pools_card_detail">
            {/* <p>Ends In</p> */}
            <p>{compareBlockNumber() ? "Starts In" : "Ends In "}</p>
            <h4 className="value_text">
              {endsIn > 0 ? `${endsIn} blocks ` : "Ended"}
            </h4>
          </div>
        </div>
        <div className="pools_card_footer">
          <div className="d-flex align-items-center justify-content-between">
            <CommonBtn
              title="See Token Info"
              onClick={() =>
                window.open(
                  networkDetails.explorerUrl + "address/" + addressStaked
                )
              }
              className="textorangeBtn pools_table_card_action"
            />
            <CommonBtn
              title="View Contract"
              onClick={() =>
                window.open(
                  networkDetails.explorerUrl + "address/" + poolAddress
                )
              }
              className="textorangeBtn pools_table_card_action"
            />
          </div>
          <div className="enabled_disabled">
            {walletAddress ? (
              <>
                {/* {totalStaked > 0 && rewardEarned == 0 ? <StakeTokensModal
                                    type="Emergency Withdraw1"
                                    icons={icons}
                                    name={nameStaked}
                                    addressStaked={addressStaked}
                                    decimalsStaked={decimalsStaked}
                                    callback={getDetails}
                                    poolAddress={poolAddress}
                                /> : ''} */}
                &nbsp; &nbsp;
                {compareBlockNumber() ? null : (
                  <>
                    {totalStaked > 0 ? (
                      <StakeTokensModal
                        type="Unstake"
                        icons={icons}
                        name={nameStaked}
                        addressStaked={addressStaked}
                        decimalsStaked={decimalsStaked}
                        callback={getDetails}
                        poolAddress={poolAddress}
                        addressReward={addressReward}
                        rewardEarned={rewardEarned}
                        decimalsReward={decimalsReward}
                      />
                    ) : null}
                    &nbsp; &nbsp;
                    {endBlock > currentTimeBlock ? (
                      <StakeTokensModal
                        type="Stake"
                        icons={icons}
                        name={nameStaked}
                        addressStaked={addressStaked}
                        decimalsStaked={decimalsStaked}
                        callback={getDetails}
                        poolAddress={poolAddress}
                      />
                    ) : null}
                  </>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <APRcalculator
        show={show}
        onHide={handleClose}
        rewardTokenPrice={rewardTokenPrice}
        apr={apy}
        nameReward={nameReward}
      />
    </Col>
  );
};

export default PoolsTableCard;
