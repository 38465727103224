import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";


import connectSlice from "../features/connect.slice";
import customTokenInfoSlice from "../features/customTokenInfo.slice";
import loaderSlice from "../features/loader.slice";
import swapAndLiquiditySlice from "../features/swapAndLiquidity.slice";

const reducers = combineReducers({
  connect: connectSlice,
  loader: loaderSlice,
  token : customTokenInfoSlice,
  settings : swapAndLiquiditySlice
  
});

const persistConfig: any = {
  key: "root",
  storage,
  whitelist :['connect' , 'token' ,'settings' ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: false,
  }),

});
export const persistor = persistStore(store);
export default { store, persistor };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
