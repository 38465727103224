import async from "async";
import { Dispatch, useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import { callApiPostMethod } from "../../../../../app/actions/api.action";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../../../app/actions/contract.action";
import { RootState } from "../../../../../app/store";
import InputCustom from "../../../../../component/common/Input/InputCustom";
import Toast from "../../../../../component/common/Toast";
import CommonBtn from "../../../../../component/ui/CommonBtn/CommonBtn";
import CommonDateTimePicker from "../../../../../component/ui/CommonDateTimePicker/CommonDateTimePicker";
import ToggleSwitch from "../../../../../component/ui/ToggleSwitch/ToggleSwitch";
import {
  BSC_ASSUMED_BLOCK_CREATION_TIME,
  ETH_ASSUMED_BLOCK_CREATION_TIME,
} from "../../../../../constant";
import { CommonService } from "../../../../../services/commonService";
import { getTokenPrice } from "./calculatePrice";
import "./CreateToken.scss";
import { useAccount } from "wagmi";
const errorIcon =
  "https://freeiconshop.com/wp-content/uploads/edd/cross-flat.png";
const successIcon =
  "https://static-00.iconduck.com/assets.00/checkmark-running-icon-256x256-qtsdzaey.png";

const CreateToken = ({ type, updateData, update_Data, setAPIStatus }: any) => {
  const { connector } = useAccount();
  const dispatch: Dispatch<any> = useDispatch();
  const defaultNetworks = useSelector(
    (state: RootState) => state.connect.networksDetails
  );
  const walletAddress: any = useSelector(
    (state: RootState) => state.connect.walletAddress
  );
  const [currentTimeBlock, setCurrentTimeBlock] = useState<any>();
  const [userLimit, setUserLimit] = useState<any>(false);
  const [pool_LimitPerUser, setPoolLimitPerUser] = useState<any>("");
  const [switchButton, setSwitchButton] = useState<any>(false);
  const [stopedPool, setStopedPool] = useState<any>(true);
  const [validateLoader, setValidateLoader] = useState<any>(false);
  const [validLp, setValidLp] = useState<any>(false);
  const [validStakeToken, setValidStakeToken] = useState<any>(false);
  const [checkValidateStatus, setValidTokenStatus] = useState<any>(false);
  const [checkReardateStatus, setRewardTokenStatus] = useState<any>(false);
  const [poolEditableStatus, setPoolEditableStatus] = useState<any>();
  const [toggleButtonStatus, setToggleButtonStatus] = useState<any>();
  const [stakedTokenDecimals, setStakedTokenDecimals] = useState<any>("");

  const [prevStartBlock, setPreStartBlock] = useState<any>();
  const [validRewardToken, setValidRewardToken] = useState<any>(false);

  const [showValidRewardErr, setShowValidRewardErr] = useState<any>("");
  const [showValidStakeErr, setShowValidStakeErr] = useState<any>("");
  const [showValidLpError, setShowValidLpError] = useState<any>("");
  const [startBlockcheck, setStartBlockcheck] = useState<any>();
  const [endBonus_Block, setEndBonusBlock] = useState<any>();
  const [blockNumberIsSame, setBlockNumberIsSame] = useState<any>(0);

  const [startTimeStamp, setStartTimeStamp] = useState("");
  const [endTimeStamp, setEndTimeStamp] = useState("");

  const [startTimeStampError, setStartTimeStampError] = useState("");
  const [endTimeStampError, setEndTimeStampError] = useState("");

  const [formData, setFormData] = useState<any>({
    multiplier: "",
    address: "",
    depositMultiplierFee: "",
    harvest: "",
    withUpdate: true,
  });

  const [poolData, setPoolData] = useState<any>({
    stakedToken: "",
    rewardToken: "",
    rewardPerBlock: "",
    startBlock: "",
    bonusEndBlock: "",
    poolLimitPerUser: "",
    referralCommissionRate: "",
    blockNumberDate: "",
    endbBlockNumberDate: "",
    endUpteBlock: "",
  });

  useEffect(() => {
    CurrentTimeStamp();
    if (updateData) {
      setFormData({
        multiplier: updateData?.allocPoint,
        address: updateData?.lpToken,
        depositMultiplierFee:
          updateData?.depositFeeBP && updateData?.depositFeeBP / 100,
        harvest: parseInt(updateData?.harvestInterval) / (60 * 60),
        withUpdate: true,
      });
    }
  }, [updateData, type]);

  useEffect(() => {
    async function validteStakeToken() {
      if (Web3.utils.isAddress(poolData?.stakedToken)) {
        setValidTokenStatus(true);
        let tokenPrice: any = await dispatch(
          getTokenPrice(poolData?.stakedToken)
        );
        if (tokenPrice) {
          setValidStakeToken(true);
          setShowValidStakeErr("");

          setValidTokenStatus(false);
        } else {
          setValidStakeToken(false);
          setValidTokenStatus(false);
          setShowValidStakeErr("Could not fetch token price");
        }
      } else {
        setValidStakeToken(false);
        setShowValidStakeErr("Please enter valid address");
      }
    }
    validteStakeToken();
  }, [poolData?.stakedToken]);

  useEffect(() => {
    async function validteRewardToken() {
      if (Web3.utils.isAddress(poolData?.rewardToken)) {
        setRewardTokenStatus(true);
        let tokenPrice: any = await dispatch(
          getTokenPrice(poolData?.rewardToken)
        );
        if (tokenPrice) {
          setValidRewardToken(true);
          setRewardTokenStatus(false);
        } else {
          setValidRewardToken(false);
          setRewardTokenStatus(false);
          setShowValidRewardErr("Could not fetch token price");
        }
      } else {
        setValidRewardToken(false);
        setShowValidRewardErr("Please enter valid address");
      }
    }
    validteRewardToken();
  }, [poolData?.rewardToken]);

  useEffect(() => {
    if (Web3.utils.isAddress(formData?.address)) {
      validateToken();
      setShowValidLpError("");
    } else {
      setShowValidLpError("Invalid Address");
      setValidLp(false);
    }
  }, [formData?.address]);

  const validateToken = async () => {
    try {
      setValidateLoader(true);

      let token0: any = await dispatch(
        callContractGetMethod(
          "token0",
          [],
          "dynamicPair",
          false,
          formData?.address,
          false
        )
      );
      let token0Price: any = await dispatch(getTokenPrice(token0));

      let token1: any = await dispatch(
        callContractGetMethod(
          "token1",
          [],
          "dynamicPair",
          false,
          formData?.address,
          false
        )
      );

      let token1Price: any = await dispatch(getTokenPrice(token1));

      if (token0Price && token1Price) {
        setShowValidLpError("");
        setValidLp(true);
      } else {
        setShowValidLpError("Could not fetch LP price");
        setValidLp(false);
      }
      setValidateLoader(false);
    } catch (error) {
      console.log('error', error)
      setShowValidLpError("Something went wrong");
      setValidLp(false);
      setValidateLoader(false);
    }
  };

  const CurrentTimeStamp = async () => {
    var web3 = new Web3(defaultNetworks?.rpc);
    let currentBlock = await web3.eth.getBlockNumber();
    setCurrentTimeBlock(currentBlock);
  };

  const addToken = async (e: any) => {
    e.preventDefault();
    if (Web3.utils.isAddress(formData?.address)) {
      const tokenStatus: any = await dispatch(
        callApiPostMethod("GET_FARM_STATUS", {
          chainType: defaultNetworks?.chainType,
          lpToken: formData?.address,
        })
      );
      if (tokenStatus?.success && !tokenStatus?.farmData) {
        let Days = Math.floor(formData?.harvest / 24);
        delete formData["depositFeeBP"];
        if (Days <= 14) {
          try {
            let token0: any = await dispatch(
              callContractGetMethod(
                "token0",
                [],
                "dynamicPair",
                false,
                formData?.address,
                false
              )
            );
            let token1: any = await dispatch(
              callContractGetMethod(
                "token1",
                [],
                "dynamicPair",
                false,
                formData?.address,
                false
              )
            );
            if (token0 && token1) {
              let provider = await connector?.getProvider();
              let response: any = await dispatch(
                callContractSendMethod(
                  provider,
                  "add",
                  [
                    parseInt(formData?.multiplier),
                    formData?.address,
                    parseInt((formData?.depositMultiplierFee * 100).toString()),
                    parseInt((formData?.harvest * 60 * 60).toString()),
                    true,
                  ],
                  walletAddress,
                  "master"
                )
              );
              if (response?.status) {
                setAPIStatus(true);
                Toast.success(`${type} created successfully`);
                setFormData({
                  multiplier: "",
                  address: "",
                  depositMultiplierFee: "",
                  harvest: "",
                  withUpdate: true,
                });
              }
            } else {
              Toast.error("Doesn't have pair");
            }
          } catch (error) {
            Toast.error("Doesn't have pair");
          }
        } else {
          Toast.error("Maximum harvest interval is 14 days.");
        }
      } else {
        Toast.error("Already farm added, please add with new address.");
      }
    } else {
      Toast.error("Please enter valid address");
    }
  };

  const updateParm = async (e: any) => {
    e.preventDefault();
    formData.pid = updateData?.pid;
    delete formData.address;
    let Days = Math.floor(parseInt(formData?.harvest) / 24);
    let second = parseInt((formData?.harvest * 60 * 60)?.toString());
    let depositFee = parseInt(
      (formData?.depositMultiplierFee * 100).toString()
    );

    if (Days <= 14) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "set",
          [
            formData?.pid,
            formData?.multiplier,
            depositFee,
            second,
            formData?.withUpdate,
          ],
          walletAddress,
          "master"
        )
      );
      if (response?.status) {
        Toast.success(`Updated ${type} successfully`);
        setAPIStatus(true);
        setFormData({
          multiplier: "",
          address: "",
          depositMultiplierFee: "",
          harvest: "",
          withUpdate: true,
        });
        update_Data("");
      } else {
        setFormData((prevState: any) => ({
          ...prevState,
          address: updateData?.lpToken,
        }));
      }
    } else {
      Toast.error("Maximum harvest interval is 14 days.");
    }
  };

  //handler  to add pool
  const addPoolData = async (e: any) => {
    e.preventDefault();

    let stakedTokenSymbol: any = await dispatch(
      callContractGetMethod(
        "symbol",
        [],
        "dynamic",
        false,
        poolData?.rewardToken
      )
    );
    let stakedTokenDecimals: any = await dispatch(
      callContractGetMethod(
        "decimals",
        [],
        "dynamic",
        false,
        poolData?.stakedToken
      )
    );
    let rewardTokenSymbol: any = await dispatch(
      callContractGetMethod(
        "symbol",
        [],
        "dynamic",
        false,
        poolData?.rewardToken
      )
    );
    let rewardTokenDecimals: any = await dispatch(
      callContractGetMethod(
        "decimals",
        [],
        "dynamic",
        false,
        poolData?.rewardToken
      )
    );
    let multiDecimalsWithRewardBlock = CommonService.convertWithDecimal(
      poolData?.rewardPerBlock,
      10 ** rewardTokenDecimals
    );
    let covertWithDcimals = CommonService.convertWithDecimal(
      poolData?.poolLimitPerUser,
      10 ** stakedTokenDecimals
    );
    if (
      poolData.startBlock > 0 &&
      poolData.bonusEndBlock > 0 &&
      poolData.startBlock < poolData.bonusEndBlock
    ) {
      if (stakedTokenSymbol && rewardTokenSymbol) {
        let provider = await connector?.getProvider();
        let response: any = await dispatch(
          callContractSendMethod(
            provider,
            "deployPool",
            [
              poolData?.stakedToken,
              poolData?.rewardToken,
              multiDecimalsWithRewardBlock,
              poolData?.startBlock,
              poolData?.bonusEndBlock,
              switchButton ? covertWithDcimals : 0,
              walletAddress,
            ],
            walletAddress,
            "smartChefFactory"
          )
        );
        if (response?.status) {
          Toast.success("Pool created successfully.");
          setAPIStatus(true);
          setPoolData({
            stakedToken: "",
            rewardToken: "",
            rewardPerBlock: "",
            startBlock: "",
            bonusEndBlock: "",
            poolLimitPerUser: "",
            referralCommissionRate: "",
          });
        }
      } else {
        Toast.success("Please enter valid address.");
      }
    } else {
      Toast.error("Invalid Start/End Block.");
    }
  };
  useEffect(() => {
    UpdatePoolData();
  }, [updateData]);

  const UpdatePoolData = async () => {
    if (currentTimeBlock && currentTimeBlock != undefined) {
      if (updateData && type == "Pool") {
        let rewardTokenDecimals: any = await dispatch(
          callContractGetMethod(
            "decimals",
            [],
            "dynamic",
            false,
            updateData?.rewardToken
          )
        );
        let stakedTokenDecimal: any = await dispatch(
          callContractGetMethod(
            "decimals",
            [],
            "dynamic",
            false,
            updateData?.stakedToken
          )
        );

        setStakedTokenDecimals(stakedTokenDecimal);
        async.parallel([
          async function (callback: any) {
            let startBlock: any = await dispatch(
              callContractGetMethod(
                "startBlock",
                [],
                "dynamicSmartChef",
                false,
                updateData?.poolAddress
              )
            );
            setStartBlockcheck(
              currentTimeBlock < startBlock ? false : "disabled"
            );

            setPreStartBlock(startBlock);
            let blockTimeStamp = await getTimeOfBlock(
              startBlock,
              currentTimeBlock
            );
            setPoolData((prevState: any) => ({
              ...prevState,
              startBlock,
              blockNumberDate: blockTimeStamp,
              rewardToken: updateData?.rewardToken,
              stakedToken: updateData?.stakedToken,
            }));
            setPoolData((prevState: any) => ({
              ...prevState,
              start_Block: startBlock,
            }));
          },
          async function (callback: any) {
            let getUserLimit: any = await dispatch(
              callContractGetMethod(
                "userLimit",
                [],
                "dynamicSmartChef",
                false,
                updateData?.poolAddress
              )
            );
            setUserLimit(getUserLimit);
            setSwitchButton(getUserLimit);
          },
          async function (callback: any) {
            let bonus_EndBlock: any = await dispatch(
              callContractGetMethod(
                "bonusEndBlock",
                [],
                "dynamicSmartChef",
                false,
                updateData?.poolAddress
              )
            );

            setPoolEditableStatus(
              currentTimeBlock > bonus_EndBlock ? "disabled" : false
            );
            setToggleButtonStatus(
              currentTimeBlock > bonus_EndBlock ? true : false
            );
            let endBlock_TimeStamp: any = await getTimeOfBlock(
              bonus_EndBlock,
              currentTimeBlock
            );
            setPoolData((prevState: any) => ({
              ...prevState,
              endbBlockNumberDate: endBlock_TimeStamp,
              bonus_EndBlock,
            }));
            setEndBonusBlock(bonus_EndBlock);
          },
          async function (callback: any) {
            let poolLimitPerUser: any = await dispatch(
              callContractGetMethod(
                "poolLimitPerUser",
                [],
                "dynamicSmartChef",
                false,
                updateData?.poolAddress
              )
            );
            let convertedDecimal = CommonService.divideBigNumberWithoutDecimal(
              poolLimitPerUser,
              10 ** stakedTokenDecimal
            );
            setPoolData((prevState: any) => ({
              ...prevState,
              poolLimitPerUser: convertedDecimal,
            }));
            setPoolLimitPerUser(poolLimitPerUser);
          },
          async function (callback: any) {
            let rewardPerBlock: any = await dispatch(
              callContractGetMethod(
                "rewardPerBlock",
                [],
                "dynamicSmartChef",
                false,
                updateData?.poolAddress
              )
            );
            let tokenDecimal =
              await CommonService.divideBigNumberWithoutDecimal(
                rewardPerBlock,
                10 ** rewardTokenDecimals
              );
            setPoolData((prevState: any) => ({
              ...prevState,
              rewardPerBlock: CommonService.toFixed(
                rewardPerBlock / 10 ** rewardTokenDecimals
              ),
            }));
          },
        ]);
      }
    }
  };

  const getTimeOfBlock = async (block: any, current_TimeBlock: any) => {
    let blockNumber_Date: any;
    if (current_TimeBlock > block) {
      const web3: any = new Web3(defaultNetworks?.rpc);
      let times_tamp: any = await web3.eth.getBlock(block);
      blockNumber_Date = new Date(times_tamp?.timestamp * 1000);
    } else {
      let remainingBlock = block - currentTimeBlock;
      let remainingTime = remainingBlock * 13.445378151;
      const currentTimeStamp = Date.now();
      blockNumber_Date = new Date(
        (remainingTime + currentTimeStamp / 1000) * 1000
      );
    }

    return blockNumber_Date;
  };
  const updateEndBlock = async (e: any) => {
    e.preventDefault();
    if (poolData?.bonusEndBlock > prevStartBlock) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "updateEndBlock",
          [poolData?.bonusEndBlock],
          walletAddress,
          "dynamicSmartChef",
          "",
          updateData?.poolAddress
        )
      );
      if (response?.status) {
        Toast.success("End block updated successfully");
      }
    } else {
      Toast.error("Please enter valid end block");
    }
  };

  const updateStartBlock = async (e: any) => {
    e.preventDefault();
    if (
      prevStartBlock > currentTimeBlock &&
      poolData?.startBlock > currentTimeBlock
    ) {
      let provider = await connector?.getProvider();
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "updateStartBlock",
          [poolData?.startBlock],
          walletAddress,
          "dynamicSmartChef",
          "",
          updateData?.poolAddress
        )
      );
      if (response?.status) {
        Toast.success("Start block updated successfully");
      }
    } else {
      Toast.error("Please enter valid start block");
    }
  };

  const updateRewardPerBlock = async (e: any) => {
    e.preventDefault();
    let rewardTokenDecimals: any = await dispatch(
      callContractGetMethod(
        "decimals",
        [],
        "dynamic",
        false,
        poolData?.rewardToken
      )
    );
    let multiDecimalsWithRewardBlock = CommonService.convertWithDecimal(
      poolData?.rewardPerBlock,
      10 ** rewardTokenDecimals
    );
    let provider = await connector?.getProvider();
    let response: any = await dispatch(
      callContractSendMethod(
        provider,
        "updateRewardPerBlock",
        [multiDecimalsWithRewardBlock],
        walletAddress,
        "dynamicSmartChef",
        "",
        updateData?.poolAddress
      )
    );
    if (response?.status) {
      getStartBlock();
      Toast.success("Reward per block updated successfully");
    }
  };
  const updateLimitPerUser = async (e: any) => {
    e.preventDefault();
    let provider = await connector?.getProvider();
    if (switchButton) {
      if (poolData?.poolLimitPerUser > 0) {
        let response: any = await dispatch(
          callContractSendMethod(
            provider,
            "updatePoolLimitPerUser",
            [
              true,
              CommonService.convertWithDecimal(
                poolData?.poolLimitPerUser,
                10 ** stakedTokenDecimals
              ),
            ],
            walletAddress,
            "dynamicSmartChef",
            "",
            updateData?.poolAddress
          )
        );
        if (response?.status) {
          Toast.success("Pool limit per user updated successfully");
        }
      } else {
        Toast.error("Please enter pool limit more than zero");
      }
    } else {
      let response: any = await dispatch(
        callContractSendMethod(
          provider,
          "updatePoolLimitPerUser",
          [false, 0],
          walletAddress,
          "dynamicSmartChef",
          "",
          updateData?.poolAddress
        )
      );
      if (response?.status) {
        Toast.success("Pool limit per user updated successfully");
      }
    }
  };

  const stopReward = async () => {
    let provider = await connector?.getProvider();
    let response: any = await dispatch(
      callContractSendMethod(
        provider,
        "stopReward",
        [],
        walletAddress,
        "dynamicSmartChef",
        "",
        updateData?.poolAddress
      )
    );

    if (response && response?.status) {
      getStartBlock();
      setStopedPool(false);
      Toast.success("Pool stopped.");
    }
  };

  const getStartBlock = async () => {
    let startBlock: any = await dispatch(
      callContractGetMethod(
        "startBlock",
        [],
        "dynamicSmartChef",
        false,
        updateData?.poolAddress
      )
    );
    setStartBlockcheck(currentTimeBlock < startBlock ? false : "disabled");
    setPreStartBlock(startBlock);

    let bonusEndBlock: any = await dispatch(
      callContractGetMethod(
        "bonusEndBlock",
        [],
        "dynamicSmartChef",
        false,
        updateData?.poolAddress
      )
    );
    setEndBonusBlock(bonusEndBlock);
  };

  useEffect(() => {
    update_Data("");
  }, [type]);

  const setStartBlock = (startTime: any) => {

    setStartTimeStamp(startTime);
    if (startTime > 0) {
      if (startTime > endTimeStamp && endTimeStamp) {
        setEndTimeStampError("");
        setStartTimeStampError("Start time should be less than end time");
      } else {
        setEndTimeStampError("");
        setStartTimeStampError("");
        const currentTimeStamp = Date.now();
        const difference: any = Math.round(
          (Math.round(startTime / 1000) - Math.round(currentTimeStamp / 1000)) /
          (defaultNetworks?.chainType == "BSC"
            ? BSC_ASSUMED_BLOCK_CREATION_TIME
            : ETH_ASSUMED_BLOCK_CREATION_TIME)
        );
        const newStartBlock: any = currentTimeBlock + difference;
        setPoolData((prevState: any) => ({
          ...prevState,
          startBlock: startTime == 0 ? 0 : newStartBlock,
        }));
      }
    }
  };

  const getEndBlock = (endTime: any) => {
    setEndTimeStamp(endTime);
    if (endTime > 0) {
      if (endTime < startTimeStamp && startTimeStamp) {
        setStartTimeStampError("");
        setEndTimeStampError("End time should be greater than start time");
      } else {
        setStartTimeStampError("");
        setEndTimeStampError("");
        const currentTimeStamp = Date.now();
        const difference: any = Math.round(
          (Math.round(endTime / 1000) - Math.round(currentTimeStamp / 1000)) /
          (defaultNetworks?.chainType == "BSC" ? 3 : 13.445378151)
        );
        const newEndBlock: any = currentTimeBlock + difference;
        setPoolData((prevState: any) => ({
          ...prevState,
          bonusEndBlock: endTime == 0 ? 0 : newEndBlock,
        }));
      }
    }
  };

  //  handler to  validate start and end block date and time
  const validateStartAndEndBlock = () => {
    if (
      (startTimeStamp > endTimeStamp && endTimeStamp) ||
      (endTimeStamp < startTimeStamp && startTimeStamp)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      <div className={!updateData ? "TokenCard" : "TokenCard editable"}>
        {type == "Pool" && !updateData ? (
          <>
            <Form onSubmit={(e) => addPoolData(e)}>
              <Row>
                <Col>
                  <h3>
                    {!updateData ? <>Create</> : <>Edit</>} New {type}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={6}>
                  <Form.Group className="commonInput">
                    <Form.Label>Stake Token</Form.Label>
                    <div className="loading-handle">
                      <InputCustom
                        type="text"
                        placeholder="Token"
                        smallTextClass="text-red"
                        value={poolData?.stakedToken}
                        smallText={
                          poolData?.stakedToken && !validStakeToken
                            ? !checkValidateStatus
                              ? defaultNetworks?.symbol == "BNB"
                                ? showValidStakeErr
                                : defaultNetworks?.symbol == "ETH"
                                  ? showValidStakeErr
                                  : ""
                              : ""
                            : ""
                        }
                        onChange={(e: any) =>
                          setPoolData((prevState: any) => ({
                            ...prevState,
                            stakedToken: e.target.value,
                          }))
                        }
                        required
                      />

                      <div className="loading-handle-icon">
                        {poolData?.stakedToken && !updateData ? (
                          <>
                            {validStakeToken ? (
                              <img width={40} src={successIcon} />
                            ) : !checkValidateStatus ? (
                              <img width={40} src={errorIcon} />
                            ) : null}
                          </>
                        ) : (
                          ""
                        )}
                      </div>

                      {checkValidateStatus ? (
                        <>
                          <Form.Text className="text-green">
                            Validating...
                          </Form.Text>
                        </>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="commonInput">
                    <Form.Label>Reward Token</Form.Label>
                    <div className="loading-handle">
                      {/* <InputCustom type="text" placeholder="Token" smallTextClass="text-red" value={poolData?.rewardToken} smallText={poolData?.rewardToken && !validRewardToken ? !checkReardateStatus ? defaultNetworks?.symbol == 'BNB' ? 'Could not fetch token price22' : defaultNetworks?.symbol == 'ETH' ? 'Could not fetch token price' : '' : '' : ''} onChange={(e: any) => setPoolData((prevState: any) => ({ ...prevState, rewardToken: e.target.value }))} required /> */}

                      <InputCustom
                        type="text"
                        placeholder="Token"
                        smallTextClass="text-red"
                        value={poolData?.rewardToken}
                        smallText={
                          poolData?.rewardToken && !validRewardToken
                            ? !checkReardateStatus
                              ? defaultNetworks?.symbol == "BNB"
                                ? showValidRewardErr
                                : defaultNetworks?.symbol == "ETH"
                                  ? showValidRewardErr
                                  : ""
                              : ""
                            : ""
                        }
                        onChange={(e: any) =>
                          setPoolData((prevState: any) => ({
                            ...prevState,
                            rewardToken: e?.target?.value,
                          }))
                        }
                        required
                      />

                      <div className="loading-handle-icon">
                        {poolData?.rewardToken && !updateData ? (
                          <>
                            {validRewardToken ? (
                              <img width={40} src={successIcon} />
                            ) : !checkReardateStatus ? (
                              <img width={40} src={errorIcon} />
                            ) : null}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      {checkReardateStatus ? (
                        <>
                          <Form.Text className="text-green">
                            Validating...
                          </Form.Text>
                        </>
                      ) : null}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              {/* <hr /> */}

              <hr />
              <Row>
                <Col xs={12} className="largeCall">
                  <CommonDateTimePicker
                    blockNumberDate={poolData?.blockNumberDate}
                    label="Start Block"
                    subtitle={`Estimated Start Block:${poolData?.startBlock ? poolData?.startBlock : 0
                      }`}
                    setTimeStamp={setStartBlock}
                  />
                  <small className="text-danger">{startTimeStampError}</small>
                </Col>
                <Col xs={12} className="largeCall">
                  <CommonDateTimePicker
                    blockNumberDate={poolData?.endbBlockNumberDate}
                    label="End Block"
                    subtitle={`Estimated End Block:${poolData?.bonusEndBlock ? poolData?.bonusEndBlock : 0
                      }`}
                    getEndBlock={getEndBlock}
                  />
                  <small className="text-danger">{endTimeStampError}</small>
                </Col>
              </Row>

              <hr />
              <Row>
                <Col md={6}>
                  <Form.Group className="commonInput pb-0 h-100 d-flex flex-column ">
                    <Form.Label>Reward Per Block</Form.Label>
                    <InputCustom
                      type="text"
                      placeholder="Reward Per Block"
                      className="mt-auto"
                      value={poolData?.rewardPerBlock}
                      maxlength={10}
                      onBlur={(e: any) => {
                        const { value } = e.target;
                        if (value == 0) {
                          setPoolData((prevState: any) => ({
                            ...prevState,
                            rewardPerBlock: 1,
                          }));
                        }
                      }}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        if (CommonService.allowOnlyNumber(value, "")) {
                          if (value == 0) {
                            setPoolData((prevState: any) => ({
                              ...prevState,
                              rewardPerBlock: value,
                            }));
                          } else {
                            setPoolData((prevState: any) => ({
                              ...prevState,
                              rewardPerBlock: value,
                            }));
                          }
                        }
                      }}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="commonInput pb-0 h-100 d-flex flex-column ">
                    <Row className="align-items-center">
                      <Col>
                        <Form.Label>Pool Limit Per User</Form.Label>
                      </Col>
                      <Col>
                        <ToggleSwitch
                          className="d-table ms-auto"
                          userLimit={userLimit}
                          switchButton={switchButton}
                          setSwitchButton={setSwitchButton}
                          poolEditableStatus={poolEditableStatus}
                          toggleButtonStatus={toggleButtonStatus}
                        />
                      </Col>
                    </Row>
                    {switchButton && (
                      <InputCustom
                        type="text"
                        placeholder="Pool Limit Per User"
                        value={poolData?.poolLimitPerUser}
                        maxlength={10}
                        className="mt-auto"
                        onChange={(e: any) => {
                          const { value } = e.target;
                          let validate: any =
                            CommonService.allowOnlyNumberWithDecimals(
                              e.target.value,
                              "token",
                              10 ** stakedTokenDecimals
                            );
                          if (validate) {
                            setPoolData((prevState: any) => ({
                              ...prevState,
                              poolLimitPerUser: e.target.value,
                            }));
                          }
                        }}
                        required
                      />
                    )}

                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                </Col>
              </Row>

              <hr />
              <Row>
                <Col xs={6}>
                  <CommonBtn
                    title="Add Pool"
                    type="submit"
                    className={` mt-5 ${validStakeToken
                        ? endTimeStampError || startTimeStampError
                          ? "disabled"
                          : false
                        : "disabled"
                      }`}
                  />
                </Col>
              </Row>
            </Form>
          </>
        ) : type == "Pool" && updateData ? (
          <>
            <Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="commonInput">
                    <Form.Label>Stake Token</Form.Label>
                    <InputCustom
                      type="text"
                      placeholder="Reward Token"
                      value={poolData?.stakedToken}
                      onChange={(e: any) =>
                        setPoolData((prevState: any) => ({
                          ...prevState,
                          stakedToken: e.target.value,
                        }))
                      }
                      inputtext="disabled"
                      required
                    />
                  </Form.Group>
                </Col>

                <Col md={6}>
                  <Form.Group className="commonInput">
                    <Form.Label>Reward Token</Form.Label>
                    <InputCustom
                      type="text"
                      placeholder="Reward Token"
                      value={poolData?.rewardToken}
                      onChange={(e: any) =>
                        setPoolData((prevState: any) => ({
                          ...prevState,
                          rewardToken: e.target.value,
                        }))
                      }
                      inputtext="disabled"
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
            </Row>
            {/* <Form onSubmit={(e) => updateStartEndBlock(e)}> */}
            <Row>
              {/* <Col md={6}>
                <Form onSubmit={(e) => updateStartBlock(e)}>
                  <Form.Group className="commonInput">
                    <Form.Label>Start Block</Form.Label>
                    <InputCustom
                      type="text"
                      placeholder="Start Block"
                      value={poolData?.startBlock}
                      maxlength={10}
                      onChange={(e: any) => {
                        if (CommonService.allowOnlyNumber(e.target.value, "")) {
                          setPoolData((prevState: any) => ({
                            ...prevState,
                            startBlock: e.target.value,
                          }));
                        }
                      }}
                      inputtext={startBlockcheck}
                      required
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                  <Col className="mb-4 pb-2">
                    <CommonBtn
                      title="Update now"
                      className={`btn-small  ${startBlockcheck}`}
                    />
                  </Col>
                </Form>
              </Col> */}

              <Col xs={12} className="largeCall">
                {/* <Form onSubmit={(e) => updateStartBlock(e)}>
                    <Form.Group className="commonInput">
                      <Form.Label>Start Block</Form.Label>
                      <InputCustom
                        type="text"
                        placeholder="Start Block"
                        value={poolData?.startBlock}
                        maxlength={10}
                        onChange={(e: any) => {
                          if (CommonService.allowOnlyNumber(e.target.value, "")) {
                            setPoolData((prevState: any) => ({
                              ...prevState,
                              startBlock: e.target.value,
                            }));
                          }
                        }}
                        inputtext={startBlockcheck}
                        required
                      />
                      <Form.Text className="text-muted"></Form.Text>
                    </Form.Group>
                    <Col className="mb-4 pb-2">
                      <CommonBtn
                        title="Update now"
                        className={`btn-small  ${startBlockcheck}`}
                      />
                    </Col> */}
                {/* </Form> */}
                <Form onSubmit={(e) => updateStartBlock(e)}>
                  <CommonDateTimePicker
                    blockNumberDate={poolData?.blockNumberDate}
                    label="Start Block"
                    subtitle={`Estimated Start Block:${poolData?.startBlock ? poolData?.startBlock : 0
                      }`}
                    setTimeStamp={setStartBlock}
                    inputtext={startBlockcheck}
                  />
                  <small className="text-danger">{startTimeStampError}</small>

                  <Col className="mb-4 pb-2">
                    <CommonBtn
                      title="Update now"
                      className={`btn-small  ${startBlockcheck}`}
                    />
                  </Col>
                </Form>
              </Col>

              <Col xs={12} className="largeCall">
                <Form onSubmit={(e) => updateEndBlock(e)}>
                  <CommonDateTimePicker
                    blockNumberDate={poolData?.endbBlockNumberDate}
                    label="End Block"
                    subtitle={`Estimated End Block:${poolData?.bonusEndBlock ? poolData?.bonusEndBlock : 0
                      }`}
                    getEndBlock={getEndBlock}
                  />
                  <small className="text-danger">{endTimeStampError}</small>

                  <Col className="mb-4 pb-2">
                    <CommonBtn
                      title="Update now"
                      className={`btn-small `}
                    />
                  </Col>
                </Form>
              </Col>
            </Row>
            <hr />

            <Row>
              <Col md={6}>
                <Form
                  onSubmit={(e) => updateRewardPerBlock(e)}
                  className="h-100"
                >
                  <Form.Group className="commonInput pb-0 h-100 d-flex flex-column ">
                    <Form.Label>Reward Per Block</Form.Label>
                    <InputCustom
                      type="text"
                      placeholder="Reward Per Block"
                      value={poolData?.rewardPerBlock}
                      onBlur={(e: any) => {
                        const { value } = e.target;
                        if (value == 0) {
                          setPoolData((prevState: any) => ({
                            ...prevState,
                            rewardPerBlock: 1,
                          }));
                        }
                      }}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        if (CommonService.allowOnlyNumber(value, "")) {
                          if (value == 0) {
                            setPoolData((prevState: any) => ({
                              ...prevState,
                              rewardPerBlock: value,
                            }));
                          } else {
                            setPoolData((prevState: any) => ({
                              ...prevState,
                              rewardPerBlock: value,
                            }));
                          }
                        }
                      }}
                      inputtext={
                        currentTimeBlock > prevStartBlock ? "disabled" : false
                      }
                      className={"mt-auto"}
                      required
                    />
                    <div className="mt-4 pt-2 mb-4 pb-2">
                      <CommonBtn
                        title="Update now"
                        className={`btn-small  ${currentTimeBlock > poolData?.startBlock
                            ? "disabled"
                            : false
                          }`}
                      />
                    </div>
                  </Form.Group>
                </Form>
              </Col>

              <Col md={6}>
                <Form onSubmit={(e) => updateLimitPerUser(e)} className="h-100">
                  <Form.Group className="commonInput pb-0 h-100 d-flex flex-column ">
                    <Row className="align-items-center">
                      <Col>
                        <Form.Label>Pool Limit Per User</Form.Label>
                      </Col>
                      <Col>
                        <ToggleSwitch
                          className="d-table ms-auto"
                          userLimit={userLimit}
                          switchButton={switchButton}
                          setSwitchButton={setSwitchButton}
                          poolEditableStatus={poolEditableStatus}
                          toggleButtonStatus={toggleButtonStatus}
                        />
                      </Col>
                    </Row>
                    {switchButton && (
                      <InputCustom
                        type="number"
                        placeholder="Pool Limit Per User"
                        value={!switchButton ? "" : poolData?.poolLimitPerUser}
                        onChange={(e: any) => {
                          const { value } = e.target;
                          let validate: any =
                            CommonService.allowOnlyNumberWithDecimals(
                              e.target.value,
                              "token",
                              10 ** stakedTokenDecimals
                            );
                          if (validate) {
                            setPoolData((prevState: any) => ({
                              ...prevState,
                              poolLimitPerUser: e.target.value,
                            }));
                          }
                        }}
                        className={"mt-auto mb-4"}
                        inputtext={`mt-auto ${poolEditableStatus == "disabled"
                            ? "disabled"
                            : !switchButton
                              ? "disabled"
                              : false
                          }`}
                        required
                      />
                    )}

                    <div className="mt-auto pt-2 mb-4 pb-2">
                      <CommonBtn
                        title={`${!switchButton ? "Remove limit per user" : "Update now"
                          }`}
                        className={`btn-small  ${switchButton || poolEditableStatus
                            ? poolEditableStatus == "disabled"
                              ? "disabled"
                              : false
                            : switchButton || userLimit
                              ? false
                              : "disabled"
                          }`}
                      />
                    </div>
                  </Form.Group>
                </Form>
              </Col>
            </Row>

            <hr />
            <Row>
              <Row>
                <Col className="mb-4 mt-5">
                  <CommonBtn
                    title="Stop Reward"
                    type="submit"
                    className={`btn-small  ${stopedPool
                        ? currentTimeBlock > poolData?.startBlock &&
                          currentTimeBlock < endBonus_Block
                          ? false
                          : "disabled"
                        : "disabled"
                      }`}
                    onClick={stopReward}
                  />
                </Col>
                <Col xs={6}>
                  <CommonBtn
                    title="Cancel"
                    className=" mb-4 mt-5 btn-small "
                    onClick={() => {
                      setEndTimeStampError("");
                      setStartTimeStampError("");
                      setPoolData({
                        stakedToken: "",
                        rewardToken: "",
                        rewardPerBlock: "",
                        startBlock: "",
                        bonusEndBlock: "",
                        poolLimitPerUser: "",
                      });
                      update_Data("");
                    }}
                  />
                </Col>
              </Row>
            </Row>
          </>
        ) : (
          <>
            <Form
              onSubmit={(e) => {
                updateData ? updateParm(e) : addToken(e);
              }}
            >
              <Row>
                <Col>
                  <h3>
                    {!updateData ? <>Create</> : <>Edit</>} New {type}
                  </h3>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={8}>
                  <Form.Group className="commonInput">
                    <Form.Label>Pool Address</Form.Label>
                    <div className="loading-handle">
                      <InputCustom
                        smallTextClass="text-red"
                        smallText={
                          formData?.address && !validLp && !validateLoader
                            ? showValidLpError
                            : ""
                        }
                        type="text"
                        placeholder="Address"
                        value={formData?.address}
                        onChange={(e: any) =>
                          setFormData((prevState: any) => ({
                            ...prevState,
                            address: e.target.value,
                          }))
                        }
                        inputtext={updateData ? "disabled" : false}
                      />
                      <div className="loading-handle-icon">
                        {formData?.address && !updateData ? (
                          <>
                            {validateLoader ? (
                              <Spinner animation="border" variant="light" />
                            ) : validLp ? (
                              <img width={40} src={successIcon} />
                            ) : (
                              <img width={40} src={errorIcon} />
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {validateLoader ? (
                      <>
                        <Form.Text className="text-green">
                          Validating...
                        </Form.Text>
                      </>
                    ) : null}
                  </Form.Group>
                </Col>

                <Col md={4}>
                  <Form.Group className="commonInput">
                    <Form.Label>Allocation Point</Form.Label>

                    <InputCustom
                      type="number"
                      placeholder="Allocation point"
                      onWheel={(e: any) => e.target.blur()}
                      value={formData?.multiplier}
                      maxlength={10}
                      onBlur={(e: any) => {
                        const { value } = e.target;
                        if (value == 0) {
                          setFormData((prevState: any) => ({
                            ...prevState,
                            multiplier: 1,
                          }));
                        }
                      }}
                      onChange={(e: any) => {
                        const { value } = e.target;
                        if (CommonService.allowOnlyNumber(value, "")) {
                          if (value == 0) {
                            setFormData((prevState: any) => ({
                              ...prevState,
                              multiplier: value,
                            }));
                          } else {
                            setFormData((prevState: any) => ({
                              ...prevState,
                              multiplier: parseInt(value),
                            }));
                          }
                        }
                      }}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={6}>
                  <Form.Group className="commonInput">
                    <Form.Label>Deposit Fee ( %)</Form.Label>
                    <InputCustom
                      type="number"
                      placeholder="Deposit Fee"
                      onWheel={(e: any) => e.target.blur()}
                      value={formData?.depositMultiplierFee}
                      maxlength={10}
                      onChange={(e: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,
                          depositMultiplierFee: e.target.value,
                        }));
                      }}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="commonInput">
                    <Form.Label>Harvest Interval ( In Hours)</Form.Label>
                    <InputCustom
                      type="number"
                      placeholder="Hours"
                      onWheel={(e: any) => e.target.blur()}
                      value={formData?.harvest}
                      maxlength={10}
                      onChange={(e: any) => {
                        setFormData((prevState: any) => ({
                          ...prevState,
                          harvest: e.target.value,
                        }));
                      }}
                      required
                    />
                    <Form.Text className="text-muted"></Form.Text>
                  </Form.Group>
                </Col>
              </Row>
              <hr />

              <Row>
                <Col xs={6}>
                  <CommonBtn
                    title={`${updateData ? "Update" : "Add"} ${type}`}
                    type="submit"
                    className=" mt-5"
                    disabled={!validLp}
                  />
                </Col>
                <Col xs={6} className="text-end">
                  {updateData ? (
                    <CommonBtn
                      title="Cancel"
                      className=" mt-5"
                      onClick={() => {
                        setFormData({
                          multiplier: "",
                          address: "",
                          depositMultiplierFee: "",
                          harvest: "",
                          withUpdate: true,
                        });

                        update_Data("");
                      }}
                    />
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </Form>
          </>
        )}
      </div>
    </>
  );
};

export default CreateToken;
