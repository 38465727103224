import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import Web3 from "web3";
import Toast from "../../component/common/Toast";
import { WALLET_TYPE } from "../../constant";
import { loading } from "../../features/loader.slice";
import { ContractService, callWeb3 } from "../../services/ContractService";
import { storeInstance } from "../../services/axios";
import { CommonService } from "../../services/commonService";
import { connectmetamask } from "./connect";
import { setLoader } from "./loader.action";

export const callContractGetMethod = (
  method: string,
  data: any = [],
  type: string,
  loading = true,
  dynamicAddress: string = "",
  showError: boolean = true,
  flag:string=""
) => {
  return async (dispatch: Dispatch<any> = useDispatch(), getState: any) => {
    try {
      const result = await ContractService.callGetMethod(
        method,
        data,
        type,
        dynamicAddress
      );
      if (result) {
        return result;
      }
    } catch (error) {
      console.log('error',data,method,type,dynamicAddress,flag, error)
      return error;
    }
  };
};

export function callContractSendMethod(
  provider: any,
  method: string,
  data: any = [],
  walletAddress: string,
  type: string,
  value: string = "",
  dynamicAddress: string = "",
  dynamicTokenAddress: string = ""
) {
  return async (dispatch: Dispatch<any> = useDispatch(), getState: any) => {
    try {
      let wallet = getState().connect.walletType;
      let verifyAccount: any = false;
      if (wallet === WALLET_TYPE.METAMASK) {
        verifyAccount = await dispatch(connectmetamask());
      }
      if (
        wallet != WALLET_TYPE.METAMASK ||
        (wallet === WALLET_TYPE.METAMASK && verifyAccount)
      ) {
        dispatch(setLoader(true));
        const createInstance = await ContractService.createInstance(provider);
        if (createInstance) {
          const result = await ContractService.callSendMethod(
            provider,
            method,
            data,
            walletAddress,
            type,
            value,
            dynamicAddress,
            dynamicTokenAddress
          );
          dispatch(setLoader(false));
          return result;
        } else {
          dispatch(setLoader(false));
          return Toast.error(
            "Some error occurred during contract interaction. Please reload the page"
          );
        }
      }
    } catch (error: any) {
      let { message } = error as any;
      if (message && message.includes("execution reverted: Lunatic: K")) {
        return supportingFeeMethod(
          provider,
          data,
          method,
          walletAddress,
          type,
          value,
          dynamicTokenAddress
        );
      } else {
        dispatch(setLoader(false));
        return Toast.error(CommonService.getError(error));
      }
    }
  };
}

const supportingFeeMethod = async (
  provider: any,
  data: any,
  method: any,
  walletAddress: string,
  type: string,
  value: string,
  dynamicTokenAddress: string
) => {
  try {
    let _method: string = "";
    switch (method) {
      case "swapExactTokensForTokens":
        _method = "swapExactTokensForTokensSupportingFeeOnTransferTokens";
        break;
      case "swapTokensForExactTokens":
        _method = "";

        break;
      case "swapExactETHForTokens":
        _method = "swapExactETHForTokensSupportingFeeOnTransferTokens";
        break;
      case "swapETHForExactTokens":
        _method = "";
        break;
      case "swapExactTokensForETH":
        _method = "swapExactTokensForETHSupportingFeeOnTransferTokens";
        break;
      case "swapTokensForExactETH":
        _method = "";
        break;
      case "removeLiquidityETHWithPermit":
        _method = "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens";
        break;
    }

    const createInstance = await ContractService.createInstance(provider);
    if (createInstance) {
      if (_method == undefined || _method == "") {
        storeInstance.dispatch(loading({ loader: false }));
        return Toast.error(
          "You are trying to swap taxable token please make sure to enter value in 'From' field and refrain from entering it in 'To' field"
        );
      } else {
        const result = await ContractService.callSendMethod(
          provider,
          _method,
          data,
          walletAddress,
          type,
          value,
          "",
          dynamicTokenAddress
        );
        storeInstance.dispatch(loading({ loader: false }));
        return result;
      }
    } else {
      storeInstance.dispatch(loading({ loader: false }));
      return Toast.error(
        "Some error occurred during contract interaction. Please reload the page"
      );
    }
  } catch (error: any) {
    storeInstance.dispatch(loading({ loader: false }));
    if (
      error &&
      error?.message.includes(
        "execution reverted: LunaticRouter: INSUFFICIENT_OUTPUT_AMOUNT"
      )
    ) {
      return Toast.error("Increase slippage tolerance");
    } else {
      return Toast.error(CommonService.getError(error));
    }
  }
};

export const validateAddress = (address: string, type: string) => {
  return async (dispatch: Dispatch<any> = useDispatch(), getState: any) => {
    try {
      let isAddress = Web3.utils.isAddress(address);
      if (isAddress) {
        let web3: any = await callWeb3();
        let check = await web3.eth.getCode(address);
        if (
          (check !== "0x" && type === "wallet") ||
          (check === "0x" && type === "contract")
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
};
