import Lottie from 'lottie-react';
import { useState } from 'react';
import { Link } from "react-router-dom";
import BCIO from "../../../../assets/icons/bcio.png";
import Luni from "../../../../assets/icons/luni.png";
import CheckLottie from '../../../../assets/lottie/check.json';
import CommonBtn from "../../CommonBtn/CommonBtn";
import CommonModal from "../CommonModal/CommonModal";
import './ConfirmSupplyModal.scss';
const ConfirmSupplyModal = ({ show, onHide }: any) => {
  const [TransactionShow, setTransactionShow] = useState(true);
  const ConfirmHandle = () => {
    setTransactionShow(false)
  }
  return (
    <>
      <CommonModal className="ConfirmSupply" modalTitle="You will receive" show={show}
        onHide={onHide} >
        {TransactionShow
          ?
          <>
            <p className="mt-5">You will Receive</p>
            <div className="ConfirmSupplyInfo">
              <div className="left">
                <img src={BCIO} alt="" className="pairOne" />
                <img src={Luni} alt="" className="pairTwo" />
                <span>BRIDGECOIN/LUNI</span>
              </div>
              <div className="right">
                <span>$ 1,023.00</span>
              </div>
            </div>
            <div className="ConfirmSupplySharePair">
              <ul className="ValuesDesign">
                <li>
                  <p>Your share in the pair</p>
                  <div className="right">
                    <span>0.3551%</span>
                  </div>
                </li>
              </ul>
            </div>
            <hr />
            <p className="mt-3">Input</p>
            <div className="ConfirmSupplyInput">
              <ul className="ValuesDesign">
                <li>
                  <p>BCIO</p>
                  <div className="right">
                    <span>0.3551%</span>
                  </div>
                </li>
                <li>
                  <p>LUNI</p>
                  <div className="right">
                    <span>0.3551%</span>
                  </div>
                </li>
              </ul>
            </div>
            <hr />
            <p className="mt-3">Rates</p>
            <div className="ConfirmSupplyRates">
              <ul className="ValuesDesign">
                <li>
                  <p>1 BCIO =</p>
                  <div className="right">
                    <span>65454 LUNI</span>
                  </div>
                </li>
                <li>
                  <p>1 LUNI =</p>
                  <div className="right">
                    <span>0.000000201 BCIO</span>
                  </div>
                </li>
              </ul>
            </div>
            <hr />
            <div className="ConfirmSupplyTolerance">
              <ul className="ValuesDesign">
                <li>
                  <p>Slippage Tolerance</p>
                  <div className="right">
                    <span>0.5%</span>
                  </div>
                </li>
              </ul>
            </div>
            <CommonBtn title="Confirm Supply" className="w-100 btn-yellow mt-5" onClick={ConfirmHandle} />
          </>
          :
          <>
            <div className="ConfirmSupplyDone">
              <Lottie
                animationData={CheckLottie}
                loop={true}
                style={{ width: 200 }}
              />
              <h3>Transaction Submitted</h3>
              <Link to="#">View on Etherscan</Link>
              <CommonBtn title="Close" className="w-100  mt-5" onClick={onHide} />
            </div>
          </>
        }
      </CommonModal>
    </>
  )
}

export default ConfirmSupplyModal