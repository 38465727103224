import Lottie from 'lottie-react';
import Empty from '../../assets/lottie/empty-file.json';

const NoDataFound = (props: any) => {
  return (
    <div className="nodata">
      <div className="Lottie">
        <Lottie
          animationData={Empty}
          loop={true}
          style={{ width: 192 }}
        />
      </div>
      <h4>No Records Found!</h4>
    </div>
  );
};

export default NoDataFound;
