import "bootstrap/dist/css/bootstrap.min.css";
import { Dispatch, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import { connectmetamask } from './app/actions/connect';
import { RootState } from "./app/store";
import AdminLayout from "./component/common/Layouts/AdminLayout/AdminLayout";
import FarmPrivate from "./component/common/Layouts/AdminLayout/FarmPrivate";
import LiquidityPrivate from "./component/common/Layouts/AdminLayout/LiquidityPrivate";
import PoolPrivate from "./component/common/Layouts/AdminLayout/PoolPrivate";
import MainLayout from "./component/common/Layouts/MainLayout/MainLayout";
import LoaderComponent from './component/common/LoaderCompoent/LoaderCompoent';
import Dashboard from "./pages/Admin/Dashboard/Dashboard";
import PoolAndFarm from './pages/Admin/LiquidityAdmin/LiquidityDashboard/PoolAndFarm';
import Login from "./pages/Admin/Login/Login";
import Settings from "./pages/Admin/Settings/Settings";
import Tokens from "./pages/Admin/Tokens/Tokens";
import FarmsPage from "./pages/FarmsPage/FarmsPage";
import Home from "./pages/Home/Home";
import Liquidity from './pages/Liquidity/Liquidity';
import PoolsPage from "./pages/Poolspage/PoolsPage";
import ProfilePage from "./pages/ProfilePage/ProfilePage";
import ProfileSetup from "./pages/ProfileSetup/ProfileSetup";
import ReferalPage from "./pages/ReferalPage/ReferalPage";
import { callContractGetMethod } from "./app/actions/contract.action";
import { saveNetworkDetails } from "./features/connect.slice";

function App() {
  const dispatch: Dispatch<any> = useDispatch();
  const defaultNetwork =  useSelector((state:RootState)=>state.connect.networksDetails)
  const walletAddress = useSelector((state: RootState) => state.connect.walletAddress);
  const walletType = useSelector((state: RootState) => state.connect.walletType);


  const getDecimals = async () => {
    // let values: any = NETWORKS.filter((items) => items?.chainType == value);
    let swapItAddress: any = await dispatch(
      callContractGetMethod("SwapIT", [], "master", false)
    );
    if (swapItAddress && swapItAddress != undefined) {
      let swapItDecimal: any = await dispatch(
        callContractGetMethod("decimals", [], "dynamic", false, swapItAddress)
      );
      if (swapItDecimal) {
        let swapItDecimals = 10 ** swapItDecimal;
        let obj: any={ ...defaultNetwork, swapItDecimals }
        await dispatch(
          saveNetworkDetails({
            networksDetails: obj,
          })
        );
      }
    }
  };

  useEffect(()=>{})

  useEffect(() => {
    walletAddress && getDecimals();
  },[defaultNetwork?.chainId,walletAddress])

  // useEffect(() => {
  //    const {ethereum} = window as any;
  //   if(ethereum ){
  //     ethereum.on('chainChanged' ,(res:any)=>{
  //       setTimeout(()=>
  //       window.location.reload()
  //       ,100)
  //     })
  //   }
  //    if(walletAddress){
  // }, [walletAddress, walletType]);

  useEffect(()=>{

  })

  return (
    <>
      <div className="App">
        <LoaderComponent />
        <Toaster />
        {/* <PersistGate loading={null} persistor={persistor}> */}
        <BrowserRouter>
          <Routes>
            <Route element={<MainLayout />}>
              <Route index element={<Home />} />
              <Route path="/liquidity" element={<Liquidity />} />
              <Route path="/farms" element={<FarmsPage />} />
              <Route path="/pools" element={<PoolsPage />} />
              <Route path="/referral" element={<ReferalPage />} />
              <Route path="socialShare/:walletAddress" element={<ReferalPage />} />
              {/* <Route path="/profile">
                <Route index element={<ProfilePage />} />
                <Route path="setup" element={<ProfileSetup />} />
              </Route> */}
            </Route>
            {/* start admin private route */}
            <Route path="/admin">
              <Route index element={<Login />} />

              <Route element={<AdminLayout />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="settings" element={<Settings />} />

                <Route element={<LiquidityPrivate />}>
                  <Route path="tokens" element={<Tokens />} />
                </Route>

                <Route element={<PoolPrivate />}>
                  <Route path="pool" element={<PoolAndFarm type="Pool" />} />
                </Route>

                <Route element={<FarmPrivate />}>
                  <Route path="farms" element={<PoolAndFarm type="Farm" />} />
                </Route>

              </Route>
            </Route>


            {/* end of private route */}
          </Routes>
        </BrowserRouter>
        {/* </PersistGate> */}
      </div>
    </>
  );
}
export default App;
