import { createSlice } from "@reduxjs/toolkit";
import { IConnectSlice } from "../interfaces/connect.interface";

const initialState: IConnectSlice = {
  walletAddress: null,
  walletType: null,
  isAdmin: false,
  networksDetails: null,
  adminStatus: false,
  adminType: null,
};
const connectSlice = createSlice({
  name: "connect",
  initialState,
  reducers: {
    addUserDetails(state, actions) {
      state.isAdmin = actions.payload.isAdmin;
      state.walletAddress = actions.payload.walletAddress;
      state.walletType = actions.payload.walletType;
    },
    adminStatus(state, actions) {
      state.adminStatus = actions.payload.adminStatus;
      state.adminType = actions.payload.adminType;
    },
    saveNetworkDetails(state, actions) {
      state.networksDetails = actions.payload.networksDetails;
    },
    removeUserDetails(state) {
      state.isAdmin = false;
      state.adminStatus = false;
      state.walletAddress = null;
      state.walletType = null;
    },

  },
});

export const { addUserDetails, adminStatus, saveNetworkDetails, removeUserDetails } = connectSlice.actions;
export default connectSlice.reducer;
