import Lottie from "lottie-react";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { Accordion, Popover } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  callApiGetMethod,
  callApiPostMethod,
} from "../../app/actions/api.action";
import { callContractGetMethod } from "../../app/actions/contract.action";
import { RootState } from "../../app/store";
import EmptyLottie from "../../assets/lottie/empty-file.json";
import noFound from "../../assets/lottie/noFound.json";
import CustomOverlay from "../../component/common/CustomOverlay";
import CommonBtn from "../../component/ui/CommonBtn/CommonBtn";
import RemoveToken from "../../component/ui/Modals/RemoveToken/RemoveToken";
import { CommonService } from "../../services/commonService";
import "./Default.scss";

var async = require("async");

export const Default = ({
  onClickLiquidity,
  deadline,
  slipPage,
  onClickImportPool,
  isRefreshed,
}: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [liquidityList, setLiquidityList] = useState<any>();
  const [liquidityListAction, setLiquidityListForAction] = useState<any>();
  const [liquidityData, setLiquidityData] = useState<any>();

  const [showToken, setshowToken] = useState(false);
  const handleshowToken = () => setshowToken(true);
  const closeToken = () => setshowToken(false);

  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loaded, setLoaded] = useState(true);

  const defaultNetworks: any = useSelector(
    (state: RootState) => state.connect.networksDetails
  );
  const walletAddress: any = useSelector(
    (state: RootState) => state.connect.walletAddress
  );

  const Rewardpopover = (
    <Popover className="commonPopover">
      <Popover.Body>
        Based on last 7 days' peformance. Does not account for impermanent loss
      </Popover.Body>
    </Popover>
  );

  const [TipTool, setTipTool] = useState<any>("right");
  useEffect(() => {
    if (window.innerWidth < 1280) {
      setTipTool("auto");
    } else {
      setTipTool("right");
    }
    const handleResize = () => {
      if (window.innerWidth < 1280) {
        setTipTool("bottom");
      } else {
        setTipTool("right");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (walletAddress) {
      setTimeout(() => {
        getLiquidityList();
      }, 2000);
    } else {
      setLiquidityList([]);
    }
  }, [walletAddress, isRefreshed, defaultNetworks?.chainId]);

  const getLiquidityList = async (
    loading: boolean = true,
    page: number = 1
  ) => {
    const _liquidityList: any = await dispatch(
      callApiGetMethod(
        `GET_LIQUIDITY`,
        {
          chainType: defaultNetworks?.chainType,
          user: walletAddress,
        },
        false
      )
    );

    if (_liquidityList && _liquidityList?.data?.docs) {
      await setLiquidityList(_liquidityList?.data?.docs);
      await setLiquidityListForAction(_liquidityList?.data?.docs);
      await setTotalData(_liquidityList?.data?.totalDocs);
      async.forEach(_liquidityList?.data?.docs, getPairDetails);
    }
  };

  useEffect(() => {
    if (liquidityListAction) {
      async.forEach(liquidityListAction, getPairDetails);
    }
  }, [liquidityListAction]);

  //Pagination
  const onPageChanged = useCallback(
    (event: any, page: number) => {
      event.preventDefault();
      getLiquidityList(true, page);
      setCurrentPage(page);
    },
    [getLiquidityList]
  );

  const getPairDetails = async (item: any) => {
    const getTotalSupply: any = await dispatch(
      callContractGetMethod("totalSupply", [], "dynamicPair", false, item?.pair)
    );
    const getToken0Balance: any = await dispatch(
      callContractGetMethod(
        "balanceOf",
        [item?.pair],
        "dynamicPair",
        false,
        item?.address0
      )
    );
    const getToken1Balance: any = await dispatch(
      callContractGetMethod(
        "balanceOf",
        [item?.pair],
        "dynamicPair",
        false,
        item?.address1
      )
    );

    const userLPBalance: any = await dispatch(
      callContractGetMethod(
        "balanceOf",
        [walletAddress],
        "dynamicPair",
        false,
        item?.pair
      )
    );
    if (userLPBalance == 0) {
      const result: any = await dispatch(
        callApiPostMethod(
          `REMOVE_LIQUIDITY`,
          {
            chainType: defaultNetworks?.chainType,
            pair: item?.pair,
            userAddress: walletAddress,
          },
          null,
          false
        )
      );
    }
    let _token0UserBalance = CommonService.divideBigNumber(
      CommonService.convertWithDecimal(getToken0Balance, userLPBalance),
      getTotalSupply
    );
    let _token1UserBalance = CommonService.divideBigNumber(
      CommonService.convertWithDecimal(getToken1Balance, userLPBalance),
      getTotalSupply
    );
    if (liquidityList != undefined) {
      let index = liquidityList?.findIndex((x: any) => x.pair === item?.pair);

      liquidityList[index]._token0UserBalance = _token0UserBalance;
      liquidityList[index]._token1UserBalance = _token1UserBalance;
      liquidityList[index].reserve0 = getToken0Balance;
      liquidityList[index].reserve1 = getToken1Balance;
      liquidityList[index].lpBalance = userLPBalance;
      liquidityList[index].getTotalSupply = getTotalSupply;
      if (item?.address0 == defaultNetworks?.wethAddress) {
        liquidityList[index].nativeCurrencyTokenA = defaultNetworks?.symbol;
      }
      if (item?.address1 == defaultNetworks?.wethAddress) {
        liquidityList[index].nativeCurrencyTokenB = defaultNetworks?.symbol;
      }
      await setLoaded(false);
      await setLiquidityList(liquidityList);

      setTimeout(function () {
        setLoaded(true);
      }, 10);
    }
  };

  return (
    <>
      {liquidityList?.length == 0 ? (
        <>
          <div className="my-auto AddLiqui">
            <div className="SwapCardNoLiquidityFound">
              <h4>No Liquidity Found</h4>
              <div className="Lottie">
                <Lottie
                  animationData={EmptyLottie}
                  loop={true}
                  style={{ width: 192 }}
                />
              </div>
            </div>
            <>
              <div className="SwapCardNoLiquidityFound">
                <CommonBtn
                  title="FIND OTHER LP TOKENS"
                  className="bordred-yellow"
                  // onClick={() => {
                  //     caseClick("ImportPool");
                  // }}
                  onClick={onClickImportPool}
                  type="button"
                />
                <span>Don't see a pair you joined?</span>
              </div>
            </>
          </div>
        </>
      ) : (
        <>
          <div className="SwapCardImportPool">
            <Accordion className="SwapCardImportPoolAccordion">
              {liquidityList?.length > 0 && loaded
                ? liquidityList?.map((item: any, index: any) => (
                    <Accordion.Item eventKey={index} key={index}>
                      <Accordion.Header>
                        <div className="left">
                          <img src={item?.icon0} alt="" className="pairOne" />
                          <img src={item?.icon1} alt="" className="pairTwo" />
                          <span>
                            {item?.nativeCurrencyTokenA
                              ? item?.nativeCurrencyTokenA
                              : item?.name0
                              ? item?.name0
                              : "--"}{" "}
                            /{" "}
                            {item?.nativeCurrencyTokenB
                              ? item?.nativeCurrencyTokenB
                              : item?.name1
                              ? item?.name1
                              : "--"}
                          </span>
                        </div>
                        <div className="right">
                          <span>
                            Your LP :{" "}
                            <span>
                              {item?.lpBalance && item?.lpDecimals ? (
                                <>
                                  {" "}
                                  {CommonService.cryptoDecimals(
                                    CommonService.toFixed(
                                      CommonService.divideBigNumber(
                                        item?.lpBalance,
                                        item?.lpDecimals
                                      )
                                    )
                                  )}
                                </>
                              ) : (
                                "0"
                              )}
                            </span>
                            {item?.lpBalance && item?.lpDecimals ? (
                              <>
                                <CustomOverlay
                                  value={CommonService.toFixed(
                                    CommonService.divideBigNumber(
                                      item?.lpBalance,
                                      item?.lpDecimals
                                    )
                                  )}
                                />
                              </>
                            ) : (
                              "0"
                            )}
                          </span>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body>
                        <ul>
                          <li>
                            <div className="left">
                              <img
                                src={item?.icon0}
                                alt=""
                                className="pairOne"
                              />{" "}
                              <p>
                                {item?.nativeCurrencyTokenA
                                  ? item?.nativeCurrencyTokenA
                                  : item?.name0
                                  ? item?.name0
                                  : "--"}{" "}
                              </p>
                            </div>
                            <div className="right">
                              <span>
                                {item?._token0UserBalance ? (
                                  <>
                                    {CommonService.cryptoDecimals(
                                      CommonService.toFixed(
                                        CommonService.divideBigNumber(
                                          item?._token0UserBalance,
                                          item?.decimals0
                                        )
                                      )
                                    )}
                                    <CustomOverlay
                                      value={CommonService.toFixed(
                                        CommonService.divideBigNumber(
                                          item?._token0UserBalance,
                                          item?.decimals0
                                        )
                                      )}
                                    />
                                  </>
                                ) : (
                                  "0"
                                )}
                              </span>
                            </div>
                          </li>
                          <li>
                            <div className="left">
                              <img
                                src={item?.icon1}
                                alt=""
                                className="pairOne"
                              />{" "}
                              <p>
                                {item?.nativeCurrencyTokenB
                                  ? item?.nativeCurrencyTokenB
                                  : item?.name1
                                  ? item?.name1
                                  : "--"}
                              </p>
                            </div>
                            <div className="right">
                              <span>
                                {item?._token1UserBalance ? (
                                  <>
                                    {CommonService.cryptoDecimals(
                                      CommonService.toFixed(
                                        CommonService.divideBigNumber(
                                          item?._token1UserBalance,
                                          item?.decimals1
                                        )
                                      )
                                    )}
                                    <CustomOverlay
                                      value={CommonService.toFixed(
                                        CommonService.divideBigNumber(
                                          item?._token1UserBalance,
                                          item?.decimals1
                                        )
                                      )}
                                    />
                                  </>
                                ) : (
                                  "0"
                                )}
                              </span>
                            </div>
                          </li>
                        </ul>
                        <CommonBtn
                          title="Remove"
                          className="bordred-green w-100 mt-4"
                          onClick={() => {
                            setLiquidityData(item);
                            handleshowToken();
                          }}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  ))
                : ""}
            </Accordion>
          </div>
        </>
      )}
      <CommonBtn
        title="ADD LIQUIDITY"
        className=" mainBtn"
        onClick={onClickLiquidity}
        type="button"
      />
      {/* <div className="pagination-wrapper">
                {totalData > LIMIT ?
                    <Paginations
                        totalRecords={totalData}
                        pageLimit={LIMIT}
                        pageNeighbours={2}
                        onPageChanged={onPageChanged}
                        currentPage={currentPage}
                    />
                    :
                    ""
                }
            </div> */}
      <RemoveToken
        showToken={showToken}
        tokenClose={closeToken}
        deadline={deadline}
        liquidityData={liquidityData}
        slipPage={slipPage}
        getLiquidityList={getLiquidityList}
      />
    </>
  );
};
