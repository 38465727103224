import { Col, Row } from "react-bootstrap";
import CommonBtn from "../../CommonBtn/CommonBtn";
import CommonModal from "../CommonModal/CommonModal";

const CommonConfirmationModal = ({ show, onHide, text, callback }: any) => {

    return (
        <>
            <CommonModal show={show} onHide={onHide} modalTitle="Delete Token" className="DeleteToken">
                <h3 className="py-5 my-4">
                    {text}
                </h3>
                <Row>
                    <Col><CommonBtn title="Cancel" className=" w-100" onClick={onHide} /></Col>
                    <Col><CommonBtn title="Confirm" className=" w-100" onClick={callback} /></Col>
                </Row>
            </CommonModal>
        </>
    )
}

export default CommonConfirmationModal