import {
  NETWORKS, WALLET_TYPE
} from "../../constant";
import Web3 from "web3";
import Toast from "../../component/common/Toast";
import { addUserDetails, adminStatus, removeUserDetails, saveNetworkDetails } from "../../features/connect.slice";
import { AppDispatch } from "../store";

export const isMetaMaskInstalled = async () => {
  const { ethereum } = window as any;
  const result = await Boolean(ethereum);
  return result;
};

export const isMetamaskConnected =()=>{
  const { ethereum } = window as any;
    const getAccount = async () =>  await ethereum.request({method: 'eth_accounts'})[0] || false;
    if(getAccount.length > 0){
      return true;
    }else{
      return false;
    }
}

export const connectmetamask = () => {
  return (dispatch: AppDispatch, getState: any) =>
    new Promise(async (resolve, reject) => {
      const installed = await isMetaMaskInstalled();
      try {
        let { chainHex } = getState()?.connect?.networksDetails as any; // hexId
        let address;
        if (installed) {
          const { ethereum } = window as any;
          const chainId = await ethereum.request({ method: "eth_chainId" }); // returns hex Id

          if (chainHex !== chainId) {
            let res = await approveNetwork(NETWORKS[0]);
            if (res) {
              const accounts = await ethereum.request({
                method: "eth_requestAccounts",
              });

              address = accounts[0];
              resolve(address);
              dispatch(addUserDetails({ walletAddress: address, walletType: WALLET_TYPE.METAMASK }));
              return await dispatch(saveNetworkDetails({ networksDetails: NETWORKS[0] }));
            } else {
              return false;
            }
          }

          let validNetwork = true;
          if (validNetwork) {

            // ethereum.on("accountsChanged", async function (accounts: string[]) {
            //   dispatch(removeUserDetails());
            //   address = accounts[0];
            //   setTimeout(function () {
            //     window.location.reload();
            //   }, 1000);

            //   dispatch(
            //     addUserDetails({
            //       walletAddress: address, walletType: WALLET_TYPE.METAMASK
            //     })
            //   );
            // });

            // ethereum.on("chainChanged", async function (networkId: number) {
            //   let values: any = NETWORKS.filter((items: any) => {
            //     return items?.chainHex == networkId
            //   });

            //   if (values && values.length > 0) {
            //     await dispatch(saveNetworkDetails({ networksDetails: values[0] }));
            //   } else {
            //     dispatch(removeUserDetails());
            //     await dispatch(saveNetworkDetails({ networksDetails: null }));
            //   }
            //   setTimeout(function () {
            //     window.location.reload();
            //   }, 1000);
            // });

            let accounts: any;
            accounts = await ethereum.request({
              method: "eth_requestAccounts",
            })

            address = accounts[0];
            resolve(address);
            return dispatch(addUserDetails({ walletAddress: address, walletType: WALLET_TYPE.METAMASK }));
          } else {
            reject(false);
          }
        } else {
          reject(false);
          return Toast.error("Please install metamask");
        }
      } catch (error: any) {
        reject(false);
        return Toast.error(error.message);
      }
    });
};



//-------------************ trustwallet connectivity in the following -----------------*****//
export const connectTrustWallet = async (dispatch: AppDispatch) => {

  try {
    const { ethereum, web3, BinanceChain } = window as any;
    const result = Boolean((ethereum && ethereum.isMetaMask) || BinanceChain);
    if (!result) {
      const web3 = await new Web3(ethereum);
      const accounts = await web3.eth.getAccounts();
      let address = accounts[0];
      return dispatch(addUserDetails({ walletAddress: address, walletType: WALLET_TYPE.TRUST_WALLET }));

    } else {
      return Toast.error("Trust Wallet Not installed!");
    }
  } catch (error: any) {
    return Toast.error(error.message);
  }
};

export const connectWallet = () => async (dispatch: AppDispatch) => {

  // try {
  //   let address;
  //   const ethereumClient = new EthereumClient(_wagmiClient, chains);
  //   address = ethereumClient?.getAccount()?.address;

  //   await dispatch(addUserDetails({ walletAddress: address, walletType: WALLET_TYPE.WALLET_CONNECT }));
  //   return { ethereumClient }

  // } catch (error) {
  //   return error;

  // }
};

export const approveNetwork = async (defaultNetwork: any) => {
  return new Promise(async (resolve, reject) => {

    try {
  if(defaultNetwork== null){
    resolve(false);
  }
      const { ethereum } = window as any;
      let checkIsMetamaskInstalled = await isMetaMaskInstalled();
      if (checkIsMetamaskInstalled == false) {
        resolve(false);
      } else {
        await ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: (defaultNetwork.chainHex) }],
          }).then((result: any) => {
            resolve(true);
          });
      }



    } catch (switchError: any) {
      if(defaultNetwork== null){
        resolve(false);
      }
      let checkIsMetamaskInstalled = await isMetaMaskInstalled();
      if (checkIsMetamaskInstalled == false) {
        resolve(false);
      } else {
        if (switchError.code === 4902) {
          try {
            const { ethereum } = window as any;
            const result: any = await ethereum
              .request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: defaultNetwork?.chainHex,
                    chainName: defaultNetwork?.networkName,
                    rpcUrls: [defaultNetwork?.rpc],
                    blockExplorerUrls: [defaultNetwork?.explorerUrl],
                    iconUrls: [defaultNetwork?.icon],
                    nativeCurrency: {
                      name: defaultNetwork?.name,
                      symbol: defaultNetwork?.symbol,
                      decimals: defaultNetwork?.decimals,
                    },
                  },
                ],
              })
              .then((result: any) => {
                resolve(true);
              });
          } catch (addError: any) {
            Toast.error(addError.message);
            resolve(false);
          }
        } else {
          Toast.error(switchError.message);
          resolve(false);
        }
      }
    }
  });
};

export const disconnectWallet =
  () => async (dispatch: AppDispatch, getState: any) => {
    try {
      dispatch(
        adminStatus({ adminStatus: false })
      );
      dispatch(
        addUserDetails({ walletType: "", walletAddress: "", isAdmin: false })
      );
    } catch (error: any) {
      return Toast.error(error.message);
    }
  };
