import { Dispatch, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addTokenInfo } from "../../../../features/customTokenInfo.slice";
import CommonBtn from "../../CommonBtn/CommonBtn";
import ImportConfirmation from "./ImportConfirmation";
import "./ImportedTokens.scss";
const ImportedTokens = ({
  onClick,
  tokenList,
  callback,
  isTokenExist
}: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const localTokenList = useSelector((state: any) => state.token.tokenInfo);

  const [TokensToView, setTokensToView] = useState(true);
  const TokenHandle = () => {
    setTokensToView(false);
    onClick();
  };

  //handler to import token locally in redux
  const handlerToImportToken = async () => {
    if (localTokenList && localTokenList?.length > 0) {
      tokenList.new = false;
      tokenList.isCreatedByUser = true;
      let _tokenList: any = localTokenList?.filter(
        (item: any) => item?.address == tokenList?.address
      );
      if (_tokenList.length > 0) {
        callback();
      } else {
        dispatch(addTokenInfo(tokenList));
        callback();
      }
    } else {
      tokenList.new = false;
      tokenList.isCreatedByUser = true;
      dispatch(addTokenInfo(tokenList));
      callback();
    }
  };

  return (
    <>
      {TokensToView ? (
        <>
          {/* <SearchToken/> */}
          <p className="pt-4">Tokens from results</p>
          <ul className="SelectTokenModalTokenList Imported pt-0">
            {tokenList && (
              <li>
                <div className="custom">
                  <div className="left">
                    <div className="thumb">
                      <img src={tokenList?.icon} alt="" />
                    </div>
                    <div className="content">
                      <h5>{tokenList?.name}</h5>
                      <span>
                        {isTokenExist ? "Added by user * " : null}
                        {tokenList?.symbol}
                      </span>
                    </div>
                  </div>
                  <div className="right">
                    <CommonBtn
                      title="import"
                      disabled={isTokenExist}
                      className=" btn-small"
                      onClick={TokenHandle}
                    />
                  </div>
                </div>
              </li>
            )}
          </ul>
        </>
      ) : (
        <>
          <ImportConfirmation
            tokenDetails={tokenList}
            callback={handlerToImportToken}
          />
        </>
      )}
    </>
  );
};
export default ImportedTokens;
