import React from 'react'
import { Container } from 'react-bootstrap'
import CommonBtn from '../../../component/ui/CommonBtn/CommonBtn';
import './FarmsBanner.scss';

const FarmsBanner = () => {
    return (
        <section className='farms_banner'>
            <Container>
                <div className="farms_banner_inner">
                    <div className="farms_banner_text">
                        <h2>Farms</h2>
                        <p>Trade, earn, and win crypto on the most popular decentralized platform in the galaxy</p>
                    </div>
                    <div className="farms_banner_actions">
                        <h4>Yield Booster</h4>
                        <p>No Swapit Locked</p>
                        <CommonBtn className='bgdark w-100' role='link' to='/pools/' type="button" title='GO TO POOL' />
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default FarmsBanner
