import { createSlice } from "@reduxjs/toolkit";
import { ISwapLiquidity } from "../interfaces/swapAndLiquidity.interface";

const initialState: ISwapLiquidity = {
  customSlippage: null,
  swapCustomSlippage:null,
  txnDeadline: null,
  swapHistory: [],
  liquidityHistory: []
};
const swapAndLiquiditySlice = createSlice({
  name: "swapAndLiquidity",
  initialState,
  reducers: {

    addDeadline(state, actions) {
      state.txnDeadline = actions.payload.txnDeadline;
    },
    addCustomSlippage(state, actions) {
      state.customSlippage = actions.payload.customSlippage;
    },
    addSwapCustomSlippage(state, actions) {
      state.swapCustomSlippage = actions.payload.swapCustomSlippage;
    },

    swapHistory(state: any, actions: any) {
      let record_length: number = state.swapHistory.length;
      if (record_length >= 10) {
        state.swapHistory.shift();
        state.swapHistory.push(actions.payload);
      } else {
        state.swapHistory.push(actions.payload);
      }
    },

    liquidityHistory(state: any, actions: any) {
      let record_length: number = state.liquidityHistory.length;
      if (record_length >= 10) {
        state.liquidityHistory.shift();
        state.liquidityHistory.push(actions.payload);
      } else {
        state.liquidityHistory.push(actions.payload);
      }
    }

  },
});

export const { addDeadline, addCustomSlippage, addSwapCustomSlippage, swapHistory, liquidityHistory } = swapAndLiquiditySlice.actions;
export default swapAndLiquiditySlice.reducer;
