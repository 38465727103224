import { ReactNode } from "react";
import { Link } from "react-router-dom";
import "./CommonBtn.scss";
type propTypes = {
  title?: string | ReactNode,
  className?: string,
  icon?: string | ReactNode,
  onClick?: any,
  to?: any,
  role?: "link",
  type?: "button" | "reset" | "submit",
  disabled?: boolean,

}
const CommonBtn = ({ title, className, icon, onClick, to, role, type, disabled }: propTypes) => {

  return (
    <>
      {(() => {
        switch (role) {
          case "link":
            return (
              <Link
                to={to}
                className={`commonBtn ${className}`}
                onClick={onClick}
              >
                <>
                  <span className="texttitle">{title}</span>
                </>

                {icon && (
                  <>
                    <span className="iconSpan">{icon}</span>
                  </>
                )}
              </Link>
            );
          default:
            return (
              <button disabled={disabled} type={type} className={`commonBtn ${className}`} onClick={onClick}>
                <>
                  <span className="texttitle">{title}</span>
                </>

                {icon && (
                  <>
                    <span className="iconSpan">{icon}</span>
                  </>
                )}
              </button>
            );
        }
      })()}
    </>
  );
};
export default CommonBtn;
