import { useEffect, useState } from 'react';
import './ToggleSwitch.scss';
const ToggleSwitch = ({ className, userLimit, switchButton, setSwitchButton, poolEditableStatus, toggleButtonStatus }: any) => {
    const [checked, setChecked] = useState<any>(false);
    const handleChange = (val: any) => {
        setChecked(val.target.checked);
        setSwitchButton(val.target.checked);
    }

    useEffect(() => {
        setChecked((switchButton));
    }, [switchButton, poolEditableStatus, userLimit]);

    return (
        <>
            <div className={`switch ${className}`}>
                <label className="switch_label">
                    <input type="checkbox" name='name' checked={checked}
                        onChange={(e: any) => handleChange(e)}
                        disabled={poolEditableStatus == 'disabled' ? true :false}
                    />
                    <span className="switch_slider"></span>
                </label>
            </div>
        </>
    )
}

export default ToggleSwitch