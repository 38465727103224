import Web3 from "web3";
import { SWAPIT_FACTORY_ABI } from "../abi/swapItFactory.abi";
import { SWAPIT_PAIR_ABI } from "../abi/swapItPair.abi";
import { SWAPIT__REFERRAL_ABI } from "../abi/swapItReferral.abi";
import { SWAPIT_ROUTER_ABI } from "../abi/swapItRouter.abi";
import { SWAPIT_MASTERCHEF_ABI } from "../abi/MasterChef.abi";
import { ORACLE_WRAPPER_ABI } from "../abi/OracleWrapper.abi";
import { SWAPIT__SMART_CHEF_ABI } from "../abi/SmarChef.abi";
import { SWAPIT_SMART_FACTORY_CHEF_ABI } from "../abi/SmartChefFactory.abi";
import { TOKEN_ABI } from "../abi/token.abi";

import { store } from "../app/store";
import { NETWORKS, WALLET_TYPE } from "../constant";
import { BSC_SWAPIT_MASTERCHEF_CONTRACT } from "../constant";

// import { provider } from "../walletProvider/WallectProvider";

let web3Instance: any,
  swapItRouterInstance: unknown,
  swapItFactoryInstance: unknown,
  swapItMasterInstance: unknown,
  swapItSmartChefFactoryInstance: unknown,
  swapItPairInstance: unknown,
  swapItSmartChefInstance: unknown,
  swapItReferralInstance: unknown,
  oracleWrapperInstance: unknown;

const BigNumber = require("big-number");

export const callWeb3 = (data: any = "") => {
  let currentNetwork = store.getState().connect?.networksDetails;
  if (!currentNetwork || currentNetwork == null) {
    currentNetwork = NETWORKS[0];
  }
  return new Promise(async (resolve, reject) => {
    try {
      if (data) {
        /**CREATE INSTANCE WITH PROVIDER */
        web3Instance = new Web3(data);
      } else {
        /**CREATE INSTANCE WITH RPC */
        web3Instance = new Web3(currentNetwork?.rpc);
      }

      resolve(web3Instance);
    } catch (error: any) {
      reject(error);
    }
  });
};

export const createInstance = async (data: any = "") => {
  let networkDetails: any = store?.getState().connect?.networksDetails;
  if (!networkDetails || networkDetails == null) {
    networkDetails = NETWORKS[0];
  } else {
    let web3: any = await callWeb3(data);
    if (web3 && web3 != undefined) {
      if (networkDetails && networkDetails != null) {
        swapItRouterInstance = new web3.eth.Contract(
          SWAPIT_ROUTER_ABI,
          networkDetails?.routerAddress
        );
        swapItFactoryInstance = new web3.eth.Contract(
          SWAPIT_FACTORY_ABI,
          networkDetails?.factoryAddress
        );
        swapItPairInstance = new web3.eth.Contract(
          SWAPIT_PAIR_ABI,
          networkDetails?.pairAddress
        );
        swapItMasterInstance = new web3.eth.Contract(
          SWAPIT_MASTERCHEF_ABI,
          networkDetails?.masterChef
        );
        swapItSmartChefFactoryInstance = new web3.eth.Contract(
          SWAPIT_SMART_FACTORY_CHEF_ABI,
          networkDetails?.smartChefFactory
        );
        swapItSmartChefInstance = new web3.eth.Contract(
          SWAPIT__SMART_CHEF_ABI,
          networkDetails?.smartChefFactory
        );
        swapItReferralInstance = new web3.eth.Contract(
          SWAPIT__REFERRAL_ABI,
          networkDetails?.referralContract
        );
        return true;
      }
    }
  }
};

setTimeout(function () {
  createInstance();
}, 1000);

// createInstance();

export const getContractInstance = async (
  type: string,
  dynamicAddress: string,
  provider: any = ""
) => {
  return new Promise(async (resolve, reject) => {
    switch (type) {
      case "router":
        swapItRouterInstance
          ? resolve(swapItRouterInstance)
          : createInstance(provider)
              .then(() => {
                resolve(swapItRouterInstance);
              })
              .catch(reject);
        break;
      case "factory":
        swapItFactoryInstance
          ? resolve(swapItFactoryInstance)
          : createInstance(provider)
              .then(() => {
                resolve(swapItFactoryInstance);
              })
              .catch(reject);
        break;
      case "master":
        swapItMasterInstance
          ? resolve(swapItMasterInstance)
          : createInstance(provider)
              .then(() => {
                resolve(swapItMasterInstance);
              })
              .catch(reject);
        break;
      case "smartChefFactory":
        swapItSmartChefFactoryInstance
          ? resolve(swapItSmartChefFactoryInstance)
          : createInstance(provider)
              .then(() => {
                resolve(swapItSmartChefFactoryInstance);
              })
              .catch(reject);
        break;
      case "pair":
        swapItPairInstance
          ? resolve(swapItPairInstance)
          : createInstance(provider)
              .then(() => {
                resolve(swapItPairInstance);
              })
              .catch(reject);
        break;
      case "smartChef":
        swapItSmartChefInstance
          ? resolve(swapItSmartChefInstance)
          : createInstance(provider)
              .then(() => {
                resolve(swapItSmartChefInstance);
              })
              .catch(reject);
        break;
      case "dynamicSmartChef":
        let swapItDynamicSmartChefInstance = web3Instance
          ? await new web3Instance.eth.Contract(
              SWAPIT__SMART_CHEF_ABI,
              dynamicAddress
            )
          : await createInstance(provider).then(async () => {
              return await new web3Instance.eth.Contract(
                SWAPIT__SMART_CHEF_ABI,
                dynamicAddress
              );
            });
        resolve(swapItDynamicSmartChefInstance);
        break;
      // case "dynamicMasterChef":
      //   let swapItDynamicMasterChefInstance = web3Instance
      //     ? await new web3Instance.eth.Contract(
      //       swapIt_MASTERCHEF_ABI,
      //       dynamicAddress
      //     )
      //     : await createInstance().then(async () => {
      //       return await new web3Instance.eth.Contract(
      //         swapIt__SMART_CHECF_ABI,
      //         dynamicAddress
      //       );
      //     });
      //   resolve(swapItDynamicMasterChefInstance);
      //   break;
      case "dynamicPair":
        let swapItDynamicPairInstance = web3Instance
          ? await new web3Instance.eth.Contract(SWAPIT_PAIR_ABI, dynamicAddress)
          : await createInstance(provider).then(async () => {
              return await new web3Instance.eth.Contract(
                SWAPIT_PAIR_ABI,
                dynamicAddress
              );
            });
        resolve(swapItDynamicPairInstance);
        break;
      case "dynamic":
        let dynamicTokenInstance = web3Instance
          ? await new web3Instance.eth.Contract(TOKEN_ABI, dynamicAddress)
          : await createInstance(provider).then(async () => {
              return await new web3Instance.eth.Contract(
                TOKEN_ABI,
                dynamicAddress
              );
            });
        resolve(dynamicTokenInstance);
        break;
      case "referral":
        swapItReferralInstance
          ? resolve(swapItReferralInstance)
          : createInstance(provider)
              .then(() => {
                resolve(swapItReferralInstance);
              })
              .catch(reject);
        break;
      case "oracle":
        let oracleWrapperInstance = web3Instance
          ? await new web3Instance.eth.Contract(
              ORACLE_WRAPPER_ABI,
              dynamicAddress
            )
          : await createInstance(provider).then(async () => {
              return await new web3Instance.eth.Contract(
                ORACLE_WRAPPER_ABI,
                dynamicAddress
              );
            });
        resolve(oracleWrapperInstance);
        break;
      default:
        return null;
    }
  });
};

const callGetMethod = async (
  method: string,
  data: any,
  type: string,
  dynamicAddress: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      let contract: any = await getContractInstance(type, dynamicAddress, "");
      if (contract?.methods) {
        contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .call()
          .then((result: any) => {
            resolve(result);
          })
          .catch((error: any) => {
            console.log("error occureed",method, error);
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const callSendMethod = async (
  provider: any,
  method: string,
  data: any,
  walletAddress: string,
  type: string,
  value: any,
  dynamicAddress: string,
  dynamicTokenAddress: string
) => {
  return new Promise(async (resolve, reject) => {
    let networkDetails = store.getState().connect?.networksDetails;
    try {
      if (walletAddress === "") {
        reject(new Error("Please connect wallet"));
      }
      let dataForSend: any = { from: walletAddress };
      if (value) {
        dataForSend.value = value;
      }
      let contract: any = await getContractInstance(
        type,
        dynamicAddress,
        provider
      );
      if (method === "addLiquidity") {
        let allowanceRes = await giveTokenAllowance({
          provider,
          walletAddress,
          tokenAddress: data[0],
          allowanceValue: data?.fromAmount,
          contract: networkDetails?.routerAddress,
        });
        let allowanceRes1 = await giveTokenAllowance({
          provider,
          walletAddress,
          tokenAddress: data[1],
          allowanceValue: data?.toAmount,
          contract: networkDetails?.routerAddress,
        });

        if (!allowanceRes) {
          return false;
        }
        if (!allowanceRes1) {
          return false;
        }
      } else if (
        method === "deposit" &&
        (type === "master" || type === "dynamicSmartChef")
      ) {
        let allowance = await giveTokenAllowance({
          provider,
          walletAddress,
          tokenAddress:
            type === "master" ? dynamicAddress : dynamicTokenAddress,
          allowanceValue: type === "master" ? data[1] : data[0],
          contract:
            type === "master" ? networkDetails?.masterChef : dynamicAddress,
        });
        if (!allowance) {
          return false;
        }
      }
      if (contract?.methods) {
        const gasLimit = await contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .estimateGas(dataForSend);
        dataForSend.gasLimit = gasLimit;

        let web3: any = await callWeb3(provider);
        dataForSend.gasPrice = await web3.eth.getGasPrice();
        contract.methods[method]
          .apply(null, Array.prototype.slice.call(data))
          .send(dataForSend)
          .then((result: any) => {
            resolve(result);
          })
          .catch((error: any) => {
            reject(error);
          });
      } else {
        reject(new Error("Contract not found."));
      }
    } catch (error) {
      reject(error);
    }
  });
};

const giveTokenAllowance = async (data: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      //dispatch(setLoader(true))
      let allowance: any = await callGetMethod(
        "allowance",
        [data.walletAddress, data.contract],
        "dynamic",
        data.tokenAddress
      );

      if (Number(allowance) <= Number(data.allowanceValue)) {
        let maxlimit = BigNumber(10).power(40);
        maxlimit = maxlimit.toString();
        let allowanceRes: any = await callSendMethod(
          data?.provider,
          "approve",
          [data.contract, maxlimit],
          data.walletAddress,
          "dynamic",
          null,
          data.tokenAddress,
          ""
        );
        if (!(allowanceRes && allowanceRes.status)) {
          let allowance: any = await callGetMethod(
            "allowance",
            [data.walletAddress, data.contract],
            "dynamic",
            data.tokenAddress
          );

          return false;
        }
      }

      resolve(allowance);
    } catch (error) {
      reject(error);
    }
  });
};

export const ContractService = {
  createInstance,
  giveTokenAllowance,
  callGetMethod,
  callSendMethod,
};
