import { Dispatch, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApiGetMethod } from '../../../../../app/actions/api.action';
import { callContractGetMethod } from '../../../../../app/actions/contract.action';
import { setLoader } from '../../../../../app/actions/loader.action';
import { RootState } from '../../../../../app/store';
import Paginations from '../../../../../component/common/Pagination/Pagination';
import CommonBtn from "../../../../../component/ui/CommonBtn/CommonBtn";
import CommonTable from "../../../../../component/ui/CommonTable/CommonTable";
import { LIMIT } from '../../../../../constant';
import { CommonService } from '../../../../../services/commonService';
import NoDataFound from '../../../../NodataFound';
import '../../../Tokens/Tokens.scss';
import './TableCard.scss';
import TableCardModal from './TableCardModal';

const TableCard = ({ type, update_Data, APIStatus, setAPIStatus }: any) => {
    const dispatch: Dispatch<any> = useDispatch();
    const defaultNetworks = useSelector((state: RootState) => state.connect.networksDetails);
    const walletAddress: any = useSelector((state: RootState) => state.connect.walletAddress);
    const [totalData, setTotalData] = useState(0);
    const [tokenListData, setTokenListData] = useState<any>([]);
    const [stakeListData, setStakeListData] = useState<any>([]);
    const [poolTableData, setPoolTableData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        if (walletAddress) {
            if (APIStatus) {
                setTimeout(() => {
                    getFarmAndPoolTokens();
                }, 3000);
            } else {
                getFarmAndPoolTokens();
            }

            setTokenListData([]);
        }
    }, [type, APIStatus]);

    const getFarmAndPoolTokens = async (loading: boolean = true, page: number = 1) => {
        dispatch(setLoader(true));
        if (type == "Farm") {
            let tokenList: any = await dispatch(callApiGetMethod('GET_FARM_TOKENS', { page, limit: LIMIT, chainType: defaultNetworks?.chainType, contractType: "farm" }));
            setStakeListData((tokenList?.data?.docs).reverse());
            dispatch(setLoader(false));
            await setTotalData(tokenList?.data?.totalDocs);
        } else {
            let tokenList: any = await dispatch(callApiGetMethod('GET_POOL_TOKEN', { page, limit: LIMIT, chainType: defaultNetworks?.chainType, contractType: "pool" }));
            if (type == "Pool" && tokenList && tokenList?.data?.docs) {
                let poolContractData = [];
                let listData = tokenList?.data?.docs;
                for (let i = 0; i < listData.length; i++) {
                    let stakedTokenSymbol: any = await dispatch(callContractGetMethod("symbol", [], "dynamic", false, listData[0]?.addressStaked));
                    let rewardTokenSymbol: any = await dispatch(callContractGetMethod("symbol", [], "dynamic", false, listData[0]?.addressReward));
                    poolContractData.push({ stakedTokenSymbol, rewardTokenSymbol });
                    setPoolTableData(poolContractData);
                }
            }
            dispatch(setLoader(false));
            setTokenListData((tokenList?.data?.docs));
            await setTotalData(tokenList?.data?.totalDocs);
        }
    }

    const onPageChanged = useCallback(
        (event: any, page: number) => {
            event.preventDefault();
            getFarmAndPoolTokens(false, page);
            setCurrentPage(page)
        },
        [getFarmAndPoolTokens]
    );

    return (
        <>
            <div className="TableCard">
                <CommonTable className="tokensTable">
                    {type == "Pool" ? (
                        <>
                            <thead>
                                <tr>
                                    <th>Sr. No</th>
                                    <th>Pool Address</th>
                                    <th>Staked Token</th>
                                    <th>Reward Token</th>
                                    <th>Transfer</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tokenListData && tokenListData.length > 0 ? tokenListData?.map((item: any, index: any) => (
                                    <>
                                        <tr key={index}>
                                            <td>{index + 1 + (currentPage - 1) * LIMIT}</td>
                                            <td>
                                                {item?.poolAddress ? <span className="copy-link" title="Copy" onClick={() => CommonService.copyToClipboard(item?.poolAddress)}>{CommonService.custmizeAddress(item?.poolAddress)} <i className="fa fa-copy mx-1" aria-hidden="true"></i></span> : "--"}
                                            </td>
                                            <td>{item?.symbolStaked}</td>
                                            <td>{item?.symbolReward}</td>
                                            <td>
                                                <TableCardModal
                                                    type="Transfer Reward"
                                                    index={index}
                                                    item={item}
                                                    APIStatus={APIStatus}
                                                    setAPIStatus={setAPIStatus}
                                                />
                                            </td>
                                            <td>
                                                <CommonBtn title="Edit" className=" btn-small ms-3" onClick={() => update_Data({ stakedTokenSymbol: poolTableData[index]?.stakedTokenSymbol, rewardTokenSymbol: poolTableData[index]?.rewardTokenSymbol, rewardToken: item?.addressReward, poolAddress: item?.poolAddress, stakedToken: item?.addressStaked })} /></td>
                                        </tr>
                                    </>
                                )) : (
                                    <tr> <td colSpan={8}>  <NoDataFound /></td></tr>
                                )}
                            </tbody>
                        </>
                    ) : (
                        <>
                            <thead>
                                <tr>
                                    <th>Pid</th>
                                    <th>Allocation Point</th>
                                    <th>Deposit Fee(%)</th>
                                    <th>Harvest(Hrs)</th>
                                    <th>Pair</th>
                                    <th>LP Token</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stakeListData?.length > 0 ? stakeListData?.map((item: any, index: any) => (
                                    <tr key={index}>
                                        <td>{item?.pid} </td>
                                        <td>{item?.allocPoint}</td>
                                        <td>{(item?.depositFeeBP) / 100}</td>
                                        <td>{(item?.harvestInterval) / (60 * 60)}</td>
                                        <td>{item?.symbol0 == 'WETH' ? defaultNetworks?.networkName == 'BSC Testnet' ? 'BNB' : 'ETH' : item?.symbol0}/{item?.symbol1 == 'WETH' ? defaultNetworks?.networkName == 'BSC Testnet' ? 'BNB' : 'ETH' : item?.symbol1}</td>
                                        <td>
                                            {item?.lpToken ? <span className="copy-link" title="Copy" onClick={() => CommonService.copyToClipboard(item?.lpToken)}>{CommonService.custmizeAddress(item?.lpToken)} <i className="fa fa-copy mx-1" aria-hidden="true"></i></span> : "--"}
                                        </td>
                                        <td><CommonBtn title="Edit" className=" btn-small" onClick={() => update_Data(item)} /></td>
                                    </tr>
                                )) : (
                                    <tr> <td colSpan={8}>  <NoDataFound /></td></tr>
                                )}
                            </tbody>
                        </>
                    )}

                </CommonTable>
                <div className="pagination-wrapper">
                    {totalData > LIMIT ?
                        <Paginations
                            totalRecords={totalData}
                            pageLimit={LIMIT}
                            pageNeighbours={2}
                            onPageChanged={onPageChanged}
                            currentPage={currentPage}
                        />
                        :
                        ""
                    }
                </div>

            </div>

        </>
    )
}

export default TableCard