import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import { persistor, store } from "./app/store";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { InjectedConnector } from "@wagmi/core";
import { walletConnectProvider } from "@web3modal/wagmi";
import { EIP6963Connector, createWeb3Modal } from "@web3modal/wagmi/react";
import { WagmiConfig, configureChains, createConfig } from "wagmi";
import {
  bsc,
  bscTestnet,
  sepolia,
  arbitrum,
  mainnet,
  arbitrumSepolia,
} from "wagmi/chains";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { publicProvider } from "wagmi/providers/public";
import { PROJECT_ID } from "./constant";
import Logo from "../src/assets/images/logo.png";

console.log('bscTestnet', bscTestnet,bsc)

const projectId = PROJECT_ID;
const tBsc = { ...bscTestnet, name: "BSC" };
const eth = { ...sepolia, name: "ETH" };
const arb = { ...arbitrumSepolia, name: "ARB" };
console.log("tBsc,bscTestnet", tBsc, bscTestnet);
const { chains, publicClient } = configureChains(
  [tBsc, eth, arb],
  [walletConnectProvider({ projectId }), publicProvider()]
);

const metadata = {
  name: "SwapIT",
  description: "SwapIT with Web3Modal v3 + Wagmi",
  url: "https://swap-it.ai/",
  icons: [Logo],
};

// const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })
export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: [
    new WalletConnectConnector({
      chains,
      options: { projectId, showQrModal: false, metadata },
    }),
    new EIP6963Connector({ chains }) as any,
    new InjectedConnector({ chains, options: { shimDisconnect: true } }),
    new CoinbaseWalletConnector({
      chains,
      options: { appName: metadata.name },
    }),
  ],
  publicClient,
});

createWeb3Modal({
  wagmiConfig,
  projectId,
  chains,
  defaultChain: chains[0],

  themeVariables: {
    // '--w3m-accent': '#000',
    "--w3m-color-mix": "#051b37",
    "--w3m-color-mix-strength": 10,
    "--w3m-font-family": '"Open Sans", sans-serif',
    "--w3m-border-radius-master": "2px",
    "--w3m-font-size-master": "14px",
  },
}) as any;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <WagmiConfig config={wagmiConfig}>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </WagmiConfig>
);

reportWebVitals();
