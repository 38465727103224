import PerfectScrollbar from "react-perfect-scrollbar";
import { Dispatch, memo, useEffect, useState } from "react";
import { Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { callContractGetMethod } from "../../../app/actions/contract.action";
import { RootState } from "../../../app/store";
import NoDataFound from "../../NodataFound";
import FarmsTableCard from "../FarmsTableCard/FarmsTableCard";
import LiquidityCard from "../../Liquidity/LiquidityCard/LiquidityCard";
import queryString from "query-string";
const FarmsPageBody = ({ data, listView }: any) => {
  const navigate = useNavigate();
  const networkDetails = useSelector(
    (state: RootState) => state.connect.networksDetails
  );
  const [closeAll, setCloseAll] = useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  const [swapItPerBlock, setSwapItPerBlock] = useState<any>(0);
  const [rewardTokenSymbol, setRewardTokenSymbol] = useState<any>("");
  const [rewardToken, setRewardToken] = useState<any>("");

  const checkName = (name: string) => {
    if (networkDetails) {
      if (name.toLocaleLowerCase() === "weth") {
        name = networkDetails?.chainType === "BSC" ? "BNB" : "ETH";
      }
      return name;
    }
  };
  const redirectToLiquidity = (data: any) => {
    let params = queryString.parse(window.location.search as any);
    let url;
    if (params.ref) {
      url = `/liquidity?ref=${params?.ref}`;
    } else {
      url = "/liquidity";
    }
    navigate(url, { state: data });
  };

  const getDetails = async () => {
    let swapItPerBlock: any = await dispatch(
      callContractGetMethod("SwapITPerBlock", [], "master")
    );
    if (swapItPerBlock != undefined) {
      setSwapItPerBlock(swapItPerBlock);
    }

    let rewardToken: any = await dispatch(
      callContractGetMethod("SwapIT", [], "master", false)
    );
    if (rewardToken != undefined) {
      setRewardToken(rewardToken);
    }
    let rewardTokenSymbol: any = await dispatch(
      callContractGetMethod("symbol", [], "dynamic", false, rewardToken)
    );
    if (rewardTokenSymbol != undefined) {
      setRewardTokenSymbol(rewardTokenSymbol);
    }
  };

  useEffect(() => {
    if (
      networkDetails &&
      networkDetails != undefined &&
      networkDetails != null
    ) {
      setTimeout(() => getDetails(), 100);
    }
  }, [networkDetails]);

  return data.length ? (
    listView ? (
      <>
        <PerfectScrollbar>
          <Table className="farms_content_outer_table">
            <tbody>
              {data.map((item: any, index: any) => (
                <>
                  <FarmsTableCard
                    key={index}
                    pid={item.pid}
                    tokenAddressA={item.address0}
                    tokenAddressB={item.address1}
                    lpToken={item.lpToken}
                    tokenDecimalA={item.decimals0}
                    tokenDecimalB={item.decimals1}
                    name={checkName(item.name0) + " / " + checkName(item.name1)}
                    multiplier={item.multiplier}
                    earned={"0"}
                    aprOld={"0"}
                    aprNew={"0"}
                    yieldBooster={"0"}
                    totalLiquidity={"0"}
                    enabled={item.enabled}
                    icons={[item.icon0, item.icon1]}
                    allocPoint={item.allocPoint}
                    lpTokenDecimals={item.lpTokenDecimals}
                    listView={listView}
                    closeAll={closeAll}
                    setCloseAll={setCloseAll}
                    swapItPerBlock={swapItPerBlock}
                    rewardToken={rewardToken}
                    rewardTokenSymbol={rewardTokenSymbol}
                    onClickLiquidity={redirectToLiquidity}
                  />
                  <tr className="empty_row"></tr>
                </>
              ))}
            </tbody>
          </Table>
        </PerfectScrollbar>
      </>
    ) : (
      <>
        <Row>
          {data.map((item: any, index: any) => (
            <>
              <FarmsTableCard
                key={index}
                pid={item.pid}
                tokenAddressA={item.address0}
                tokenAddressB={item.address1}
                lpToken={item.lpToken}
                tokenDecimalA={item.decimals0}
                tokenDecimalB={item.decimals1}
                name={checkName(item.name0) + " / " + checkName(item.name1)}
                multiplier={item.multiplier}
                earned={"0"}
                aprOld={"0"}
                aprNew={"0"}
                yieldBooster={"0"}
                totalLiquidity={"0"}
                enabled={item.enabled}
                icons={[item.icon0, item.icon1]}
                allocPoint={item.allocPoint}
                lpTokenDecimals={item.lpTokenDecimals}
                listView={listView}
                closeAll={closeAll}
                setCloseAll={setCloseAll}
                swapItPerBlock={swapItPerBlock}
                rewardToken={rewardToken}
                rewardTokenSymbol={rewardTokenSymbol}
                onClickLiquidity={redirectToLiquidity}
              />
            </>
          ))}
        </Row>
      </>
    )
  ) : (
    <Table>
      <tr>
        {" "}
        <td colSpan={8} className="no_record_found">
          {" "}
          <NoDataFound />
        </td>
      </tr>
    </Table>
  );
};

export default memo(FarmsPageBody);
