import { ReactNode } from "react";
import { Modal } from "react-bootstrap";
import "./CommonModal.scss";

type propTypes = {
  children?: ReactNode,
  show?: boolean,
  onHide?: () => void,
  modalTitle?: ReactNode | string,
  subTitle?: string | ReactNode,
  className?: string,
  centered?: boolean,
  backdrop?: any,
  keyboard?: boolean,
  rewardPoolBalance?: any,
  rewardEarned?: any,
  staked?: any
}

const CommonModal = (props: propTypes) => {
  const { children, centered, show, onHide, modalTitle, subTitle, className, backdrop, keyboard, rewardPoolBalance, rewardEarned, staked } = props;
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        className={`commonModal ${className}`}
        centered={centered}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {children}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CommonModal;
