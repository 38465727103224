import moment from "moment";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Web3 from "web3";
import {
  callApiGetMethod,
  callApiPostMethod,
} from "../../../app/actions/api.action";
import { RootState } from "../../../app/store";
import Paginations from "../../../component/common/Pagination/Pagination";
import Toast from "../../../component/common/Toast";
import CommonBtn from "../../../component/ui/CommonBtn/CommonBtn";
import CommonTable from "../../../component/ui/CommonTable/CommonTable";
import AddTokenModal from "../../../component/ui/Modals/AddTokenModal/AddTokenModal";
import CommonConfirmationModal from "../../../component/ui/Modals/CommonConfirmationModal/CommonConfirmationModal";
import { LIMIT } from "../../../constant";
import { CommonService } from "../../../services/commonService";
import NoDataFound from "../../NodataFound";
import "./Tokens.scss";

const Tokens = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [showLogout, setShowLogout] = useState(false);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [showDelete, setshowDelete] = useState(false);
  const ShowDelete = () => setshowDelete(true);
  const handleDelte = () => setshowDelete(false);
  const [tokenList, setTokenList] = useState<any>([]);
  const [totalData, setTotalData] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [tokenDataForDelete, setTokenDataForDelete] = useState<any>("");

  const defaultNetworks = useSelector(
    (state: RootState) => state.connect.networksDetails
  );
  const addToken = async (tokenAddress: any) => {
    if (Web3.utils.isAddress(tokenAddress)) {
      const res: any = await dispatch(
        callApiPostMethod("ADD_TOKEN", {
          chainType: defaultNetworks?.chainType,
          address: tokenAddress,
        })
      );
      if (res?.success) {
        setShow(false);
        getTokenList();
      }
    } else {
      Toast.error("Please enter valid address");
    }
  };

  useEffect(() => {
    if (defaultNetworks) {
      getTokenList();
    }
  }, [defaultNetworks]);

  const getTokenList = async (loading: boolean = true, page: number = 1) => {
    const tokenList: any = await dispatch(
      callApiGetMethod(
        "GET_ADMIN_TOKEN",
        {
          page,
          limit: LIMIT,
          chainType: defaultNetworks?.chainType,
          contractType: "factory",
        },
        loading
      )
    );
    if (tokenList && tokenList?.data?.docs) {
      setTokenList(tokenList?.data?.docs);
      await setTotalData(tokenList?.data?.totalDocs);
    }
  };
  const onPageChanged = useCallback(
    (event: any, page: number) => {
      event.preventDefault();
      getTokenList(false, page);
      setCurrentPage(page);
    },
    [getTokenList]
  );
  const deleteToken = async () => {
    const res: any = await dispatch(
      callApiPostMethod("DELETE_TOKEN", {
        address: tokenDataForDelete?.address,
        chainType: tokenDataForDelete?.chainType,
      })
    );
    if (res?.success) {
      getTokenList();
      setshowDelete(false);
    }
  };
  return (
    <>
      <section className="tokens">
        <Container fluid>
          <Row className="align-items-center pb-5">
            <Col>
              <h3>Tokens</h3>
            </Col>
            <Col className="text-end">
              <CommonBtn
                title="Add Token"
                className="btn-small "
                onClick={handleShow}
              />
            </Col>
          </Row>
          <CommonTable className="tokensTable">
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Icon</th>

                <th>Name</th>
                <th>Symbol</th>
                <th>Type</th>
                <th>Token Address</th>
                <th>Network</th>
                <th>Created On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tokenList?.length ? (
                tokenList?.map((item: any, index: any) => (
                  <tr key={index} className="set_ico">
                    <td>{index + 1 + (currentPage - 1) * LIMIT}</td>
                    <td>{<img src={item?.icon} />}</td>
                    <td>{item?.name}</td>
                    <td>{item?.symbol}</td>
                    <td>{item?.type}</td>
                    <td>
                      {item?.type == "currency" ? (
                        "--"
                      ) : item?.address ? (
                        <span
                          className="copy-link"
                          title="Copy"
                          onClick={() =>
                            CommonService.copyToClipboard(item?.address)
                          }
                        >
                          {CommonService.custmizeAddress(item?.address)}{" "}
                          <i className="fa fa-copy mx-1" aria-hidden="true"></i>
                        </span>
                      ) : (
                        "--"
                      )}
                    </td>
                    <td>{item?.chainType}</td>
                    <td>
                      {" "}
                      {moment(item?.updatedAt).format("YYYY-MM-DD hh:mm A")}
                    </td>
                    <td>
                      {item?.type == "currency" ? (
                        "--"
                      ) : (
                        <CommonBtn
                          title="Delete"
                          className="bordred-red"
                          onClick={() => {
                            ShowDelete();
                            setTokenDataForDelete(item);
                          }}
                        />
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  {" "}
                  <td colSpan={9}>
                    {" "}
                    <NoDataFound />
                  </td>
                </tr>
              )}
            </tbody>
          </CommonTable>
          <div className="pagination-wrapper">
            {totalData > LIMIT ? (
              <Paginations
                totalRecords={totalData}
                pageLimit={LIMIT}
                pageNeighbours={2}
                onPageChanged={onPageChanged}
                currentPage={currentPage}
              />
            ) : (
              ""
            )}
          </div>
        </Container>
      </section>
      <AddTokenModal show={show} onHide={handleClose} addToken={addToken} />
      <CommonConfirmationModal
        show={showDelete}
        onHide={handleDelte}
        text="Are you sure you want to delete?"
        callback={deleteToken}
      />
    </>
  );
};
export default Tokens;
